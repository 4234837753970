/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Hima Mohan
 */
import httpApi from "./httpApi";

const getMetaTitle = async () => ({
  success: true,
  data: ["[name]", "[name] [description]"],

});

const getMetaKeyWord = async () => ({
  success: true,
  data: ["[name]", "[name] [description]"],
});

const getMetaDesccription = async () => ({
  success: true,
  data: ["[name]", "[name] [description]", "[name] [LongDescription]", "[name] [CatgeoryName]", "[name] [CatgeoryName] [rootCategory]"],
});

const getMetaTagConfiguration = async () => {
  const response = await httpApi.get("/v1/seo-configuration/metatags");
  if (response && response.data
    && response.data.success) {
    return response.data;
  }
  return null;
};

const updateMetaTagConfiguration = async (metaTagConfiguration) => {
  const responsebody = {
    product: {
      pageTitlePattern: metaTagConfiguration.productmetaTitle,
      keywordPattern: metaTagConfiguration.productmetaKeyword,
      descriptionPattern: metaTagConfiguration.productmetaDescription,
    },
    category: {
      pageTitlePattern: metaTagConfiguration.categorymetaTitle,
      keywordPattern: metaTagConfiguration.categorymetaKeyword,
      descriptionPattern: metaTagConfiguration.categorymetaDescription,
    },
  };
  const response = await httpApi.patch("/v1/seo-configuration/metatags", responsebody);
  if (response && response.data
    && response.data.success) {
    return response.data;
  }
  return null;
};

const getUrlConfiguration = async () => {
  const response = await httpApi.get("/v1/seo-configuration/urls");
  if (response && response.data
    && response.data.success) {
    return response.data;
  }
  return null;
};

const updateUrlConfiguration = async (urlConfiguration) => {
  const response = await httpApi.patch("/v1/seo-configuration/urls", urlConfiguration);
  if (response && response.data
    && response.data.success) {
    return response.data;
  }
  return null;
};

/**
 * This service function is used to get site map
 */
const getSiteMap = async () => {
  const response = await httpApi.get("/v1/seo/sitemap");
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This service function is used to get seo infos
 */
const getSeoInfos = async () => {
  const response = await httpApi.get("/v1/seo/seo-info");
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This service function is used to generate/ update site map
 */
const generateSiteMap = async () => {
  const response = await httpApi.post("/v1/seo/sitemap");
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This service function is used to upload robots txt file
 */
const uploadRobotsTxtFile = async (fileData) => {
  const formData = new FormData();
  formData.append("robotsTxt", fileData);
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  const response = await httpApi.post("/v1/seo/robots-txt/upload", formData, config);
  if (response && response.data && response.data.success) {
    return response.data.data;
  }
  return null;
};

/**
* This service function is used to get all vanity urls
*  @param {Number} page
*  @param {Number} size
*  @param {String} searchTerm
*/
const getVanityUrls = async (page = 0, size = 10, searchTerm = "") => {
  const response = await httpApi.get("/v1/seo/vanity-urls", { params: { page, size, searchTerm } });
  if (response && response.data) {
    return response.data;
  }
  return null;
};
/**
 * This service function is used to create a vanity url
*  @param {Object} requestData
 */
const createVanityUrl = async (requestData) => {
  const response = await httpApi.post("/v1/seo/vanity-urls", requestData);
  if (response && response.data) {
    return response.data;
  }
  return null;
};
/**
 * This service function is used to update a vanity url
 *  @param {Object} requestData
 */
const updateVanityUrl = async (requestData) => {
  const response = await httpApi.patch("/v1/seo/vanity-urls", requestData);
  if (response && response.data) {
    return response.data;
  }
  return null;
};
/**
 * This service function is used to delete a vanity url
 * @param {String} id
 */
const deleteVanityUrlById = async (id) => {
  const response = await httpApi.delete(`/v1/seo/vanity-urls/id/${id}`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
* This service function is used to get all page redirects
*  @param {Number} page
*  @param {Number} size
*  @param {String} searchTerm
*/
const getPageRedirects = async (page = 0, size = 10, searchTerm = "") => {
  const response = await httpApi.get("/v1/seo/page-redirects", { params: { page, size, searchTerm } });
  if (response && response.data) {
    return response.data;
  }
  return null;
};


/**
 * This service function is used to create a page redirect
*  @param {Object} requestData
 */
const createPageRedirect = async (requestData) => {
  const response = await httpApi.post("/v1/seo/page-redirects", requestData);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This service function is used to update a page redirect
 *  @param {Object} requestData
 */
const updatePageRedirect = async (requestData) => {
  const response = await httpApi.patch("/v1/seo/page-redirects", requestData);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This service function is used to delete a page redirect
 * @param {String} id
 */
const deletePageRedirectById = async (id) => {
  const response = await httpApi.delete(`/v1/seo/page-redirects/id/${id}`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

export {
  getMetaTitle,
  getMetaKeyWord,
  getMetaDesccription,
  getMetaTagConfiguration,
  updateMetaTagConfiguration,
  getUrlConfiguration,
  updateUrlConfiguration,
  generateSiteMap,
  getSiteMap,
  getSeoInfos,
  uploadRobotsTxtFile,
  getVanityUrls,
  createVanityUrl,
  updateVanityUrl,
  deleteVanityUrlById,
  getPageRedirects,
  createPageRedirect,
  updatePageRedirect,
  deletePageRedirectById,
};
