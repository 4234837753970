/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */

import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ===== TAG COMPONENTS =========== */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Label from "../../../common/core/Label";
import Input from "../../../common/core/Input";
import FormGroup from "../../../common/core/FormGroup";

/** ===== IMAGE ICONS =========== */

/** ========= CUSTOM COMPONENTS ========= */
import VariantSelector from "../containers/VariantSelectorContainer";
import ProductTypeSelector from "../containers/ProductTypeSelectorContainer";
import CategorySelector from "../../categoryManagement/containers/SimpleCategorySelectorContainer";

/** ======== MODULE STYLES ========== */
import styles from "../css/ProductForm.module.css";
import FormFeedback from "../../../common/core/FormFeedback";

import constants from "../../../common/utils/validationConstants";

const { IS_NUMBER } = constants;

const ProductForm = ({
  name,
  productType,
  categories,
  attributes,
  skuName,
  listPrice,
  salePrice,
  quantity,
  enableProduct,
  onSale,
  handleFormChange,
  handleChange,
  register,
  errors,
  primaryParentCategory,
}) => {
  /** internationalization using i18n from common and product  */
  const { t } = useTranslation(["common", "product"]);

  return (
    <>
      <Row className={styles.block3}>
        <Col xs="12">
          <Row>
            <Col xs="12" sm="11" md="10">
              <FormGroup>
                <Label className="formTextLabel">{t("common:label_name")}</Label>
                <Input
                  className="formText"
                  placeholder={t("product:placeholder_name")}
                  name="name"
                  defaultValue={name}
                  onChange={handleFormChange}
                  innerRef={
                    register({
                      required: t("common:validation_name"),
                      minLength: {
                        value: 3,
                        message: t("common:validation_min_three-letter"),
                      },
                    })
                  }
                  invalid={!!(errors.name && errors.name.message)}
                />
                <FormFeedback>{errors.name && errors.name.message}</FormFeedback>
              </FormGroup>
            </Col>
            <Col xs="7" sm="5" md="4">
              <FormGroup>
                <Label className="formTextLabel">{t("product:label_type")}</Label>
                <ProductTypeSelector
                  name="productType"
                  value={productType}
                  onChange={(o) => handleChange("productType", o)}
                />
              </FormGroup>
            </Col>
            <Col xs="5" sm="6" md="7">
              <FormGroup className={clsx(styles.noLabelFormGroup, "d-flex")}>
                <Label title={t("product:label_enable")} className="formTextLabel text-truncate">{t("product:label_enable")}</Label>
                <div
                  className={clsx(
                    "custom-control",
                    "custom-switch",
                    "ml-1",
                  )}
                >
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="enableProduct-new-product"
                    name="enableProduct"
                    checked={enableProduct}
                    onChange={() => handleChange("enableProduct", !enableProduct)}
                  />
                  <Label className="custom-control-label" htmlFor="enableProduct-new-product" />
                </div>
              </FormGroup>
            </Col>
            <Col xs="12" sm="11" md="10">
              <FormGroup>
                <Label className="formTextLabel">{t("common:label_category")}</Label>
                <CategorySelector
                  disablePIMCategory
                  name="categories"
                  value={categories}
                  onChange={(o) => handleChange("categories", o)}
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className={styles.titleTextContainer}>
        <span className="pageTitleText, fontsizeMediumBold ">{t("product:variant")}</span>
      </div>
      <Row className={styles.block3}>
        <Col xs="12" sm="11" md="10">
          <FormGroup>
            <Label className="formTextLabel">{t("common:label_skuName")}</Label>
            <Input
              className="formText"
              placeholder={t("product:placeholder_skuName")}
              name="skuName"
              innerRef={
                register({
                  required: t("product:validation_sku_name"),
                })
              }
              defaultValue={skuName}
              onChange={handleFormChange}
              invalid={!!(errors.skuName && errors.skuName.message)}
            />
            <FormFeedback>{errors.skuName && errors.skuName.message}</FormFeedback>
          </FormGroup>
        </Col>
      </Row>
      <Row className={styles.block3}>
        <VariantSelector
          productType={productType}
          value={attributes}
          onChange={(o) => handleChange("attributes", o)}
          register={register}
          errors={errors}
        />
      </Row>
      <Row className={styles.block3}>
        <Col>
          <Row>
            <Col xs="12" sm="6" md="4">
              <FormGroup>
                <Label className="formTextLabel">{t("common:label_listPrice")}</Label>
                <Input
                  type="number"
                  className="formText"
                  placeholder={t("product:placeholder_listPrice")}
                  name="listPrice"
                  defaultValue={listPrice}
                  innerRef={
                    register({
                      pattern: {
                        value: IS_NUMBER,
                        message: t("common:validation_is_number"),
                      },
                    })
                  }
                  onChange={handleFormChange}
                  invalid={!!(errors.salePrice && errors.salePrice.message)}
                />
                <FormFeedback>{errors.salePrice && errors.salePrice.message}</FormFeedback>
              </FormGroup>
            </Col>
            <Col xs="12" sm="6" md="4">
              <FormGroup>
                <Label className="formTextLabel">{t("common:label_salePrice")}</Label>
                <Input
                  type="number"
                  className="formText"
                  placeholder={t("product:placeholder_salePrice")}
                  name="salePrice"
                  defaultValue={salePrice}
                  innerRef={
                    register({
                      pattern: {
                        value: IS_NUMBER,
                        message: t("common:validation_is_number"),
                      },
                    })
                  }
                  onChange={handleFormChange}
                  invalid={!!(errors.salePrice && errors.salePrice.message)}
                />
                <FormFeedback>{errors.salePrice && errors.salePrice.message}</FormFeedback>
              </FormGroup>
            </Col>
            <Col xs="12" sm="6" md="4">
              <FormGroup className={clsx(styles.noLabelFormGroup, "d-flex")}>
                <Label title={t("product:placeholder_onSale")} className="formTextLabel text-truncate">{t("common:label_onSale")}</Label>
                <div
                  className={clsx(
                    "custom-control",
                    "custom-switch",
                    "ml-1",
                  )}
                >
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="onsale-checkbox"
                    name="onSale"
                    checked={onSale}
                    onChange={() => handleChange("onSale", !onSale)}
                  />
                  <Label className="custom-control-label" htmlFor="onsale-checkbox" />
                </div>
              </FormGroup>
            </Col>
            <Col xs="12" sm="6" md="4">
              <FormGroup>
                <Label className="formTextLabel">{t("common:label_quantity")}</Label>
                <Input
                  type="number"
                  className="formText"
                  placeholder={t("product:placeholder_quantity")}
                  name="quantity"
                  defaultValue={quantity}
                  innerRef={
                    register({
                      pattern: {
                        value: IS_NUMBER,
                        message: t("common:validation_is_number"),
                      },
                    })
                  }
                  onChange={handleFormChange}
                  invalid={!!(errors.salePrice && errors.salePrice.message)}
                />
                <FormFeedback>{errors.salePrice && errors.salePrice.message}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

ProductForm.propTypes = {
  /** form data */
  name: PropTypes.string.isRequired,
  enableProduct: PropTypes.bool.isRequired,
  productType: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(PropTypes.any).isRequired,
  attributes: PropTypes.objectOf(PropTypes.any).isRequired,
  skuName: PropTypes.string.isRequired,
  listPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  salePrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onSale: PropTypes.bool.isRequired,
  // input filed handle change
  handleFormChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  // validtion
  register: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ProductForm;
