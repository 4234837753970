/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
*/

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ========= TAG COMPONENTS ========= */
/** ========= SUB COMPONENT ========= */
/** ========= CUSTOM COMPONENTS ========= */
import MultiSelect from "../../../common/components/MultiSelect/MultiSelect";
import Select from "../../../common/components/MultiSelect/Select";

/** ========= API SERVICE FUNCTIONS ========= */
/** ========= MODULE STYLES ========= */
import styles from "../css/SimpleProductSelector.module.css";


const MultiSelector = (props) => {
  const {
    name, onChange, products, selectedProducts, handleTypeaheadChange,
    excluded, disabled, checkVendor,
  } = props;
  /** internationalization using i18n from common and product  */
  const { t } = useTranslation(["product"]);
  let options = [];

  if (checkVendor) {
    options = Array.isArray(products)
      && products.map(({
        id: value, name: label, vendorFulfilled,
      }) => ({ value, label, disabled: vendorFulfilled }));
  } else {
    options = Array.isArray(products)
      && products.map(({
        id: value, name: label,
      }) => ({ value, label }));
  }

  return (
    <MultiSelect
      className="formText"
      typeaheadPlaceholder={t("product:placeholder_select_Products")}
      name={name}
      id="product-promotion"
      value={selectedProducts}
      onChange={onChange}
      typeahead={handleTypeaheadChange}
      typeaheadText="Product name"
      chipClass={styles.chipWidth}
      options={options}
      excluded={excluded}
      disabled={disabled}
    />
  );
};

MultiSelector.defaultProps = {
  products: null,
  excluded: [],
  checkVendor: false,
};

MultiSelector.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.any),
  selectedProducts: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleTypeaheadChange: PropTypes.func.isRequired,
  excluded: PropTypes.arrayOf(PropTypes.any),
  disabled: PropTypes.bool.isRequired,
  checkVendor: PropTypes.bool,
};

const SingleSelector = (props) => {
  const {
    name, onChange, products,
    selectedProduct, handleTypeaheadChange,
    innerRef, invalid, excluded, disabled, checkVendor,
  } = props;
  /** internationalization using i18n from common and product  */
  const { t } = useTranslation(["product"]);
  const [options, setOptions] = useState();
  useEffect(() => {
    if (checkVendor) {
      setOptions(Array.isArray(products)
        && products.map(({
          id: value, name: label, vendorFulfilled, sellerRestriction,
        }) => ({ value, label, disabled: vendorFulfilled || !sellerRestriction })));
    } else {
      setOptions(Array.isArray(products)
        && products.map(({
          id: value, name: label,
        }) => ({ value, label })));
    }
  }, [products, checkVendor]);
  return (
    <Select
      className="formText"
      typeaheadPlaceholder={t("product:placeholder_search_Products")}
      name={name}
      id="product-promotion"
      value={selectedProduct}
      onChange={onChange}
      typeahead={handleTypeaheadChange}
      typeaheadText="Product name"
      options={options}
      innerRef={innerRef}
      invalid={invalid}
      excluded={excluded}
      disabled={disabled}
    />
  );
};

SingleSelector.defaultProps = {
  products: [],
  excluded: [],
  checkVendor: false,

};

SingleSelector.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.any),
  selectedProduct: PropTypes.string.isRequired,
  handleTypeaheadChange: PropTypes.func.isRequired,
  innerRef: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  excluded: PropTypes.arrayOf(PropTypes.any),
  disabled: PropTypes.bool.isRequired,
  checkVendor: PropTypes.bool,
};


const SimpleProductSelector = (props) => {
  const { singleSelect } = props;
  return (
    <>
      {
        singleSelect
          ? SingleSelector(props)
          : MultiSelector(props)
      }
    </>
  );
};

SimpleProductSelector.defaultProps = {
  singleSelect: false,
};

SimpleProductSelector.propTypes = {
  singleSelect: PropTypes.bool,
};

export default SimpleProductSelector;
