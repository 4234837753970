/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * ExternalCategoryEditor
 *
 * @author Naseef O
 *
 */

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import CategorySelector from "./CategorySelector";

import Col from "../../../common/core/Col";
import FormGroup from "../../../common/core/FormGroup";
import Input from "../../../common/core/Input";
import Row from "../../../common/core/Row";
import Label from "../../../common/core/Label";
import Button from "../../../common/core/Button";
import Nav from "../../../common/core/Nav";
import NavItem from "../../../common/core/NavItem";
import NavLink from "../../../common/core/NavLink";
import TabPane from "../../../common/core/TabPane";
import TabContent from "../../../common/core/TabContent";
import Spinner from "../../../common/core/Spinner";
import UncontrolledTooltip from "../../../common/core/UncontrolledTooltip";

import styles from "../css/ExternalCategories.module.css";

import warningIcon from "../../../common/assets/images/svg/warning-mui.svg";
import ClearIcon from "../../../common/assets/images/svg/clear.svg";

const ExternalCategoryEditor = ({
  handleSave,
  form,
  loading,
  activeTab,
  toggleTab,
  categories,
  dropdownOpen,
  toggleDropdown,
  clearCategories,
  handleChangeCategory,
  handleRedirectToMarketPlace,
}) => (!loading
  ? (
    <>
      <Row className="pageHeader common-page-card align-items-center">
        <Col xs="12" md="7" className="text-truncate">
          <span className="pageText">{form.externalCategoryName}</span>
          <br />
          <span className={styles.lineageText}>{form.lineage ? form.lineage.split(",").join(" -> ") : ""}</span>
        </Col>
        <Col xs="12" md="5" className="d-flex justify-content-end">
          <Button
            type="button"
            color="secondary"
            className={clsx(styles.buttonSize, "border-radius-2 secondary-color border-0 text-truncate mr-2")}
            onClick={() => handleRedirectToMarketPlace()}
          >
            CANCEL
          </Button>

          <Button
            type={!form.processed ? "button" : "submit"}
            color="primary"
            disabled={!form.processed}
            onClick={() => handleSave()}
            className={clsx(styles.buttonSize, "border-radius-2 primary-color border-0 text-truncate mr-1")}
          >
            <span id="id-can-not-save">SAVE</span>
          </Button>
          {!form.processed && (
            <UncontrolledTooltip placement="bottom" target="id-can-not-save">
              {" You can't save. This category is on processing..."}
            </UncontrolledTooltip>
          )}
        </Col>
      </Row>
      <Nav tabs className="border-0">
        <NavItem
          active={activeTab === "1"}
          className={clsx(styles.navLinkWrapper, "cursor-pointer", "text-center", activeTab === "1" ? "bg-white" : " bg-light")}
        >
          <NavLink
            className="border-0"
            onClick={() => { toggleTab("1"); }}
          >
            Details
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1" className="p-0">
          <Row className={clsx(styles.block2, styles.blockWrapper, "common-page-card")}>
            <Col xs="12">
              <Row>
                <Col xs="6">
                  <FormGroup>
                    <Label className={styles.formLabelWrapper}>
                      External Id
                    </Label>
                    <Input
                      id="id"
                      className="formText"
                      name="id"
                      value={form.externalCategoryId}
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col xs="6">
                  <FormGroup>
                    <Label className={styles.formLabelWrapper}>
                      Name
                    </Label>
                    <Input
                      id="name"
                      className="formText"
                      placeholder="Category name"
                      name="name"
                      value={form.externalCategoryName}
                      disabled
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <CategorySelector
                  categories={categories}
                  onChange={handleChangeCategory}
                  clearCategories={clearCategories}
                  toggle={toggleDropdown}
                  dropdownOpen={dropdownOpen}
                />
              </Row>
              <Row className="justify-content-start align-items-center my-2">
                <Col>
                  <span className={styles.mappedCatText}>  Mapped categories</span>
                </Col>
              </Row>
              {Array.isArray(categories) && categories.length > 0
                ? (
                  <Row className="justify-content-start align-items-center mt-3">
                    {categories.map((item) => (
                      <Col xs="auto">
                        <span className={clsx(styles.categoryChip, "d-flex justify-content-between align-items-center")}>
                          <span className={styles.textPrimary}>{item.name}</span>
                          <span
                            className="pl-1 d-flex justify-content-between align-items-center"
                            role="presentation"
                            onClick={() => clearCategories(item.id)}
                          >
                            <img className={styles.close} src={ClearIcon} alt="clear" />
                          </span>
                        </span>
                      </Col>
                    ))}
                  </Row>
                )
                : (
                  <Row className="justify-content-start align-items-center ml-2mt-1">
                    <Col>
                      <span className={clsx(styles.warningMsg, "d-flex align-items-center")}>
                        <img src={warningIcon} width="25px" className="pr-2" alt="warning" />
                        Map Storefront Categories to show it on storefront
                      </span>
                    </Col>
                  </Row>
                )}
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </>
  )
  : (
    <Row className={clsx(styles.spinnerWrapper, "common-page-card")}>
      <Col xs="12" className={clsx(styles.color, "d-flex justify-content-center align-items-center")}>
        <Spinner size="sm" />
      </Col>
    </Row>
  )
);

ExternalCategoryEditor.propTypes = {
  activeTab: PropTypes.string.isRequired,
  toggleTab: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.any).isRequired,
  dropdownOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  toggleDropdown: PropTypes.func.isRequired,
  clearCategories: PropTypes.func.isRequired,
  handleChangeCategory: PropTypes.func.isRequired,
  handleRedirectToMarketPlace: PropTypes.func.isRequired,
};
export default ExternalCategoryEditor;
