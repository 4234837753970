/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */

import React, { useState } from "react";
import PropTypes from "prop-types";

/** ========== CUSTOM COMPONENT ================ */
import Category from "../components/Category";

/** ========= SUB COMPONENT ========= */
import ConfirmationModal from "../../../common/components/ConfirmationModal";

/** =========== IMPORT API FUNCTIONS =========== */
import { deleteLeafCategory } from "../../../api/categoryManagementServices";

const CategoryContainer = ({
  id, name, type, active, startDate, endDate, childCategories, childProducts, source,
}) => {
  /** local states */
  const [deleteCategory, setDeleteCategory] = useState(null);

  /** unique key for ui component id */
  const getLocalKey = () => [1, 2, 3]
    .map((o) => `${new Date().getTime()}-${Math.floor((Math.random() * 100 * o))}`)
    .join("-");

  /**
     * This method is used to cancel confirm form
     */
  const cancelConfirm = () => {
    setDeleteCategory(null);
  };

  const isFunction = (functionToCheck) => functionToCheck && {}.toString.call(functionToCheck) === "[object Function]";

  /**
   * method for delete confirm
   */
  const confirmDelete = async () => {
    if (Boolean(deleteCategory) && deleteCategory.categoryId && deleteCategory.element) {
      const { categoryId, element } = deleteCategory;
      const response = await deleteLeafCategory(categoryId);
      if (response && response.success === true) {
        if (element && element.style) { element.style.display = "none"; }
      }
      if (isFunction(deleteCategory.callback)) { deleteCategory.callback(); }
      setDeleteCategory(null);
    }
  };

  return (
    <>
      <Category
        id={id}
        /** component self functionalities  */
        name={name}
        type={type}
        active={active}
        startDate={startDate}
        endDate={endDate}
        getLocalKey={getLocalKey}
        childCategories={childCategories}
        childProducts={childProducts}
        setDeleteCategory={setDeleteCategory}
        source={source}
      />
      <ConfirmationModal
        isOpen={Boolean(deleteCategory)}
        toggleOpen={cancelConfirm}
        togglClose={cancelConfirm}
        handleConfirm={confirmDelete}
        content=" Are you sure you want to delete this category ?"
      />
    </>
  );
};

CategoryContainer.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  childCategories: PropTypes.arrayOf(PropTypes.any),
  childProducts: PropTypes.arrayOf(PropTypes.any),
  source: PropTypes.string.isRequired,
};

CategoryContainer.defaultProps = {
  childCategories: null,
  childProducts: [],
};

export default CategoryContainer;
