/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * ExternalCategories
 *
 * @author Naseef O
 *
 */
import React from "react";
import PropTypes from "prop-types";
import { Switch } from "react-router-dom";
import ExternalCategoriesContainer from "./containers/ExternalCategoriesContainer";
import PrivateRouter from "../../common/routes/PrivateRouter";
import ExternalCategoryEditorContainer from "./containers/ExternalCategoryEditorContainer";

const ExternalCategories = ({ match: { path } }) => (
  <Switch>
    <PrivateRouter exact path={`${path}`} component={ExternalCategoriesContainer} />
    <PrivateRouter path={`${path}/details`} component={ExternalCategoryEditorContainer} />
  </Switch>
);

ExternalCategories.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    params: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
};

export default ExternalCategories;
