/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Naseef O
 */

import clsx from "clsx";
import moment from "moment";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
/** =========== TAG COMPONENTS =========== */

import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import UncontrolledTooltip from "../../../common/core/UncontrolledTooltip";

/** =========== IMPORT API FUNCTIONS =========== */
import { getSubExternalCategories } from "../../../api/externalCategoryServices";

/** ============ SVG IMAGE ICON ===================== */
import PlusIcon from "../../../common/assets/images/svg/add-24px.svg";
import MinusIcon from "../../../common/assets/images/svg/minus-24px.svg";
import EditIcon from "../../../common/assets/images/svg/edit.svg";
import rightArrow from "../../../common/assets/images/svg/right-arrow.svg";
import warningIcon from "../../../common/assets/images/svg/warning-mui.svg";

/** ================ MODULE STYLES ================== */
import styles from "../css/Category.module.css";
import styles2 from "../css/Table.module.css";
import constants from "../../../common/utils/constants";

const Category = ({
  page,
  key,
  index,
  rowItem,
  tableView,
}) => {
  const history = useHistory();
  const currentRow = useRef(null);

  /** local states */
  const [isOpen, setIsOpen] = useState(false);
  const [categoryList, setCategoryList] = useState(null);


  /** defines has sub categories  */
  const [hasCategory, setHasCategory] = useState(
    Boolean(Array.isArray(rowItem.childCategories) && rowItem.childCategories.length > 0),
  );


  /**
   * This method is used to get category list by parent Category id
   * @param {String} id
   */
  const getCategoryByParenCategory = async (externalCategoryId) => {
    getSubExternalCategories(externalCategoryId).then((response) => {
      if (response && response.success
        && Array.isArray(response.data) && response.data.length > 0) {
        setHasCategory(true);
        setCategoryList(response.data);
      } else {
        setHasCategory(false);
      }
    });
  };

  useEffect(() => {
    getSubExternalCategories(rowItem.externalCategoryId).then((response) => {
      if (response && response.success
        && Array.isArray(response.data) && response.data.length > 0) {
        setHasCategory(true);
        setCategoryList(response.data);
      } else {
        setHasCategory(false);
      }
    });
  }, [rowItem]);

  /**
 * This method is sued to toggle the catalogue collapse
 * call sub category list when its open
 * @param {String} categoryId
 */
  const toggle = (categoryId) => {
    if (hasCategory) {
      setIsOpen(!isOpen);
      if (!isOpen) { getCategoryByParenCategory(categoryId); }
    }
  };


  /**
   * This method used to position sub category - left
   * @param {Number} limit
   */
  const getDivForPositioning = (limit) => {
    const positionComponents = [];
    for (let o = 0; o < limit; o += 1) { positionComponents.push(<span />); }
    return positionComponents;
  };

  /**
   * This method is used redirect to details page
   */
  const handleEdit = (id) => {
    history.push(`/merchandising/taxonomy-mapping/details/${id}?view=${tableView}`);
    window.scroll(0, 0);
  };

  const [viewMore, setViewMore] = useState("");
  const handleViewMore = (id) => {
    if (id === viewMore) {
      setViewMore("");
    } else {
      setViewMore(id);
    }
  };

  const firstElement = (elements) => {
    if (Array.isArray(elements) && elements.length > 0) {
      return elements.slice(0, 3);
    }
    return null;
  };

  return (
    <>
      <tr key={key} className={styles.categoryTreeItem} ref={currentRow}>
        <td className="text-truncate">
          {rowItem.externalCategoryId}
        </td>
        <td className={clsx(styles.tableTd, "py-0", "align-items-stretch")}>
          <div className={clsx(styles.treeLineAnimationRootComponent, "d-flex", "h-100", "align-items-stretch")}>
            {getDivForPositioning(index)}
            <div className={clsx(styles.itemTitle, "bg-white", "d-flex", (!hasCategory) && styles.noDescendants, isOpen ? styles.isOpen : styles.isClosed)}>
              {hasCategory
                && (
                  <span className="commonPointer" onClick={(o) => toggle(rowItem.externalCategoryId, o)} onKeyDown={() => { }} tabIndex={0} role="button">
                    <img
                      className={styles.icon}
                      src={isOpen ? MinusIcon : PlusIcon}
                      alt=""
                    />
                  </span>
                )}
              <span className={clsx(styles.fontWeightBold, "px-1", "text-truncate")}>{rowItem.externalCategoryName}</span>
            </div>
          </div>
        </td>
        {page === constants.CATEGORY_PAGE_TYPE.EXTERNAL_CATEGORY_PAGE
          && (
            <td>
              <Row className="justify-content-start">
                {rowItem.mappedCategories && rowItem.mappedCategories.length > 0 ? (
                  <>
                    {" "}
                    {viewMore === rowItem.id ? rowItem.mappedCategories.map((item) => (
                      <>
                        <Col xs="auto" className={styles2.categoryChip}>
                          {item.name}
                        </Col>
                      </>
                    ))
                      : firstElement(rowItem.mappedCategories)
                      && firstElement(rowItem.mappedCategories).map((item, indexIn) => (
                        <>
                          <Col xs="auto" className={styles2.categoryChip}>
                            {item.name}
                          </Col>
                          {
                            rowItem.mappedCategories.length > 3
                            && firstElement(rowItem.mappedCategories).length === indexIn + 1
                            && (
                              <Col
                                xs="auto"
                                onClick={() => handleViewMore(rowItem.id)}
                                className={clsx(styles2.viewAllChip, "pl-2 pr-1")}
                              >
                                View more
                                <img src={rightArrow} width="20px" alt=" view all" />
                              </Col>
                            )
                          }
                        </>
                      ))}
                  </>
                )
                  : (
                    <Col xs="auto">
                      <span className={clsx(styles2.warningMsg, "d-flex align-items-center")}>
                        <img src={warningIcon} width="25px" className="pr-2" alt="warning" />
                        Map Storefront Categories to show it on storefront
                      </span>
                    </Col>
                  )}
              </Row>
            </td>
          )}
        <td>
          <span className={styles2.date}>
            {moment(rowItem.updatedDate).format("HH:mm:ss DD/MM/YYYY")}
          </span>
        </td>
      </tr>
      {hasCategory && isOpen && (
        Array.isArray(categoryList) ? (
          <>
            {
              categoryList.length > 0 ? (
                <>
                  {categoryList.map((category) => (
                    <Category
                      page={page}
                      index={index + 1}
                      key={`child${category.id}`}
                      id={category.id}
                      rowItem={category}
                    />
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan="7" className="p-0">
                    <div className="text-center pt-1 pb-3">
                      <span>No Data</span>
                    </div>
                  </td>
                </tr>
              )
            }
          </>
        ) : (
          <tr className={styles.categoryTreeItem}>
            <td className={clsx(styles.tableTd, "py-0", "align-items-stretch")}>
              <div className={clsx(styles.treeLineAnimationRootComponent, "d-flex", "h-100", "align-items-stretch")}>
                {getDivForPositioning(index)}
              </div>
            </td>
            <td colSpan="6" className="p-0">
              <div className="text-center pt-1 pb-3">
                <div className="spinner-grow" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </td>
          </tr>
        )
      )}
    </>
  );
};

Category.propTypes = {
  key: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  rowItem: PropTypes.objectOf(PropTypes.any).isRequired,
  tableView: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
};

const CategoryComponent = ({
  rowItem, key, tableView, page,
}) => (
  <Category
    index={0}
    key={key}
    page={page}
    rowItem={rowItem}
    tableView={tableView}
  />
);

CategoryComponent.propTypes = {
  rowItem: PropTypes.objectOf(PropTypes.any).isRequired,
  key: PropTypes.string.isRequired,
  tableView: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
};

export default CategoryComponent;
