/**
 * Copyright(c) 2022 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Philip
 */

import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datetime";
import Pagination from "../../../common/components/Pagination";

/** ===== TAG COMPONENTS =========== */
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import FormFeedback from "../../../common/core/FormFeedback";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Alert from "../../../common/core/Alert";
import CustomizationTable from "../../../common/components/Table";
import ClearIconAlt from "../../../common/assets/images/svg/clear-alt.svg";
import FilterIcon from "../../../common/assets/images/svg/filter.svg";

/** ===== UTILS =========== */
import {
  getJobTypeByType,
} from "../../../common/utils/bulkUploadUtils";
import constants from "../../../common/utils/constants";

/** ===== IMAGES/ICONS =========== */
import DropdownMenu from "../../../common/core/DropdownMenu";
import Dropdown from "../../../common/core/Dropdown";
import DropdownToggle from "../../../common/core/DropdownToggle";
import refreshIcon from "../../../common/assets/images/svg/sync.svg";
/** ===== MODULE STYLES =========== */
import styles from "../css/ColorPaletteManagement.module.css";

const {
  PRODUCT_BULK_MAINTENANCE: {
    JOB_STATUSES,
    QUEUED,
    FAILED,
  },
  DATE_FORMAT,
} = constants;

const BulkUploadHistory = (props) => {
  const {
    jobHistoryData,
    searchQuery,
    documentDownloadSteps,
    isLoading,
    message,
    isValidDate,
    uploadModes,
    handleSelectFilter,
    getPageData,
    setDates,
    pagination,
    modeDropdownOpen,
    statusDropdownOpen,
    toggleModeDropdown,
    toggleStatusDropdown,
    maintenanceType,
  } = props;

  const customRows = [
    {
      label: "Created",
      value: "createdDate",
      path: "createdDate",
      transform: "",
      type: "date",
    },
    {
      label: "Username",
      value: "userName",
      path: "userName",
      transform: "",
    },
    {
      label: "File Name",
      value: "fileName",
      path: "fileName",
      transform: "",
    },
    {
      label: "Mode of data",
      value: "mode",
      path: "mode",
      transform: "",
    },
    {
      label: "Status",
      value: "statusText",
      path: "statusText",
      transform: "",
    },
  ];
  if (searchQuery.status != QUEUED) {
    customRows.push({
      label: "Errors",
      value: "",
      path: "render",
      render: (row) => (row.status === FAILED
        && (
          <button type="button" className="btn btn-sm btn-info text-white" onClick={() => { documentDownloadSteps(row.jobId, getJobTypeByType(maintenanceType)); }}>
            {t("product-bulk-maintenance:label_download")}
          </button>
        )
      ),
      transform: "",
    });
  }

  const { t } = useTranslation(["common", "globalSettings", "product-bulk-maintenance"]);
  return (
    <>
      <Row>
        <Col>
          <FormGroup>
            <Label className="formTextLabel" for="fromDate">
              {t("product-bulk-maintenance:label_from_date")}
            </Label>
            <DatePicker
              id="fromDate"
              dateFormat={DATE_FORMAT}
              timeFormat
              closeOnSelect
              input
              name="fromDate"
              onChange={(e) => setDates("BULK_DATE_FROM", e)}
              isValidDate={(e) => isValidDate(e, "BULK_DATE_FROM")}
              inputProps={{
                placeholder: t("common:placeholder_date_example"),
              }}
              value={searchQuery.fromDate}
            />
            <FormFeedback>
              {t("product-bulk-maintenance:label_invalid_date_msg")}
            </FormFeedback>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label className="formTextLabel" for="toDate">
              {t("product-bulk-maintenance:label_to_date")}
            </Label>
            <DatePicker
              id="toDate"
              dateFormat={DATE_FORMAT}
              timeFormat
              closeOnSelect
              input
              name="toDate"
              onChange={(e) => setDates("BULK_DATE_TO", e)}
              isValidDate={(e) => isValidDate(e, "BULK_DATE_TO")}
              inputProps={{
                placeholder: t("common:placeholder_date_example"),
              }}
              value={searchQuery.toDate}
            />
            <FormFeedback>
              {t("product-bulk-maintenance:label_invalid_date_msg")}
            </FormFeedback>
          </FormGroup>
        </Col>

      </Row>
      <Row className="mb-5 align-items-center">
        <Col xs="auto" className="py-2">
          <Dropdown
            isOpen={modeDropdownOpen}
            toggle={toggleModeDropdown}
            className={styles.dropdownButtonWrapper}
          >
            <DropdownToggle caret>
              <span
                className={
                  clsx(styles.selectedFilter,
                    !searchQuery.mode && styles.filterText)
                }
              >
                {searchQuery.mode ? searchQuery.mode : `${t("product-bulk-maintenance:filter_by_mode")}`}
              </span>
            </DropdownToggle>
            <div
              role="presentation"
              className={clsx(styles.dropdownClear, "cursor-pointer")}
              onClick={() => (searchQuery.mode ? handleSelectFilter("mode", null) : toggleModeDropdown())}
            >
              {!searchQuery.mode ? (
                <img
                  src={FilterIcon}
                  alt="Select filter"
                  width="20px"
                  className={styles.icon}
                />
              )
                : (
                  <img
                    src={ClearIconAlt}
                    alt="Clear filter"
                    width="20px"
                    className={styles.icon}
                  />
                )}
            </div>
            <DropdownMenu className={styles.dropdownWrapper}>
              {Array.isArray(uploadModes) && uploadModes.length > 0
                && uploadModes.map((option) => (
                  <div
                    key={`filter-${option.name}`}
                    onKeyPress={() => { }}
                    role="button"
                    tabIndex="0"
                    className={clsx(styles.dropdownItem)}
                    onClick={() => !option.disabled && handleSelectFilter("mode", option.code)}
                  >
                    {option.name}
                  </div>
                ))}
            </DropdownMenu>
          </Dropdown>
        </Col>
        {searchQuery.status !== QUEUED
          && (
            <Col xs="auto" className="py-2">
              <Dropdown
                isOpen={statusDropdownOpen}
                toggle={toggleStatusDropdown}
                className={styles.dropdownButtonWrapper}
              >
                <DropdownToggle caret>
                  <span
                    className={
                      clsx(styles.selectedFilter,
                        !searchQuery.status && styles.filterText)
                    }
                  >
                    {searchQuery.status ? JOB_STATUSES
                      .filter((status) => status.code == searchQuery.status).map((status) => status.value) : `${t("product-bulk-maintenance:filter_by_status")}`}
                  </span>
                </DropdownToggle>
                <div
                  role="presentation"
                  className={clsx(styles.dropdownClear, "cursor-pointer")}
                  onClick={() => (searchQuery.status ? handleSelectFilter("status", null) : toggleStatusDropdown())}
                >
                  {!searchQuery.status ? (
                    <img
                      src={FilterIcon}
                      alt="Select filter"
                      width="20px"
                      className={styles.icon}
                    />
                  )
                    : (
                      <img
                        src={ClearIconAlt}
                        alt="Clear filter"
                        width="20px"
                        className={styles.icon}
                      />
                    )}
                </div>
                <DropdownMenu className={styles.dropdownWrapper}>
                  {JOB_STATUSES
                    .filter((status) => status.code !== QUEUED)
                    .map((option) => (

                      <div
                        key={`filter-${option.code}`}
                        onKeyPress={() => { }}
                        role="button"
                        tabIndex="0"
                        className={clsx(styles.dropdownItem)}
                        onClick={() => !option.disabled && handleSelectFilter("status", option.code)}
                      >
                        {option.value}
                      </div>
                    ))}
                </DropdownMenu>
              </Dropdown>
            </Col>
          )}
        {Array.isArray(jobHistoryData) && jobHistoryData.length > 0 && (
          <Col xs="auto" className="py-2 text-truncate d-flex  align-items-right">
            <span className="font-weight-bold pl-1">{`${pagination.numberOfElements} of ${pagination.totalElements}`}</span>
          </Col>
        )}
        <Col xs="auto" >
          <img
            onClick={() => handleSelectFilter()}
            src={refreshIcon}
            alt="upload"
            role="presentation"
            className="mr-2 commonPointer"
          />
          <span>Please Refresh to know the updated file upload status.</span>
        </Col>
      </Row>
      <div className="custom-table">
        <CustomizationTable
          isLoading={isLoading}
          customRows={customRows}
          customizationData={jobHistoryData}
          isStatusEnable={false}
          isDeleteEnable={false}
          isEditEnable={false}
        />

        {Array.isArray(jobHistoryData) && jobHistoryData.length > 0 && (
          <Pagination
            totalCount={pagination.totalElements}
            page={searchQuery.page}
            limit={searchQuery.limit}
            getPageData={getPageData}
          />
        )}

      </div>
    </>
  );
};

BulkUploadHistory.propTypes = {
  searchQuery: PropTypes.objectOf(PropTypes.any).isRequired,

};
BulkUploadHistory.defaultProps = {
  jobHistoryData: [],
};

export default BulkUploadHistory;
