/**
 * Copyright(c) 2022 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * CacheManagement
 *
 * @author Naseef O
 *
 */


import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import Col from "../../../common/core/Col";
import Row from "../../../common/core/Row";
import Button from "../../../common/core/Button";
import styles from "../css/CacheManagement.module.css";
import Alert from "../../../common/core/Alert";
import ListGroup from "../../../common/core/ListGroup";
import ListGroupItem from "../../../common/core/ListGroupItem";
import Spinner from "../../../common/core/Spinner";

import commonUtils from "../../../common/utils/commonUtils";

const CacheManagement = ({
  toggleModal, message, loading, cacheSettings,
}) => {
  const { t } = useTranslation(["common"]);
  return (
    <>
      {
        message && message.type && (
          <Alert color={message.type}>
            {message.message}
          </Alert>
        )
      }
      <Row className="pageHeader common-page-card">
        <Col xs="12" md="7" className="text-truncate">
          <span className="pageText">{t("common:cache_settings")}</span>
        </Col>
      </Row>
      <Row className={clsx(styles.block1, "common-page-card justify-content-end")}>
        <Col xs="auto">
          <Button
            type="button"
            onClick={() => toggleModal({ name: "", url: "/all" })}
            className="common-primary-button"
          >
            {t("common:label_clear_all")}
          </Button>
        </Col>
      </Row>
      <Row className={clsx(styles.block2, "common-page-card")}>
        <Col xs="12">
          {loading ? (
            <div className="d-flex justify-content-center align-items-center h-100">
              <Spinner size="sm" animation="border" role="status" />
            </div>
          )
            : (
              <ListGroup>
                {commonUtils.isListNotEmpty(cacheSettings) ? cacheSettings.map((item) => (
                  <ListGroupItem>
                    <Row className="justify-content-between">
                      <Col xs="auto">
                        {item.name}
                      </Col>
                      <Col xs="auto">
                        <Button
                          type="button"
                          onClick={() => toggleModal({ name: item.name, url: item.url })}
                          className={clsx("common-primary-button", styles.btnCustom)}
                        >
                          {t("common:label_clear")}
                        </Button>
                      </Col>
                    </Row>
                  </ListGroupItem>
                )) : ""}
              </ListGroup>
            )}
        </Col>
      </Row>
    </>
  );
};
CacheManagement.defaultProps = {
  cacheSettings: [],
  loading: false,
};

CacheManagement.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  message: PropTypes.objectOf(PropTypes.any).isRequired,
  cacheSettings: PropTypes.arrayOf(PropTypes.any),
  loading: PropTypes.bool,
};

export default CacheManagement;
