/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Image List
 *
 * @author Naseef O
 *
 */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { PopoverBody, PopoverHeader, UncontrolledPopover } from "reactstrap";
import copyIcon from "../../../common/assets/images/svg/copy.svg";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";

const ImageList = (props) => {
  const {
    imageUrls,
  } = props;

  const [success, setSuccess] = useState("");

  const copyToClipboard = (text) => {
    setSuccess(text);
    const dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    setTimeout(() => {
      setSuccess("");
    }, 2000);
  };

  return (
    <UncontrolledPopover trigger="legacy" placement="left" target="popover-uploaded-images">
      <PopoverHeader>Uploaded Images</PopoverHeader>
      <PopoverBody>

        <Row>
          <Col style={{
            maxHeight: "430px",
            overflowY: "auto",
          }}
          >
            <ul className="p-0">
              {Array.isArray(imageUrls) && imageUrls.length > 0 ? imageUrls.map((url) => (
                <li
                  className="d-flex align-items-center p-2"
                  style={{
                    width: "100%",
                    wordBreak: "break-all",
                  }}
                >

                  <span
                    style={{
                      height: "100px",
                      width: "100%",
                      background: "#f3f3f3",
                    }}
                    className="d-flex "
                  >
                    <div className="d-flex align-items-center">
                      <img
                        width="100%"
                        style={{ objectFit: "contain" }}
                        height="100%"
                        src={url}
                        alt="uploaded data"
                      />
                    </div>
                  </span>
                  {success === url ? (
                    <span
                      className="ml-2"
                      style={{
                        background: "#278a2b",
                        color: "white",
                        borderRadius: "5px",
                        whiteSpace: "nowrap",
                        paddingLeft: "5px",
                        paddingRight: "5px",
                        fontSize: "10px",
                      }}
                    >
                      Copied to clipboard
                    </span>
                  )
                    : (
                      <span className="ml-2">
                        {url}
                      </span>
                    )}
                  <span
                    className="ml-2"
                    role="presentation"
                    onClick={() => copyToClipboard(url)}
                  >
                    <img src={copyIcon} alt="copy" />
                  </span>

                </li>
              )) : <li>No images</li>}
            </ul>
          </Col>
        </Row>
      </PopoverBody>
    </UncontrolledPopover>

  );
};

ImageList.propTypes = {
  imageUrls: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default ImageList;
