/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * ExternalCategoryTreeTable
 *
 * @author Naseef O
 *
 */

import React from "react";
import PropTypes from "prop-types";

import Table from "../../../common/core/Table";
import Category from "./Category";
import constants from "../../../common/utils/constants";


const ExternalCategoryTreeTable = (props) => {
  const {
    page,
    loading,
    tableView,
    tableData,
  } = props;

  return (
    <div className="custom-table mt-3">
      <Table borderless>
        <thead>
          <tr>
            <th>External Id</th>
            <th>Name</th>
            {page === constants.CATEGORY_PAGE_TYPE.EXTERNAL_CATEGORY_PAGE
              && <th>Mapped categories</th>}
            <th>Last modified</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(tableData) && tableData.length > 0
            ? tableData.map((each, index) => (
              <Category
                page={page}
                key={`root-${each.externalCategoryId}`}
                rowItem={each}
                index={index}
                tableView={tableView}
              />
            )) : (
              <tr>
                <td colSpan="100%" align="center" valign="center">
                  {loading ? "Loading..." : "Nothing to show."}
                </td>
              </tr>
            )}
        </tbody>
      </Table>
    </div>
  );
};

ExternalCategoryTreeTable.propTypes = {
  page: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  tableView: PropTypes.string.isRequired,
  tableData: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default ExternalCategoryTreeTable;
