/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Naseef O
 */
import httpApi from "./httpApi";

/**
 * This method is used to get external categories
 * @returns {Object}
 */
const getExternalCategories = async (searchTerm = "", mapped = null, page = 0, size = 6) => {
  const response = await httpApi.get("v1/external-category/list", {
    params: {
      page, size, searchTerm, mapped,
    },
  });
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to update an external category
 * @returns {Object}
 */
const updateExternalCategory = async (requestData) => {
  const response = await httpApi.patch("v1/external-category", requestData);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to get external category by id
 * @param {String} id
 * @returns {Object}
 */
const getExternalCategoryById = async (id) => {
  const response = await httpApi.get(`v1/external-category/id/${id}`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to get root external categories
 * @returns {Object}
 */
const getRootExternalCategories = async () => {
  const response = await httpApi.get("v1/external-category/roots");
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to get external sub external categories
 * @param {String} externalCategoryId
 * @returns {Object}
 */
const getSubExternalCategories = async (externalCategoryId) => {
  const response = await httpApi.get(`v1/external-category/${externalCategoryId}/children`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to get external categories by category id
 * @param {String} categoryId
 * @returns {Object}
 */
const getExternalCategoriesByCategoryId = async (categoryId) => {
  const response = await httpApi.get(`v1/external-category/category-id/${categoryId}`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to update external external categories by category id
 * @param {Object} externalCategoryUpdateRequest
 * @param {String} categoryId
 * @returns {Object}
 */
const updateMappingByCategoryId = async (externalCategoryUpdateRequest, categoryId) => {
  const response = await httpApi.patch(`v1/external-category/category-id/${categoryId}`, externalCategoryUpdateRequest);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

export {
  getExternalCategories,
  updateExternalCategory,
  getSubExternalCategories,
  getRootExternalCategories,
  getExternalCategoryById,
  updateMappingByCategoryId,
  getExternalCategoriesByCategoryId,
};
