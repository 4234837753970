/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Image Uploader
 *
 * @author Naseef O
 *
 */
import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "../../../common/core/Modal";
import ModalHeader from "../../../common/core/ModalHeader";
import ModalBody from "../../../common/core/ModalBody";
import copyIcon from "../../../common/assets/images/svg/copy.svg";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";

const ImageUploader = (props) => {
  const {
    isOpen,
    defaultMediaUrl,
    toggle,
    loading,
  } = props;

  const [success, setSuccess] = useState(false);

  const copyToClipboard = (text) => {
    setSuccess(true);
    const dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    setTimeout(() => {
      setSuccess(false);
    }, 2000);
  };

  return (
    <>
      <Modal isOpen={isOpen}>

        <ModalHeader toggle={toggle} />
        <ModalBody>
          {success && (
            <Row className="mb-2 mx-2 row ">
              <Col className="text-center">
                <span style={{
                  background: "#278a2b",
                  color: "white",
                  borderRadius: "5px",
                  whiteSpace: "nowrap",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                  fontSize: "10px",
                }}
                >
                  Copied to clipboard
                </span>
              </Col>
            </Row>
          )}
          <Row className="mb-2 mx-2 row">
            <Col

              className="p-2 d-flex align-items-center"
              style={{
                background: "rgb(239 235 235 / 34%)",
              }}
            >
              <span style={{ wordBreak: "break-word" }}>
                {loading ? "Loading..." : defaultMediaUrl || "Upload failed"}
              </span>
              {!loading && defaultMediaUrl && (
                <span
                  className="ml-3 cursor-pointer"
                  role="presentation"
                  onClick={() => copyToClipboard(defaultMediaUrl)}
                >
                  <img src={copyIcon} alt="copy" />
                </span>
              )}
            </Col>
          </Row>
          {defaultMediaUrl && (
            <Row className="mb-2 mt-4 mx-2">
              <Col
                xs="12"
                style={{
                  height: "400px",
                  width: "100%",
                  background: "#f3f3f3",
                }}
                className="d-flex"
              >
                <div className="d-flex align-items-center">
                  <img width="100%" height="100%" src={defaultMediaUrl} alt="uploaded data" />
                </div>
              </Col>
            </Row>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

ImageUploader.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  defaultMediaUrl: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ImageUploader;
