/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

/** ======== CUSTOM COMPONENTS ========= */
import AddProduct from "../components/AddProduct";


/** ===== API SERVICE FUNCTIONS =========== */
import { addProduct } from "../../../api/productManagementServices";

/** Base container for add new category  */
const AddProductContainer = () => {
  const history = useHistory();


  /** local states */
  const [form, setForm] = useState({
    name: "",
    productType: "Apparel",
    enableProduct: false, /** product buy default disabled */
    categories: [], /**  multiple category selection  */
    attributes: {}, /** multiple attribute key value pairs */
    skuName: "",
    listPrice: "",
    salePrice: "",
    quantity: "",
    onSale: false, /** by default on sale is false */
    images: [], /** selected images list */
  });
  const [onSubmitting, setOnSubmitting] = useState(false);

  /**
   * This method is used to change fom state
   * @param {String} name
   * @param {String} value
   */
  const handleChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  /**
   * This method is used to update sku name by product name and attribute changes
   * @param {String} productName
   * @param {Object} productAttributes
   */
  const skuNameUpdate = (productName, productAttributes) => {
    if (productName && productName !== "") {
      let newSKUName = `${productName}${Object.keys(productAttributes).length > 0
        ? "-" : ""}${Object.entries(productAttributes).map((each) => `${each[1]}`).join("-")}`;
      newSKUName = newSKUName.replace(/ /g, "_");
      setForm({
        ...form,
        skuName: newSKUName,
      });
    }
  };

  useEffect(() => {
    skuNameUpdate(form.name, form.attributes);
  }, [form.name, form.attributes]);


  /**
   * This method is used to change form filed input
   * @param {Event} event
   */
  const handleFormChange = (event) => {
    handleChange(event.target.name, event.target.value);
  };

  /**
   * This method is used for cancel the form
   */
  const onCancel = () => {

  };

  /**
   * This method is used to submit the form
   * @param {Event} event
   */
  const onSubmit = async (value) => {
    const requestBody = {
      name: form.name,
      type: form.productType,
      active: form.enableProduct,
      parentCategories: form.categories,
      skuSelector: form.attributes,
      skuName: form.skuName,
      salePrice: form.salePrice,
      listPrice: form.listPrice,
      quantity: form.quantity,
      onSale: form.onSale,
    };
    if (value) {
      /** setting on submit true */
      setOnSubmitting(true);
      const response = await addProduct(requestBody);
      if (response && response.success) {
        setOnSubmitting(false);
        history.push(`/merchandising/product/edit/${response.data.id}`);
      }
    }
  };

  const handleRedirect = () => {
    history.push("/merchandising/product");
  };
  return (
    <AddProduct
      /** form data */
      name={form.name}
      enableProduct={form.enableProduct}
      skuName={form.skuName}
      listPrice={form.listPrice}
      salePrice={form.salePrice}
      quantity={form.quantity}
      onSale={form.onSale}
      images={form.images}
      categories={form.categories}
      productType={form.productType}
      attributes={form.attributes}
      /** sate change events */
      handleChange={handleChange}
      handleFormChange={handleFormChange}
      /** form action */
      onSubmitting={onSubmitting}
      onCancel={onCancel}
      onSubmit={onSubmit}
      handleRedirect={handleRedirect}
    />
  );
};

AddProductContainer.propTypes = {

};

export default AddProductContainer;
