/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * Variant
 *
 * @author Naseef O
 */


import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import UncontrolledCollapse from "../../../common/core/UncontrolledCollapse";

import Image from "./Image";
import OffersTable from "./OffersTable";

import ExpandLessIcon from "../../../common/assets/images/svg/expand-less.svg";
import ExpandMoreIcon from "../../../common/assets/images/svg/expand-more.svg";
import warningIcon from "../../../common/assets/images/svg/warning-mui.svg";

import styles from "../css/ProductDetails.module.css";


const Variant = (props) => {
  const {
    variant, selectedVariant, toggleVariant, offers, pagination, getPageData, key,
  } = props;

  return (
    <React.Fragment key={key}>
      <div
        className={clsx(styles.variantText, "mb-4")}
        id={`toggler-${variant.id}`}
        role="presentation"
        onClick={() => toggleVariant(variant.id)}
      >
        <span>
          {`${variant.id}`}
        </span>
        {selectedVariant === variant.id ? <img src={ExpandLessIcon} alt="expand less" />
          : <img src={ExpandMoreIcon} alt="expand more" />}
      </div>
      <div className="mx-3">
        <UncontrolledCollapse toggler={`toggler-${variant.id}`}>
          <Row
            className={clsx("justify-content-between align-items-center")}
          >
            <Col xs="auto" className={styles.product}>
              <div className="d-flex align-items-center ">
                <span className={clsx(styles.text1)}>Name: </span>
                <span className={styles.text2}>{variant.name || "Not available"}</span>
              </div>
              <div className="d-flex ">
                <span className={clsx(styles.text1)}>External Id: </span>
                <span className={styles.text2}>{variant.uniqueId || "Not available"}</span>
              </div>
              <div className="d-flex ">
                <span className={clsx(styles.text1)}>Marketplace Id: </span>
                <span className={styles.text2}>{variant.marketPlaceProductId || "Not available"}</span>
              </div>
            </Col>
            <Col xs="auto">
              {Array.isArray(variant.medias)
                && variant.medias.length > 0
                && variant.medias[0]
                && (
                  <Image
                    width="90px"
                    alt={variant.name}
                    className="img-thumbnail"
                    src={variant.medias[0].mediaThumbnailUrl || variant.medias[0].defaultMediaUrl}
                  />
                )}
            </Col>
          </Row>
          {variant.priceInfo
            && (
              <Row className=" justify-content-between align-items-center mb-2">
                <Col xs="auto" className={styles.variant}>
                  <div className={clsx(styles.title, "d-flex mb-1")}>
                    Price
                  </div>
                  <div className="d-flex ">
                    <span className={clsx(styles.text1, "mr-1")}>List price: </span>
                    <span className={styles.text2}>{`${variant.priceInfo.listPrice} ${variant.priceInfo.currencyCode || "AED"}`}</span>
                  </div>
                  <div className="d-flex ">
                    <span className={clsx(styles.text1, "mr-1")}>Sale price: </span>
                    <span className={styles.text2}>{`${variant.priceInfo.salePrice} ${variant.priceInfo.currencyCode || "AED"}`}</span>
                  </div>
                </Col>
              </Row>
            )}
          {variant.inventory
            && (
              <Row className=" justify-content-between align-items-center">
                <Col xs="auto" className={styles.variant}>
                  <div className={clsx(styles.title, "d-flex mb-1")}>
                    Inventory
                  </div>
                  <div className="d-flex ">
                    <span className={clsx(styles.text1, "mr-1")}>Quantity: </span>
                    <span className={styles.text2}>{`${variant.inventory.quantity}`}</span>
                  </div>
                  <div className="d-flex ">
                    <span className={clsx(styles.text1, "mr-1")}>Status: </span>
                    <span className={styles.text2}>{`${variant.inventory.status}`}</span>
                  </div>
                </Col>
              </Row>
            )}
          <Row className="justify-content-between align-items-center mt-3">
            <Col className={styles.title}>
              Offers
            </Col>
            <Col className="d-flex justify-content-end align-items-center">
              {!variant.vendorFulfilled && variant.sellerRestriction
                && (
                  <span className={clsx(styles.warningMsg, "d-flex align-items-center")}>
                    <img src={warningIcon} width="25px" className="pr-2" alt="warning" />
                    This variant is currently restricted for sellers
                  </span>
                )}
            </Col>
          </Row>
          <OffersTable
            offers={offers}
            pagination={pagination}
            getPageData={getPageData}
          />
        </UncontrolledCollapse>
      </div>
    </React.Fragment>
  );
};

Variant.propTypes = {
  variant: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedVariant: PropTypes.string.isRequired,
  toggleVariant: PropTypes.func.isRequired,
  offers: PropTypes.arrayOf(PropTypes.any).isRequired,
  getPageData: PropTypes.func.isRequired,
  pagination: PropTypes.objectOf(PropTypes.any).isRequired,
  key: PropTypes.string.isRequired,
};

export default Variant;
