/**
 * Copyright(c) 2023 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Neeraj Raj
 */

import React, { useState, useRef } from "react";

/** ===== COMPONENTS =========== */
import CatalogueMangement from "../components/CatalogueMangement";

/** ========= CUSTOM COMPONENTS ========= */
import AlertModal from "../components/AlertModal";

/** ========= API SERVICE FUNCTIONS ========= */
import uploadCatalogueFile from "../../../api/catalogueService";

const CatalogueManagementContainer = () => {
  const fileSelector = useRef(null);
  const [loading, setLoading] = useState(false);
  const [modalAttibutes, setModalAttributes] = useState({
    isOpen: false,
    content: "",
    header: "",
  });

  /**
  * @param {Event} event
  * This function is used to handle upload catalogue file
  */
  const handleUploadFile = async (event) => {
    if (event && event.target && event.target.files) {
      const fileData = event.target.files[0] || "";
      if (fileData) {
        if (fileData.type !== "application/pdf") {
          setModalAttributes({ isOpen: true, content: "Accepts only pdf format", header: "Note" });
          return;
        }
        setLoading(true);
        await uploadCatalogueFile(fileData).then((response) => {
          if (response && response.success && response.data) {
            setModalAttributes({ isOpen: true, content: "Catalogue file uploaded successfully", header: "Success" });
          } else {
            setModalAttributes({ isOpen: true, content: "Loading error. Please try again", header: "Error" });
          }
        }).catch(() => {
          setModalAttributes({ isOpen: true, content: "Loading error. Please try again", header: "Error" });
        }).finally(() => {
          setLoading(false);
        });
      }
    }
    /** [fix] No file change trigger when select same file 2nd time in multi select enabled  */
    if (fileSelector && fileSelector.current) fileSelector.current.value = null;
  };

  return (
    <>
      <CatalogueMangement
        loading={loading}
        fileSelector={fileSelector}
        handleUploadFile={handleUploadFile}
      />
      <AlertModal
        isOpen={modalAttibutes.isOpen}
        content={modalAttibutes.content}
        header={modalAttibutes.header}
        togglClose={() => setModalAttributes({
          isOpen: false,
          content: "",
          header: "",
        })}
      />
    </>
  );
};

export default CatalogueManagementContainer;
