/**
 * Copyright(c) 2022 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Abdul Razack
 */

import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ===== TAG COMPONENTS =========== */
import Row from "../../../common/core/Row";
import BulkUploadHistory from "../containers/BulkUploadHistoryContainer";
import Col from "../../../common/core/Col";
import Button from "../../../common/core/Button";
import Alert from "../../../common/core/Alert";
import Select from "../../../common/components/Select";
import Spinner from "../../../common/core/Spinner";
import UncontrolledTooltip from "../../../common/core/UncontrolledTooltip";

/** ===== UTILS =========== */
import {
  getNameByType,
  getFilenameByType,
  getJobTypeByType,
} from "../../../common/utils/bulkUploadUtils";
import constants from "../../../common/utils/constants";

/** ===== IMAGES/ICONS =========== */
import downloadIcon from "../../../common/assets/images/svg/file-upload.svg";
import publishIcon from "../../../common/assets/images/svg/publish.svg";
import refreshIcon from "../../../common/assets/images/svg/sync.svg";

/** ===== MODULE STYLES =========== */
import styles from "../css/ColorPaletteManagement.module.css";

const UploadManagement = ({
  DataUploadModes,
  selectedUploadMode,
  isExporting,
  isUploading,
  isProcessingUploadJob,
  shadeExportResult,
  handleUploadMode,
  handleUploadBulkImport,
  downloadBulkImportTemplate,
  exportColorPalette,
  message,
  documentDownloadSteps,
  maintenanceType,
  checkShadeExportStatus,
  checkingExportStatus,
  refreshBulkUploadData,
}) => {
  const {
    COLOR_PALETTE_MANAGEMENT, JOB_TYPES: {
      PRODUCT_SHADE_EXPORT_JOB,
    },
    PRODUCT_BULK_MAINTENANCE: {
      COLOR_PALETTE_UPLOAD,
        QUEUED,
        FAILED,
    },
  } = constants;

  const { t } = useTranslation(["common", "globalSettings", "product-bulk-maintenance"]);
  return (
    <>
      <Row className={clsx(styles.block2, styles.block2Wrapper, "common-page-card")}>
        <Col xs="12">
          <span
            className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}
          >
            {t(`product-bulk-maintenance:${getNameByType(maintenanceType)}`)}
          </span>
          <Row className="align-items-center my-5">
            <Col xs="auto">
              <span
                className={clsx(styles.pageSelectionText, "formTextLabel")}
              >
                {t("product-bulk-maintenance:label_data_upload_mode")}
              </span>
            </Col>
            <Col xs="auto">
              <Select
                className="formText "
                placeholder="Select upload mode"
                id="upload-mode"
                value={selectedUploadMode.name}
                onChange={(e) => {
                  handleUploadMode(e.target.value);
                }}
                options={[
                  ...Array.isArray(DataUploadModes) ? DataUploadModes.map((DataUploadMode) => (
                    { value: DataUploadMode.code, label: DataUploadMode.name })) : [],
                ]}
              />
            </Col>
            <Col xs="auto">
              <Button
                className="common-button-white"
                role="presentation"
                id="bulk-upload-template-download"
                onClick={() => downloadBulkImportTemplate(
                  getFilenameByType(maintenanceType),
                )}
              >
                <img
                  src={downloadIcon}
                  alt="download"
                />
              </Button>
              <UncontrolledTooltip
                target="bulk-upload-template-download"
                placement="bottom"
              >
                Download template
              </UncontrolledTooltip>
            </Col>
            <Col xs="auto">
              <Button
                className="common-primary-button"
                id="bulk-upload-button"
                disabled={isProcessingUploadJob}
              >
                <label htmlFor="colorPaletteFile" className="commonPointer w-100 h-100 m-0">
                  {
                    isUploading
                      ? <Spinner size="sm" />
                      : (
                        <img
                          src={publishIcon}
                          alt="upload"
                        />
                      )
                  }
                  <input
                    name="colorPalette"
                    id="colorPaletteFile"
                    accept={COLOR_PALETTE_MANAGEMENT.BULK_UPLOAD_LIMIT}
                    type="file"
                    className="d-none"
                    disabled={isProcessingUploadJob}
                    onChange={
                      (e) => handleUploadBulkImport(e)
                    }
                  />
                </label>
              </Button>
            </Col>

          </Row>
          {
            maintenanceType === COLOR_PALETTE_UPLOAD ? (
              <>
                <Row className="mb-5 align-items-center">
                  <Col xs="auto">
                    <span
                      className={clsx(styles.pageSelectionText, "formTextLabel")}
                    >
                      {t("product-bulk-maintenance:label_download_color_palette")}
                    </span>
                  </Col>
                  <Col xs="auto">
                    <Button
                      onClick={() => exportColorPalette()}
                      className="common-primary-button"
                    >
                      {t("product-bulk-maintenance:button_export")}
                    </Button>
                  </Col>
                  <Col xs="auto">
                    <img
                      onClick={() => checkShadeExportStatus()}
                      src={refreshIcon}
                      alt="upload"
                      role="presentation"
                      className="mr-2 commonPointer"
                    />
                  </Col>
                  {shadeExportResult.id && !isExporting && (
                    <div className="formTextLabel" align="center" valign="center">
                      <Button
                        className="btn btn-sm btn-info text-white"
                        role="presentation"
                        id="bulk-upload-shade-download"
                        onClick={() => documentDownloadSteps(
                          shadeExportResult.id, PRODUCT_SHADE_EXPORT_JOB,
                        )}
                      >
                        {t("product-bulk-maintenance:label_download_product")}
                      </Button>
                    </div>
                  )}
                </Row>
              </>
            ) : ""
          }
          <Row>
            <Col className="mb-2" xs="12" sm="12" md="12">
              {isExporting && maintenanceType === COLOR_PALETTE_UPLOAD
                ? (
                  <div className="formTextLabel" align="center" valign="center">
                    <Spinner size="sm" />
                    <img src={publishIcon} alt="loading" />
                    {checkingExportStatus ? t("product-bulk-maintenance:alert_checking_export_status") : t("product-bulk-maintenance:alert_download_color_palette_started")}
                  </div>
                )
                : ""}
            </Col>
            {
              message && message.type && (
                <Alert color={message.type}>
                  {message.message}
                </Alert>
              )
            }
          </Row>
          <Row className={clsx(styles.block2, styles.block2Wrapper, "common-page-card")}>
            <Col xs="12" className="py-2">
              <span
                className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}
              >
                {t("product-bulk-maintenance:label_queued")}

              </span>
              <BulkUploadHistory
                uploadModes={DataUploadModes}
                maintenanceType={maintenanceType}
                documentDownloadSteps={documentDownloadSteps}
                getJobTypeByType={getJobTypeByType}
                status={QUEUED}
              />
            </Col>
          </Row>
          <Row className={clsx(styles.block2, styles.block2Wrapper, "common-page-card")}>
            <Col xs="12" className="py-2">
              <span
                className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}
              >
                {t("product-bulk-maintenance:label_completed")}

              </span>
              <BulkUploadHistory
                uploadModes={DataUploadModes}
                maintenanceType={maintenanceType}
                documentDownloadSteps={documentDownloadSteps}
                getJobTypeByType={getJobTypeByType}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
UploadManagement.defaultProps = {
  isUploading: false,
  isProcessingUploadJob: false,
  maintenanceType: "",
  documentDownloadSteps: () => { },
};
UploadManagement.propTypes = {
  DataUploadModes: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedUploadMode: PropTypes.objectOf(PropTypes.any).isRequired,
  isExporting: PropTypes.bool.isRequired,
  maintenanceType: PropTypes.string,
  isUploading: PropTypes.bool,
  isProcessingUploadJob: PropTypes.bool,
  shadeExportResult: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleUploadMode: PropTypes.func.isRequired,
  handleUploadBulkImport: PropTypes.func.isRequired,
  downloadBulkImportTemplate: PropTypes.func.isRequired,
  exportColorPalette: PropTypes.func.isRequired,
  documentDownloadSteps: PropTypes.func,
  message: PropTypes.objectOf(PropTypes.any).isRequired,
  checkShadeExportStatus: PropTypes.func.isRequired,
  refreshBulkUploadData: PropTypes.func.isRequired,
};

export default UploadManagement;
