/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * cropper utils
 *
 * @author Naseef O
 *
 */


/**
 * This method create an image
 *
 * @param {*} url
 */
const createImage = (url) => new Promise((resolve, reject) => {
  const image = new Image();
  image.addEventListener("load", () => resolve(image));
  image.addEventListener("error", (error) => reject(error));
  image.setAttribute("crossOrigin", "anonymous");
  image.src = url;
});


/**
 * This method is used to get radian angle
 *
 * @param {*} degreeValue
 * @returns
 */
const getRadianAngle = (degreeValue) => (degreeValue * Math.PI) / 180;

/**
 * This method is used to get cripeed image
 *
 * @export
 * @param {*} imageSrc
 * @param {*} pixelCrop
 * @param {number} [rotation=0]
 * @returns
 */
const getCroppedImg = async (imageSrc, pixelCrop, rotation = 0) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  const safeArea = Math.max(image.width, image.height) * 2;
  canvas.width = safeArea;
  canvas.height = safeArea;
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);
  ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);
  const data = ctx.getImageData(0, 0, safeArea, safeArea);
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  ctx.putImageData(
    data,
    0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
    0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y,
  );

  return canvas.toDataURL();
};


/**
 * This method is used to convert dataUrl/base64Url to file
 *
 * @param {*} dataurl
 * @param {*} filename
 * @returns
 */
const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n > 0) {
    n -= 1;
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

/**
 * This method is used to read URL
 *
 * @param {*} file
 */
const readURL = (file) => new Promise((res, rej) => {
  const reader = new FileReader();
  reader.onload = (e) => res(e.target.result);
  reader.onerror = (e) => rej(e);
  reader.readAsDataURL(file);
});


/**
* This method is used to parse url to youtube id
* for construct thumb image of youtube video
*
* @param {String} url
* @returns
*/
export const youtubeThumbnailExtractor = (url) => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url ? String(url).match(regExp) : false;
  return (match && match[7].length === 11) ? match[7] : false;
};

/**
* This method is used to check wether ulr is youtube or not.
*
* @param {String} url
* @returns
*/
export const matchYoutubeUrl = (url) => {
  const p = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  const match = url ? String(url).match(p) : false;
  if (match) {
    return url.match(p)[1];
  }
  return false;
};

export default {
  getCroppedImg,
  dataURLtoFile,
  readURL,
};
