/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * ExternalCategoriesContainer
 *
 * @author Naseef O
 *
 */

import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import ExternalCategories from "../components/ExternalCategories";

import { getExternalCategories, getRootExternalCategories } from "../../../api/externalCategoryServices";

import constants from "../../../common/utils/constants";
import commonUtils from "../../../common/utils/commonUtils";

const ExternalCategoriesContainer = () => {
  const location = useLocation();
  const { view } = commonUtils.getQueryParams(location);
  const [pagination, setPagination] = useState({
    page: 0,
    totalPages: 0,
    totalCount: 0,
    limit: 6,
    numberOfElements: 0,
  });
  const [categories, setCategories] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [dropdownOpen, setDropdown] = useState(false);
  const [selectedFilter, setFilter] = useState(null);
  const [loading, setLoading] = useState(false);
  /**
   * This method is used to valid table view
   *
   * @returns
   */
  const getValidTableView = () => {
    let validView = constants.TABLE_VIEW.TREE_VIEW;
    if (view === constants.TABLE_VIEW.LIST_VIEW) {
      validView = constants.TABLE_VIEW.LIST_VIEW;
    }
    return validView;
  };
  const [tableView, setTabView] = useState(getValidTableView());


  const history = useHistory();

  /**
   * This method is used to handle change search
   * @param {Object} e
   */
  const handleSearch = async (e) => {
    const key = e.target.value;
    setSearchKey(key);
  };

  /**
   * This method is used redirect to details page
   */
  const handleRedirectToDetails = (id) => {
    history.push(`/merchandising/taxonomy-mapping/details/${id}?view=${tableView}`);
    window.scroll(0, 0);
  };

  /**
   * This method is used to toggle dropdown
   */
  const toggleDropdown = () => {
    setDropdown(!dropdownOpen);
  };

  /**
   * This method is used to select filter
   * @param {Object} selected
   */
  const handleSelectFilter = (selected) => {
    if (selected) {
      setFilter(selected);
      toggleDropdown();
    } else {
      setFilter(null);
    }
  };

  /**
   * This method is used get external categories
   * @param {Number} pageNo
   */
  const getPageData = (pageNo) => {
    getExternalCategories(
      searchKey.trim(),
      selectedFilter ? selectedFilter.value : null,
      pageNo,
    ).then((response) => {
      if (response && response.success && response.data) {
        const {
          content, totalPages, totalElements, endOfResult,
        } = response.data;
        setPagination({
          ...pagination,
          page: pageNo,
          totalPages,
          totalCount: totalElements,
          numberOfElements: !endOfResult
            ? pagination.limit * (pageNo + 1)
            : totalElements,
        });
        setCategories(content);
      }
    });
  };

  /**
 * This method is used to toggle table view
 *
 * @param {*} id
 */
  const toggleTableView = (id) => {
    setTabView(id);
  };

  const clearData = () => {
    setSearchKey("");
    setFilter(null);
    setPagination({
      page: 0,
      totalPages: 0,
      totalCount: 0,
      limit: 6,
      numberOfElements: 0,
    });
  };

  useEffect(() => {
    setCategories([]);
    setLoading(true);
    if (tableView === constants.TABLE_VIEW.LIST_VIEW) {
      getExternalCategories(
        searchKey.trim(),
        selectedFilter ? selectedFilter.value : null,
      ).then((response) => {
        if (response && response.success && response.data) {
          const {
            content, totalPages, totalElements, numberOfElements: totalItems,
          } = response.data;
          setPagination({
            ...pagination,
            page: 0,
            totalPages,
            totalCount: totalElements,
            numberOfElements: totalItems,
          });
          setCategories(content);
        }
      });
    } else {
      getRootExternalCategories().then((response) => {
        if (response && response.success && response.data) {
          setCategories(response.data);
          clearData();
        } else {
          setCategories([]);
          clearData();
        }
      });
    }

    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, [selectedFilter, searchKey, tableView]);


  return (
    <ExternalCategories
      page={constants.CATEGORY_PAGE_TYPE.EXTERNAL_CATEGORY_PAGE}
      loading={loading}
      tableView={tableView}
      searchKey={searchKey}
      setTabView={setTabView}
      pagination={pagination}
      categories={categories}
      getPageData={getPageData}
      handleSearch={handleSearch}
      dropdownOpen={dropdownOpen}
      selectedFilter={selectedFilter}
      toggleDropdown={toggleDropdown}
      toggleTableView={toggleTableView}
      handleSelectFilter={handleSelectFilter}
      handleRedirectToDetails={handleRedirectToDetails}
    />
  );
};

export default ExternalCategoriesContainer;
