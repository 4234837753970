/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Sreelekshmi Prasannan
 * */

import httpApi from "./httpApi";

/**
 * This method is used  to get list of  badge codes
 * */

const getBadges = async (searchTerm, page, size, sortBy, sortDirection) => {
  const response = await httpApi.get("v1/badges", {
    params: {
      searchTerm, page, size, sortBy, sortDirection,
    },
  });
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to crate new badge
 * @param {Object} data
 * @returns {Object}
 */
const addBadge = async (data) => {
  const response = await httpApi.post("v1/badges", data);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to get badges by Id
 * @param {String}
 * @returns {Object}
 */
const getBadge = async (badgeId, locale) => {
  const params = { locale }
  const response = await httpApi.get(`v1/badges/id/${badgeId}`, { params });
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to update badge details
 * @param {Object} data
 */
const updateBadge = async (data, locale) => {
  const params = { locale }
  const response = await httpApi.patch("v1/badges", data, { params });
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to delete a badge
 * @param {String} badgeId
 */
const deleteBadge = async (badgeId) => {
  const response = await httpApi.delete(`v1/badges/id/${badgeId}`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to set active flag 
 *
 * @returns
 */
const activateCustomBadge = async (id) => {
  const response = await
    httpApi.patch(`v1/badges/status/id/${id}/activate`);
  if (response && response.data.success && response.data) {
    return response.data;
  }
  return null;
};

const deactivateCustomBadge = async (id) => {
  const response = await
    httpApi.patch(`v1/badges/status/id/${id}/deactivate`);
  if (response && response.data.success && response.data) {
    return response.data;
  }
  return null;
};


export {
  getBadges, addBadge, getBadge, updateBadge, deleteBadge, activateCustomBadge, deactivateCustomBadge
};
