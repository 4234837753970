/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * ProductDetailsContainer
 *
 * @author Naseef O
 */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Variant from "../components/Variant";
import offerServices from "../../../api/offerServices";
import { getPriceAndStoreInventories } from "../../../api/productManagementServices";

const VariantsContainer = (props) => {
  const {
    variant: product, toggleVariant, selectedVariant, key,
  } = props;
  const [variant, setVariant] = useState(null);
  const [offers, setOffers] = useState(null);
  const [pagination, setPagination] = useState({
    page: 0,
    totalPages: 0,
    totalCount: 0,
    limit: 5,
    numberOfElements: 0,
  });

  useEffect(() => {
    if (product && !product.vendorFulfilled) {
      const variantData = { ...product };
      const reqObject = [{
        productId: product.id,
        skuId: product.defaultSKU,
        vendorFulfilled: product.vendorFulfilled,
        uniqueId: product.uniqueId,
        productBrand: product.productBrand
      }];
      getPriceAndStoreInventories(reqObject).then((responseR) => {
        if (responseR && responseR.success && responseR.data) {
          const priceObject = responseR.data[`${variantData.defaultSKU}`];
          variantData.priceInfo = priceObject.price;
          variantData.inventory = priceObject.inventory;
        }
      });
      setVariant(variantData);
    }
  }, [product]);
  /**
   * This method is used get offers with pagination
   * @param {Number} pageNo
   */
  const getPageData = (pageNo) => {
    offerServices.getOffersByProductId(product.id, pageNo).then((response) => {
      if (response && response.success && response.data) {
        const {
          content, totalPages, totalElements, endOfResult,
        } = response.data;
        setPagination({
          ...pagination,
          page: pageNo,
          totalPages,
          totalCount: totalElements,
          numberOfElements: !endOfResult
            ? pagination.limit * (pageNo + 1)
            : totalElements,
        });
        setOffers(content);
      }
    });
  };

  useEffect(() => {
    if (product.vendorFulfilled || !product.sellerRestriction) {
      offerServices.getOffersByProductId(product.id).then((responseIn) => {
        if (responseIn && responseIn.success && responseIn.data) {
          const {
            content, totalPages, totalElements, numberOfElements: totalItems,
          } = responseIn.data;
          setPagination({
            ...pagination,
            page: 0,
            totalPages,
            totalCount: totalElements,
            numberOfElements: totalItems,
          });
          setOffers(content);
        }
      });
    }
  }, [product]);

  return variant && (
    <Variant
      key={key}
      offers={offers}
      variant={variant}
      toggleVariant={toggleVariant}
      selectedVariant={selectedVariant}
      pagination={pagination}
      getPageData={getPageData}
    />
  );
};

VariantsContainer.propTypes = {
  variant: PropTypes.objectOf(PropTypes.any).isRequired,
  toggleVariant: PropTypes.func.isRequired,
  selectedVariant: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
};

export default VariantsContainer;
