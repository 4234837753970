/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Mohammed Shefeeq
 */

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import JobScheduler from "../components/JobScheduler";
import {
  getBatchSchedulers, changeStatus, restartInactiveScheduler,
} from "../../../api/jobSchedulerService";

const JobSchedulerContainer = () => {
  const history = useHistory();
  const [schedulerList, setSchedulerList] = useState([]);
  const [editable, setEditable] = useState(false);

  function transformSchedulerResponse(schedulerDetails) {
    const schedulers = [];
    schedulerDetails.map((jobSchedule) => {
      const schedulerListItem = {};
      schedulerListItem.type = jobSchedule.type;
      schedulerListItem.name = jobSchedule.name;
      schedulerListItem.id = jobSchedule.id;
      schedulerListItem.jobConfigId = jobSchedule.jobConfigId;
      schedulerListItem.nextJobExecutionTime = jobSchedule.nextJobExecutionTime;
      schedulerListItem.prvsJobCompletionTime = jobSchedule.prvsJobCompletionTime;
      schedulerListItem.status = jobSchedule.status;
      schedulerListItem.enabled = jobSchedule.enabled;
      schedulerListItem.active = jobSchedule.active;
      schedulers.push(schedulerListItem);
      return null;
    });
    return schedulers;
  }

  function getSchedulerDetails() {
    getBatchSchedulers().then(async (schedulerResponse) => {
      if (schedulerResponse && schedulerResponse.success && schedulerResponse.data) {
        const { data } = schedulerResponse;
        if (data && Array.isArray(data) && data.length > 0) {
          const schedulers = transformSchedulerResponse(data);
          setSchedulerList(schedulers);
        }
      }
    });
  }

  const changeJobStatus = async (id) => {
    const response = await changeStatus(id);
    if (response && response.success && response.data && response.data === true) {
      getSchedulerDetails();
    }
  };

  const onHandleSchedularStatus = async (id) => {
    const response = await restartInactiveScheduler(id);
    if (response && response.success && response.data && response.data === true) {
      getSchedulerDetails();
    }
  };


  useEffect(() => {
    getSchedulerDetails();
  }, []);

  const onHandleEditClass = (id) => {
    setEditable(true);
    history.push(`/administration/job-scheduler/edit/${id}`);
  };
  return (
    <JobScheduler
      schedulerList={schedulerList}
      handleJobStatus={changeJobStatus}
      onHandleEditClass={onHandleEditClass}
      handleSchedularStatus={onHandleSchedularStatus}
    />
  );
};

export default JobSchedulerContainer;
