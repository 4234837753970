// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Copyright(c) 2021 Mozanta Technologies Private Ltd.\n *\n * All rights reserved.\n *\n * This software is the confidential and proprietary information of Mozanta (\"Confidential\n * Information\"). You shall not disclose such Confidential Information and shall use it only in\n * accordance with the terms of the contract agreement you entered into with Mozanta.\n *\n * @author Mohammed Shefeeq\n */\n\n.PriceReport_block1__1rrv7 .PriceReport_buttonNewUser__2ZqqE {\n    color: #ffffff;\n    width: 113px;\n    border-radius: 2px;\n    background-color: #e91e63;\n    border: 0px;\n    float: right;\n}\n\n\n/* block 2 */\n\n.PriceReport_tableWrapper__35ORA {\n    padding-bottom: 20px;\n    background: #ffffff;\n    margin: 0px !important;\n    min-height: calc(100vh - 408px);\n    margin-bottom: 10px !important;\n}\n\n\n/* block 3 */\n\n.PriceReport_block3__3maUw .PriceReport_dropdownBtn__bqsqk {\n    padding: 0px;\n}\n\n.PriceReport_block3__3maUw .PriceReport_dropdownBtn__bqsqk button {\n    float: right !important;\n    font-size: 11px;\n    min-width: 100px;\n}\n\n.PriceReport_block3__3maUw .PriceReport_dateFilterRow__1C_if {\n    border-radius: 4px;\n    border: 1px solid #dff3f7;\n    background-color: #fdfdfd;\n    color: black;\n}\n\n.PriceReport_block3__3maUw .PriceReport_verticalLine__85Lzv {\n    height: 15px;\n    padding: 5px;\n}\n\n.PriceReport_block3__3maUw .PriceReport_verticalLineNone__3l5RZ {\n    padding: 5px;\n    border-radius: 4px;\n}\n\n.PriceReport_block3__3maUw .PriceReport_verticalLine__85Lzv:hover {\n    background-color: #17a2b8;\n    border-radius: 4px;\n    color: rgb(255, 255, 255);\n}\n\n.PriceReport_block3__3maUw .PriceReport_verticalLineNone__3l5RZ:hover {\n    background-color: #17a2b8;\n    border-radius: 4px;\n    color: rgb(255, 255, 255);\n}", ""]);
// Exports
exports.locals = {
	"block1": "PriceReport_block1__1rrv7",
	"buttonNewUser": "PriceReport_buttonNewUser__2ZqqE",
	"tableWrapper": "PriceReport_tableWrapper__35ORA",
	"block3": "PriceReport_block3__3maUw",
	"dropdownBtn": "PriceReport_dropdownBtn__bqsqk",
	"dateFilterRow": "PriceReport_dateFilterRow__1C_if",
	"verticalLine": "PriceReport_verticalLine__85Lzv",
	"verticalLineNone": "PriceReport_verticalLineNone__3l5RZ"
};
module.exports = exports;
