/**
 * Copyright(c) 2022 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Abdul Razack
 */
import constants from "./constants";
import commonUtils from "./commonUtils";

const { isListNotEmpty } = commonUtils;

const {
  COLOR_PALETTE_MANAGEMENT,
  PRODUCT_MAPPING_MANAGEMENT,
  PRODUCT_BULK_MAINTENANCE: {
    PRODUCT_MAPPING_UPLOAD, COLOR_PALETTE_UPLOAD,
    DATA_UPLOAD_MODES, PRODUCT_MAPPING_UPLOAD_MODES,

  },
  JOB_TYPES: {
    PRODUCT_SHADE_EXPORT_JOB,
    PRODUCT_CATEGORY_MAPPING,
    COLOR_PALETTE_UPLOAD_JOB,
  },
} = constants;

const getConstantsByType = (type) => {
  switch (type) {
    case PRODUCT_MAPPING_UPLOAD:
      return PRODUCT_MAPPING_MANAGEMENT;
    case COLOR_PALETTE_UPLOAD:
      return COLOR_PALETTE_MANAGEMENT;
    default:
      return null;
  }
};

export const getModesByType = (type) => {
  switch (type) {
    case PRODUCT_MAPPING_UPLOAD:
      return PRODUCT_MAPPING_UPLOAD_MODES;
    case COLOR_PALETTE_UPLOAD:
      return DATA_UPLOAD_MODES;
    default:
      return null;
  }
};


export const getNameByType = (type) => {
  switch (type) {
    case PRODUCT_MAPPING_UPLOAD:
      return "sub_title_product_mapping_upload";
    case COLOR_PALETTE_UPLOAD:
      return "sub_title_color_palette_upload";
    default:
      return null;
  }
};

export const getFilenameByType = (type) => {
  switch (type) {
    case PRODUCT_MAPPING_UPLOAD:
      return "productMapping";
    case COLOR_PALETTE_UPLOAD:
      return "colourPalettes";
    default:
      return null;
  }
};

export const getJobTypeByType = (type) => {
  switch (type) {
    case PRODUCT_MAPPING_UPLOAD:
      return PRODUCT_CATEGORY_MAPPING;
    case COLOR_PALETTE_UPLOAD:
      return COLOR_PALETTE_UPLOAD_JOB;
    default:
      return PRODUCT_SHADE_EXPORT_JOB;
  }
};

const isValidHeaders = (rawData, header, type) => {
  const currentHeader = header;
  let result = true;
  if (currentHeader.length === getConstantsByType(type).HEADER_KEYS.length) {
    for (let i = 0; i < currentHeader.length; i += 1) {
      if (currentHeader[i].trim() !== getConstantsByType(type).HEADER_KEYS[i].trim()) {
        result = false;
        break;
      }
    }
  } else {
    result = false;
  }
  return result;
};

const validateColumnValues = (data, index, type) => {
  const columnValidationResult = [];
  let errorObj = {};
  Object.entries(getConstantsByType(type).FIELD_VALIDATIONS).forEach(async ([key, value]) => {
    if (value.REQUIRED && (!data[value.name]
      || !(data[value.name] && data[value.name].toString().trim()).length > 0)) {
      errorObj = {};
      errorObj.isError = true;
      errorObj.row = index + 2;
      errorObj.message = `The value of ${value.name} is empty, found in Row: ${index + 2}. Please refer the template for valid data format.`;
      columnValidationResult.push(errorObj);
    } else if (data[value.name] && (data[value.name].toString().trim()).length > value.MAX_LENGTH) {
      errorObj = {};
      errorObj.isError = true;
      errorObj.row = index + 2;
      errorObj.message = `The length of the value in ${value.name} is greater than the ${value.MAX_LENGTH}. Please refer the template for valid data format.`;
      columnValidationResult.push(errorObj);
    }
  });
  return columnValidationResult;
};
const removeWhiteSpaceFromKeys = (data) => {
  const formattedObject = {};
  const keys = Object.keys(data);
  keys.forEach((key) => {
    formattedObject[`${key.trim()}`] = data[key];
  });
  return formattedObject;
};
const validateEntries = async (rawData, type) => {
  let validationResult = [];
  const formattedData = [];
  const uniqueEntryIds = [];
  let errorObj = {};
  await rawData.forEach(async (data, index) => {
    const whiteSpaceRemovedData = await removeWhiteSpaceFromKeys(data);
    if (uniqueEntryIds.includes(`${whiteSpaceRemovedData["SAP ARTICLE"]}*${whiteSpaceRemovedData["Colors Code"]}`)) {
      errorObj = {};
      errorObj.isError = true;
      errorObj.row = index + 2;
      errorObj.message = `Duplicated entries for SAP ARTICLE: ${whiteSpaceRemovedData["SAP ARTICLE"]} and Colors Code: ${whiteSpaceRemovedData["Colors Code"]} found in Row: ${index + 2}.  Please refer the template for valid data format.`;
      validationResult.push(errorObj);
    } else {
      uniqueEntryIds.push(`${whiteSpaceRemovedData["SAP ARTICLE"]}*${whiteSpaceRemovedData["Colors Code"]}`);
    }
    const columnValidationResult = await validateColumnValues(whiteSpaceRemovedData, index, type);
    if (columnValidationResult && columnValidationResult.length > 0) {
      validationResult = [...validationResult, ...columnValidationResult];
    }
    formattedData.push(whiteSpaceRemovedData);
  });
  return { validationResult, formattedData };
};


const parseDataFromSheet = async (rawData, header, type) => {
  if (isListNotEmpty(rawData)) {
    if (!isValidHeaders(rawData, header, type)) {
      return {
        isValid: false,
        message: "Invalid sheet format. Column headers are mismatched, Please refer the template for valid data format.",
      };
    }
    if (rawData.length > getConstantsByType(type).BULK_UPLOAD_LIMIT) {
      return {
        isValid: false,
        message: ` You are uploading ${rawData.length} entries but the maximum allowed limit is ${getConstantsByType(type).BULK_UPLOAD_LIMIT}.`,
      };
    }

    return {
      isValid: true,
      // formattedData: validationResponse.formattedData,
    };

    // TODO: BELOW CODE IS COOMENTED TO AVOID FRONT END COLUMN VALIDATION
    // const validationResponse = await validateEntries(rawData);
    // if (validationResponse?.validationResult.length > 0) {
    //   return {
    //     isValid: false,
    //     message: "Invalid values. Please refer the template for valid data format.",
    //     errors: validationResponse.validationResult,
    //   };
    // } else {
    //   return {
    //     isValid: true,
    //     formattedData: validationResponse.formattedData,
    //   };
    // }
  }
  return {
    isValid: false,
    message: "No Data Found",
  };
};


const ColourPaletteMapper = [{
  " Colors Arabic": "<Colors Arabic 1>",
  Colors: "<Colors 1>",
  "Colors Code": "<Colors Code 1>",
  Brand: "<Brand 1>",
  Product: "<Product 1>",
  Size: "<Size 1>",
  Base: "<Base 1>",
  "SAP ARTICLE": "<SAP ARTICLE 1>",
  Red: "<Red 1>",
  Green: "<Green 1>",
  Blue: "<Blue 1>",
  "RGB HEX": "<RGB HEX 1>",
},
{
  " Colors Arabic": "<Colors Arabic 2>",
  Colors: "<Colors 2>",
  "Colors Code": "<Colors Code 2>",
  Brand: "<Brand 2>",
  Product: "<Product 2>",
  Size: "<Size 2>",
  Base: "<Base 2>",
  "SAP ARTICLE": "<SAP ARTICLE 2>",
  Red: "<Red 2>",
  Green: "<Green 2>",
  Blue: "<Blue 2>",
  "RGB HEX": "<RGB HEX 2>",
},
{
  " Colors Arabic": "<Colors Arabic 3>",
  Colors: "<Colors 3>",
  "Colors Code": "<Colors Code 3>",
  Brand: "<Brand 3>",
  Product: "<Product 3>",
  Size: "<Size 3>",
  Base: "<Base 3>",
  "SAP ARTICLE": "<SAP ARTICLE 3>",
  Red: "<Red 3>",
  Green: "<Green 3>",
  Blue: "<Blue 3>",
  "RGB HEX": "<RGB HEX 3>",
},
{
  " Colors Arabic": "<Colors Arabic 4>",
  Colors: "<Colors 4>",
  "Colors Code": "<Colors Code 4>",
  Brand: "<Brand 4>",
  Product: "<Product 4>",
  Size: "<Size 4>",
  Base: "<Base 4>",
  "SAP ARTICLE": "<SAP ARTICLE 4>",
  Red: "<Red 4>",
  Green: "<Green 4>",
  Blue: "<Blue 4>",
  "RGB HEX": "<RGB HEX 4>",
},
];

const ProductMappingMapper = [
  {
    "Category ID": "<Category ID>",
    "Unique ID": "<Unique ID>",
    "Product ID": "<Product ID>",
  },
  {
    "Category ID": "<Category ID>",
    "Unique ID": "<Unique ID>",
    "Product ID": "<Product ID>",
  },
  {
    "Category ID": "<Category ID>",
    "Unique ID": "<Unique ID>",
    "Product ID": "<Product ID>",
  },
  {
    "Category ID": "<Category ID>",
    "Unique ID": "<Unique ID>",
    "Product ID": "<Product ID>",
  },
];

const getImportSheetTemplate = (type) => {
  switch (type) {
    case PRODUCT_MAPPING_UPLOAD:
      return ProductMappingMapper;
    case COLOR_PALETTE_UPLOAD:
      return ColourPaletteMapper;
    default:
      return null;
  }
};

export default {
  parseDataFromSheet,
  getImportSheetTemplate,
};
