/* eslint-disable react/jsx-props-no-spreading */

/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Drag and droppable item table
 *
 * @author Naseef O
 *
 */


import React from "react";
import PropTypes from "prop-types";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { get } from "lodash";
import deleteIcon from "../../../common/assets/images/svg/delete.svg";
import dragIndicatorIcon from "../../../common/assets/images/svg/dragIndicator.svg";
import Table from "../../../common/core/Table";
import Spinner from "../../../common/core/Spinner";
import styles from "../css/Table.module.css";
import transformers from "../../../common/utils/transformers";

const RowItem = (props) => {
  const {
    rowItem,
    index,
    pageCustomizationRows,
    handleDelete,
    isLoading,
    selectedIndex,
  } = props;

  /**
   *This method is used to get a value from an item
   *
   * @param {object} row
   * @param {object} rowItemData
   * @returns
   */
  const getItem = (row, rowItemData) => {
    let data = get(row, rowItemData.path);
    if (rowItemData.transform && rowItemData.transform !== "") {
      data = transformers[rowItemData.transform](row, rowItemData.path);
    }
    return data;
  };

  return (
    <Draggable draggableId={Math.random().toString(36).slice(2)} width="100%" index={index}>
      {(provided) => (
        <tr
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <th scope="row" width="10%">
            <img src={dragIndicatorIcon} alt="drag indiactor icon" width="30px" height="24px" />
            <span className="p-2">{index + 1}</span>
          </th>
          <td width="10%">
            {rowItem.imageUrl ? <img src={getItem(rowItem, { path: "imageUrl" })} alt="preview" width="60px" /> : <span className="p-2">No image</span>}
          </td>
          {pageCustomizationRows.map((row) => <td width="10%">{getItem(rowItem, row)}</td>)}
          <td width="10%">
            {isLoading
              && (selectedIndex === index)
              ? (
                <Spinner
                  className={styles.spinnerWrapper}
                />
              )
              : (
                <img
                  src={deleteIcon}
                  className="commonPointer"
                  alt="drag indiactor icon"
                  width="20px"
                  role="presentation"
                  onClick={() => handleDelete(rowItem.id, index)}
                />
              )}
          </td>
        </tr>
      )}
    </Draggable>
  );
};


RowItem.propTypes = {
  pageCustomizationRows: PropTypes.arrayOf(PropTypes.any).isRequired,
  rowItem: PropTypes.objectOf(PropTypes.any).isRequired,
  index: PropTypes.number.isRequired,
  widgetType: PropTypes.string.isRequired,
  configId: PropTypes.string.isRequired,
  handleRedirectToWidgetContentEditor: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  deleteEnabled: PropTypes.bool.isRequired,
};

const RowsList = React.memo((props) => {
  const {
    rowData,
    pageCustomizationRows,
    handleDelete,
    isLoading,
    selectedIndex,
  } = props;
  return rowData.map((rowItem, index) => (
    <RowItem
      pageCustomizationRows={pageCustomizationRows}
      rowItem={rowItem}
      index={index}
      key={rowItem.id}
      handleDelete={handleDelete}
      isLoading={isLoading}
      selectedIndex={selectedIndex}
    />
  ));
});

RowsList.propTypes = {
  rowData: PropTypes.arrayOf(PropTypes.any).isRequired,
  pageCustomizationRows: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleDelete: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  selectedIndex: PropTypes.number.isRequired,
};

const DragAndDropItemTable = (props) => {
  const {
    customizationData,
    onDragEnd,
    handleDelete,
    isLoading,
    selectedIndex,
    deleteEnabled,
  } = props;


  const pageCustomizationRows = [
    {
      label: "Id",
      value: "",
      path: "id",
    },
    {
      label: "name",
      value: "",
      path: "name",
    },
  ];

  return (
    <Table borderless>
      <thead>
        <tr>
          <th>Order</th>
          <th>Preview</th>
          {pageCustomizationRows.map((row) => <th>{row.label}</th>)}
          <th>Delete</th>
        </tr>
      </thead>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="list">
          {(provided) => (
            <tbody ref={provided.innerRef} {...provided.droppableProps}>
              <RowsList
                rowData={customizationData}
                pageCustomizationRows={pageCustomizationRows}
                handleDelete={handleDelete}
                isLoading={isLoading}
                selectedIndex={selectedIndex}
                deleteEnabled={deleteEnabled}
              />
              {provided.placeholder}
            </tbody>
          )}
        </Droppable>
      </DragDropContext>
    </Table>
  );
};

DragAndDropItemTable.propTypes = {
  pageCustomizationRows: PropTypes.arrayOf(PropTypes.any).isRequired,
  customizationData: PropTypes.arrayOf(PropTypes.any).isRequired,
  onDragEnd: PropTypes.func.isRequired,
  widgetType: PropTypes.string.isRequired,
  configId: PropTypes.string.isRequired,
  handleRedirectToWidgetContentEditor: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  deleteEnabled: PropTypes.bool.isRequired,
};
export default DragAndDropItemTable;
