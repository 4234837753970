/**
 * Copyright(c) 2022 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Rahna
 */

import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ===== TAG COMPONENTS =========== */
import Col from "../../../common/core/Col";
import Row from "../../../common/core/Row";
import Form from "../../../common/core/Form";
import Button from "../../../common/core/Button";

/** ========== SUB COMPONENT ================ */
import ExtendedWarrantyForm from "./EditFormExtendedWarranty";
import LocaleDropdownContainer from "../../LocaleDropdown/containers/LocaleDropdownContainer";

/** ======== MODULE STYLES ========== */
import styles from "../css/EditProductOption.module.css";
import validateForm from "../../../common/utils/validateForm";

const EditExtendedWarranty = (props) => {
  const {
    name,
    productValue,
    price,
    handleChange,
    handleFormChange,
    onCancel,
    onSubmit,
    onSubmitting,
    year,
    localeHandler
  } = props;
  /** internationalization using i18n from common and product  */
  const { t } = useTranslation(["common", "product-option"]);
  const methods = validateForm();

  const {
    handleSubmit
  } = methods;

  return (
    <Row className="">
      <Col xs="12">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <fieldset disabled={onSubmitting}>
            <Row className="pageHeader common-page-card" >
              <Col xs="12" md="7" className="text-truncate">
                <span className="pageText">
                  {t("product-option:title_edit")}
                </span>
              </Col>
              <Col xs="12" md="5" className="d-flex justify-content-end">
                <LocaleDropdownContainer localeHandler={localeHandler} entityName="productOptions" isIndocument={false} />
                <Button type="button" onClick={onCancel} className="mx-1 common-secondary-button">
                  {t("common:button_cancel")}
                </Button>
                <Button type="submit" color="secondary" className="mr-1 common-primary-button">
                  {t("common:button_save")}
                </Button>
              </Col>
            </Row>
            <div className={clsx(styles.blockWrapper, "common-page-card")}>
              <ExtendedWarrantyForm
                /** form data */
                name={name}
                productValue={productValue}
                price={price}
                year={year}
                /** events */
                handleFormChange={handleFormChange}
                handleChange={handleChange}
              />
            </div>
          </fieldset>
        </Form>
      </Col>
    </Row>
  );
};

EditExtendedWarranty.propTypes = {
  /** form data */
  name: PropTypes.string.isRequired,
  year: PropTypes.number.isRequired,
  productValue: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  /** events */
  handleChange: PropTypes.func.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  /** form event */
  onSubmitting: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  localeHandler: PropTypes.func.isRequired,
};

export default EditExtendedWarranty;
