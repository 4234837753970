/*
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Naseef O
 */

import clsx from "clsx";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/** ========= TAG COMPONENTS ========= */
import CustomInput from "../../../common/core/CustomInput";

/** ========= SUB COMPONENT ========= */
/** ========= MODULE STYLES ========= */
import styles from "../css/TreeCategorySelector.module.css";

/** ========= CUSTOM COMPONENTS ========= */

/** ========= API SERVICE FUNCTIONS ========= */
import { getSubCategoriesBySource } from "../../../api/categoryManagementServices";


/** ============ SVG IMAGE ICON ===================== */
import PlusIcon from "../../../common/assets/images/svg/add-24px.svg";
import MinusIcon from "../../../common/assets/images/svg/minus-24px.svg";
import constants from "../../../common/utils/constants";


const Category = (props) => {
  const {
    index,
    id,
    childCategories,
    name,
    active,
    onChange,
    categories,
    handleSelectedCategories,
    updateUniqueTotalCategoryList,
    noProductsOnly,
    childProducts,
    exclude,
    htmlId,
  } = props;

  /** local states */
  const [isOpen, setIsOpen] = useState(false);
  const [categoryList, setCategoryList] = useState(null);
  /** setting details into local state for local changes  */
  const [details, setDetails] = useState({
    id, name, active,
  });

  useEffect(() => {
    setDetails({
      id, name, active,
    });
  }, [id, name, active]);

  /** defines has sub categories  */
  const [hasCategory, setHasCategory] = useState(
    Boolean(Array.isArray(childCategories) && childCategories.length > 0 && active),
  );

  /**
   * This method is used to get category list by parent Category id
   * @param {String} id
   */
  const getCategoryByParenCategory = async (categoryId) => {
    const response = await getSubCategoriesBySource(categoryId, constants.CATEGORY_SOURCE.PIM);
    if (response && response.success === true) {
      const { data } = response;
      if (data) {
        setHasCategory(details.active);
        setCategoryList(data);
        updateUniqueTotalCategoryList(data);
      } else {
        setHasCategory(false);
      }
    }
  };

  /**
 * This method is sued to toggle the catalogue collapse
 * call sub category list when its open
 * @param {String} categoryId
 */
  const toggle = (categoryId) => {
    if (hasCategory) {
      setIsOpen(!isOpen);
      if (!isOpen) { getCategoryByParenCategory(categoryId); }
    }
  };

  /**
   * This method used to position sub category - left
   * @param {Number} limit
   */
  const getDivForPositioning = (limit) => {
    const positionComponents = [];
    for (let o = 0; o < limit; o += 1) { positionComponents.push(<span key={o} />); }
    return positionComponents;
  };

  if (Array.isArray(childProducts) && childProducts.length > 0 && noProductsOnly) { return null; }

  return !exclude.includes(id) ? (
    <>
      <tr key={`row_${`${htmlId}-${id}`}`} className={styles.categoryTreeItem}>
        <td className={clsx(styles.tableTd, "py-0", "align-items-stretch")}>
          <div className={clsx(styles.treeLineAnimationRootComponent, "d-flex", "h-100", "align-items-stretch")}>
            {getDivForPositioning(index)}
            <div className={clsx(styles.itemTitle, "bg-white", "d-flex", !hasCategory && styles.noDescendants, isOpen ? styles.isOpen : styles.isClosed)}>
              {hasCategory
                && (
                  <span className="commonPointer" onClick={(o) => toggle(id, o)} onKeyDown={() => { }} tabIndex={0} role="button">
                    <img
                      className={styles.icon}
                      src={isOpen ? MinusIcon : PlusIcon}
                      alt=""
                    />
                  </span>
                )}
              <span className={clsx(styles.fontWeightBold, "px-1", "text-truncate")}>
                <CustomInput type="checkbox" checked={categories.includes(id)} onChange={() => { }} onClick={(o) => handleSelectedCategories(id, o)} id={`key_id_${`${htmlId}-${id}`}`} label={`${details.name}`} />
              </span>
            </div>
          </div>
        </td>
      </tr>
      {/* ========= SUB CATEGORIES S[0004] ======================= */}
      {hasCategory && isOpen && (
        Array.isArray(categoryList) ? (
          <>
            {
              categoryList.length > 0 ? (
                <>
                  {categoryList.map((category) => (
                    <Category
                      index={index + 1}
                      id={category.id}
                      key={`key_${htmlId}-${category.id}`}
                      name={category.name}
                      active={category.active}
                      childCategories={category.childCategories}
                      childProducts={category.childProducts}
                      onChange={onChange}
                      categories={categories}
                      handleSelectedCategories={handleSelectedCategories}
                      updateUniqueTotalCategoryList={updateUniqueTotalCategoryList}
                      noProductsOnly={noProductsOnly}
                      exclude={exclude}
                    />
                  ))}
                </>
              ) : (
                <tr>
                  <td className="p-0">
                    <div className="text-center pt-1 pb-3">
                      <span>No Data</span>
                    </div>
                  </td>
                </tr>
              )
            }
          </>
        ) : (
          <tr className={styles.categoryTreeItem}>
            <td className={clsx(styles.tableTd, "py-0", "align-items-stretch")}>
              <div className={clsx(styles.treeLineAnimationRootComponent, "d-flex", "h-100", "align-items-stretch")}>
                {getDivForPositioning(index)}
              </div>
            </td>
          </tr>

        )
      )}
      {/* ========= SUB CATEGORIES E[0004] ======================= */}
    </>
  ) : null;
};

Category.defaultProps = {
  childProducts: null,
};

Category.propTypes = {
  index: PropTypes.number.isRequired,
  /* actual category details */
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  categories: PropTypes.arrayOf(PropTypes.any).isRequired,
  /** events */
  onChange: PropTypes.func.isRequired,
  handleSelectedCategories: PropTypes.func.isRequired,
  /** sub category details */
  childCategories: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any)]).isRequired,
  updateUniqueTotalCategoryList: PropTypes.func.isRequired,
  childProducts: PropTypes.arrayOf(PropTypes.string),
  exclude: PropTypes.arrayOf(PropTypes.any).isRequired,
  noProductsOnly: PropTypes.bool.isRequired,
  htmlId: PropTypes.string.isRequired,
};

const TreeCategorySelector = (props) => {
  const {
    categoryList,
    categories,
    handleTreeCategorySelector,
    handleSelectedCategories,
    updateUniqueTotalCategoryList,
    noProductsOnly,
    exclude,
    htmlId,
  } = props;
  const excludeArray = Array.isArray(exclude) ? exclude : [];

  return (
    <div className={styles.categoryTree}>
      <div className="table-responsive">
        <table>
          <tbody>
            {
              categoryList.map(({
                id, name, childCategories, active, childProducts,
              }) => (!exclude.includes(id) ? (
                <Category
                  index={0}
                  id={id}
                  key={`cat_${`${htmlId}-${id}`}`}
                  name={name}
                  active={active}
                  categories={categories}
                  childCategories={childCategories}
                  onChange={handleTreeCategorySelector}
                  handleSelectedCategories={handleSelectedCategories}
                  updateUniqueTotalCategoryList={updateUniqueTotalCategoryList}
                  childProducts={childProducts}
                  noProductsOnly={noProductsOnly}
                  htmlId={htmlId}
                  exclude={excludeArray}
                />
              ) : null))
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

TreeCategorySelector.propTypes = {
  categoryList: PropTypes.arrayOf(PropTypes.any).isRequired,
  categories: PropTypes.arrayOf(PropTypes.any).isRequired,
  exclude: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleTreeCategorySelector: PropTypes.func.isRequired,
  handleSelectedCategories: PropTypes.func.isRequired,
  updateUniqueTotalCategoryList: PropTypes.func.isRequired,
  noProductsOnly: PropTypes.bool.isRequired,
  htmlId: PropTypes.string.isRequired,
};

export default TreeCategorySelector;
