/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Sreelekshmi
 */

import constants from "./constants";
import commonUtils from "./commonUtils";

const {
  isListNotEmpty,
} = commonUtils;
const {
  CART_MANAGEMENT: {
    BULK_UPLOAD_LIMIT,
    SHEET_CONTEXT_SKU,
  },
} = constants;


const parseDataFromSheet = (rawData) => {
  const headerKeys = ["ProductSKU", "CartLimit"];
  if (isListNotEmpty(rawData)) {
    const isValid = rawData.every((data) => headerKeys.every((each) => Object.keys(data).includes(each)));
    if (isValid) {
      const mappedValues = rawData.map((each) => each.id);
      const filteredValues = mappedValues.filter((each, index) => mappedValues.indexOf(each) === index);
      if (filteredValues.length < BULK_UPLOAD_LIMIT) {
        return {
          isValid,
          options: rawData,
          value: filteredValues,
        };
      }
      return {
        isValid: false,
        message: `The maximum number of item that can be uploaded has exceeded.Please upload the items in batches of ${BULK_UPLOAD_LIMIT}`,
      };
    }
    return {
      isValid,
      message: "Invalid sheet format. Please refer the template for valid data format.",
    };
  }
  return {
    isValid: false,
    message: "No Data Found",
  };
};

const sheetTemplateMapper = {
  [SHEET_CONTEXT_SKU]: [{
    ProductSKU: "<ProductSKU 1>",
    CartLimit: "<CartLimit 1>",
  },
  {
    ProductSKU: "<ProductSKU 2>",
    CartLimit: "<CartLimit 2>",
  },
  {
    ProductSKU: "<ProductSKU 3>",
    CartLimit: "<CartLimit 3>",
  },
  {
    ProductSKU: "<ProductSKU 4>",
    CartLimit: "<CartLimit 4>",
  },
  ],
};

const getImportSheetTemplate = (context) => sheetTemplateMapper[context];

export default {
  parseDataFromSheet,
  getImportSheetTemplate,
};