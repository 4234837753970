/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Mohammed Shefeeq
 */


import React, { useState, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import SchedulerManagement from "../components/SchedulerManagement";
import {
  getBatchScheduler,
  updateScheduler,
  validateCron,
} from "../../../api/jobSchedulerService";

const SchedulerManagementContainer = () => {
  const history = useHistory();
  const { params } = useRouteMatch();
  const [isValidCronExpression, setIsValidCronExpression] = useState(true);
  const [form, setForm] = useState({
    retailer: "",
    brand: "",
    location: "",
    jobSchedule: "",
    nextJobExecutionTime: "",
    prvsJobCompletionTime: "",
    jobConfigId: "",
    status: "",
    executionMode: "",
  });

  useEffect(() => {
    if (params.id) {
      getBatchScheduler(params.id).then(async (response) => {
        if (response && response.success && response.data) {
          const { data } = response;
          setForm(data);
        }
      });
    }
  }, [params.id]);

  const handleChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleFormChange = (event) => {
    handleChange(event.target.name, event.target.value);
  };

  const onHandleCancel = () => {
    history.push("/administration/job-scheduler");
  };

  const onHandleSave = async () => {
    validateCron(form.jobSchedule).then(async (response) => {
      if (response && response.success && response.data && response.data === true) {
        setIsValidCronExpression(true);
        updateScheduler(form).then(async (updateSchedulerResponse) => {
          if (updateSchedulerResponse && updateSchedulerResponse.success
            && updateSchedulerResponse.data && updateSchedulerResponse.data === true) {
            history.push("/administration/job-scheduler");
          }
        });
      } else {
        setIsValidCronExpression(false);
      }
    });
  };

  return (
    <SchedulerManagement
      handleFormChange={handleFormChange}
      form={form}
      onHandleSave={onHandleSave}
      onHandleCancel={onHandleCancel}
      isValidCronExpression={isValidCronExpression}
    />
  );
};

export default SchedulerManagementContainer;
