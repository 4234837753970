/*
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Hima Mohan
 *
 */

import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import Input from "../../../common/core/Input";
import CustomInput from "../../../common/core/CustomInput";
import Badge from "../../../common/core/Badge";
import WYSIWYG from "../../../common/components/WYSIWYG";

const ColorSelector = (props) => {
  const { value, options, onChange } = props;

  /**
   * This function is used to select color
   * @param {String} selectedValue
   */
  const localOnClick = (selectedValue) => {
    if (typeof onChange === "function") {
      onChange(selectedValue);
    }
  };

  return (
    <div>
      {Array.isArray(options) &&
        options.map((option) => (
          <span className="pr-1">
            <Badge
              color="primary"
              style={{ background: option }}
              onClick={() => localOnClick(option)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                {value === option && (
                  <path
                    fill="#fff"
                    d="M16.771 5.14c.475-.679 1.41-.844 2.09-.369.638.447.822 1.302.445 1.967l-.077.122-7 10c-.468.668-1.375.834-2.044.4l-.122-.089-5-4c-.647-.517-.752-1.461-.234-2.108.485-.607 1.345-.737 1.983-.324l.125.09 3.751 3 6.083-8.69z"
                  />
                )}
              </svg>
            </Badge>
          </span>
        ))}
    </div>
  );
};

ColorSelector.propTypes = {
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const StorefrontSettingsForm = (props) => {
  const {
    validator,
    onCampaign,
    showInBrowsingJourney,
    badge,
    colorOptions,
    handleFormChange,
    calloutMessage,
    handleChange,
    description,
    translatableFileds,
    isDefault,
  } = props;

  /** internationalization using i18n from common and promotion  */
  const { t } = useTranslation(["common", "promotion"]);

  /** validation methods */
  const { register, errors } = validator;
  const readOnly = true;
  return (
    <div className="common-page-card">
      <Row className={clsx("px-3", "pb-1")}>
        <Col xs="12" className="text-truncate pageHeader pb-0">
          <span className="pageText">Storefront Settings</span>
          <hr />
        </Col>
        <Col xs="12">
          <Row>
            <Col xs="12" className="mb-3">
              <Label className="formTextLabel">
                {t("common:label_description")}
              </Label>
              <WYSIWYG
                handleChange={(value) => handleChange("description", value)}
                content={description}
                disabled={
                  Array.isArray(translatableFileds)
                    ? !isDefault && !translatableFileds.includes("description")
                    : false
                }
              />
            </Col>
            <Col xs="12">
              <FormGroup>
                <Label className="formTextLabel">
                  Call-out message
                  {/* <span className="pl-1 text-danger">*</span> */}
                </Label>
                <Input
                  className="formText"
                  placeholder="Call-out Message"
                  name="calloutMessage"
                  value={calloutMessage}
                  onChange={handleFormChange}
                  disabled={
                    Array.isArray(translatableFileds)
                      ? !isDefault &&
                        !translatableFileds.includes("calloutMessage")
                      : false
                  }
                />
              </FormGroup>
            </Col>
            {/*
            <Col xs="12">
              <FormGroup>
                <Label className="formTextLabel">
                  Color
                  <span className="pl-1 text-danger">*</span>
                </Label>
                <div className="py-1">
                  <ColorSelector options={colorOptions} onChange={(o) => handleChange("badge", o)} value={badge} />
                </div>
              </FormGroup>
            </Col> */}
            <Col xs="12" sm={onCampaign ? "12" : "6"}>
              <CustomInput
                type="checkbox"
                checked={showInBrowsingJourney}
                name="showInBrowsingJourney"
                onChange={() => {}}
                onClick={() =>
                  handleChange("showInBrowsingJourney", !showInBrowsingJourney)
                }
                id="qualifying-products-label-999"
                className="font-weight-light small d-flex"
                label={t("promotion:label_show_in_browsing")}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

StorefrontSettingsForm.propTypes = {
  calloutMessage: PropTypes.string.isRequired,
  badge: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  onCampaign: PropTypes.bool.isRequired,
  showInBrowsingJourney: PropTypes.bool.isRequired,
  validator: PropTypes.shape({
    register: PropTypes.func,
    errors: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
  colorOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleChange: PropTypes.func.isRequired,
  translatableFileds: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedLanguage: PropTypes.string.isRequired,
};

export default StorefrontSettingsForm;
