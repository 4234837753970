/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * ExternalCategories
 *
 * @author Naseef O
 *
 */

import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

import ExternalCategoriesTable from "./ExternalCategoriesTable";
import Pagination from "../../../common/components/Pagination";
import ExternalCategoryTreeTable from "./ExternalCategoryTreeTable";

import Col from "../../../common/core/Col";
import FormGroup from "../../../common/core/FormGroup";
import Input from "../../../common/core/Input";
import InputGroup from "../../../common/core/InputGroup";
import InputGroupAddon from "../../../common/core/InputGroupAddon";
import Row from "../../../common/core/Row";
import Dropdown from "../../../common/core/Dropdown";
import DropdownToggle from "../../../common/core/DropdownToggle";
import DropdownMenu from "../../../common/core/DropdownMenu";
import styles from "../css/ExternalCategories.module.css";

import SearchIcon from "../../../common/assets/images/svg/search.svg";
import CategoryIcon from "../../../common/assets/images/svg/category.svg";
import ClearIconAlt from "../../../common/assets/images/svg/clear-alt.svg";
import FilterIcon from "../../../common/assets/images/svg/filter.svg";
import ListViewIcon from "../../../common/assets/images/svg/list-view.svg";
import TreeViewIcon from "../../../common/assets/images/svg/tree-view.svg";
import PreviewIcon from "../../../common/assets/images/svg/preview-alt.svg";


import constants from "../../../common/utils/constants";

const customRows = [
  {
    label: "Name",
    path: "name",
  },
  {
    label: "Mapped categories",
    path: "parentCategories",
    disabled: true,
  },
];


const ExternalCategories = ({
  page,
  loading,
  searchKey,
  pagination,
  categories,
  getPageData,
  handleSearch,
  dropdownOpen,
  selectedFilter,
  toggleDropdown,
  handleSelectFilter,
  handleRedirectToDetails,
  tableView,
  toggleTableView,
}) => {
  const [viewDropDown, setViewDropDown] = useState(false);
  const toggleViewDropDown = () => {
    setViewDropDown(!viewDropDown);
  };
  return (
    <>
      <Row className="pageHeader common-page-card">
        <Col xs="12" md="5" className="text-truncate">
          <span className="pageText d-flex align-items-center">
            <img src={CategoryIcon} alt="marketplace" className="mr-1" />
            Categories
          </span>
        </Col>
      </Row>
      <Row className={clsx(styles.block2, styles.blockWrapper, "common-page-card")}>
        <Col xs="12">
          <Row className={clsx(styles.areaActionRight, " align-items-center ")}>
            {page === constants.CATEGORY_PAGE_TYPE.EXTERNAL_CATEGORY_PAGE && (
              <Col xs="auto" className="py-2">
                <Dropdown
                  isOpen={viewDropDown}
                  toggle={toggleViewDropDown}
                  className={styles.dropdownButtonWrapper}
                >
                  <DropdownToggle caret>
                    <span
                      className={
                        clsx(styles.selectedFilter, "d-flex align-items-center")
                      }
                    >
                      {tableView === constants.TABLE_VIEW.LIST_VIEW ? (
                        <>
                          <img src={ListViewIcon} alt="list-view" className="mr-1" />
                          {tableView}
                        </>
                      )
                        : (
                          <>
                            <img src={TreeViewIcon} alt="tree-view" className="mr-1" />
                            {tableView}
                          </>
                        )}
                    </span>
                  </DropdownToggle>
                  <div
                    role="presentation"
                    className={clsx(styles.dropdownClear, "cursor-pointer")}
                    onClick={() => toggleViewDropDown()}
                  >
                    <img src={PreviewIcon} alt="View" width="20px" />
                  </div>
                  <DropdownMenu className={styles.dropdownWrapper}>
                    <div
                      key="table-view-tree-list"
                      onKeyPress={() => { }}
                      role="button"
                      tabIndex="0"
                      onClick={() => {
                        toggleTableView(constants.TABLE_VIEW.TREE_VIEW);
                        setViewDropDown(false);
                      }}
                      className={clsx(styles.dropdownItem, "d-flex align-items-center commonPointer")}
                    >
                      <img src={TreeViewIcon} alt="tree-view" className="mr-1" />
                      {constants.TABLE_VIEW.TREE_VIEW}
                    </div>
                    <div
                      key="table-view-simple-list"
                      onKeyPress={() => { }}
                      role="button"
                      tabIndex="0"
                      onClick={() => {
                        toggleTableView(constants.TABLE_VIEW.LIST_VIEW);
                        setViewDropDown(false);
                      }}
                      className={clsx(styles.dropdownItem, "d-flex align-items-center commonPointer")}
                    >
                      <img src={ListViewIcon} alt="list-view" className="mr-1" />
                      {constants.TABLE_VIEW.LIST_VIEW}
                    </div>
                  </DropdownMenu>
                </Dropdown>
              </Col>
            )}
            {tableView === constants.TABLE_VIEW.LIST_VIEW && (
              <Col xs="auto" className="py-2">
                <FormGroup className=" m-0">
                  <InputGroup className={clsx(styles.customInput, "color-tertiary-main")}>
                    <Input placeholder="Search categories " value={searchKey} onChange={(e) => handleSearch(e)} />
                    <InputGroupAddon
                      addonType="append"
                      className="commonPointer bg-color-tertiary-main"
                    >
                      <img
                        role="presentation"
                        src={searchKey ? ClearIconAlt : SearchIcon}
                        onClick={() => handleSearch({ target: { value: "" } })}
                        width="25px"
                        alt={searchKey ? "clear search" : "search category"}
                      />
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
            )}
            {tableView === constants.TABLE_VIEW.LIST_VIEW && (
              <>
                <Col xs="auto" className="py-2">
                  <Dropdown
                    isOpen={dropdownOpen}
                    toggle={toggleDropdown}
                    className={styles.dropdownButtonWrapper}
                  >
                    <DropdownToggle caret>
                      <span
                        className={
                          clsx(styles.selectedFilter,
                            !selectedFilter && styles.filterText)
                        }
                      >
                        {selectedFilter ? selectedFilter.label : "Filter by"}
                      </span>
                    </DropdownToggle>
                    <div
                      role="presentation"
                      className={clsx(styles.dropdownClear, "cursor-pointer")}
                      onClick={() => (selectedFilter
                        ? handleSelectFilter(null) : toggleDropdown())}
                    >
                      {!selectedFilter ? (
                        <img
                          src={FilterIcon}
                          alt="Select filter"
                          width="20px"
                          className={styles.icon}
                        />
                      )
                        : (
                          <img
                            src={ClearIconAlt}
                            alt="Clear filter"
                            width="20px"
                            className={styles.icon}
                          />
                        )}
                    </div>
                    <DropdownMenu className={styles.dropdownWrapper}>
                      {constants.EXTERNAL_CATEGORIES_FILTER_OPTIONS.map((option) => (
                        option.disabled ? (
                          <div
                            key={`filter-${option.label}`}
                            className={clsx(styles.disabled)}
                          >
                            {option.label}
                          </div>
                        )
                          : (
                            <div
                              key={`filter-${option.label}`}
                              onKeyPress={() => { }}
                              role="button"
                              tabIndex="0"
                              className={clsx(styles.dropdownItem)}
                              onClick={() => !option.disabled && handleSelectFilter(option)}
                            >
                              {option.label}
                            </div>
                          )
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                </Col>
                <Col xs="auto" className="py-2">
                  {Array.isArray(categories) && categories.length > 0 && (
                    <span className="">
                      <span className="font-weight-bold pl-1">{`${pagination.numberOfElements} of ${pagination.totalCount}`}</span>
                    </span>
                  )}
                </Col>
              </>
            )}
          </Row>
          <Row>
            <Col xs="12">
              {tableView === constants.TABLE_VIEW.LIST_VIEW ? (
                <>
                  <ExternalCategoriesTable
                    loading={loading}
                    tableRows={customRows}
                    tableData={categories}
                    handleEdit={handleRedirectToDetails}
                  />
                  {Array.isArray(categories) && categories.length > 0 && (
                    <Pagination
                      totalCount={pagination.totalCount}
                      page={pagination.page}
                      limit={pagination.limit}
                      getPageData={getPageData}
                    />
                  )}
                </>
              )
                : (
                  <ExternalCategoryTreeTable
                    loading={loading}
                    tableView={tableView}
                    tableRows={customRows}
                    tableData={categories}
                    page={page}
                    handleEdit={handleRedirectToDetails}
                  />
                )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

ExternalCategories.propTypes = {
  page: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  searchKey: PropTypes.string.isRequired,
  pagination: PropTypes.objectOf(PropTypes.any).isRequired,
  categories: PropTypes.arrayOf(PropTypes.any).isRequired,
  getPageData: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  dropdownOpen: PropTypes.bool.isRequired,
  selectedFilter: PropTypes.func.isRequired,
  toggleDropdown: PropTypes.func.isRequired,
  handleSelectFilter: PropTypes.func.isRequired,
  handleRedirectToDetails: PropTypes.func.isRequired,
  tableView: PropTypes.string.isRequired,
  toggleTableView: PropTypes.func.isRequired,
};

export default ExternalCategories;
