/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Mohammed Shefeeq
 */

import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Button from "../../../common/core/Button";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import styles from "../css/JobScheduler.module.css";
import Label from "../../../common/core/Label";
import CustomizationTable from "../../../common/components/Table";


const JobScheduler = (props) => {
  const {
    handleJobStatus,
    schedulerList,
    onHandleEditClass,
    handleSchedularStatus,
  } = props;

  const { t } = useTranslation(["common", "job-scheduler"]);
  const getStatus = (row) => {
    const newStatus = (
      <div
        className={clsx(
          "custom-control",
          "custom-switch",
        )}
      >
        <input
          type="checkbox"
          className="custom-control-input"
          id={`expand-multiple${row.id}`}
          checked={row.enabled}
          onChange={(o) => handleJobStatus(row.id, o)}
        />
        <Label className="custom-control-label" htmlFor={`expand-multiple${row.id}`} />
      </div>
    );
    return newStatus;
  };

  const InactiveScheduler = (row) => {
    const schedularStatus = (
      <Button
        className={clsx(styles.borderRadius2, "text-uppercase", "text-truncate", "btn-tertiary", "button-font-size")}
        disabled={row.active}
        onClick={(o) => handleSchedularStatus(row.id, o)}
      >
        {t("job-scheduler:activate-scheduler")}
      </Button>
    );
    return schedularStatus;
  };

  return (
    (
      <>
        <Row className={clsx(styles.block1, "common-page-card pageHeader")}>
          <Col xs="8">
            <span className="pageText">
              {t("job-scheduler:job-scheduler-details")}
            </span>
          </Col>
        </Row>
        <Row className={clsx(styles.block2, styles.tableWrapper, "common-page-card")}>
          <Col xs="12">
            <div className="custom-table">
              <CustomizationTable
                customRows={[
                  {
                    label: "JOB Name",
                    path: "name",
                  },
                  {
                    label: "Next Job Run Time",
                    path: "nextJobExecutionTime",
                    type: "date",
                  },
                  {
                    label: "Last Job Run Time",
                    path: "prvsJobCompletionTime",
                    type: "date",
                  },
                  {
                    label: "Inactive Job",
                    path: "render",
                    render: InactiveScheduler,
                  },
                  {
                    label: "Status",
                    path: "status",
                  },
                  {
                    label: "Enabled",
                    path: "render",
                    render: getStatus,
                  }]}
                customizationData={schedulerList}
                isStatusEnable={false}
                isDeleteEnable={false}
                isEditEnable
                onEditData={onHandleEditClass}
              />
            </div>
            <div />
          </Col>
        </Row>
      </>
    )
  );
};
JobScheduler.propTypes = {
  schedulerList: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleJobStatus: PropTypes.func.isRequired,
  onHandleEditClass: PropTypes.func.isRequired,
  handleSchedularStatus: PropTypes.func.isRequired,
};
export default JobScheduler;
