/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * @author Naseef O
 */
import httpApi from "./httpApi";

/**
 *This method is used to get static content by Code
 *
 * @param {*} code
 */
const getStaticContentByCode = async (code, locale) => {
  const params = { locale };
  const response = await httpApi.get(`/v1/static-content/code/${code}`, { params });
  if (response && response.data.success) {
    const { data } = response;
    return data;
  }
  return null;
};
export {
  getStaticContentByCode,
};
