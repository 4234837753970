/**
 * Copyright(c) 2023 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Neeraj Raj
 */

import httpApi from "./httpApi";

/**
 * @param {File} fileData
 * This service function is used to upload catalogue file
 * @returns
 */
const uploadCatalogueFile = async (fileData) => {
  const formData = new FormData();
  formData.append("catalogFile", fileData);
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  const response = await httpApi.post("v1/content/upload", formData, config);
  return (response && response.data) || null;
};

export default uploadCatalogueFile;
