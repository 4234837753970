/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Layout Settings
 *
 * @author Naseef O
 *
 */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import styles from "../css/LayoutSettings.module.css";
import Dropdown from "../../../common/core/Dropdown";
import DropdownToggle from "../../../common/core/DropdownToggle";
import DropdownMenu from "../../../common/core/DropdownMenu";
import leftArrow from "../../../common/assets/images/svg/left-arrow.svg";
import rightArrow from "../../../common/assets/images/svg/right-arrow.svg";
import { updatePageLayouts } from "../../../api/LayoutServices";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import visibility from "../../../common/assets/images/svg/visibility.svg";
import Button from "../../../common/core/Button";
import Alert from "../../../common/core/Alert";
import config from "../../../config";

const LayoutSettings = (props) => {
  const {
    layouts,
    toggleDropdown,
    dropdownOpen,
    handleSelectPage,
    pageType,
    domainName,
    previewEnabled,
    previewSiteUrl,
    handlePushToLive,
    pushToLiveEnabled,
    message,
    getReplicationStatus,
    pushToLiveButtonEnabled,
  } = props;
  const { t } = useTranslation(["common"]);

  const pages = [
    { name: "Home", type: "HOME" },
    { name: "PLP", type: "PLP" },
    { name: "CLP", type: "CLP" },
    { name: "PDP", type: "PDP" },
  ];

  const [selectedLayout, setLayout] = useState(null);
  const [activeLayout, setActiveLayout] = useState(null);

  useEffect(() => {
    if (layouts && layouts.length > 0) {
      const layout = layouts.find((layoutItem) => layoutItem.selected);
      setLayout(layout);
      setActiveLayout(layout);
    }
  }, [layouts]);

  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
  const isTabletDevice = useMediaQuery({ query: "(max-width: 1024px)" });
  const isDesktopOrLaptop = useMediaQuery({ query: "(max-width: 2000px)" });
  let displayCount = 0;
  if (isMobile) {
    displayCount = 2;
  } else if (isTabletDevice) {
    displayCount = 3;
  } else if (isDesktopOrLaptop) {
    displayCount = 4;
  } else {
    displayCount = 4;
  }
  const layoutsArray = [...Array(displayCount).keys()];

  const [layoutConfirmationModal, setLayoutConfirmationModal] = useState(false);

  /**
  *This method is used to handle select Layout
  *
  * @param {String} Layout
  */
  const handleLayoutSelection = async (layout) => {
    setLayoutConfirmationModal(true);
    setLayout(layout);
  };

  /**
  *This method is used to toggle layout confirmation modal
  *
  */
  const toggleLayoutConfirmationModal = () => {
    setLayoutConfirmationModal(!layoutConfirmationModal);
  };

  /**
  *This method is used to handle save page layout
  *
  */
  const handleSavePageLayout = () => {
    updatePageLayouts(selectedLayout.subType, selectedLayout.layoutId, selectedLayout.configId).then((response) => {
      if (response && response.success) {
        setLayoutConfirmationModal(false);
        setActiveLayout(selectedLayout);
      }
    });
  };

  return (
    <>
      {message && message.type && (
        <Alert color={message.type}>{message.message}</Alert>
      )}
      <Row className="common-page-card pageHeader">
        <Col xs="12" md="3" lg="3">
          <span className="pageText"> Layout settings</span>
        </Col>
        <Col xs="12" md="4" lg="5" />
      </Row>
      <Row className={clsx(styles.block2, "common-page-card")}>
        <Col xs="12">
          <Row className="m-0">
            <span
              className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}
            >
              {" "}
              Page Selection
            </span>
          </Row>
          <Row className="m-0">
            <Dropdown
              isOpen={dropdownOpen}
              toggle={toggleDropdown}
              className={styles.pageSelection}
            >
              <DropdownToggle caret>
                <span>{pageType}</span>
              </DropdownToggle>
              <DropdownMenu className={styles.dropdownWrapper}>
                {pages.map((page, index) => (
                  <div
                    key={index}
                    onKeyPress={() => {}}
                    role="button"
                    tabIndex="0"
                    className={styles.dropdownItem}
                    onClick={() => handleSelectPage(page.type)}
                  >
                    {page.name}
                  </div>
                ))}
              </DropdownMenu>
            </Dropdown>
          </Row>
        </Col>
      </Row>
      <Row className={clsx(styles.block3, "common-page-card")}>
        <Col xs="12">
          <Row>
            <Col xs="3">
              <span className={clsx("fontsizeNormalBold")}>
                {" "}
                Layout Options
              </span>
            </Col>
            <Col xs="12" md="3" lg="3" className={styles.liveBtnWrapper}>
              {previewEnabled && (
                <Button
                  className={clsx(
                    styles.liveBtn,
                    "bg-color-tertiary-main float-right"
                  )}
                >
                  <a
                    href={previewSiteUrl}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {t("common:button_preview")}
                  </a>
                </Button>
              )}
            </Col>
            <Col xs="12" md="3" lg="3" className={styles.liveBtnWrapper}>
              {pushToLiveEnabled && (
                <Button
                  onClick={handlePushToLive}
                  disabled={!pushToLiveButtonEnabled}
                  className={clsx(
                    styles.liveBtn,
                    "float-right",
                    "text-truncate",
                    "btn-tertiary",
                    "button-font-size"
                  )}
                >
                  {t("common:button_push_to_live")}
                </Button>
              )}
            </Col>
            <Col xs="12" md="3" lg="3" className={styles.liveBtnWrapper}>
              {pushToLiveEnabled && (
                <Button
                  onClick={getReplicationStatus}
                  className={clsx(
                    styles.liveBtn,
                    "float-right",
                    "text-truncate",
                    "btn-tertiary",
                    "button-font-size"
                  )}
                >
                  {t("common:button_refresh_status")}
                </Button>
              )}
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Row className={styles.layoutOptions}>
                <span className={styles.leftArrow}>
                  <img src={leftArrow} alt="click left" />
                </span>
                <span className={styles.rightArrow}>
                  <img src={rightArrow} alt="click left" />
                </span>
                <div className={styles.layoutOptionsWrapper}>
                  {layouts &&
                    layouts.length > 0 &&
                    layouts.map((layout) => (
                      <LayoutPreview
                        layout={layout}
                        activeLayout={activeLayout}
                        handleLayoutSelection={handleLayoutSelection}
                        selectedLayout={selectedLayout}
                        domainName={domainName}
                        previewSiteUrl={previewSiteUrl}
                        previewEnabled={previewEnabled}
                      />
                    ))}
                </div>
              </Row>
              <ConfirmationModal
                isOpen={layoutConfirmationModal}
                toggleOpen={toggleLayoutConfirmationModal}
                togglClose={toggleLayoutConfirmationModal}
                handleConfirm={handleSavePageLayout}
                content=" Are you sure you want to select this layout ?"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={clsx(styles.block4, "common-page-card")}>
        <Col xs="12">
          <Row>
            <Col xs="12">
              <span className={clsx("fontsizeNormalBold")}>
                {" "}
                Current Homepage
              </span>
            </Col>
          </Row>
          <Row className={styles.selectedLayout}>
            <Col xs="8">
              <img
                src={activeLayout && activeLayout.largeImageUrl}
                alt="layout"
                width="100%"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
LayoutSettings.propTypes = {
  layouts: PropTypes.arrayOf(PropTypes.any).isRequired,
  toggleDropdown: PropTypes.func.isRequired,
  handleSelectPage: PropTypes.func.isRequired,
  pageType: PropTypes.objectOf(PropTypes.any).isRequired,
  domainName: PropTypes.objectOf(PropTypes.any).isRequired,
  messaeg: PropTypes.objectOf(PropTypes.any).isRequired,
  handlePushToLive: PropTypes.func.isRequired,
  pushToLiveEnabled: PropTypes.func.isRequired,
  previewEnabled: PropTypes.func.isRequired,
  previewSiteUrl: PropTypes.string.isRequired,
};

const LayoutPreview = (props) => {
  const {
    layout,
    handleLayoutSelection,
    activeLayout,
    previewSiteUrl,
    previewEnabled,
  } = props;
  return (
    <>
      <Col
        xs="6"
        md="4"
        lg="3"
        className={clsx(styles.layoutsPreview, "text-center pl-0 pr-0 ")}
        onClick={() =>
          activeLayout &&
          activeLayout.layoutId !== layout.layoutId &&
          handleLayoutSelection(layout)
        }
      >
        <div
          className={clsx(
            styles.layoutsPreviewWrapper,
            activeLayout &&
              activeLayout.layoutId === layout.layoutId &&
              styles.active,
            activeLayout &&
              activeLayout.layoutId === layout.layoutId &&
              previewEnabled &&
              styles.livePreviewWrapper
          )}
        >
          {activeLayout &&
            activeLayout.layoutId === layout.layoutId &&
            previewEnabled && (
              <a
                href={previewSiteUrl}
                target="_blank"
                className={styles.livePreview}
              >
                <Row>
                  <img src={visibility} alt="visibility" />
                </Row>
                <Row>
                  <span>See live</span>
                </Row>
              </a>
            )}
          <span className={styles.layoutName}>{layout.name}</span>
          <img
            className={styles.layoutImg}
            src={layout && layout.smallImageUrl}
            alt="layout"
            width="100%"
          />
        </div>
      </Col>
    </>
  );
};

LayoutPreview.propTypes = {
  layout: PropTypes.objectOf(PropTypes.any).isRequired,
  handleLayoutSelection: PropTypes.func.isRequired,
  activeLayout: PropTypes.objectOf(PropTypes.any).isRequired,
  previewSiteUrl: PropTypes.string.isRequired,
  previewEnabled: PropTypes.bool.isRequired,
};

export default LayoutSettings;
