/**
 * Copyright(c) 2022 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Abdul Razack
 */

import React from "react";
import PropTypes from "prop-types";
import { Switch } from "react-router-dom";

import ProductBulkManagementContainer from "./containers/ProductBulkManagementContainer";

import PrivateRouter from "../../common/routes/PrivateRouter";

const ProductBulkManagement = ({ match: { path, params } }) => (
  <Switch>
    <PrivateRouter exact path={`${path}`} component={ProductBulkManagementContainer} />
  </Switch>
);
ProductBulkManagement.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    params: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
};

export default ProductBulkManagement;
