// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\r\n * Copyright(c) 2022 Mozanta Technologies Private Ltd.\r\n * \r\n * All rights reserved.                \r\n * \r\n * This software is the confidential and proprietary information of Mozanta (\"Confidential\r\n * Information\"). You shall not disclose such Confidential Information and shall use it only in\r\n * accordance with the terms of the contract agreement you entered into with Mozanta.\r\n * \r\n * @author Rahna\r\n */\r\n\r\n.EditProductOption_blockWrapper__1j9kO {\r\n  min-height: calc(100vh - 337px);\r\n}\r\n\r\n.EditProductOption_block3__2F9rJ {\r\n  padding: 1.25rem;\r\n}", ""]);
// Exports
exports.locals = {
	"blockWrapper": "EditProductOption_blockWrapper__1j9kO",
	"block3": "EditProductOption_block3__2F9rJ"
};
module.exports = exports;
