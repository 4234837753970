/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Mohammed Shefeeq
 */

import React, { useState, useEffect } from "react";
import InventoryReport from "../components/InventoryReport";
import {
  getStoreList,
} from "../../../api/inventoryManagementService";
import { updateBatchConfig, getJobHistory } from "../../../api/InventoryReportServices";

const InventoryReportContainer = () => {
  const [storeList, setStoreList] = useState([]);
  const [isExporting, setIsExporting] = useState(false);
  const [schedulerDetails, setSchedulerDetails] = useState({
    criteria: {
      storeId: "MASTER",
    },
    type: "inventoryExportBatchJob",
  });
  useEffect(() => {
    getStoreList().then(async (response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        if (data && Array.isArray(data) && data.length > 0) {
          const storeLists = [{ value: "MASTER", label: "Fulfillment Store" }];
          data.map((store) => {
            const storeListItem = {};
            storeListItem.value = store.storeId;
            storeListItem.label = store.storeId;
            storeLists.push(storeListItem);
            return null;
          });
          setStoreList(storeLists);
        }
      }
    });
  }, []);

  const onHandleStoreChange = (e) => {
    const { value } = e.target;
    setSchedulerDetails({ ...schedulerDetails, criteria: { ...schedulerDetails.criteria, storeId: value } });
  };

  const exportInventory = async (event) => {
    const response = await updateBatchConfig(schedulerDetails);
    if (response && response.data == true && response.success) {
      setIsExporting(response.data);
    }
  };

  const [jobHistoryReqData, setJobHistoryReqData] = useState({
    page: 0,
    size: 10,
    status: null,
    jobConfigId: null,
    productId: "",
    jobConfigType: null,
    searchTerm: "inventoryExportBatchJob",
    startDate: null,
    endDate: null,
  });
  const [jobHistoryData, setJobHistoryData] = useState([]);

  const jobHistoryServiceCall = (reqObj) => {
    getJobHistory(reqObj).then(async (response) => {
      if (response && response.data) {
        const { data } = response;
        if (data && data.content) {
          setJobHistoryData(data.content);
        }
      }
    });
  };
  useEffect(() => {
    jobHistoryServiceCall(jobHistoryReqData);
  }, [jobHistoryReqData]);

  const refreshData = () => {
    jobHistoryServiceCall(jobHistoryReqData);
  };
  return (
    <InventoryReport
      storeList={storeList}
      onHandleStoreChange={onHandleStoreChange}
      storeId={schedulerDetails.criteria.storeId}
      exportInventory={exportInventory}
      schedulerDetails={schedulerDetails}
      jobHistoryData={jobHistoryData}
      isExporting={isExporting}
      refreshData={refreshData}
    />
  );
};

export default InventoryReportContainer;
