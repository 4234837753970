/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * ProductDetailsContainer
 *
 * @author Naseef O
 */


import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import offerServices from "../../../api/offerServices";
import { getPriceAndStoreInventories, getProduct } from "../../../api/productManagementServices";

import ProductDetails from "../components/ProductDetails";


const ProductDetailsContainer = () => {
  const history = useHistory();
  const { params } = useRouteMatch();

  /**
   * This method is used to change route to products
   *
   */
  const goBack = () => {
    history.push("/merchandising/product");
  };

  /**
   * This method is used to change route to edit product
   *
   */
  const goToEdit = () => {
    history.push(`/merchandising/product/edit/${params.productId}`);
  };

  const [isOpen, setIsOpen] = useState(false);
  const [selectedVariant, setSelectedVariant] = useState(false);
  const [product, setProduct] = useState(null);
  const [offers, setOffers] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingOffers, setLoadingOffers] = useState(false);
  const [pagination, setPagination] = useState({
    page: 0,
    totalPages: 0,
    totalCount: 0,
    limit: 5,
    numberOfElements: 0,
  });
  const [priceAndInventoriesLoading, setPriceAndInventoriesLoading] = useState(false);

  const [locale, setLocale] = useState("en_AE");

  const localeHandler = (localeLang) => {
    setLocale(localeLang);
  };

  /**
   * This method is used get offers with pagination
   * @param {Number} pageNo
   */
  const getPageData = (pageNo) => {
    offerServices.getOffersByProductId(params.productId, pageNo).then((response) => {
      if (response && response.success && response.data) {
        const {
          content, totalPages, totalElements, endOfResult,
        } = response.data;
        setPagination({
          ...pagination,
          page: pageNo,
          totalPages,
          totalCount: totalElements,
          numberOfElements: !endOfResult
            ? pagination.limit * (pageNo + 1)
            : totalElements,
        });
        setOffers(content);
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    setLoadingOffers(true);
    // Getting product details
    if (locale && params.productId) {
      getProduct(params.productId, locale).then(async (response) => {
        if (response && response.success && response.data) {
          const { data } = response;
          setProduct(data);
          setPriceAndInventoriesLoading(true);
          const productData = { ...data };
          const reqObject = {
            productId: data.id,
            skuId: data.defaultSKU,
            vendorFulfilled: data.vendorFulfilled,
            uniqueId: data.uniqueId,
            productBrand: data.productBrand
          };
          // Getting price and inventories of product
          if (!data.vendorFulfilled) {
            getPriceAndStoreInventories(reqObject).then((responseR) => {
              if (responseR && responseR.success && responseR.data) {
                const priceInventoryObject = responseR.data;
                productData.priceInfo = priceInventoryObject.price;
                productData.inventory = priceInventoryObject.inventory;
                setProduct(productData);
              }
            }).finally(() => {
              setPriceAndInventoriesLoading(false);
            });
          } else {
            setPriceAndInventoriesLoading(false);
          }
          // Getting offers with pagination
          if (data.vendorFulfilled || !data.sellerRestriction) {
            offerServices.getOffersByProductId(params.productId).then((responseIn) => {
              if (responseIn && responseIn.success && responseIn.data) {
                const {
                  content, totalPages, totalElements, numberOfElements: totalItems,
                } = responseIn.data;
                setPagination({
                  ...pagination,
                  page: 0,
                  totalPages,
                  totalCount: totalElements,
                  numberOfElements: totalItems,
                });
                setOffers(content);
              }
            }).finally(() => {
              setLoadingOffers(false);
            });
          } else {
            setLoadingOffers(false);
          }
        } else {
          setLoadingOffers(false);
          goBack();
        }
      }).finally(() => { setLoading(false); });
    }
  }, [params.productId, locale]);

  /**
   * This method is used to toggle Variant
   *
   * @param {String} id
   */
  const toggleVariant = (id) => {
    setIsOpen(!isOpen);
    if (id === selectedVariant) setSelectedVariant("id");
    else setSelectedVariant(id);
  };

  return (
    <ProductDetails
      goBack={goBack}
      offers={offers}
      loading={loading}
      product={product}
      goToEdit={goToEdit}
      pagination={pagination}
      getPageData={getPageData}
      localeHandler={localeHandler}
      loadingOffers={loadingOffers}
      toggleVariant={toggleVariant}
      selectedVariant={selectedVariant}
      priceAndInventoriesLoading={priceAndInventoriesLoading}
    />
  );
};

ProductDetailsContainer.propTypes = {
};

export default ProductDetailsContainer;
