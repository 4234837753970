/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Login Container
 *
 * @author Dipin
 *
 */

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import Login from "../components/Login";
import { login } from "../../../api/UserServices";
import { isAuthenticated } from "../../../common/security/permissionUtils";
import UserData from "../../../common/security/UserData";
import constants from "../../../common/utils/constants";

const LoginContainer = ({ location }) => {
  const history = useHistory();
  const redirectPath = location && location.search ? new URLSearchParams(location.search) : null;

  const [loginData, setLoginData] = useState({ username: "", password: "" });
  const [error, setError] = useState({ username: "", password: "", isValid: "" });
  const [submitting, setSubmitting] = useState(false);
  /**
   * to redirect admin dashboard
   */
  const loadDashboard = () => {
    if (redirectPath && redirectPath.get(constants.REDIRECT_PATH)) {
      history.push(decodeURIComponent(redirectPath.get(constants.REDIRECT_PATH)));
    } else {
      history.push("/");
    }
  };

  if (isAuthenticated()) {
    history.push("/");
  }

  /**
   * This method is used to handle submit form
   *
   * @param {Object} loginData
   */
  const handleSubmitForm = () => {
    setSubmitting(true);
    login(loginData).then((response) => {
      localStorage.removeItem(constants.LOCAL_STORAGE_KEY_IS_REDIRECTING);
      setSubmitting(false);
      if (response && response.success && response.data) {
        const {
          data: {
            token, refreshToken, username, name, authorities,
          },
        } = response;
        const locale = "en_AE";
        UserData.setAll({
          token,
          authenticated: true,
          refreshToken,
          username,
          name,
          authorities,
          locale,
        });
        loadDashboard();
      } else {
        setError({ isValid: "Incorrect username or password" });
      }
    });
  };

  /**
   * This method is used to handle change text
   *
   * @param {String} key
   * @param {Object} e
   */
  const handleChange = (key, e) => {
    setError({ username: "", password: "", isValid: "" });
    setLoginData({ ...loginData, [key]: e.target.value });
  };

  /**
   * Tjis method is used to validate login form
   *
   * @returns
   */
  const validateForm = () => {
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let isValid = true;
    let passwordError = "";
    let usernameError = "";
    if (loginData.password === "") {
      passwordError = "This field is mandatory";
      isValid = false;
    } else if (loginData.password.length < 5) {
      passwordError = "Password must have 5 letters";
      isValid = false;
    }
    if (loginData.username === "" && isValid) {
      usernameError = "This field is mandatory";
      isValid = false;
    } else if (!regexp.test(loginData.username)) {
      usernameError = "Invalid email format";
      isValid = false;
    }
    setError({ password: passwordError, username: usernameError });
    return isValid;
  };

  /**
   * This method is used to handle submit form
   *
   */
  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm(loginData)) {
      handleSubmitForm();
    }
  };

  return (
    <>
      <Login
        handleSubmitForm={handleSubmitForm}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        loginData={loginData}
        error={error}
        submitting={submitting}
      />
    </>
  );
};

LoginContainer.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default LoginContainer;
