/**
 * Copyright(c) 2022 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Mohammed Shefeeq
 */

import React, { useState, useEffect } from "react";
import BatchTypeReport from "../components/BatchTypeReport";
import { updateBatchConfig, getBatchStatus, getJobHistory } from "../../../api/batchTypeReportService";

const BatchTypeReportContainer = () => {

  const reportTypeList = [{ value: "productDataDumpExportJob", label: "Product Report" }];
  const [isExporting, setIsExporting] = useState(false);
  const [schedulerDetails, setSchedulerDetails] = useState({
    criteria: {},
    type: "",
  });

  const [refreshDisabler, setRefreshDisabler] = useState(false);

  const [jobHistoryReqData, setJobHistoryReqData] = useState({
    page: 0,
    size: 5,
    status: null,
    jobConfigId: null,
    productId: "",
    jobConfigType: null,
    searchTerm: "productDataDumpExportJob",
    startDate: null,
    endDate: null,
  });

  const checkDownloadStatus = async (type) => {
    const response = await getBatchStatus(type);
    if (response && response.success) {
      if (response.data == true) {
        setIsExporting(true);
      } else {
        setIsExporting(false);
      }
    }
  };

  const [jobHistoryData, setJobHistoryData] = useState([]);

  const jobHistoryServiceCall = (reqObj) => {
    setRefreshDisabler(true)
    getJobHistory(reqObj).then(async (response) => {
      if (response && response.data) {
        const { data } = response;
        if (data && data.content) {
          setJobHistoryData(data.content);
        }
      }
      checkDownloadStatus(reqObj.searchTerm);
    }).then (() => {
      setRefreshDisabler(false)
    });
  };

  const exportBatchTypeReport = async (event) => {
    if (schedulerDetails.type != "") {
      const response = await updateBatchConfig(schedulerDetails);
      if (response && response.data == true && response.success) {
        setIsExporting(true);
      }
    }
  };

  const onHandleReportTypeChange = (e) => {
    const { value } = e.target;
    setSchedulerDetails({
      type: value,
      criteria: {},
    });
    if (value) {
      jobHistoryReqData.searchTerm = value;
      jobHistoryServiceCall(jobHistoryReqData);
    }
  };

  return (
    <BatchTypeReport
      exportBatchTypeReport={exportBatchTypeReport}
      schedulerDetails={schedulerDetails}
      isExporting={isExporting}
      onHandleReportTypeChange={onHandleReportTypeChange}
      reportTypeList={reportTypeList}
      jobHistoryData={jobHistoryData}
      jobHistoryServiceCall={jobHistoryServiceCall}
      jobHistoryReqData={jobHistoryReqData}
      refreshDisabler={refreshDisabler}

    />
  );
};

export default BatchTypeReportContainer;