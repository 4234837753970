/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * DragAndDrop Item Table Container
 *
 * @author Naseef O
 *
 */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { updatePageContent } from "../../../api/pageContentServices";
import { getCategoryByIds } from "../../../api/categoryManagementServices";
import { getProductByIds } from "../../../api/productManagementServices";
import DragAndDropItemTable from "../components/DragAndDropItemTable";

const DragAndDroppableItemTableContainer = (props) => {
  const {
    getPageContents,
    widgetContent,
    channel,
    locale,
  } = props;

  const [customizationData, setCustomizationData] = useState([]);
  useEffect(() => {
    if (widgetContent && widgetContent.contentType) {
      const { contentIds, contentType } = widgetContent;
      const constructData = (dataArray) => {
        const constructedArray = [];
        if (dataArray && dataArray.length > 0) {
          dataArray.map((item) => {
            const newItem = {};
            newItem.name = item.name;
            newItem.id = item.id;
            if (item.medias && item.medias[0]) {
              newItem.imageUrl = item.medias[0].defaultMediaUrl;
            }
            constructedArray.push(newItem);
            return newItem;
          });
        }
        return constructedArray;
      };

      if (contentType && contentIds) {
        const selectedData = [];
        if (contentType === "Category" || contentType === "Brand") {
          getCategoryByIds(contentIds).then(async (response) => {
            if (response && response.success) {
              const { data: categories } = response;
              if (Array.isArray(contentIds)) {
                contentIds.map((item) => {
                  categories.map((category) => {
                    if (category.id === item) {
                      selectedData.push(category);
                    }
                    return null;
                  });
                  return null;
                });
                setCustomizationData(constructData(selectedData));
              }
            }
          });
        }
        if (contentType === "Product") {
          getProductByIds(contentIds).then(async (response) => {
            if (response && response.success && response.data) {
              await contentIds.map((item) => {
                response.data.map((product) => {
                  if (product.id === item) {
                    selectedData.push(product);
                  }
                  return null;
                });
                return null;
              });
              setCustomizationData(constructData(selectedData));
            }
          });
        }
      }
    } else {
      setCustomizationData([]);
    }
  }, [widgetContent]);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);

  /**
   * Delete a content
   *
   * @param {*} id
   * @param {*} index
   */
  const handleDelete = async (id, index) => {
    setIsLoading(true);
    setSelectedIndex(index);
    const newWidgetContent = { ...widgetContent };
    const contentIds = widgetContent.contentIds
      && widgetContent.contentIds.filter(((item) => item !== id));
    newWidgetContent.contentIds = contentIds;
    if (contentIds.length <= 0) {
      newWidgetContent.contentType = "";
    }
    await updatePageContent(newWidgetContent, channel, locale).then((response) => {
      if (response && response.success) {
        getPageContents();
        setIsLoading(false);
      }
    });
  };

  /**
   *This method is used to modify the list after drag
   *
   * @param {Array} list
   * @param {Number} startIndex
 * @param {Number} endIndex
 * @returns
 */
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  /**
   *This method is used ito on drag end
 *
 * @param {object} result
 * @returns
 */
  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const rowData = reorder(
      customizationData,
      result.source.index,
      result.destination.index,
    );
    setCustomizationData(rowData);

    const newWidgetContent = { ...widgetContent };
    const contentIds = rowData.map((item) => item.id);
    newWidgetContent.contentIds = contentIds;
    if (contentIds.length <= 0) {
      newWidgetContent.contentType = "";
    }
    await updatePageContent(newWidgetContent, channel, locale).then((response) => response);
  };


  return (
    <DragAndDropItemTable
      customizationData={customizationData}
      onDragEnd={onDragEnd}
      handleDelete={handleDelete}
      isLoading={isLoading}
      selectedIndex={selectedIndex}
    />
  );
};
DragAndDroppableItemTableContainer.propTypes = {
  widgetContent: PropTypes.objectOf(PropTypes.any).isRequired,
  getPageContents: PropTypes.func.isRequired,
  channel: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
};
export default DragAndDroppableItemTableContainer;
