/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/** ========== CUSTOM COMPONENT ================ */
import SimpleCategorySelector from "../components/SimpleCategorySelector";

/** ===== API SERVICE FUNCTIONS =========== */
import { listLeafCategories } from "../../../api/categoryManagementServices";


const SimpleCategorySelectorContainer = ({
  onChange,
  name,
  value,
  options,
  disablePIMCategory,
}) => {
  /** local states */
  const [categories, setCategories] = useState(options || []);

  useEffect(() => {
    setCategories((preCategories) => (preCategories.length < 1 ? options : preCategories));
  }, [options]);

  /**
   * This method is used to trigger onChange
   * @param {Event} event
   */
  const localOnChange = (event) => {
    const { value: localValue } = event.target;
    let filteredList = [];
    if (Array.isArray(categories) && categories.length > 0) {
      filteredList = categories.filter((c) => localValue.includes(c.id));
    }
    onChange(localValue, filteredList);
  };

  /**
 * This method is used to handle typeahead change
 * @param {Event} event
 */
  const handleTypeaheadChange = (queryText) => {
    if (!queryText || (queryText && `${queryText}`.trim().length < 3)) {
      setCategories([]);
      return;
    }
    listLeafCategories(queryText).then((response) => {
      if (response && response.success && Array.isArray(response.data)) {
        setCategories(response.data);
      }
    });
  };

  return (
    <SimpleCategorySelector
      categories={categories}
      selectedCategories={value}
      onChange={localOnChange}
      name={name}
      disablePIMCategory={disablePIMCategory}
      handleTypeaheadChange={handleTypeaheadChange}
    />
  );
};


SimpleCategorySelectorContainer.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.any).isRequired,
  options: PropTypes.arrayOf(PropTypes.any),
  disablePIMCategory: PropTypes.bool,
};

SimpleCategorySelectorContainer.defaultProps = {
  name: "category",
  options: undefined,
  disablePIMCategory: false,
};

export default SimpleCategorySelectorContainer;
