/**
 * Copyright(c) 2022 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Abdul Razack
 */

import React, { useState } from "react";
import constants from "../../../common/utils/constants";
/** ===== COMPONENTS  =========== */
import ProductBulkManagement from "../components/ProductBulkManagement";

const {
  PRODUCT_BULK_MAINTENANCE: {
    MAINTENANCE_TYPES,
  },
} = constants;

const ProductBulkManagementContainer = () => {
  const [selectedMaintenanceType, setSelectedMaintenanceType] = useState(null);
  const handleMaintenanceType = (item) => {
    const type = MAINTENANCE_TYPES.find((data) => data.value === item.value);
    if (item) {
      setSelectedMaintenanceType(type);
    }
  };
  return (
    <ProductBulkManagement
      maintenanceType={selectedMaintenanceType}
      maintenanceTypes={MAINTENANCE_TYPES}
      handleMaintenanceType={handleMaintenanceType}
    />
  );
};
export default ProductBulkManagementContainer;
