/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */
import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ========= TAG COMPONENTS ========= */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Button from "../../../common/core/Button";
import Form from "../../../common/core/Form";
import Alert from "../../../common/core/Alert";

/** ========= SUB COMPONENT ========= */
/** ========= CUSTOM COMPONENTS ========= */
import CouponForm from "./CouponForm";
import AdvancedSettingsForm from "./AdvancedSettingsForm";

/** ========= MODULE STYLES ========= */
import styles from "../css/AddCoupon.module.css";
import validateForm from "../../../common/utils/validateForm";

const AddCoupon = (props) => {
  const {
    code, description, startDate, endDate, active,
    usesPerCoupon, usesPerCustomer,
    formSubmit, formCancel, onSubmitting, indefinite,
    handleFormChange, handleChange, submitForm, unlimited, message, onCampaign,
    caseSensitive, generateCoupon, prefix, numberOfCoupons, isCampaignPage,
  } = props;

  /** internationalization using i18n from common and coupon  */
  const { t } = useTranslation(["common", "coupon"]);
  const validator = validateForm();
  const { handleSubmit } = validator;
  return (
    <>
      <Row>
        <Col xs="12">
          <Form onSubmit={handleSubmit(formSubmit)}>
            {
              message && message.type && (
                <Alert color={message.type}>
                  {message.message}
                </Alert>
              )
            }
            <fieldset disabled={onSubmitting}>
              {/*  ====== PAGE COMPONENT FORM S[0002]============ */}
              {!onCampaign
                ? (
                  <Row className="pageHeader common-page-card">
                    <Col xs="12" md="7" className="text-truncate">
                      <span className="pageText">
                        {t("coupon:title_add")}
                      </span>
                    </Col>
                    <Col xs="12" md="5" className="d-flex justify-content-end">
                      <Button onClick={(o) => formCancel(o)} type="button" color="secondary" className={clsx(styles.borderRadius2, styles.btn, styles.secondaryColor, "text-truncate", "mr-1")}>
                        {t("common:button_cancel")}
                      </Button>
                      <Button type="submit" color="secondary" className={clsx(styles.borderRadius2, styles.btn, styles.primaryColor, "text-truncate", "mr-1", "button-font-size")}>
                        {t("common:button_save")}
                      </Button>
                    </Col>
                  </Row>
                )
                : ""}
              <Row className={clsx(styles.blockWrapper, " common-page-card", "pb-0")}>
                <Col xs="12" lg={onCampaign ? "12" : "10"}>
                  <div className="common-page-card">
                    {/*  ====== MENU FORM S[0005]============ */}
                    <CouponForm
                      code={code}
                      description={description}
                      startDate={startDate}
                      endDate={endDate}
                      indefinite={indefinite}
                      active={active}
                      isCampaignPage={isCampaignPage}
                      /** form methods */
                      handleFormChange={handleFormChange}
                      handleChange={handleChange}
                      validator={validator}
                      onCampaign={onCampaign}
                      caseSensitive={caseSensitive}
                      generateCoupon={generateCoupon}
                      prefix={prefix}
                      numberOfCoupons={numberOfCoupons}
                    />
                  </div>
                </Col>
              </Row>

              <Row className={clsx(styles.blockWrapper, " common-page-card", "pt-0")}>
                <Col xs="12" lg={onCampaign ? "12" : "10"}>
                  <div className="common-page-card">
                    {/*  ====== MENU FORM S[0005]============ */}
                    <AdvancedSettingsForm
                      usesPerCoupon={usesPerCoupon}
                      usesPerCustomer={usesPerCustomer}
                      /** form methods */
                      handleFormChange={handleFormChange}
                      handleChange={handleChange}
                      validator={validator}
                      unlimited={unlimited}
                      onCampaign={onCampaign}
                    />
                  </div>
                </Col>
              </Row>
              {
                onCampaign && (
                  <div className="w-100 d-flex justify-content-end">
                    <Button type="button" onClick={formCancel} color="secondary" className={clsx(styles.borderRadius2, styles.btn, styles.secondaryColor, "text-truncate", "mr-1")}>
                      {t("common:button_cancel")}
                    </Button>
                    <Button type="submit" color="tertiary" className={clsx(styles.borderRadius2, styles.btn, "text-truncate", "mr-1", "button-font-size,btn-tertiary ")}>
                      {t("common:button_save")}
                    </Button>
                  </div>
                )
              }
              {/*  ====== PAGE COMPONENT FORM E[0002]============ */}
            </fieldset>
          </Form>
        </Col>
      </Row>
    </>
  );
};

AddCoupon.defaultProps = {
  isCampaignPage: null,
};

AddCoupon.propTypes = {
  code: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  startDate: PropTypes.objectOf(PropTypes.any).isRequired,
  endDate: PropTypes.objectOf(PropTypes.any).isRequired,
  active: PropTypes.bool.isRequired,
  usesPerCoupon: PropTypes.string.isRequired,
  usesPerCustomer: PropTypes.string.isRequired,
  onSubmitting: PropTypes.bool.isRequired,
  indefinite: PropTypes.bool.isRequired,
  unlimited: PropTypes.bool.isRequired,
  onCampaign: PropTypes.bool.isRequired,
  caseSensitive: PropTypes.bool.isRequired,
  generateCoupon: PropTypes.bool.isRequired,
  message: PropTypes.objectOf(PropTypes.any).isRequired,
  prefix: PropTypes.string.isRequired,
  numberOfCoupons: PropTypes.string.isRequired,
  isCampaignPage: PropTypes.bool,
  // form actions
  formSubmit: PropTypes.func.isRequired,
  formCancel: PropTypes.func.isRequired,
  // form methods
  handleFormChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default AddCoupon;
