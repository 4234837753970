/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Categories Table
 *
 * @author Naseef O
 *
 */

import React, { useState } from "react";
import PropTypes from "prop-types";

import clsx from "clsx";
import moment from "moment";
import Table from "../../../common/core/Table";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";

import styles from "../css/Table.module.css";

import rightArrow from "../../../common/assets/images/svg/right-arrow.svg";
import warningIcon from "../../../common/assets/images/svg/warning-mui.svg";


const ExternalCategoriesTable = (props) => {
  const {
    loading,
    tableRows,
    tableData,
  } = props;
  const [viewMore, setViewMore] = useState("");
  const handleViewMore = (id) => {
    if (id === viewMore) {
      setViewMore("");
    } else {
      setViewMore(id);
    }
  };

  const firstElement = (elements) => {
    if (Array.isArray(elements) && elements.length > 0) {
      return elements.slice(0, 3);
    }
    return null;
  };


  return (
    <div className="custom-table mt-3">
      <Table borderless>
        <thead>
          <tr>
            <th>External Id</th>
            {Array.isArray(tableRows) && tableRows.length > 0 && tableRows.map((row) => (
              <th>{row.label}</th>
            ))}
            <th>Last modified</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(tableData) && tableData.length > 0
            ? tableData.map((rowItem) => (
              <tr>
                <th scope="row" width="auto">
                  {rowItem.externalCategoryId}
                </th>
                <td width="auto">
                  <span>{rowItem.externalCategoryName}</span>
                  <br />
                  <span className={styles.lineageText}>{rowItem.lineage ? rowItem.lineage.split(",").join(" -> ") : ""}</span>
                </td>
                <td>
                  <Row className="justify-content-start">
                    {rowItem.mappedCategories && rowItem.mappedCategories.length > 0 ? (
                      <>
                        {" "}
                        {viewMore === rowItem.id ? rowItem.mappedCategories.map((item) => (
                          <>
                            <Col xs="auto" className={styles.categoryChip}>
                              {item.name}
                            </Col>
                          </>
                        ))
                          : firstElement(rowItem.mappedCategories)
                          && firstElement(rowItem.mappedCategories).map((item, index) => (
                            <>
                              <Col xs="auto" className={styles.categoryChip}>
                                {item.name}
                              </Col>
                              {
                                rowItem.mappedCategories.length > 3
                                && firstElement(rowItem.mappedCategories).length === index + 1
                                && (
                                  <Col
                                    xs="auto"
                                    onClick={() => handleViewMore(rowItem.id)}
                                    className={clsx(styles.viewAllChip, "pl-2 pr-1")}
                                  >
                                    View more
                                    <img src={rightArrow} width="20px" alt=" view all" />
                                  </Col>
                                )
                              }
                            </>
                          ))}
                      </>
                    )
                      : (
                        <Col xs="auto">
                          <span className={clsx(styles.warningMsg, "d-flex align-items-center")}>
                            <img src={warningIcon} width="25px" className="pr-2" alt="warning" />
                            Map Storefront Categories to show it on storefront
                          </span>
                        </Col>
                      )}
                  </Row>
                </td>
                <td>
                  <span className={styles.date}>
                    {moment(rowItem.updatedDate).format("HH:mm:ss DD/MM/YYYY")}
                  </span>
                </td>
              </tr>
            )) : (
              <tr>
                <td colSpan="100%" align="center">
                  {loading ? "Loading..." : "Nothing to show."}
                </td>
              </tr>
            )}
        </tbody>
      </Table>
    </div>
  );
};

ExternalCategoriesTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  tableRows: PropTypes.arrayOf(PropTypes.any).isRequired,
  tableData: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default ExternalCategoriesTable;
