/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * TreeExternalCategorySelector
 *
 * @author Naseef O
 */

import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState, useRef, useEffect } from "react";
import { getSubExternalCategories } from "../../../api/externalCategoryServices";

import PlusIcon from "../../../common/assets/images/svg/add-24px.svg";
import MinusIcon from "../../../common/assets/images/svg/minus-24px.svg";

import styles from "../../externalCategories/css/Category.module.css";

const ExternalCategory = ({
  key,
  index,
  externalCategory,
  handleSelectCategories,
  tempSelectedExternalCategories,
}) => {
  const currentRow = useRef(null);

  /** local states */
  const [isOpen, setIsOpen] = useState(false);
  const [childExternalCategories, setChildExternalCategories] = useState(null);
  const [hasChild, setHasChildExternalCategory] = useState(false);

  /**
    * This method is used to get category list by parent ExternalCategory id
    * @param {String} id
    */
  const getCategoryByParenCategory = async (externalCategoryId) => {
    getSubExternalCategories(externalCategoryId).then((response) => {
      if (response && response.success
        && Array.isArray(response.data) && response.data.length > 0) {
        setHasChildExternalCategory(true);
        setChildExternalCategories(response.data);
      } else {
        setHasChildExternalCategory(false);
      }
    });
  };

  useEffect(() => {
    /** Fetching sub external categories by external category */
    getSubExternalCategories(externalCategory.externalCategoryId).then((response) => {
      if (response && response.success
        && Array.isArray(response.data) && response.data.length > 0) {
        setHasChildExternalCategory(true);
        setChildExternalCategories(response.data);
      } else {
        setHasChildExternalCategory(false);
      }
    });
  }, [externalCategory]);

  /**
  * This method is used to toggle the external category collapse
  * call sub external category list when its open
  * @param {String} externalCategoryId
  */
  const toggle = (externalCategoryId) => {
    if (hasChild) {
      setIsOpen(!isOpen);
      if (!isOpen) { getCategoryByParenCategory(externalCategoryId); }
    }
  };


  /**
      * This method used to position sub external category -> left side
      * @param {Number} limit
      */
  const getDivForPositioning = (limit) => {
    const positionComponents = [];
    for (let o = 0; o < limit; o += 1) { positionComponents.push(<span />); }
    return positionComponents;
  };

  return (
    <>
      <div key={key} className={styles.categoryTreeItem} ref={currentRow}>
        <div className={clsx("py-0", "align-items-stretch")}>
          <div className={clsx(styles.treeLineAnimationRootComponent, "d-flex", "h-100", "align-items-stretch")}>
            {getDivForPositioning(index)}
            <div className={clsx(styles.itemTitle, "bg-white", "d-flex", (!hasChild) && styles.noDescendants, isOpen ? styles.isOpen : styles.isClosed)}>
              {hasChild
                && (
                  <span className="commonPointer d-flex align-items-center " onClick={(o) => toggle(externalCategory.externalCategoryId, o)} onKeyDown={() => { }} tabIndex={0} role="button">
                    <img
                      className={styles.icon}
                      src={isOpen ? MinusIcon : PlusIcon}
                      alt=""
                    />
                  </span>
                )}
              <span className={clsx(styles.fontWeightBold, "px-1", "text-truncate d-flex align-items-center m-0")}>
                <label htmlFor={externalCategory.id} className="d-flex align-items-center m-0 ">
                  <input
                    id={externalCategory.id}
                    type="checkbox"
                    checked={
                      !!(Array.isArray(tempSelectedExternalCategories)
                        && tempSelectedExternalCategories.length > 0
                        && tempSelectedExternalCategories.find((c) => c.id === externalCategory.id))
                    }
                    onClick={() => handleSelectCategories(externalCategory)}
                  />
                  { }
                  <span className="ml-2">
                    {externalCategory.externalCategoryName}
                  </span>
                </label>
              </span>
            </div>
          </div>
        </div>
      </div>
      {hasChild && isOpen && (
        Array.isArray(childExternalCategories) && childExternalCategories.length > 0 ? (
          <>
            {childExternalCategories.map((category) => (
              <ExternalCategory
                index={index + 1}
                key={`extCat-child-${category.id}`}
                id={category.id}
                externalCategory={category}
                handleSelectCategories={handleSelectCategories}
                tempSelectedExternalCategories={tempSelectedExternalCategories}
              />
            ))}
          </>
        ) : (
          <div className={styles.categoryTreeItem}>
            <div className={clsx("py-0", "align-items-stretch")}>
              <div className={clsx(styles.treeLineAnimationRootComponent, "d-flex", "h-100", "align-items-stretch")}>
                {getDivForPositioning(index)}
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};

ExternalCategory.propTypes = {
  key: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  externalCategory: PropTypes.objectOf(PropTypes.any).isRequired,
  handleSelectCategories: PropTypes.func.isRequired,
  tempSelectedExternalCategories: PropTypes.arrayOf(PropTypes.any).isRequired,
};

const TreeExternalCategorySelector = ({
  handleSelectCategories,
  tempSelectedExternalCategories,
  externalCategories,
  loadingExternalCategories,
}) => (
  <>
    {Array.isArray(externalCategories) && externalCategories.length > 0
      ? externalCategories.map((each, index) => (
        <ExternalCategory
          index={1}
          externalCategory={each}
          key={`extCat-${index + 1}`}
          handleSelectCategories={handleSelectCategories}
          tempSelectedExternalCategories={tempSelectedExternalCategories}
        />
      )) : <>{loadingExternalCategories ? " Loading..." : "Nothing found!"}</>}
  </>
);

TreeExternalCategorySelector.defaultProps = {
  loadingExternalCategories: false,
};
TreeExternalCategorySelector.propTypes = {
  loadingExternalCategories: PropTypes.bool,
  handleSelectCategories: PropTypes.func.isRequired,
  externalCategories: PropTypes.arrayOf(PropTypes.any).isRequired,
  tempSelectedExternalCategories: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default TreeExternalCategorySelector;
