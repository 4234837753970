/**
 * Copyright(c) 2022 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Seller Messaging
 *
 * @author Krishnanunny H
 *
 */
import React, { useState } from "react";
import clsx from "clsx";
import DatePicker from "react-datetime";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import styles from "../css/sellerMessaging.module.css";
import CustomizationTable from "../../../common/components/Table";
import InputGroup from "../../../common/core/InputGroup";
import Input from "../../../common/core/Input";
import InputGroupAddon from "../../../common/core/InputGroupAddon";
import SearchIcon from "../../../common/assets/images/svg/search.svg";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import FormFeedback from "../../../common/core/FormFeedback";
import ClearIconAlt from "../../../common/assets/images/svg/clear-alt.svg";
import refreshIcon from "../../../common/assets/images/svg/sync.svg";
import DropdownMenu from "../../../common/core/DropdownMenu";
import Dropdown from "../../../common/core/Dropdown";
import DropdownToggle from "../../../common/core/DropdownToggle";
import ExpandMoreIcon from "../../../common/assets/images/svg/expand-more-alt.svg";
import ExpandLessIcon from "../../../common/assets/images/svg/expand-less-alt.svg";
import Pagination from "../../../common/components/Pagination";

import constants from "../../../common/utils/constants";

const moment = require("moment");

const {
  SELLER_MESSAGING: { CONVERSATION_DATE_FROM }, DATE_FORMAT,
} = constants;

const SellerMessaging = ({
  setFromDate,
  setSearchTerm,
  onHandleSearch,
  threads,
  isValidDate,
  onHandleClear,
  searchTerm,
  fromDate,
  toDate,
  confirmBlocking,
  setEnabled,
  pagination,
  enabled,
}) => {
  const { t } = useTranslation(["common", "seller-messaging"]);
  const [viewDropDown, setViewDropDown] = useState(false);
  const toggleViewDropDown = () => {
    setViewDropDown(!viewDropDown);
  };

  /**
  * This method to change status in thread list
  * @param {Object} row
  * @returns {Element}
  */
  const getStatus = (row) => {
    if (row && row.threadExpired) {
      return null;
    }
    const newStatus = (
      <div
        className={clsx(
          "custom-control",
          "custom-switch",
        )}
      >
        <input
          type="checkbox"
          className="custom-control-input"
          id={`expand-multiple${row.threadId}`}
          checked={!row.threadBlocked}
          onChange={(o) => !row.threadBlocked && confirmBlocking(row.threadId)}
        />
        <Label className="custom-control-label" htmlFor={`expand-multiple${row.threadId}`} />
      </div>
    );
    return newStatus;
  };

  const getStatusText = () => {
    if (enabled === true) {
      return t("seller-messaging:disabled");
    }
    if (enabled === false) {
      return t("seller-messaging:enabled");
    }
    return t("seller-messaging:status");
  };

  const transformUTCDateToLocal = (date) => {
    const gmtDateTime = moment.utc(date, "YYYY-MM-DD HH:mm:ss");
    const local = gmtDateTime.local().format("YYYY-MM-DD HH:mm:ss");
    return local;
  };

  const transformBlockedDate = (row) => {
    if (row && row.threadBlockedDate) {
      return transformUTCDateToLocal(row.threadBlockedDate);
    }
    return null;
  };

  const transformStartDate = (row) => {
    if (row && row.threadStartDate) {
      return transformUTCDateToLocal(row.threadStartDate);
    }
    return null;
  };

  return (
    <>
      <Row className="common-page-card pageHeader">
        <Col xs="12">
          <span className="pageText">
            {t("seller-messaging:seller_messaging_title")}
          </span>
        </Col>
        <Col xs="12" md="4" lg="5" />
      </Row>
      <Row className={clsx(styles.block1, "common-page-card ")}>
        <Col xs="12">
          <Row className="m-0 ">
            <Col xs="12" className="p-0">
              <div className={styles.areaActionRight}>
                <div className={clsx(styles.searchContainer)}>
                  <InputGroup className={clsx(styles.customInput, "color-tertiary-main")}>
                    <Input
                      placeholder={t(
                        "seller-messaging:placeholder_search_shipment_id",
                      )}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <InputGroupAddon
                      addonType="append"
                      className="commonPointer bg-color-tertiary-main px-2"
                    >
                      <img
                        src={searchTerm ? ClearIconAlt : SearchIcon}
                        alt={t("seller-messaging:placeholder_search_shipment_id")}
                        role="presentation"
                        width="25px"
                        onClick={() => setSearchTerm("")}
                        className=""
                      />
                    </InputGroupAddon>
                  </InputGroup>
                  {" "}
                </div>
                <div className={clsx(styles.searchContainer)}>
                  <Dropdown
                    isOpen={viewDropDown}
                    toggle={toggleViewDropDown}
                    className={styles.dropdownButtonWrapper}
                  >
                    <DropdownToggle caret>
                      <span
                        className={
                  clsx(styles.selectedFilter, "d-flex align-items-center")
                }
                      >
                        {getStatusText()}
                      </span>
                    </DropdownToggle>
                    <div
                      role="presentation"
                      className={clsx(styles.dropdownClear, "cursor-pointer")}
                      onClick={() => toggleViewDropDown()}
                    >
                      {viewDropDown ? <img src={ExpandLessIcon} alt="View" width="20px" />
                        : <img src={ExpandMoreIcon} alt="View" width="20px" />}
                    </div>
                    <DropdownMenu className={styles.dropdownWrapper}>
                      {[{ name: "Enabled", value: false }, { name: "Disabled", value: true }].map((item) => (
                        <div
                          key={item.value}
                          onKeyPress={() => { }}
                          role="button"
                          tabIndex="0"
                          onClick={() => {
                            setEnabled(item.value);
                            setViewDropDown(false);
                          }}
                          className={clsx(styles.dropdownItem, "d-flex align-items-center commonPointer")}
                        >
                          {item.name}
                        </div>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                </div>
                <div className="ml-2">
                  <FormGroup>
                    <Label className="formTextLabel" for="fromDate">
                      {t("seller-messaging:start_date")}
                    </Label>
                    <DatePicker
                      id="fromDate"
                      dateFormat={DATE_FORMAT}
                      timeFormat={false}
                      closeOnSelect
                      input
                      name="fromDate"
                      onChange={(e) => setFromDate(e)}
                      isValidDate={(e) => isValidDate(e, CONVERSATION_DATE_FROM)}
                      inputProps={{
                        placeholder: t("common:placeholder_date_example"),
                      }}
                      value={fromDate}
                    />
                    <FormFeedback>
                      {t("seller-messaging:label_invalid_order_date_msg")}
                    </FormFeedback>
                  </FormGroup>
                </div>
                <div className={clsx(styles.searchContainer)}>
                  <span
                    onClick={() => onHandleClear()}
                    className="commonPointer"
                    title="Refresh"
                  >
                    <img
                      width="40"
                      src={refreshIcon}
                      alt="search category"
                      className="p-1 commonPointer"
                    />
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={clsx(styles.tableWrapper, "common-page-card")}>
        <Col xs="12">
          <Row>
            <Col xs="12">
              <div className="custom-table">
                <CustomizationTable
                  customRows={[
                    {
                      label: "Shipment ID",
                      value: "",
                      path: "shipmentId",
                      transform: "",
                    },
                    {
                      label: "Customer ID",
                      value: "",
                      path: "userId",
                      transform: "",
                    },
                    {
                      label: "Seller name",
                      value: "",
                      path: "sellerName",
                      transform: "",
                    },
                    {
                      label: "Seller code",
                      value: "",
                      path: "sellerId",
                      transform: "",
                    },
                    {
                      label: "Status",
                      path: "render",
                      render: getStatus,
                    },
                    {
                      label: "Start date",
                      path: "render",
                      render: transformStartDate,
                    },
                    {
                      label: "Blocked Date",
                      path: "render",
                      render: transformBlockedDate,
                    },
                  ]}
                  customizationData={threads}
                />
              </div>
              {Array.isArray(threads) && threads.length > 0 && (
              <Pagination
                totalCount={pagination.totalCount}
                page={pagination.page}
                limit={pagination.limit}
                getPageData={onHandleSearch}
              />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

SellerMessaging.propTypes = {
  setToDate: PropTypes.func.isRequired,
  setFromDate: PropTypes.func.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  onHandleSearch: PropTypes.func.isRequired,
  threads: PropTypes.arrayOf(PropTypes.any).isRequired,
  page: PropTypes.number.isRequired,
  isValidDate: PropTypes.bool.isRequired,
  onHandleClear: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  fromDate: PropTypes.objectOf(PropTypes.any).isRequired,
  toDate: PropTypes.objectOf(PropTypes.any).isRequired,
  confirmBlocking: PropTypes.func.isRequired,
  setEnabled: PropTypes.func.isRequired,
  pagination: PropTypes.objectOf(PropTypes.any).isRequired,
  enabled: PropTypes.bool.isRequired,
};

export default SellerMessaging;
