/**
 * Copyright(c) 2022 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Mohammed Shefeeq
 */

import httpApi from "./httpApi";

const updateBatchConfig = async (data) => {
  const response = await httpApi.patch("/v1/batch-scheduler/activate", data);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

const getBatchStatus = async (type) => {
  const response = await httpApi.get(`v1/batch-scheduler/${type}/status`);
  if (response && response.data) {
    return response.data;
  }
};

const getJobHistory = async (data) => {
  const response = await httpApi.post("/v1/batchjob/logs", data);
  if (response && response.data) {
    return response.data;
  }
};

export {
  updateBatchConfig,
  getBatchStatus,
  getJobHistory
};
