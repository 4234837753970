/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */

import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ===== TAG COMPONENTS =========== */
import Row from "../../../common/core/Row";
import Nav from "../../../common/core/Nav";
import Col from "../../../common/core/Col";
import Form from "../../../common/core/Form";
import Label from "../../../common/core/Label";
import Alert from "../../../common/core/Alert";
import Table from "../../../common/core/Table";
import Button from "../../../common/core/Button";
import NavLink from "../../../common/core/NavLink";
import NavItem from "../../../common/core/NavItem";
import TabPane from "../../../common/core/TabPane";
import FormGroup from "../../../common/core/FormGroup";
import TabContent from "../../../common/core/TabContent";
import validateForm from "../../../common/utils/validateForm";
import FormFeedback from "../../../common/core/FormFeedback";
import Input from "../../../common/core/Input";
import validationConstants from "../../../common/utils/validationConstants";
/** ========== SUB COMPONENT ================ */
import CategoryForm from "./CategoryForm";
import CategorySelectionContainer from "../containers/CategorySelectionContainer";
import ImageSelection from "../containers/ImageSelectionContainer";
import ProductSelectionContainer from "../containers/ProductSelectionContainer";
import LocaleDropdownContainer from "../../LocaleDropdown/containers/LocaleDropdownContainer";

/** ===== CUSTOM COMPONENTS =========== */
import Select from "../../../common/components/Select";

/** ======== MODULE STYLES ========== */
import styles from "../css/EditCategory.module.css";
import ExternalCategorySelectorContainer from "../containers/ExternalCategorySelectorContainer";
import constants from "../../../common/utils/constants";

const EditCategory = (props) => {
  const {
    id,
    name,
    longDescription,
    description,
    startDate,
    endDate,
    status,
    bannerImage,
    image,
    disableDelete,
    childProductDetails,
    handleFormChange,
    handleChange,
    submitForm,
    cancelForm,
    iconUrl,
    parentCategory,
    handleParentCategory,
    navPosition,
    type,
    source,
    parentCategoryName,
    selectedCategoryChange,
    selectedProductChange,
    childProducts,
    message,
    onSubmitting,
    submitting,
    changeProductStatus,
    handleDeleteCategory,
    facet,
    sortOptions,
    sortRuleOnchange,
    selectedSort,
    translatableFileds,
    navigable,
    locale,
    localeHandler,
    isDefault,
    saveChildProducts,
    setMessage,
  } = props;
  const validator = validateForm();
  const {
    handleSubmit, errors, register, clearError,
  } = validator;

  useEffect(() => {
    if (!navigable) {
      clearError(["navPosition"]);
    }
  }, [navigable]);

  const [activeTab, setActiveTab] = useState("1");
  const { t } = useTranslation(["common", "product-option"]);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const errorArray = errors ? Object.entries(errors) : [];

  const { IS_NUMBER } = validationConstants;
  return (
    <>
      <Row>
        <Col xs="12">
          {/*  ====== PAGE COMPONENT FORM S[0002]============ */}
          {message && message.type && (
            <Alert color={message.type}>{message.message}</Alert>
          )}

          {/*  ====== PAGE COMPONENT HEADER S[0001]============ */}
          <Row className="pageHeader  common-page-card justify-content-between">
            <Col xs="12" md="6" className="text-truncate">
              <span className="pageText">Edit Category</span>
            </Col>

            <Col xs="auto" className="d-flex justify-content-between align-items-center ">
              <LocaleDropdownContainer localeHandler={localeHandler} entityName="category" isIndocument />
              <Button className="common-secondary-button ml-3" role="presentation" onClick={() => cancelForm()}>
                Back
              </Button>
            </Col>
          </Row>

          {errorArray
            && activeTab !== "1"
            && errorArray.map((error) => (
              <Alert color="danger" className="mb-2 p-2">
                <h5 className="m-0 alert-heading text-capitalize">
                  {error && error[0]}
                </h5>
                <p className="m-0">
                  {(error && error[1] && error[1].message)
                    || "Invalid input"}
                  <span className="pl-1">in details page</span>
                  {" "}
                </p>
              </Alert>
            ))}

          <Nav tabs>
            <NavItem
              active={activeTab === "1"}
              className={clsx(
                styles.mw25,
                "cursor-pointer",
                "text-center",
                activeTab === "1" ? "bg-white" : " bg-light",
              )}
            >
              <NavLink
                className="border-0"
                onClick={() => !onSubmitting && toggle("1")}
              >
                Details
              </NavLink>
            </NavItem>
            <NavItem
              active={activeTab === "2"}
              className={clsx(
                styles.mw25,
                "cursor-pointer",
                "text-center",
                activeTab === "2" ? "bg-white" : " bg-light",
              )}
            >
              <NavLink
                className="border-0"
                onClick={() => !onSubmitting && toggle("2")}
              >
                Products
              </NavLink>
            </NavItem>
            {source === constants.CATEGORY_SOURCE.PIM ? (
              <NavItem
                active={activeTab === "3"}
                className={clsx(
                  styles.mw25,
                  "cursor-pointer",
                  "text-center",
                  activeTab === "3" ? "bg-white" : " bg-light",
                )}
              >
                <NavLink
                  className="border-0"
                  onClick={() => source === constants.CATEGORY_SOURCE.PIM && !onSubmitting && toggle("3")}
                >
                  Categories
                </NavLink>
              </NavItem>
            ) : ""}
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1" className="p-0">
              {/*  ====== PAGE COMPONENT HEADER E[0001]============ */}
              <div className="common-page-card">
                <Form onSubmit={handleSubmit(submitForm)}>
                  <fieldset disabled={onSubmitting}>
                    <Row className="justify-content-end m-0">
                      <Col xs="auto">
                        <Button
                          disabled={disableDelete}
                          type="button"
                          className="common-danger-button mr-1 "
                          onClick={handleDeleteCategory}
                        >
                          DELETE
                        </Button>
                      </Col>
                      <Col xs="auto">
                        <Button
                          type="submit"
                          color="secondary"
                          className="common-primary-button mr-1"
                        >
                          SAVE
                        </Button>
                      </Col>
                    </Row>
                    {/*  ====== CATEGORY FORM S[0007]============ */}
                    <CategoryForm
                      id={id}
                      name={name}
                      longDescription={longDescription}
                      description={description}
                      startDate={startDate}
                      endDate={endDate}
                      status={status}
                      bannerImage={bannerImage}
                      image={image}
                      iconUrl={iconUrl}
                      handleFormChange={handleFormChange}
                      handleChange={handleChange}
                      parentCategory={parentCategory}
                      handleParentCategory={handleParentCategory}
                      validator={validator}
                      parentCategoryName={parentCategoryName}
                      translatableFileds={translatableFileds}
                      type={type}
                      source={source}
                      isDefault={isDefault}
                      isEdit
                    />
                    <Row className={styles.block3}>

                      <Col xs="12" md="5" sm="5">
                        <FormGroup>
                          <Label className="formTextLabel">
                            Position
                          </Label>
                          <Input
                            className="formText"
                            placeholder="Position"
                            name="navPosition"
                            disabled={!navigable}
                            innerRef={
                              navigable ? (
                                register({
                                  pattern: {
                                    value: IS_NUMBER,
                                    message: "Please Enter a Valid Number",
                                  },
                                })
                              )
                                : null
                            }
                            defaultValue={navPosition}
                            onChange={handleFormChange}
                            invalid={!!(errors.navPosition && errors.navPosition.message)}
                          />
                          <FormFeedback>
                            {errors.navPosition && errors.navPosition.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col xs="12" md="5" sm="5">
                        <FormGroup>
                          <Label className="formTextLabel">
                            Show in Site Navigation
                          </Label>
                          <div
                            className={clsx(
                              "custom-control",
                              "custom-switch",
                              "ml-1 pt-2",
                            )}
                          >
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="navigable-new-category"
                              name="navigable"
                              checked={navigable}
                              onChange={() => handleChange("navigable", !navigable)}
                            />
                            <Label className="custom-control-label" htmlFor="navigable-new-category" />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    {/*  ====== CATEGORY FORM E[0007]============ */}
                    <Row className={styles.block3}>
                      <Col>
                        <Row>
                          <Col xs="12">
                            <FormGroup>
                              <Label className="formTextLabel">
                                Banner Image
                              </Label>
                              <ImageSelection
                                images={bannerImage}
                                single={false}
                                aspectRatio={235 / 88}
                                handleChange={(o) => {
                                  handleChange("bannerImage", o);
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col xs="12">
                            <FormGroup>
                              <Label className="formTextLabel">Image</Label>
                              <ImageSelection
                                images={image}
                                single={false}
                                aspectRatio={1}
                                handleChange={(o) => {
                                  handleChange("image", o);
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col xs="12">
                            <FormGroup>
                              <Label className="formTextLabel">
                                Icon Image
                              </Label>
                              <ImageSelection
                                images={iconUrl}
                                single
                                aspectRatio={1}
                                handleChange={(o) => {
                                  handleChange("iconUrl", o);
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    {/*  ====== ADD SUB CATEGORIES S[0003]============ */}
                    {Array.isArray(childProducts)
                      && childProducts.length < 1
                      && (
                        <CategorySelectionContainer
                          onChange={selectedCategoryChange}
                          exclude={[id]}
                        />
                      )}
                    {/*  ====== ADD SUB CATEGORIES E[0003]============ */}
                    {/* <Col xs="6">
                      <FormGroup>
                        <Label className="formTextLabel">Sorting Rules</Label>
                        <Select
                          className="formText"
                          name="sortRules"
                          id="sort-rules"
                          onChange={(e) => {
                            sortRuleOnchange(e);
                          }}
                          options={sortOptions}
                          value={selectedSort}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <div className={(styles.product, "mt-3")}> */}
                    {/*  ====== MAIN COMPONENT ROOT  S[0001]============ */}
                    {/* <Label className="formTextLabel">Search facet</Label>
                        <div
                          className={clsx(
                            styles.productBody,
                            "table-responsive",
                            "custom-table",
                          )}
                        >
                          <Table borderless className={styles.productTable}> */}
                    {/* ========= TABLE HEAD S[0003] ======================= */}
                    {/* <thead>
                              <tr>
                                <th>{t("common:label_name")}</th>
                                <th>{t("common:label_enabled")}</th>
                              </tr>
                            </thead> */}
                    {/* ========= TABLE HEAD S[0003] ======================= */}
                    {/* <tbody> */}
                    {/* ========= TABLE BODY S[0002] ======================= */}
                    {/* {facet && facet.length > 0 ? (
                                facet.map((item, index) => {
                                  const { field, active, level } = item;
                                  return (
                                    <tr
                                      key={field}
                                      className={styles.productTreeItem}
                                    >
                                      <td>
                                        <span
                                          title={field}
                                          className={clsx(
                                            styles.productName,
                                            "text-truncate",
                                            "d-block",
                                          )}
                                        >
                                          {field}
                                        </span>
                                      </td>
                                      <td>
                                        <div
                                          className={clsx(
                                            "custom-control",
                                            "custom-switch",
                                            submitting ? "loading" : "",
                                          )}
                                        >
                                          <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id={`expand-multiple${index + 1}`}
                                            checked={active}
                                            onChange={(o) => changeProductStatus(
                                              !active,
                                              field,
                                              level,
                                              o,
                                            )}
                                          />
                                          <Label
                                            className="custom-control-label"
                                            htmlFor={`expand-multiple${index + 1}`}
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })
                              )
                                : (
                                  <tr>
                                    <td
                                      colSpan={8}
                                      align="center"
                                      valign="center"
                                    >
                                      {" "}
                                      Nothing to show.
                                    </td>
                                  </tr>
                                )} */}
                    {/* ========= TABLE BODY E[0002] ======================= */}
                    {/* </tbody>
                          </Table>
                        </div>
                        {/*  ====== MAIN COMPONENT ROOT  E[0001]============ */}
                  </fieldset>
                </Form>
              </div>
            </TabPane>
            <TabPane tabId="2" className="p-0">
              {/*  ====== PAGE COMPONENT HEADER E[0001]============ */}
              <div className="common-page-card">
                <Row className="justify-content-end m-0">
                  <Col xs="auto">
                    <Button
                      type="submit"
                      color="secondary"
                      onClick={() => saveChildProducts()}
                      className="common-primary-button mr-1"
                    >
                      SAVE
                    </Button>
                  </Col>
                </Row>
                <div className="py-2">
                  {/*  ====== ADD PRODUCTS S[0003]============ */}
                  <ProductSelectionContainer
                    selectedProductDetails={childProductDetails}
                    onChange={selectedProductChange}
                    categoryId={id}
                    showRanking
                    locale={locale}
                  />
                  {/*  ====== ADD PRODUCTS E[0003]============ */}
                </div>
              </div>
            </TabPane>
            <TabPane tabId="3" className="p-0">
              <ExternalCategorySelectorContainer
                categoryId={id}
                setMessage={setMessage}
              />
            </TabPane>
          </TabContent>
          {/*  ====== PAGE COMPONENT FORM E[0002]============ */}
        </Col>
      </Row>
    </>
  );
};

EditCategory.defaultProps = {
  source: "",
};

EditCategory.propTypes = {
  // form field
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  longDescription: PropTypes.string.isRequired,
  childProducts: PropTypes.arrayOf(PropTypes.any).isRequired,
  childProductDetails: PropTypes.arrayOf(PropTypes.any).isRequired,
  startDate: PropTypes.objectOf(PropTypes.any).isRequired,
  endDate: PropTypes.objectOf(PropTypes.any).isRequired,
  status: PropTypes.bool.isRequired,
  onSubmitting: PropTypes.bool.isRequired,
  message: PropTypes.objectOf(PropTypes.any).isRequired,
  image: PropTypes.objectOf(PropTypes.any),
  bannerImage: PropTypes.objectOf(PropTypes.any),
  disableDelete: PropTypes.bool.isRequired,
  // input filed handle change
  handleFormChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  // form actions
  submitForm: PropTypes.func.isRequired,
  cancelForm: PropTypes.func.isRequired,
  handleDeleteCategory: PropTypes.func.isRequired,
  // parent category
  parentCategory: PropTypes.string.isRequired,
  handleParentCategory: PropTypes.func.isRequired,
  parentCategoryName: PropTypes.string.isRequired,
  // sub category
  selectedCategoryChange: PropTypes.func.isRequired,
  // products of the category
  selectedProductChange: PropTypes.func.isRequired,
  // apply selected language
  translatableFileds: PropTypes.arrayOf(PropTypes.any).isRequired,
  navPosition: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  source: PropTypes.string,
  facet: PropTypes.arrayOf(PropTypes.any).isRequired,
  navigable: PropTypes.bool.isRequired,
  iconUrl: PropTypes.string.isRequired,
  submitting: PropTypes.bool.isRequired,
  changeProductStatus: PropTypes.func.isRequired,
  sortOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
  sortRuleOnchange: PropTypes.func.isRequired,
  selectedSort: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  localeHandler: PropTypes.func.isRequired,
  isDefault: PropTypes.bool.isRequired,
  saveChildProducts: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
};

EditCategory.defaultProps = {
  image: undefined,
  bannerImage: undefined,
};

export default EditCategory;
