
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ===== TAG COMPONENTS =========== */
import BulkUploadHistory from "../components/BulkUploadHistory";
import constants from "../../../common/utils/constants";

import { getBulkUploadHistory } from "../../../api/productManagementServices";

const moment = require("moment");
const Datetime = require("react-datetime");
const {
  PRODUCT_BULK_MAINTENANCE: {
    JOB_STATUSES,
    DATE_FORMAT
  },
} = constants;
const BulkUploadHistoryContainer = ({ message,
  documentDownloadSteps,
  getJobTypeByType,
  maintenanceType,
  status,
  uploadModes,
  checkUploadOrDownloadStatus }) => {
  const { t } = useTranslation(["common", "product-bulk-maintenance"]);
  const [searchQuery, setSearchQuery] = useState({
    mode: "",
    toDate: null,
    fromDate: null,
    status: status,
    page: 0,
    limit: 5,
    sort: -1,
    sortBy: "updatedDate",
    sortDirection: "desc",
  });
  const [jobHistoryData, setJobHistoryData] = useState([]);
  const [pagination, setPagination] = useState({ numberOfElements: 0, totalCount: 0 });
  const [modeDropdownOpen, setModeDropdown] = useState(false);
  const [statusDropdownOpen, setStatusDropdown] = useState(false);

  const getJobStatusText = (statusCode) => {
    return JOB_STATUSES.filter(status => status.code == statusCode).map(status => status.value);
  };
  const isValidDate = (date, type) => {
    if (type === "BULK_DATE_FROM") {
      if (searchQuery.toDate) {
        return date.isBefore(moment(searchQuery.toDate, DATE_FORMAT).add(1, "days"));
      }
      return date.isBefore(Datetime.moment());
    }
    if (type === "BULK_DATE_TO") {
      if (searchQuery.fromDate) {
        return (
          date.isSameOrAfter(moment(searchQuery.fromDate, DATE_FORMAT))
          && date.isSameOrBefore(Datetime.moment())
        );
      }
      return date.isSameOrBefore(Datetime.moment());
    }
  };
  const [isLoading, setIsLoading] = useState(false);
  const jobHistoryServiceCall = (reqObj, pageObj) => {
    setIsLoading(true);
    getBulkUploadHistory(reqObj, pageObj).then(async (response) => {
      setIsLoading(false);
      if (response && response.data) {
        const { data } = response;
        if (data && Array.isArray(data.content)) {
          const contentArray = data.content;
          const newContent = contentArray.map((content, index) => { return { ...content, statusText: getJobStatusText(content.status), id: content.jobId ? content.jobId : maintenanceType + content.systemGeneratedFileName } });
          setJobHistoryData(newContent);
          const pageView = { totalElements: data.totalElements, numberOfElements: data.numberOfElements }
          setPagination(pageView);
        }
      }
    })
  }

  useEffect(() => {
    const startTime = searchQuery.fromDate && searchQuery.fromDate instanceof moment ? searchQuery.fromDate.format(DATE_FORMAT) : null;
    const endTime = searchQuery.toDate && searchQuery.toDate instanceof moment ? searchQuery.toDate.format(DATE_FORMAT) : null;
    var pageObj = {
      page: searchQuery.page,
      size: searchQuery.limit
    }
    jobHistoryServiceCall({ type: getJobTypeByType(maintenanceType), status: searchQuery.status, mode: searchQuery.mode, startTime: startTime, endTime: endTime }, pageObj);
  }, [maintenanceType, searchQuery]);

  const handleChange = (name, value, changeFunction) => {
    changeFunction({
      ...searchQuery,
      [name]: value,
    });
  };
  const getPageData = (page) => {
    handleChange("page", page, setSearchQuery);
  };
  const setDates = (type, date) => {
    if (type === "BULK_DATE_FROM") {
      handleChange("fromDate", date, setSearchQuery);
    } else {
      handleChange("toDate", date, setSearchQuery);
    }
  };
  const toggleModeDropdown = () => {
    setModeDropdown(!modeDropdownOpen);
  };
  const toggleStatusDropdown = () => {
    setStatusDropdown(!statusDropdownOpen);
  };
  const handleSelectFilter = (key, value) => {
    if (value) {
      handleChange(key, value, setSearchQuery);
      setModeDropdown(false);
      setStatusDropdown(false);
    } else {
      handleChange(key, null, setSearchQuery);
    }
  };
  useEffect(() => {
    setSearchQuery({
      mode: "",
      toDate: null,
      fromDate: null,
      status: status,
      page: 0,
      limit: 5,
      sort: -1,
      sortBy: "updatedDate",
      sortDirection: "desc",
    })
  }, [maintenanceType]);
  return (

    <BulkUploadHistory
      jobHistoryData={jobHistoryData}
      searchQuery={searchQuery}
      uploadModes={uploadModes}
      handleSelectFilter={handleSelectFilter}
      pagination={pagination}
      isValidDate={isValidDate}
      setDates={setDates}
      isLoading={isLoading}
      modeDropdownOpen={modeDropdownOpen}
      statusDropdownOpen={statusDropdownOpen}
      toggleModeDropdown={toggleModeDropdown}
      toggleStatusDropdown={toggleStatusDropdown}
      maintenanceType={maintenanceType}
      documentDownloadSteps={documentDownloadSteps}
      getJobTypeByType={getJobTypeByType}
      getPageData={getPageData}
      checkUploadOrDownloadStatus={checkUploadOrDownloadStatus}
    />
  );
};

export default BulkUploadHistoryContainer;
