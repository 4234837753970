/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * ExternalCategorySelectorContainer
 *
 * @author Naseef O
 */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import ExternalCategorySelector from "../components/ExternalCategorySelector";

import {
  getExternalCategories,
  getExternalCategoriesByCategoryId,
  getRootExternalCategories,
  updateMappingByCategoryId,
} from "../../../api/externalCategoryServices";

const ExternalCategorySelectorContainer = ({ categoryId, setMessage }) => {
  const [searchKey, setSearchKey] = useState("");
  const [pagination, setPagination] = useState({
    page: 0,
    totalPages: 0,
    totalCount: 0,
    limit: 10,
    numberOfElements: 0,
  });
  const [disabled, setDisabled] = useState(false);
  const [externalCategories, setExternalCategories] = useState([]);
  const [selectedExternalCategories, setSelectedExternalCategories] = useState([]);
  const [tempSelectedExternalCategories, setTempSelectedExternalCategories] = useState([]);
  const [loadingSelectedExternalCategories, setLoadingSelectedExternalCategories] = useState(false);
  const [loadingExternalCategories, setLoadingExternalCategories] = useState(false);

  /**
   * This method is used get external categories
   * @param {Number} pageNo
   */
  const getExternalCategoriesDetails = (pageNo) => {
    getExternalCategories(
      searchKey.trim(),
      null,
      pageNo,
      pagination.limit,
    ).then((response) => {
      if (response && response.success && response.data) {
        const {
          content, totalPages, totalElements, endOfResult,
        } = response.data;
        setPagination({
          ...pagination,
          page: pageNo,
          totalPages,
          totalCount: totalElements,
          numberOfElements: !endOfResult
            ? pagination.limit * (pageNo + 1)
            : totalElements,
        });
        setExternalCategories(content);
      }
    });
  };

  useEffect(() => {
    /** Fetching ROOT external categories */
    setLoadingExternalCategories(true);
    getRootExternalCategories().then((response) => {
      if (response && response.success && response.data) {
        setExternalCategories(response.data);
      }
    }).then(() => {
      setLoadingExternalCategories(false);
    });
  }, []);

  useEffect(() => {
    /** Fetching external categories associated to a category */
    setLoadingSelectedExternalCategories(true);
    if (categoryId) {
      getExternalCategoriesByCategoryId(categoryId).then((response) => {
        if (response
          && response.success && Array.isArray(response.data)) {
          setSelectedExternalCategories(response.data);
          setTempSelectedExternalCategories(response.data);
        }
      }).then(() => {
        setLoadingSelectedExternalCategories(false);
      });
    }
  }, [categoryId]);

  useEffect(() => {
    /** checking if there is any change done by user */
    const selectedIds = selectedExternalCategories.map((c) => c.externalCategoryId);
    const tempSelectedIds = tempSelectedExternalCategories.map((c) => c.externalCategoryId);
    setDisabled(
      selectedIds.length === tempSelectedIds.length
      && selectedIds.every((c) => tempSelectedIds.includes(c)),
    );
  }, [selectedExternalCategories, tempSelectedExternalCategories]);

  /**
   * This method is used to handle change search external categories
   * @param {Object} e
   */
  const handleSearch = async (e) => {
    const key = e.target.value;
    setSearchKey(key);
    if (key === "") {
      setLoadingExternalCategories(true);
      getRootExternalCategories().then((response) => {
        if (response && response.success && response.data) {
          setExternalCategories(response.data);
        } else {
          setExternalCategories([]);
        }
      }).then(() => {
        setLoadingExternalCategories(false);
      });
    } else {
      getExternalCategoriesDetails();
    }
  };

  /**
 * This method is used to handle selected categories
 *
 * @param {Object externalCategory
 */
  const handleSelectCategories = (externalCategory) => {
    let tempExternalCategories = [...tempSelectedExternalCategories];
    const externalCategoryIn = Array.isArray(tempExternalCategories)
      && tempExternalCategories.length > 0
      && tempExternalCategories.find((c) => c.id === externalCategory.id);
    if (externalCategoryIn) {
      tempExternalCategories = tempExternalCategories.filter((c) => c.id !== externalCategoryIn.id);
    } else {
      tempExternalCategories.push(externalCategory);
    }
    setTempSelectedExternalCategories(tempExternalCategories);
  };

  /**
 * This method is used to handle remove external categories
 *  from selected external categories list
 *
 * @param {String} id
 */
  const handleRemove = (id) => {
    let tempExternalCategories = [...tempSelectedExternalCategories];
    tempExternalCategories = tempExternalCategories.filter((c) => c.id !== id);
    setTempSelectedExternalCategories(tempExternalCategories);
  };
  const [loading, setLoading] = useState(false);
  /**
 * This method is used to handle save external categories mappings
 *
 */
  const handleSaveExternalCategoryMappings = () => {
    setLoading(true);
    const selectedIds = Array.isArray(selectedExternalCategories)
      && selectedExternalCategories.length > 0
      ? selectedExternalCategories.map((c) => c.externalCategoryId) : [];
    const tempSelectedIds = Array.isArray(tempSelectedExternalCategories)
      && tempSelectedExternalCategories.length > 0
      ? tempSelectedExternalCategories.map((c) => c.externalCategoryId) : [];
    const addedExternalCategories = tempSelectedIds.filter((c) => !selectedIds.includes(c));
    const removedExternalCategories = selectedIds.filter((c) => !tempSelectedIds.includes(c));
    const externalCategoryUpdateRequest = {
      categoryId,
      addedExternalCategories,
      removedExternalCategories,
    };

    updateMappingByCategoryId(externalCategoryUpdateRequest, categoryId).then((response) => {
      if (response && response.success) {
        setMessage({ type: "success", message: "Category mapping updated successfully." });
        setTimeout(() => {
          setMessage({ type: null, message: "" });
        }, 3000);
        getExternalCategoriesByCategoryId(categoryId).then((responseIn) => {
          if (responseIn
            && responseIn.success && Array.isArray(responseIn.data)) {
            setTempSelectedExternalCategories(responseIn.data);
            setSelectedExternalCategories(responseIn.data);
          }
        });
      } else {
        setMessage({ type: "warning", message: "Something went wrong." });
        setTimeout(() => {
          setMessage({ type: null, message: "" });
        }, 3000);
      }
    }).then(() => {
      setLoading(false);
    });
  };

  return (
    <ExternalCategorySelector
      loading={loading}
      disabled={disabled}
      searchKey={searchKey}
      categoryId={categoryId}
      handleSearch={handleSearch}
      externalCategories={externalCategories}
      handleRemove={handleRemove}
      handleSelectCategories={handleSelectCategories}
      loadingExternalCategories={loadingExternalCategories}
      tempSelectedExternalCategories={tempSelectedExternalCategories}
      loadingSelectedExternalCategories={loadingSelectedExternalCategories}
      handleSaveExternalCategoryMappings={handleSaveExternalCategoryMappings}
    />
  );
};

ExternalCategorySelectorContainer.defaultProps = {
  categoryId: "",
};
ExternalCategorySelectorContainer.propTypes = {
  categoryId: PropTypes.string,
  setMessage: PropTypes.func.isRequired,
};

export default ExternalCategorySelectorContainer;
