/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * WidgetContentsWrapper
 *
 * @author Naseef O
 *
 */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import UncontrolledTooltip from "../../../common/core/UncontrolledTooltip";
import { getPageContentByTypeAndConfigId, updatePageContent } from "../../../api/pageContentServices";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import FormGroup from "../../../common/core/FormGroup";
import Input from "../../../common/core/Input";
import FormFeedback from "../../../common/core/FormFeedback";
import Button from "../../../common/core/Button";
import styles from "../css/PageCustomization.module.css";
import edit from "../../../common/assets/images/svg/edit.svg";
import ProductsTableContainer from "./ProductsTableContainer";
import DynamicContentInfo from "../components/DynamicContentInfo";
import PageContentContainer from "./WidgetContentContainer";
import Label from "../../../common/core/Label";
import CustomInput from "../../../common/core/CustomInput";
import DragAndDroppableItemTableContainer from "./DragAndDroppableItemTableContainer";

const WidgetContentsWrapper = ({
  isEdit,
  selectedWidget,
  widgetTitle,
  setWidgetTitle,
  handleEditWidget,
  index,
  widgetType,
  widgets,
  handleSaveTitle,
  handleCancelTitle,
  categoryId,
  handleGetContentsCount,
  selectedPage,
  handleRedirectToWidgetContentEditor,
  handleRedirectToProductEditor,
  configId,
  channel,
  handleRedirectToCombinedWidgetEditor,
  toggleActiveWidget,
  widgetConfigurations,
  globalLayout,
  locale,
}) => {
  const { t } = useTranslation(["common", "page-customization"]);

  const [widgetContent, setPageContent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [contentsCount, setContentsCount] = useState(0);

  /**
   * This method is used to get page contents
   *
   * @returns
   */
  const getPageContents = async () => {
    let newConfigId = configId;
    if (selectedPage !== "GLOBAL" && configId === "GLOBAL") {
      newConfigId = null;
    } else if (selectedPage === "GLOBAL") {
      newConfigId = "GLOBAL";
    }
    const response = await getPageContentByTypeAndConfigId(
      widgets[widgetType].widgetType, newConfigId, categoryId, channel, locale,
    );
    if (response && response.success && response.data) {
      setPageContent(response.data);
      setContentsCount(response.data.contents ? response.data.contents.length : 0);
      return true;
    }
    setContentsCount(0);
    setPageContent(null);
    return false;
  };

  useEffect(() => {
    getPageContents().then((response) => {
      if (response) {
        setIsLoading(false);
      }
    });
  }, [configId, widgetType, locale]);


  /**
 * Delete a content
 *
 * @param {*} indexNumber
 */
  const handleUseDefaultContent = async () => {
    let newConfigId = configId;
    if (selectedPage !== "GLOBAL" && configId === "GLOBAL") {
      newConfigId = null;
    } else if (selectedPage === "GLOBAL") {
      newConfigId = "GLOBAL";
    }
    await updatePageContent({
      ...widgetContent,
      active: widgetContent && !widgetContent.active,
      configId: newConfigId,
      categoryId,
    }, channel, locale).then((response) => {
      if (response && response.success) {
        getPageContents();
        setIsLoading(false);
      }
    });
  };

  const isGlobalWidget = (type) => {
    let isGlobal = false;
    if (globalLayout && selectedPage !== "GLOBAL") {
      Object.keys((globalLayout && globalLayout.widgets) || {}).map((key) => {
        if (globalLayout.widgets[key].widgetType === type) {
          isGlobal = true;
        }
        return isGlobal;
      });
    }
    return isGlobal;
  };

  /**
   * This method is used to get content limit by widget type and page type
   *
   * @param {*} pageType
   * @param {*} widgetType
   * @returns
   */
  const getContentLimit = (pageType, type) => {
    const widgetConfig = widgetConfigurations && widgetConfigurations.length > 0
      ? widgetConfigurations.find((widget) => widget.widgetType === type
        && widget.pageType === pageType)
      : null;
    return widgetConfig ? widgetConfig.contentLimit : 3;
  };

  return (
    <>
      <Row className={clsx(styles.block3)}>
        <Col xs="12" className="p-0">
          <Row className="m-0">
            <Col xs="12" md="4" className="d-flex align-items-center">
              <Row className="m-0">
                {isEdit && selectedWidget === index ? (
                  <FormGroup className="m-0">
                    <Input
                      className="formText"
                      value={widgetTitle}
                      onChange={(e) => setWidgetTitle(e.target.value)}
                      placeholder={t("page-customization:title")}
                    />
                    <FormFeedback>You </FormFeedback>
                  </FormGroup>
                )
                  : (
                    <>
                      <span className={clsx(styles.pageWidgetsText, "fontsizeMediumBold")}>
                        {widgets[widgetType].title ? widgets[widgetType].title : t(`page-customization:widget-type-${widgets[widgetType].widgetType}`)}
                      </span>
                      <img
                        src={edit}
                        alt="edit"
                        width="20px"
                        role="presentation"
                        className="pointer"
                        onClick={() => !isEdit
                          && handleEditWidget(index, widgets[widgetType].title)}
                      />
                    </>
                  )}
              </Row>
            </Col>
            <Col xs="12" md="8" className="d-flex justify-content-end">
              <Row className=" w-100 align-items-center justify-content-end">
                <div className="col-12">
                  <div className="row justify-content-end">
                    {isEdit && selectedWidget === index ? (
                      <div className="col-12 ">
                        <Button
                          className={clsx(styles.buttonAdd, "")}
                          onClick={
                            () => handleSaveTitle(widgets[widgetType], widgetType,
                              configId)
                          }
                        >
                          {t("page-customization:save")}
                        </Button>
                        <Button
                          className={clsx(styles.buttonCancel, "")}
                          onClick={
                            () => handleCancelTitle(widgets[widgetType], widgetType,
                              configId)
                          }
                        >
                          {t("page-customization:cancel")}
                        </Button>
                      </div>
                    )
                      : (
                        <>
                          {isGlobalWidget(widgets[widgetType].widgetType) || selectedPage !== "GLOBAL" ? (
                            <div className="col-auto align-items-center d-flex">
                              <div className="d-flex align-items-bottom pt-2  ">
                                <Label className="formTextLabel">Status</Label>
                                <div
                                  className={clsx(
                                    "custom-control",
                                    "custom-switch",
                                    "ml-3",
                                  )}
                                >
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id={`id-${widgetType}`}
                                    name="active"
                                    checked={widgets[widgetType].active}
                                    onChange={() => toggleActiveWidget(
                                      widgets[widgetType], widgetType,
                                      configId, widgets[widgetType].active,
                                    )}
                                  />
                                  <Label className="custom-control-label" htmlFor={`id-${widgetType}`} title="Operation not permitted" />
                                </div>
                              </div>
                            </div>
                          ) : ""}
                          {widgets[widgetType].widgetType && widgets[widgetType].contentType !== "Query"
                            && widgets[widgetType].contentType !== "Combined"
                            && (
                              <>
                                {isGlobalWidget(widgets[widgetType].widgetType) && channel === "web"
                                  && (
                                    <div className="col-auto d-flex align-items-center cursor-pointer">
                                      <CustomInput
                                        type="checkbox"
                                        onChange={() => handleUseDefaultContent()}
                                        id={`override-global-content${widgetType}`}
                                        checked={widgetContent && widgetContent.active && widgetContent.configId !== "GLOBAL"}
                                        label="Override global content"
                                        inline
                                      />
                                    </div>
                                  )}
                                {(widgetContent && widgetContent.active && widgetContent.configId !== "GLOBAL") || selectedPage === "GLOBAL" || !widgetContent ? (
                                  <div className="col-auto">
                                    {contentsCount >= getContentLimit(
                                      selectedPage, widgets[widgetType].widgetType, channel,
                                    )
                                      && (
                                        <UncontrolledTooltip
                                          target={widgets[widgetType].widgetType}
                                        >
                                          Limit reached. You can not add contents anymore.
                                        </UncontrolledTooltip>
                                      )}
                                    <Button
                                      id={widgets[widgetType].widgetType}
                                      className={clsx(styles.buttonAdd,
                                        contentsCount >= getContentLimit(
                                          selectedPage, widgets[widgetType].widgetType, channel,
                                        )
                                          ? "bg-color-secondary" : "bg-color-tertiary-main")}
                                      onClick={
                                        () => contentsCount < getContentLimit(
                                          selectedPage, widgets[widgetType].widgetType, channel,
                                        )
                                          && handleRedirectToWidgetContentEditor(
                                            widgets[widgetType].widgetType,
                                            configId,
                                          )
                                      }
                                    >
                                      {t("page-customization:add")}
                                    </Button>
                                  </div>
                                ) : ""}
                              </>
                            )}

                          {(widgets[widgetType].widgetType === "FEATURED_PRODUCTS" || widgets[widgetType].widgetType === "BRAND_PRODUCTS")
                            && (
                              <div className="col-auto">
                                <Button
                                  id={widgets[widgetType].widgetType}
                                  className={clsx(styles.buttonAdd, "bg-color-tertiary-main")}
                                  onClick={
                                    () => handleRedirectToProductEditor(
                                      widgets[widgetType].widgetType,
                                      configId,
                                    )
                                  }
                                >
                                  Add/Remove
                                </Button>
                              </div>
                            )}
                          {widgets[widgetType].contentType === "Combined"
                            && (
                              <div className="col-auto">
                                <Button
                                  id={widgets[widgetType].widgetType}
                                  className={clsx(styles.buttonAdd, "bg-color-tertiary-main")}
                                  onClick={
                                    () => handleRedirectToCombinedWidgetEditor(
                                      widgets[widgetType].widgetType,
                                      configId,
                                    )
                                  }
                                >
                                  Add
                                </Button>
                              </div>
                            )}
                        </>
                      )}
                  </div>
                </div>
              </Row>
            </Col>
          </Row>
          <Row className={clsx(styles.widgetsTable, "m-0 ")}>
            <Col xs="12">
              {(widgets[widgetType].widgetType === "FEATURED_PRODUCTS" || widgets[widgetType].widgetType === "BRAND_PRODUCTS")
                ? (
                  <ProductsTableContainer
                    categoryId={categoryId}
                  />
                )
                : (
                  <>
                    {widgets[widgetType].contentType === "Query" && <DynamicContentInfo />}
                    {widgets[widgetType].contentType === "Combined"
                      ? (
                        <DragAndDroppableItemTableContainer
                          widgetType={widgets[widgetType].widgetType}
                          configId={configId}
                          categoryId={categoryId}
                          handleGetContentsCount={handleGetContentsCount}
                          selectedPage={selectedPage}
                          getPageContents={getPageContents}
                          widgetContent={widgetContent}
                          isLoading={isLoading}
                          channel={channel}
                          locale={locale}
                        />
                      )
                      : (
                        <>
                          {(widgetContent && widgetContent.active && widgetContent.configId !== "GLOBAL") || selectedPage === "GLOBAL"
                            ? (
                              <PageContentContainer
                                widgetType={widgets[widgetType].widgetType}
                                configId={configId}
                                categoryId={categoryId}
                                handleGetContentsCount={handleGetContentsCount}
                                selectedPage={selectedPage}
                                getPageContents={getPageContents}
                                widgetContent={widgetContent}
                                isLoading={isLoading}
                                channel={channel}
                                widgetConfig={
                                  widgetConfigurations
                                    && widgetConfigurations.length > 0
                                    ? widgetConfigurations.find((
                                      widget,
                                    ) => widget.widgetType === widgets[widgetType].widgetType
                                      && widget.pageType === selectedPage)
                                    : null
                                }
                                locale={locale}
                              />
                            ) : ""}
                        </>
                      )}
                  </>
                )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

WidgetContentsWrapper.propTypes = {
  widgetType: PropTypes.string.isRequired,
  configId: PropTypes.string.isRequired,
  categoryId: PropTypes.string.isRequired,
  selectedPage: PropTypes.string.isRequired,
  handleGetContentsCount: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  selectedWidget: PropTypes.objectOf(PropTypes.any).isRequired,
  widgetTitle: PropTypes.string.isRequired,
  setWidgetTitle: PropTypes.func.isRequired,
  handleEditWidget: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  widgets: PropTypes.objectOf(PropTypes.any).isRequired,
  handleSaveTitle: PropTypes.func.isRequired,
  handleCancelTitle: PropTypes.func.isRequired,
  handleRedirectToWidgetContentEditor: PropTypes.func.isRequired,
  handleRedirectToProductEditor: PropTypes.func.isRequired,
  channel: PropTypes.string.isRequired,
  handleRedirectToCombinedWidgetEditor: PropTypes.func.isRequired,
  toggleActiveWidget: PropTypes.func.isRequired,
  widgetConfigurations: PropTypes.arrayOf(PropTypes.any).isRequired,
  globalLayout: PropTypes.objectOf(PropTypes.any).isRequired,
  locale: PropTypes.string.isRequired,
};
export default WidgetContentsWrapper;
