/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */

import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

/** ===== TAG COMPONENTS =========== */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Form from "../../../common/core/Form";
import Button from "../../../common/core/Button";
import Alert from "../../../common/core/Alert";
import validateForm from "../../../common/utils/validateForm";

/** ========== SUB COMPONENT ================ */
import CategoryForm from "./CategoryForm";
import CategorySelectionContainer from "../containers/CategorySelectionContainer";

/** ======== MODULE STYLES ========== */
import styles from "../css/AddCategory.module.css";


const AddCategory = (props) => {
  const {
    name, longDescription, description, startDate, endDate, status, type, source,
    handleFormChange, handleChange,
    submitForm, cancelForm,
    parentCategory, handleParentCategory,
    selectedCategoryChange,
    onSubmitting,
    message,
  } = props;
  const validator = validateForm();
  const { handleSubmit } = validator;

  return (
    <>
      <Row>
        <Col xs="12">
          {/*  ====== PAGE COMPONENT FORM S[0002]============ */}
          <Form onSubmit={handleSubmit(submitForm)}>
            <fieldset disabled={onSubmitting}>
              <Row className="pageHeader common-page-card">
                <Col xs="12" md="7" className="text-truncate">
                  <span className="pageText">Create category</span>
                </Col>
                <Col xs="12" md="5" className="d-flex justify-content-end">
                  <Button className="common-secondary-button mr-1" onClick={cancelForm}>
                    CANCEL
                  </Button>
                  <Button type="submit" className="common-primary-button mr-1">
                    SAVE
                  </Button>
                </Col>
              </Row>
              {/*  ====== PAGE COMPONENT HEADER E[0001]============ */}

              <div className="common-page-card">
                {/*  ====== CATEGORY FORM S[0007]============ */}
                <CategoryForm
                  name={name}
                  longDescription={longDescription}
                  description={description}
                  startDate={startDate}
                  endDate={endDate}
                  status={status}
                  handleFormChange={handleFormChange}
                  handleChange={handleChange}
                  parentCategory={parentCategory}
                  handleParentCategory={handleParentCategory}
                  validator={validator}
                  type={type}
                  source={source}
                />
                {/*  ====== CATEGORY FORM E[0007]============ */}
                {/*  ====== ADD SUB CATEGORIES S[0003]============ */}
                <CategorySelectionContainer
                  onChange={selectedCategoryChange}
                />
                {/*  ====== ADD SUB CATEGORIES E[0003]============ */}

              </div>
            </fieldset>
          </Form>
          {/*  ====== PAGE COMPONENT FORM E[0002]============ */}
        </Col>
      </Row>
    </>
  );
};
AddCategory.propTypes = {
  // form field
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  longDescription: PropTypes.string.isRequired,
  startDate: PropTypes.objectOf(PropTypes.any).isRequired,
  endDate: PropTypes.objectOf(PropTypes.any).isRequired,
  status: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  onSubmitting: PropTypes.bool.isRequired,
  message: PropTypes.objectOf(PropTypes.any).isRequired,
  // input filed handle change
  handleFormChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  // form actions
  submitForm: PropTypes.func.isRequired,
  cancelForm: PropTypes.func.isRequired,
  // parent category
  parentCategory: PropTypes.string.isRequired,
  handleParentCategory: PropTypes.func.isRequired,
  // sub category
  selectedCategoryChange: PropTypes.func.isRequired,
};

export default AddCategory;
