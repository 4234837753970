// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Copyright(c) 2022 Mozanta Technologies Private Ltd.\n * \n * All rights reserved.                \n * \n * This software is the confidential and proprietary information of Mozanta (\"Confidential\n * Information\"). You shall not disclose such Confidential Information and shall use it only in\n * accordance with the terms of the contract agreement you entered into with Mozanta.\n * \n * @author Naseef O\n */\n\n\n.CacheManagement_block2__1YJT3 {\n  min-height: calc(100vh - 299px);\n\n}\n\n.CacheManagement_block2__1YJT3 .CacheManagement_btnCustom__1Zvgt {\n  min-width: 55px;\n  max-height: 25px;\n  font-size: 12px !important;\n}", ""]);
// Exports
exports.locals = {
	"block2": "CacheManagement_block2__1YJT3",
	"btnCustom": "CacheManagement_btnCustom__1Zvgt"
};
module.exports = exports;
