/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */

import clsx from "clsx";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ===== TAG COMPONENTS =========== */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Table from "../../../common/core/Table";
import Button from "../../../common/core/Button";
import Alert from "../../../common/core/Alert";
import FormGroup from "../../../common/core/FormGroup";
import Input from "../../../common/core/Input";
import InputGroup from "../../../common/core/InputGroup";
import InputGroupAddon from "../../../common/core/InputGroupAddon";


/** ======== COMPONENTS */
import Category from "../containers/CategoryContainer";
import Pagination from "../../../common/components/Pagination";
import CategoryTable from "../containers/CategoryTable";



/** ===== MODULE STYLES =========== */
import styles from "../css/CategoryManagement.module.css";
import ListViewIcon from "../../../common/assets/images/svg/list-view.svg";
import TreeViewIcon from "../../../common/assets/images/svg/tree-view.svg";
import PreviewIcon from "../../../common/assets/images/svg/preview-alt.svg";
import SearchIcon from "../../../common/assets/images/svg/search.svg";
import ClearIconAlt from "../../../common/assets/images/svg/clear-alt.svg";
import FilterIcon from "../../../common/assets/images/svg/filter.svg";
import DropdownMenu from "../../../common/core/DropdownMenu";
import Dropdown from "../../../common/core/Dropdown";
import DropdownToggle from "../../../common/core/DropdownToggle";

import constants from "../../../common/utils/constants";


const CategoryManagement = ({
  page,
  loading,
  categories,
  addNewCategory,
  handlePushToLive,
  pushToLiveEnabled,
  message,
  previewSiteUrl,
  previewEnabled,
  getReplicationStatus,
  pushToLiveButtonEnabled,
  toggleTableView,
  tableView,
  searchKey,
  pagination,
  getPageData,
  handleSearch,
  dropdownOpen,
  selectedFilter,
  toggleDropdown,
  handleSelectFilter,
}) => {
  /** internationalization using i18n from common and category  */
  const { t } = useTranslation(["common", "category"]);
  const redirectToPage = () => {
    const url = `${previewSiteUrl}`;
    window.open(url, "_blank");
  };
  const [viewDropDown, setViewDropDown] = useState(false);
  const toggleViewDropDown = () => {
    setViewDropDown(!viewDropDown);
  };

  return (
    <>
      {
        message && message.type && (
          <Alert color={message.type}>
            {message.message}
          </Alert>
        )
      }
      <Row className="pageHeader common-page-card">
        <Col xs="12" className="text-truncate d-flex  align-items-center justify-content-between">
          <span className={clsx("d-flex align-items-center", styles.pageTitle)}>
            <span className="pageText">Navigation Taxonomy</span>
          </span>
          <Button
            onClick={addNewCategory}
            className="common-primary-button"
          >
            CREATE CATEGORY
          </Button>
        </Col>
      </Row>
      <Col xs="12" md="6" lg="7" className={styles.liveBtnWrapper}>
        <Row>
          <Col xs="12" md="4" lg="4" className={styles.liveBtnWrapper}>
            {previewEnabled && (
              <Button
                className={clsx(styles.liveBtn, "float-right", "text-truncate", "btn-tertiary", "button-font-size ")}
                onClick={() => redirectToPage()}
              >
                {t("common:button_preview")}
              </Button>
            )}
          </Col>
          <Col xs="12" md="4" lg="4" className={styles.liveBtnWrapper}>
            {pushToLiveEnabled && (
              <Button
                onClick={handlePushToLive}
                disabled={!pushToLiveButtonEnabled}
                className={clsx(styles.liveBtn, "float-right", "text-truncate", "btn-tertiary", "button-font-size")}
              >
                {t("common:button_push_to_live")}
              </Button>
            )}
          </Col>
          <Col xs="12" md="4" lg="4" className={styles.liveBtnWrapper}>
            {pushToLiveEnabled && (
              <Button
                onClick={getReplicationStatus}
                className={clsx(styles.liveBtn, "float-right", "text-truncate", "btn-tertiary", "button-font-size")}
              >
                {t("common:button_refresh_status")}
              </Button>
            )}
          </Col>
        </Row>
      </Col>
      <div className={clsx(styles.block2, styles.blockWrapper, "common-page-card")}>
        <Row >
          <Col xs="12">
            <Row className={clsx(styles.areaActionRight, " align-items-center ")}>
              {page === constants.CATEGORY_PAGE_TYPE.CATEGORY_PAGE && (
                <Col xs="auto" className="py-2">
                  <Dropdown
                    isOpen={viewDropDown}
                    toggle={toggleViewDropDown}
                    className={styles.dropdownButtonWrapper}
                  >
                    <DropdownToggle caret>
                      <span
                        className={
                          clsx(styles.selectedFilter, "d-flex align-items-center")
                        }
                      >
                        {tableView === constants.TABLE_VIEW.LIST_VIEW ? (
                          <>
                            <img src={ListViewIcon} alt="list-view" className="mr-1" />
                            {tableView}
                          </>
                        )
                          : (
                            <>
                              <img src={TreeViewIcon} alt="tree-view" className="mr-1" />
                              {tableView}
                            </>
                          )}
                      </span>
                    </DropdownToggle>
                    <div
                      role="presentation"
                      className={clsx(styles.dropdownClear, "cursor-pointer")}
                      onClick={() => toggleViewDropDown()}
                    >
                      <img src={PreviewIcon} alt="View" width="20px" />
                    </div>
                    <DropdownMenu className={styles.dropdownWrapper}>
                      <div
                        key="table-view-tree-list"
                        onKeyPress={() => { }}
                        role="button"
                        tabIndex="0"
                        onClick={() => {
                          toggleTableView(constants.TABLE_VIEW.TREE_VIEW);
                          setViewDropDown(false);
                        }}
                        className={clsx(styles.dropdownItem, "d-flex align-items-center commonPointer")}
                      >
                        <img src={TreeViewIcon} alt="tree-view" className="mr-1" />
                        {constants.TABLE_VIEW.TREE_VIEW}
                      </div>
                      <div
                        key="table-view-simple-list"
                        onKeyPress={() => { }}
                        role="button"
                        tabIndex="0"
                        onClick={() => {
                          toggleTableView(constants.TABLE_VIEW.LIST_VIEW);
                          setViewDropDown(false);
                        }}
                        className={clsx(styles.dropdownItem, "d-flex align-items-center commonPointer")}
                      >
                        <img src={ListViewIcon} alt="list-view" className="mr-1" />
                        {constants.TABLE_VIEW.LIST_VIEW}
                      </div>
                    </DropdownMenu>
                  </Dropdown>
                </Col>
              )}
              {tableView === constants.TABLE_VIEW.LIST_VIEW && (
                <Col xs="auto" className="py-2">
                  <FormGroup className=" m-0">
                    <InputGroup className={clsx(styles.customInput, "color-tertiary-main")}>
                      <Input placeholder="Search categories " value={searchKey} onChange={(e) => handleSearch(e)} />
                      <InputGroupAddon
                        addonType="append"
                        className="commonPointer bg-color-tertiary-main"
                      >
                        <img
                          role="presentation"
                          src={searchKey ? ClearIconAlt : SearchIcon}
                          onClick={() => handleSearch({ target: { value: "" } })}
                          width="25px"
                          alt={searchKey ? "clear search" : "search category"}
                        />
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                </Col>
              )}
              {tableView === constants.TABLE_VIEW.LIST_VIEW && (
                <>
                  <Col xs="auto" className="py-2">
                    <Dropdown
                      isOpen={dropdownOpen}
                      toggle={toggleDropdown}
                      className={styles.dropdownButtonWrapper}
                    >
                      <DropdownToggle caret>
                        <span
                          className={
                            clsx(styles.selectedFilter,
                              !selectedFilter && styles.filterText)
                          }
                        >
                          {selectedFilter ? selectedFilter.label : "Filter by"}
                        </span>
                      </DropdownToggle>
                      <div
                        role="presentation"
                        className={clsx(styles.dropdownClear, "cursor-pointer")}
                        onClick={() => (selectedFilter
                          ? handleSelectFilter(null) : toggleDropdown())}
                      >
                        {!selectedFilter ? (
                          <img
                            src={FilterIcon}
                            alt="Select filter"
                            width="20px"
                            className={styles.icon}
                          />
                        )
                          : (
                            <img
                              src={ClearIconAlt}
                              alt="Clear filter"
                              width="20px"
                              className={styles.icon}
                            />
                          )}
                      </div>
                      <DropdownMenu className={styles.dropdownWrapper}>
                        {constants.CATEGORIES_FILTER_OPTIONS.map((option) => (
                          option.disabled ? (
                            <div
                              key={`filter-${option.label}`}
                              className={clsx(styles.disabled)}
                            >
                              {option.label}
                            </div>
                          )
                            : (
                              <div
                                key={`filter-${option.label}`}
                                onKeyPress={() => { }}
                                role="button"
                                tabIndex="0"
                                className={clsx(styles.dropdownItem)}
                                onClick={() => !option.disabled && handleSelectFilter(option)}
                              >
                                {option.label}
                              </div>
                            )
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                  <Col xs="auto" className="py-2">
                    {Array.isArray(categories) && categories.length > 0 && (
                      <span className="">
                        <span className="font-weight-bold pl-1">{`${pagination.numberOfElements} of ${pagination.totalCount}`}</span>
                      </span>
                    )}
                  </Col>
                </>
              )}
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            {tableView === constants.TABLE_VIEW.LIST_VIEW ? (
              <>
                <CategoryTable
                  loading={loading}
                  tableData={categories}
                  getPageData={getPageData}
                  hideSource={!!selectedFilter}
                />
                {Array.isArray(categories) && categories.length > 0 && (
                  <Pagination
                    totalCount={pagination.totalCount}
                    page={pagination.page}
                    limit={pagination.limit}
                    getPageData={getPageData}
                  />
                )}
              </>
            ) : (
              <div className={styles.categoryTree}>
                {/*  ====== MAIN COMPONENT ROOT  S[0001]============ */}
                <div
                  className={clsx(styles.categoryTreeBody, "table-responsive", "custom-table mt-3")}
                >
                  <Table borderless className={styles.categoryTreeTable}>
                    {/* ========= COMPONENT HEAD S[0003] ======================= */}
                    <thead className={clsx(styles.unsetThMinWidth)}>
                      <tr>
                        <th>{t("category:label_category_id")}</th>
                        <th>{t("category:label_category_name")}</th>
                        <th>{t("common:label_type")}</th>
                        <th>{t("common:label_startDate")}</th>
                        <th>{t("common:label_endDate")}</th>
                        <th className="text-center">{t("common:label_status")}</th>
                        <th className="text-center">Source</th>
                        <th className="text-center">{t("common:label_actions")}</th>
                      </tr>
                    </thead>
                    {/* ========= COMPONENT HEAD S[0003] ======================= */}
                    <tbody>
                      {/* ========= COMPONENT BODY S[0002] ======================= */}
                      {
                        categories.length > 0
                          ? categories.map((each) => (
                            <Category
                              key={each.id}
                              id={each.id}
                              name={each.name}
                              type={each.type}
                              active={each.active}
                              startDate={each.startDate}
                              source={each.source}
                              endDate={each.endDate}
                              childCategories={each.childCategories}
                              childProducts={each.childProducts}
                            />
                          )) : (
                            <tr>
                              <td colSpan={6} align="center" valign="center">{loading ? "Loading..." : "Nothing to show."}</td>
                            </tr>
                          )}
                      {/* ========= COMPONENT BODY E[0002] ======================= */}
                    </tbody>
                  </Table>
                </div>
                {/*  ====== MAIN COMPONENT ROOT  E[0001]============ */}
              </div>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

CategoryManagement.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.any).isRequired,
  addNewCategory: PropTypes.func.isRequired,
  handlePushToLive: PropTypes.func.isRequired,
  pushToLiveEnabled: PropTypes.bool.isRequired,
  message: PropTypes.objectOf(PropTypes.any).isRequired,
  getReplicationStatus: PropTypes.func.isRequired,
  pushToLiveButtonEnabled: PropTypes.bool.isRequired,
  previewSiteUrl: PropTypes.string.isRequired,
  previewEnabled: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  page: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  toggleTableView: PropTypes.func.isRequired,
  tableView: PropTypes.func.isRequired,
  searchKey: PropTypes.string.isRequired,
  pagination: PropTypes.objectOf(PropTypes.any).isRequired,
  getPageData: PropTypes.func.isRequired,
  handleSearch: PropTypes.bool.isRequired,
  dropdownOpen: PropTypes.bool.isRequired,
  selectedFilter: PropTypes.string.isRequired,
  toggleDropdown: PropTypes.func.isRequired,
  handleSelectFilter: PropTypes.func.isRequired,
};

export default CategoryManagement;
