// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Copyright(c) 2021 Mozanta Technologies Private Ltd.\n *\n * All rights reserved.\n *\n * This software is the confidential and proprietary information of Mozanta (\"Confidential\n * Information\"). You shall not disclose such Confidential Information and shall use it only in\n * accordance with the terms of the contract agreement you entered into with Mozanta.\n *\n * @author Mohammed Shefeeq\n */\n\n.InventoryReport_block1__3PD-E .InventoryReport_buttonNewUser__3YArx {\n    color: #ffffff;\n    width: 113px;\n    border-radius: 2px;\n    background-color: #e91e63;\n    border: 0px;\n    float: right;\n}\n\n\n/* block 2 */\n\n.InventoryReport_tableWrapper__AbJ37 {\n    padding-bottom: 20px;\n    background: #ffffff;\n    margin: 0px !important;\n    min-height: calc(100vh - 408px);\n    margin-bottom: 10px !important;\n}\n\n\n/* block 3 */\n\n.InventoryReport_block3__1gvDX .InventoryReport_dropdownBtn__3DYO2 {\n    padding: 0px;\n}\n\n.InventoryReport_block3__1gvDX .InventoryReport_dropdownBtn__3DYO2 button {\n    float: right !important;\n    font-size: 11px;\n    min-width: 100px;\n}\n\n.InventoryReport_block3__1gvDX .InventoryReport_dateFilterRow__3uzk4 {\n    border-radius: 4px;\n    border: 1px solid #dff3f7;\n    background-color: #fdfdfd;\n    color: black;\n}\n\n.InventoryReport_block3__1gvDX .InventoryReport_verticalLine__1yMtZ {\n    height: 15px;\n    padding: 5px;\n}\n\n.InventoryReport_block3__1gvDX .InventoryReport_verticalLineNone__1b-m3 {\n    padding: 5px;\n    border-radius: 4px;\n}\n\n.InventoryReport_block3__1gvDX .InventoryReport_verticalLine__1yMtZ:hover {\n    background-color: #17a2b8;\n    border-radius: 4px;\n    color: rgb(255, 255, 255);\n}\n\n.InventoryReport_block3__1gvDX .InventoryReport_verticalLineNone__1b-m3:hover {\n    background-color: #17a2b8;\n    border-radius: 4px;\n    color: rgb(255, 255, 255);\n}", ""]);
// Exports
exports.locals = {
	"block1": "InventoryReport_block1__3PD-E",
	"buttonNewUser": "InventoryReport_buttonNewUser__3YArx",
	"tableWrapper": "InventoryReport_tableWrapper__AbJ37",
	"block3": "InventoryReport_block3__1gvDX",
	"dropdownBtn": "InventoryReport_dropdownBtn__3DYO2",
	"dateFilterRow": "InventoryReport_dateFilterRow__3uzk4",
	"verticalLine": "InventoryReport_verticalLine__1yMtZ",
	"verticalLineNone": "InventoryReport_verticalLineNone__1b-m3"
};
module.exports = exports;
