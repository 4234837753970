/**
 * Copyright(c) 2022 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Rahna
 */

import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ===== TAG COMPONENTS =========== */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Label from "../../../common/core/Label";
import Input from "../../../common/core/Input";
import FormGroup from "../../../common/core/FormGroup";

/** ===== IMAGE ICONS =========== */

/** ======== MODULE STYLES ========== */
import styles from "../css/EditProductOption.module.css";

const ExtendedWarrantyForm = (props) => {
  const { name, productValue, year, price, handleFormChange } = props;

  /** internationalization using i18n from common and product  */
  const { t } = useTranslation(["common", "product"]);
  return (
    <>
      <Row className={styles.block3}>
        <Col lg="10">
          <Row>
            <Col xs="7" sm="5" md="4">
              <FormGroup>
                <Label className="formTextLabel">
                  {t("common:label_warranty_Id")}
                </Label>
                <Input name="priceOptionName" value={name} disabled />
              </FormGroup>
            </Col>
            <Col xs="7" sm="5" md="4">
              <FormGroup>
                <Label className="formTextLabel">
                  {t("common:label_warranty_name")}
                </Label>
                <Input
                  className="formText"
                  placeholder=""
                  name="productValue"
                  value={productValue}
                  onChange={(o) => handleFormChange("productValue", o)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs="7" sm="5" md="4">
              <FormGroup>
                <Label className="formTextLabel">
                  {t("common:label_year")}
                </Label>
                <Input name="priceOptionName" value={year} disabled />
              </FormGroup>
            </Col>
            <Col xs="7" sm="5" md="4">
              <FormGroup>
                <Label className="formTextLabel">
                  {t("common:label_rate")}
                </Label>
                <Input name="priceOptionName" value={price} disabled />
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

ExtendedWarrantyForm.propTypes = {
  /** form data */
  name: PropTypes.string.isRequired,
  productValue: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
  // input filed handle change
  handleFormChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default ExtendedWarrantyForm;
