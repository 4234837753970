/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Mohammed Shefeeq
 */

import React, { useState, useEffect } from "react";
import PriceReport from "../components/PriceReport";
import { updateBatchConfig, getJobHistory } from "../../../api/InventoryReportServices";

const PriceReportContainer = () => {
  const [isExporting, setIsExporting] = useState(false);
  const [schedulerDetails, setSchedulerDetails] = useState({
    criteria: {},
    type: "priceExportBatchJob",
  });

  const exportPrice = async (event) => {
    const response = await updateBatchConfig(schedulerDetails);
    if (response && response.data == true && response.success) {
      setIsExporting(response.data);
    }
  };

  const [jobHistoryReqData, setJobHistoryReqData] = useState({
    page: 0,
    size: 10,
    status: null,
    jobConfigId: null,
    productId: "",
    jobConfigType: null,
    searchTerm: "priceExportBatchJob",
    startDate: null,
    endDate: null,
  });
  const [jobHistoryData, setJobHistoryData] = useState([]);

  const jobHistoryServiceCall = (reqObj) => {
    getJobHistory(reqObj).then(async (response) => {
      if (response && response.data) {
        const { data } = response;
        if (data && data.content) {
          setJobHistoryData(data.content);
        }
      }
    });
  };
  useEffect(() => {
    jobHistoryServiceCall(jobHistoryReqData);
  }, [jobHistoryReqData]);

  const refreshData = () => {
    jobHistoryServiceCall(jobHistoryReqData);
  };

  return (
    <PriceReport
      exportPrice={exportPrice}
      schedulerDetails={schedulerDetails}
      jobHistoryData={jobHistoryData}
      isExporting={isExporting}
      refreshData={refreshData}
    />
  );
};

export default PriceReportContainer;
