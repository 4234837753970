/**
 * Copyright(c) 2022 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Krishnanunny H
 */
import httpApi from "./httpApi";

/**
  * This method is used to return Thread list
  * @returns {Array}
  */
const getThreads = async (requestData, page, size, sortBy = "createdDate", sortDirection = "desc") => {
  const response = await httpApi.post("v1/messages/threads/search", requestData, {
    params: {
      page, size, sortBy, sortDirection,
    },
  });
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
  * This method is used to block thread
  * @returns {Array}
  */
const block = async (threadId) => {
  const response = await httpApi.patch(`v1/messages/${threadId}/change-block-status?threadBlocked=true`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

export {
  getThreads,
  block,
};
