/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * @author Aardra S
 *
 */
import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ========= TAG COMPONENTS ========= */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Button from "../../../common/core/Button";
import Form from "../../../common/core/Form";
import Alert from "../../../common/core/Alert";
import validateForm from "../../../common/utils/validateForm";

/** ========= CUSTOM COMPONENTS ========= */
import CouponForm from "./CouponForm";
import AdvancedSettingsForm from "./AdvancedSettingsForm";


/** ========= MODULE STYLES ========= */
import styles from "../css/EditCoupon.module.css";

const EditCoupon = (props) => {
  const {
    code, description, startDate, endDate, active,
    usesPerCoupon, usesPerCustomer, handleChange, handleFormChange,
    formCancel, message, onSubmitting, onSubmit, unlimited, indefinite,
    onCampaign, selectedLanguageChange, translatableFileds, selectedLanguage,
    caseSensitive, generateCoupon,
    prefix,
    numberOfCoupons, downloadCouponCodes,
  } = props;

  /** internationalization using i18n from common and coupon  */
  const { t } = useTranslation(["common", "coupon"]);
  const validator = validateForm();
  const { handleSubmit } = validator;
  const isEditPage = true;
  return (
    <>
      <Row>
        <Col xs="12">
          <Form onSubmit={handleSubmit(onSubmit)}>
            {
              message && message.type && (
                <Alert color={message.type}>
                  {message.message}
                </Alert>
              )
            }
            <fieldset disabled={onSubmitting}>
              <Row className="pageHeader common-page-card">
                <Col xs="12" md="7" className="text-truncate">
                  <span className="pageText">
                    {t("coupon:title_edit")}
                  </span>
                </Col>
                <Col xs="12" md="5" className="d-flex justify-content-end">
                  <Button onClick={formCancel} type="button" color="secondary" className={clsx(styles.borderRadius2, styles.btn, styles.secondaryColor, "text-truncate", "mr-1")}>
                    {t("common:button_cancel")}
                  </Button>
                  <Button type="submit" color="secondary" className={clsx(styles.borderRadius2, styles.btn, styles.primaryColor, "text-truncate", "mr-1", "button-font-size")}>
                    {t("common:button_update")}
                  </Button>
                </Col>
              </Row>
              <Row className="common-page-card">
                <Col xs="12" sm="6">
                  <select className="form-control" onChange={(e) => selectedLanguageChange(e.target.value)}>
                    <option value="en">English</option>
                    <option value="ar">Arabic</option>
                  </select>
                </Col>
              </Row>
              {/*  ====== EDIT COUPON FORM ============ */}
              <Row className={clsx(styles.blockWrapper, " common-page-card", "pb-0")}>
                <Col xs="12" lg="10">
                  <div className="common-page-card">
                    <CouponForm
                      code={code}
                      description={description}
                      startDate={startDate}
                      endDate={endDate}
                      indefinite={indefinite}
                      active={active}
                      caseSensitive={caseSensitive}
                      generateCoupon={generateCoupon}
                      prefix={prefix}
                      numberOfCoupons={numberOfCoupons}
                      isEditPage={isEditPage}
                      /** form methods */
                      handleFormChange={handleFormChange}
                      handleChange={handleChange}
                      validator={validator}
                      onCampaign={onCampaign}
                      translatableFileds={translatableFileds}
                      selectedLanguage={selectedLanguage}
                      downloadCouponCodes={downloadCouponCodes}
                    />
                  </div>
                </Col>
              </Row>
              {/*  ====== Advanced Settings Edit============ */}
              <Row className={clsx(styles.blockWrapper, " common-page-card", "pt-0")}>
                <Col xs="12" lg="10">
                  <div className="common-page-card">
                    <AdvancedSettingsForm
                      usesPerCoupon={usesPerCoupon}
                      usesPerCustomer={usesPerCustomer}
                      /** form methods */
                      handleFormChange={handleFormChange}
                      handleChange={handleChange}
                      validator={validator}
                      unlimited={unlimited}
                      onCampaign={onCampaign}
                      translatableFileds={translatableFileds}
                      selectedLanguage={selectedLanguage}
                    />
                  </div>
                </Col>
              </Row>
            </fieldset>
          </Form>
        </Col>
      </Row>
    </>
  );
};

EditCoupon.propTypes = {
  code: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  message: PropTypes.objectOf(PropTypes.any).isRequired,
  onSubmitting: PropTypes.bool.isRequired,
  startDate: PropTypes.objectOf(PropTypes.any).isRequired,
  endDate: PropTypes.objectOf(PropTypes.any).isRequired,
  active: PropTypes.bool.isRequired,
  usesPerCoupon: PropTypes.string.isRequired,
  usesPerCustomer: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  formCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCampaign: PropTypes.bool.isRequired,
  indefinite: PropTypes.bool.isRequired,
  unlimited: PropTypes.bool.isRequired,
  caseSensitive: PropTypes.bool.isRequired,
  translatableFileds: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedLanguageChange: PropTypes.func.isRequired,
  selectedLanguage: PropTypes.string.isRequired,
  generateCoupon: PropTypes.bool.isRequired,
  prefix: PropTypes.string.isRequired,
  numberOfCoupons: PropTypes.string.isRequired,
  downloadCouponCodes: PropTypes.func.isRequired,
};

export default EditCoupon;
