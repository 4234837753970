/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Mohammed Shefeeq
 */
import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import styles from "../css/PriceReport.module.css";
import FormGroup from "../../../common/core/FormGroup";
import Button from "../../../common/core/Button";
import CustomizationTable from "../../../common/components/Table";
import RefreshIcon from "../../../common/assets/images/svg/refresh.svg";

const customRows = [
  {
    label: "Status",
    value: "status",
    path: "status",
    transform: "",
  },
  {
    label: "Start Date",
    value: "startDate",
    path: "startDate",
    transform: "",
    type: "date",
  },
  {
    label: "End Date",
    value: "endDate",
    path: "endDate",
    transform: "",
    type: "date",
  },
  {
    label: "Actions",
    value: "",
    path: "render",
    render: (row) => {
      if (row && row.attributes && row.attributes.exportedFileUrl) {
        return (
          <a href={row.attributes.exportedFileUrl} className="btn btn-sm btn-info text-white">
            Download
          </a>
        );
      }
      return (
        <button type="button" className="btn btn-sm btn-info text-white" disabled>
          Download
        </button>
      );
    },
    transform: "",
  }];
const PriceReport = ({
  exportPrice,
  jobHistoryData,
  refreshData,
}) => {
  const { t } = useTranslation(["common", "price-report"]);

  return (
    <>
      {" "}
      <Row className={clsx(styles.block1, "common-page-card pageHeader")}>
        <Col xs="8">
          <span className="pageText">
            {" "}
            {t("price-report:price_report")}
          </span>
        </Col>
      </Row>
      <Row className={clsx(styles.block2, "common-page-card")}>
        <Col xs="12" className="p-0">
          <span
            onClick={() => refreshData()}
            className="commonPointer"
            title="Refresh"
            role="presentation"
            style={{ float: "right" }}
          >
            <img
              width="40"
              src={RefreshIcon}
              alt="search category"
              className={styles.icon}
            />
          </span>
          <Button
            style={{ float: "right", marginRight: "2%" }}
            onClick={exportPrice}
            className={clsx(styles.borderRadius2, "text-uppercase", "text-truncate", "btn-tertiary", "button-font-size")}
          >
            {t("price-report:export")}
          </Button>
        </Col>
      </Row>
      <Row className={clsx(styles.block2, styles.tableWrapper, "common-page-card")}>
        <Col xs="12">
          <div className="custom-table">
            <CustomizationTable
              customRows={customRows}
              customizationData={jobHistoryData}
              isStatusEnable={false}
              isDeleteEnable={false}
              isEditEnable={false}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};
export default PriceReport;
