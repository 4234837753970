/**
 * Copyright(c) 2022 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Abdul Razack
 */

import clsx from "clsx";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ===== TAG COMPONENTS =========== */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Dropdown from "../../../common/core/Dropdown";
import DropdownToggle from "../../../common/core/DropdownToggle";

/** ======== COMPONENTS */
import UploadManagementContainer from "../containers/UploadManagementContainer";

/** ===== MODULE STYLES =========== */
import styles from "../css/ColorPaletteManagement.module.css";


/** ===== ICONS =========== */
import ExpandMoreIcon from "../../../common/assets/images/svg/expand-more-alt.svg";
import ExpandLessIcon from "../../../common/assets/images/svg/expand-less-alt.svg";
import DropdownMenu from "../../../common/core/DropdownMenu";


const ProductBulkManagement = ({
  maintenanceType, maintenanceTypes, handleMaintenanceType,
}) => {
  const { t } = useTranslation(["common", "product-bulk-maintenance"]);
  const [viewDropDown, setViewDropDown] = useState(false);
  const toggleViewDropDown = () => {
    setViewDropDown(!viewDropDown);
  };
  return (
    <>
      <Row className="pageHeader common-page-card">
        <Col xs="12" md="6" lg="5" className="text-truncate">
          <span className="pageText">{t("product-bulk-maintenance:title_product_bulk_maintenance")}</span>
        </Col>
      </Row>
      <Row className={clsx(styles.block2, "common-page-card")}>
        <Col xs="auto" className="py-2">
          <Dropdown
            isOpen={viewDropDown}
            toggle={toggleViewDropDown}
            className={styles.dropdownButtonWrapper}
          >
            <DropdownToggle caret>
              <span
                className={
                  clsx(styles.selectedFilter, "d-flex align-items-center")
                }
              >
                {maintenanceType ? maintenanceType.name : "Upload Type"}
              </span>
            </DropdownToggle>
            <div
              role="presentation"
              className={clsx(styles.dropdownClear, "cursor-pointer")}
              onClick={() => toggleViewDropDown()}
            >
              {viewDropDown ? <img src={ExpandLessIcon} alt="View" width="20px" />
                : <img src={ExpandMoreIcon} alt="View" width="20px" />}
            </div>
            <DropdownMenu className={styles.dropdownWrapper}>
              {maintenanceTypes.map((item) => (
                <div
                  key={item.value}
                  onKeyPress={() => { }}
                  role="button"
                  tabIndex="0"
                  onClick={() => {
                    handleMaintenanceType(item);
                    setViewDropDown(false);
                  }}
                  className={clsx(styles.dropdownItem, "d-flex align-items-center commonPointer")}
                >
                  {item.name}
                </div>
              ))}
            </DropdownMenu>
          </Dropdown>
        </Col>
      </Row>
      {maintenanceType && maintenanceType.value
        ? (
          <UploadManagementContainer maintenanceType={maintenanceType.value} />
        ) : (
          <Row className={clsx(styles.blockWrapper, " common-page-card justify-content-center align-items-center")}>
            <Col xs="auto text-hint">
              Nothing selected
            </Col>
          </Row>
        )}
    </>
  );
};
ProductBulkManagement.propTypes = {
  maintenanceType: PropTypes.string.isRequired,
  maintenanceTypes: PropTypes.objectOf(PropTypes.any).isRequired,
  handleMaintenanceType: PropTypes.func.isRequired,
};
export default ProductBulkManagement;
