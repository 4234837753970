/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Categories Table
 *
 * @author Roshna Accu
 *
 */

import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { setStatus, deleteLeafCategory } from "../../../api/categoryManagementServices";

import CategoryTableListView from "../components/CategoryTableListView";

const CategoryTable = (props) => {
  const {
    loading,
    tableData: propTableData,
    getPageData,
    hideSource
  } = props;

  /** local states */
  const [deleteCategory, setDeleteCategory] = useState(null);

  /**
     * This method is used to cancel confirm form
     */
  const cancelConfirm = () => {
    setDeleteCategory(null);
  };

  const isFunction = (functionToCheck) => functionToCheck && {}.toString.call(functionToCheck) === "[object Function]";

  /**
 * method for delete confirm
 */
  const confirmDelete = async () => {
    if (Boolean(deleteCategory) && deleteCategory.categoryId) {
      const { categoryId } = deleteCategory;
      const response = await deleteLeafCategory(categoryId);
      if (response && response.success === true) {
        getPageData();
      }
      if (isFunction(deleteCategory.callback)) { deleteCategory.callback(); }
      setDeleteCategory(null);
    }
  };


  const history = useHistory();
  const currentRow = useRef(null);

  /** local states */
  const [isOpen, setIsOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [tableData, setTableData] = useState(propTableData);

  useEffect(() => {
    setTableData(propTableData)
  }, [propTableData])

  /**
  * This method is used to set category status
  * @param {Boolean} status
  * @param {Event} event
  */
  const changeCategoryStatus = async (status, categoryId) => {
    if (submitting) return;
    setSubmitting(true); /** on updating  */
    const response = await setStatus(categoryId, status);
    if (response && response.success === true) {
      setTableData(data => data.map(each => ({ ...each, active: each.id === categoryId ? status : each.active })))
    }
    setSubmitting(false);
  };
  /**
   * This method is used to load edit page
   * @param {String} categoryId
   */
  const editCategory = (categoryId) => {
    history.push(`/merchandising/navigation-taxonomy/edit/${categoryId}`);
    window.scroll(0, 0);
  };
  /**
   * This method is used to delete a category
   * @param {String} categoryId
   */
  const handleDeleteCategory = (categoryId) => {
    setDeleteCategory({
      categoryId,
      element: currentRow.current,
    });
  };

  return (
    <CategoryTableListView
      loading={loading}
      tableData={tableData}
      hideSource={hideSource}
      confirmDelete={confirmDelete}
      submitting={submitting}
      changeCategoryStatus={changeCategoryStatus}
      editCategory={editCategory}
      cancelConfirm={cancelConfirm}
      deleteCategory={deleteCategory}
      isOpen={isOpen}
      handleDeleteCategory={handleDeleteCategory}
    />
  );
};

CategoryTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  tableRows: PropTypes.arrayOf(PropTypes.any).isRequired,
  tableData: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default CategoryTable;
