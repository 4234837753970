/*
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 *
 */

import React, { useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ========= TAG COMPONENTS ========= */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import Input from "../../../common/core/Input";
import FormFeedback from "../../../common/core/FormFeedback";
import Form from "../../../common/core/Form";
import CustomInput from "../../../common/core/CustomInput";
import Alert from "../../../common/core/Alert";
import InputGroup from "../../../common/core/InputGroup";
import InputGroupAddon from "../../../common/core/InputGroupAddon";
import InputGroupAddonText from "../../../common/core/InputGroupText";
import UncontrolledTooltip from "../../../common/core/UncontrolledTooltip";
import Spinner from "../../../common/core/Spinner";

/** ========= UTIlS ========= */
import constants from "../../../common/utils/constants";
import validationConstants from "../../../common/utils/validationConstants";

/** ========= SUB COMPONENT ========= */
import PromotionTypeSelector from "../containers/PromotionTypeSelector";
import ClassTypeSelector from "../containers/ClassTypeSelector";
import DiscountTypeSelector from "../containers/DiscountTypeSelector";
import FulfilmentTypeSelector from "../containers/FulfilmentTypeSelectorConatiner";
import ProductOptionSelector from "../containers/ProductOptionSelectorContainer";
import SkuSelector from "../../productManagement/containers/SimpleSkuSelectorContainer";
import ProductBundleSelector from "../containers/ProductBundleContainer";
/** ===== IMAGE ICONS =========== */
import QuestionIcon from "../../../common/assets/images/svg/question-mark.svg";

/** ========= MODULE STYLES ========= */
import styles from "../css/PromotionForm.module.css";

/** ========= CUSTOM COMPONENTS ========= */
/** ========= SVG IMAGE ICON ========= */
import downloadIconfrom from "../../../common/assets/images/svg/file-upload.svg";
import publishIcon from "../../../common/assets/images/svg/publish.svg";

const DiscountForm = (props) => {
  const {
    classType, discountType, onCampaign,
    cartValue, promotionType,
    discountRate,
    discountAmount,
    miniProduct,
    buy, amount, payFor,
    validator,
    handleChange, handleFormChange,
    isapplicationsPerOrderUnlimited, maxApplicationsPerOrder,
    externalId,
    fulfilmentTypes, fulfilmentTypesDetails,
    productOptions, refProductOptions, productOptionDetails,
    bonusProduct, bonusProductDetails, promotionBundles, promotionBundleDetails,
    handleBulkImport, downloadBulkImportTemplate, translatableFileds,
    isDefault, loadingFlags,
  } = props;
  const {
    CLASS_PRODUCT,
    CLASS_ORDER,
    CLASS_SHIPPING,
    DISCOUNT_AMOUNT,
    BUY_X_GET_Y_PROMOTION,
    BUY_X_PAY_Y_PROMOTION,
    PRODUCT_OPTION_PROMOTION,
    PERCENTAGE_OFF,
    FLAT_AMOUNT_OFF,
    FIXED_AMOUNT,
    BUY_X_PAY_Y,
    BUY_X_GET_Y_FREE,
    BUY_X_FIXED_AMOUNT,
    BONUS_OPTION_PROMOTION,
    BUNDLE_PROMOTION,
    SHEET_CONTEXT_BUNDLE,
    FILE_TYPES_PROMOTION,
  } = constants.PROMOTION;
  const { IS_NUMBER, IS_INTEGER } = validationConstants;

  /** internationalization using i18n from common and promotion  */
  const { t } = useTranslation(["common", "promotion", "fulfillment-method"]);

  /** validation methods */
  const {
    register, errors, setError, clearError,
  } = validator;
  const validNumber = new RegExp(IS_INTEGER);
  useEffect(() => {
    if (!isapplicationsPerOrderUnlimited
      && (maxApplicationsPerOrder < 1 || !validNumber.test(maxApplicationsPerOrder))) {
      setError("maxApplicationsPerOrder", "noMatch", t("promotion:validation_max_application_per_order_invalid"));
    } else {
      clearError(["maxApplicationsPerOrder"]);
    }
  }, [maxApplicationsPerOrder]);
  const discountRateSelector = () => (
    <Col xs="12" sm={onCampaign ? "12" : "6"}>
      <FormGroup>
        <Label className="formTextLabel">
          {t("promotion:label_discount_rate")}
          <span className="pl-1 text-danger">*</span>
        </Label>
        <Input
          className="formText"
          placeholder=""
          name="discountRate"
          defaultValue={discountRate}
          onChange={handleFormChange}
          innerRef={
            register({
              required: t("promotion:validation_discount_rate"),
              validate: (value) => {
                const x = parseFloat(value);
                return (Number.isNaN(x) || x < 1) ? t("common:validation_is_number") : true;
              },
            })
          }
          invalid={!!(errors.discountRate && errors.discountRate.message)}
        />
        <FormFeedback>{errors.discountRate && errors.discountRate.message}</FormFeedback>
      </FormGroup>
    </Col>
  );

  const percentageOffSelector = () => (
    <Col xs="12" sm={onCampaign ? "12" : "6"}>
      <FormGroup>
        <Label className="formTextLabel">
          {t("promotion:label_percentage_off")}
          <span className="pl-1 text-danger">*</span>
        </Label>
        <Input
          className="formText"
          placeholder=""
          name="discountRate"
          defaultValue={discountRate}
          onChange={handleFormChange}
          innerRef={
            register({
              required: t("promotion:validation_percentage_off"),
              validate: (value) => {
                const x = parseFloat(value);
                return (Number.isNaN(x) || x < 1 || x > 100) ? t("promotion:validation_discount_percentage_off_invalid") : true;
              },
              pattern: {
                value: IS_NUMBER,
                message: t("common:validation_is_number"),
              },
            })
          }
          invalid={!!(errors.discountRate && errors.discountRate.message)}
          disabled={Array.isArray(translatableFileds) ? !isDefault && !translatableFileds.includes("discountRate") : false}
        />
        <FormFeedback>{errors.discountRate && errors.discountRate.message}</FormFeedback>
      </FormGroup>
    </Col>
  );

  const maxDiscountAmountSelector = () => (
    <Col xs="12" sm={onCampaign ? "12" : "6"}>
      <FormGroup>
        <Label className="formTextLabel">
          {t("promotion:label_max_discount_amount")}
        </Label>
        <Input
          className="formText"
          placeholder=""
          name="discountAmount"
          value={discountAmount}
          onChange={handleFormChange}
          innerRef={
            register({
              min: {
                value: 1,
                message: t("promotion:validation_discount_amount_invalid"),
              },
              pattern: {
                value: IS_NUMBER,
                message: t("common:validation_is_number"),
              },
            })
          }
          invalid={!!(errors.discountAmount && errors.discountAmount.message)}
          disabled={Array.isArray(translatableFileds) ? !isDefault && !translatableFileds.includes("discountAmount") : false}
        />
        <FormFeedback>{errors.discountAmount && errors.discountAmount.message}</FormFeedback>
      </FormGroup>
    </Col>
  );

  const getMinProductQuantitySelector = () => (
    <Col xs="12" sm={onCampaign ? "12" : "6"}>
      <FormGroup>
        <Label className="formTextLabel">
          {t("promotion:label_min_product_quantity")}
          <span className="pl-1 text-danger">*</span>
        </Label>
        <Input
          className="formText"
          placeholder=""
          name="miniProduct"
          value={miniProduct}
          onChange={handleFormChange}
          innerRef={
            register({
              required: t("promotion:validation_min_product_quantity"),
              min: {
                value: 1,
                message: t("promotion:validation_min_product_quantity_invalid"),
              },
              pattern: {
                value: IS_NUMBER,
                message: t("common:validation_is_number"),
              },
            })
          }
          invalid={!!(errors.miniProduct && errors.miniProduct.message)}
        />
        <FormFeedback>{errors.miniProduct && errors.miniProduct.message}</FormFeedback>
      </FormGroup>
    </Col>
  );

  const buySelector = () => (
    <Col xs="12" sm={onCampaign ? "12" : "6"}>
      <FormGroup>
        <Label className="formTextLabel">
          {t("promotion:label_number_of_products")}
          <span className="pl-1 text-danger">*</span>
        </Label>
        <Input
          className="formText"
          placeholder=""
          name="buy"
          value={buy}
          onChange={handleFormChange}
          innerRef={
            register({
              required: t("promotion:validation_buy"),
              min: {
                value: 1,
                message: t("promotion:validation_buy_invalid"),
              },
              pattern: {
                value: IS_NUMBER,
                message: t("common:validation_is_number"),
              },
            })
          }
          invalid={!!(errors.buy && errors.buy.message)}
        />
        <FormFeedback>{errors.buy && errors.buy.message}</FormFeedback>
      </FormGroup>
    </Col>
  );

  const getSelector = () => (
    <Col xs="12" sm={onCampaign ? "12" : "6"}>
      <FormGroup>
        <Label className="formTextLabel">
          {t("promotion:label_get")}
          <span className="pl-1 text-danger">*</span>
        </Label>
        <Input
          className="formText"
          placeholder=""
          name="payFor"
          value={payFor}
          onChange={handleFormChange}
          innerRef={
            register({
              required: t("promotion:validation_get"),
              min: {
                value: 1,
                message: t("promotion:validation_get_invalid"),
              },
              pattern: {
                value: IS_NUMBER,
                message: t("common:validation_is_number"),
              },
            })
          }
          invalid={!!(errors.payFor && errors.payFor.message)}
        />
        <FormFeedback>{errors.payFor && errors.payFor.message}</FormFeedback>
      </FormGroup>
    </Col>
  );

  const payForSelector = () => (
    <Col xs="12" sm={onCampaign ? "12" : "6"}>
      <FormGroup>
        <Label className="formTextLabel">
          {t("promotion:label_pay_for")}
          <span className="pl-1 text-danger">*</span>
        </Label>
        <Input
          className="formText"
          placeholder=""
          name="payFor"
          value={payFor}
          onChange={handleFormChange}
          innerRef={
            register({
              required: t("promotion:validation_pay_for"),
              min: {
                value: 1,
                message: t("promotion:validation_pay_for_invalid"),
              },
              pattern: {
                value: IS_NUMBER,
                message: t("common:validation_is_number"),
              },
            })
          }
          invalid={!!(errors.payFor && errors.payFor.message)}
        />
        <FormFeedback>{errors.payFor && errors.payFor.message}</FormFeedback>
      </FormGroup>
    </Col>
  );

  const forSelector = () => (
    <Col xs="12" sm={onCampaign ? "12" : "6"}>
      <FormGroup>
        <Label className="formTextLabel">
          {t("promotion:label_for")}
          <span className="pl-1 text-danger">*</span>
        </Label>
        <Input
          className="formText"
          placeholder=""
          name="discountRate"
          value={discountRate}
          onChange={handleFormChange}
          innerRef={
            register({
              required: t("promotion:validation_for"),
              min: {
                value: 1,
                message: t("promotion:validation_for_invalid"),
              },
              pattern: {
                value: IS_NUMBER,
                message: t("common:validation_is_number"),
              },
            })
          }
          invalid={!!(errors.discountRate && errors.discountRate.message)}
        />
        <FormFeedback>{errors.discountRate && errors.discountRate.message}</FormFeedback>
      </FormGroup>
    </Col>
  );

  const amountSelector = () => (
    <Col xs="12" sm={onCampaign ? "12" : "6"}>
      <FormGroup>
        <Label className="formTextLabel">
          {t("label_amount")}
          <span className="pl-1 text-danger">*</span>
        </Label>
        <Input
          className="formText"
          placeholder=""
          name="amount"
          value={amount}
          onChange={handleFormChange}
          innerRef={
            register({
              required: t("promotion:validation_amount"),
              min: {
                value: 1,
                message: t("promotion:validation_amount_invalid"),
              },
              pattern: {
                value: IS_NUMBER,
                message: t("common:validation_is_number"),
              },
            })
          }
          invalid={!!(errors.amount && errors.amount.message)}
        />
        <FormFeedback>{errors.amount && errors.amount.message}</FormFeedback>
      </FormGroup>
    </Col>
  );

  const minAmountSelector = () => (
    <Col xs="12" sm={onCampaign ? "12" : "6"}>
      <FormGroup>
        <Label className="formTextLabel">
          {t("promotion:label_min_amount")}
          <span className="pl-1 text-danger">*</span>
        </Label>
        <Input
          className="formText"
          placeholder=""
          name="cartValue"
          value={cartValue}
          onChange={handleFormChange}
          innerRef={
            register({
              required: t("promotion:validation_min_amount"),
              min: {
                value: 1,
                message: t("promotion:validation_min_amount_invalid"),
              },
              pattern: {
                value: IS_NUMBER,
                message: t("common:validation_is_number"),
              },
            })
          }
          invalid={!!(errors.cartValue && errors.cartValue.message)}
        />
        <FormFeedback>{errors.cartValue && errors.cartValue.message}</FormFeedback>
      </FormGroup>
    </Col>
  );

  const minCartValueSelector = () => (
    <Col xs="12" sm={onCampaign ? "12" : "6"}>
      <FormGroup>
        <Label className="formTextLabel">
          {t("promotion:label_min_cart_value")}
          <span className="pl-1 text-danger">*</span>
        </Label>
        <Input
          className="formText"
          placeholder=""
          name="cartValue"
          value={cartValue}
          onChange={handleFormChange}
          innerRef={
            register({
              required: t("promotion:validation_min_cart_value"),
              min: {
                value: 1,
                message: t("promotion:validation_min_cart_value_invalid"),
              },
              pattern: {
                value: IS_NUMBER,
                message: t("common:validation_is_number"),
              },
            })
          }
          invalid={!!(errors.cartValue && errors.cartValue.message)}
        />
        <FormFeedback>{errors.cartValue && errors.cartValue.message}</FormFeedback>
      </FormGroup>
    </Col>
  );

  /** This method is sued to create example text #tightly coupled function */
  const exampleConstructor = () => {
    let example = null;
    if (discountType === DISCOUNT_AMOUNT) {
      example = `Example : Spend over AED ${cartValue}`;

      if (promotionType === PERCENTAGE_OFF) {
        example = `${example} ${discountRate} off`;
      }
      if (promotionType === FLAT_AMOUNT_OFF) {
        example = `${example} ${discountRate} discountRate`;
      }
      if (promotionType === FIXED_AMOUNT) {
        example = `${example} ${discountRate} discountRate`;
      }
      example = null;
    }

    if (discountType === BUY_X_GET_Y_PROMOTION) {
      if (promotionType === BUY_X_GET_Y_FREE) {
        example = `Example : Buy ${Number(buy) || 2} Men Denim shirts get ${Number(payFor) || 1} Free`;
      }
      if (promotionType === PERCENTAGE_OFF) {
        example = `Example : Buy ${Number(buy) || 2} Men Denim shirts get ${Number(payFor) || 1} at ${discountRate || 45}% off`;
      }
    }
    if (discountType === BUY_X_PAY_Y_PROMOTION) {
      if (promotionType === BUY_X_PAY_Y) {
        example = `Example : Buy ${Number(buy) || 3} pay for ${Number(payFor) || 2} on Men t-shirt`;
      }
      if (promotionType === BUY_X_FIXED_AMOUNT) {
        example = `Example : Buy  ${Number(buy) || 3} Shirts for AED ${Number(discountRate) || 45} `;
      }
      if (promotionType === PERCENTAGE_OFF) {
        example = `Example : Buy  ${Number(buy) || 3} Shirts for AED ${Number(discountRate) || 45} % off`;
      }
    }

    return example ? (
      <Alert color="warning">
        {example}
      </Alert>
    ) : null;
  };

  const maxPromotionsApplicationPerOrder = () => (
    <Col xs="12" sm={onCampaign ? "12" : "6"}>
      <Form inline>
        <FormGroup>
          <Row className="my-2">
            <Label for="maxApplicationsPerOrder" className="formTextLabel mt-1">
              {t("promotion:label_maximum_application_per_order")}
            </Label>
            <Col xs="3">
              <Input
                className="formText w-100"
                placeholder="0"
                id="maxApplicationsPerOrder"
                name="maxApplicationsPerOrder"
                type="number"
                min={0}
                disabled={isapplicationsPerOrderUnlimited}
                value={maxApplicationsPerOrder}
                onChange={handleFormChange}
                innerRef={
                  register({
                    validate: (value) => {
                      const x = parseInt(value, 10);
                      return (!isapplicationsPerOrderUnlimited && (Number.isNaN(x) || x < 1))
                        ? t("promotion:validation_max_application_per_order_invalid")
                        : true;
                    },
                  })
                }
                invalid={!!(errors.maxApplicationsPerOrder && errors.maxApplicationsPerOrder.message)}
              />
            </Col>
            <Col>
              <CustomInput
                type="checkbox"
                checked={isapplicationsPerOrderUnlimited}
                name="isapplicationsPerOrderUnlimited"
                onClick={() => handleChange("isapplicationsPerOrderUnlimited", !isapplicationsPerOrderUnlimited)}
                id="isapplicationsPerOrderUnlimited"
                className="font-weight-light small d-flex ml-1 justify-content-start "
                label={t("promotion:label_unlimited")}
              />
            </Col>
            <FormFeedback className="d-block">{errors.maxApplicationsPerOrder && errors.maxApplicationsPerOrder.message}</FormFeedback>
          </Row>
        </FormGroup>
      </Form>
    </Col>
  );

  const externalIdInput = () => (
    <Col xs="12" sm={onCampaign ? "12" : "6"}>
      <FormGroup>
        <Label className="formTextLabel">
          {t("promotion:label_promotion_external_id")}
        </Label>
        <Input
          className="formText"
          name="externalId"
          value={externalId}
          onChange={handleFormChange}
          innerRef={
            register({
              maxLength: {
                value: 252,
                message: t("common:validation_too_large"),
              },
            })
          }
          invalid={!!(errors.externalId && errors.externalId.message)}
        />
        <FormFeedback>{errors.externalId && errors.externalId.message}</FormFeedback>
      </FormGroup>
    </Col>
  );

  const fulfilmentTypeInput = () => (
    <Col xs="12">
      <div className="p-3 my-1 border rounded">
        <FormGroup>
          <Label className="formTextLabel">{t("fulfillment-method:label_fulfillment_methods")}</Label>
          <UncontrolledTooltip target="fulfilment">
            {t("promotion:tooltip_fulfilment")}
          </UncontrolledTooltip>
          <img
            src={QuestionIcon}
            alt="?"
            className="ml-2"
            id="fulfilment"
          />
          <FulfilmentTypeSelector
            name="fulfilmentTypes"
            value={Array.isArray(fulfilmentTypes) ? fulfilmentTypes : []}
            options={Array.isArray(fulfilmentTypesDetails) ? fulfilmentTypesDetails : []}
            onChange={(o) => handleChange("fulfilmentTypes", o)}
          />
        </FormGroup>
      </div>
    </Col>
  );

  const ProductOptionInput = () => (
    <Col xs="12">
      <div className={clsx(styles.productOptionSelector, "p-3 my-1 border rounded")}>
        <FormGroup>
          <Label className="formTextLabel">
            {t("common:label_product_options")}
            <span className="pl-1 text-danger">*</span>
          </Label>
          <ProductOptionSelector
            name="productOptions"
            value={Array.isArray(productOptions) ? productOptions : []}
            options={Array.isArray(productOptionDetails) ? productOptionDetails : []}
            onChange={(o) => handleChange("productOptions", o)}
            invalid={errors.productOptions && errors.productOptions.message}
          />
          <span className={clsx(styles.error)}>{errors.productOptions && errors.productOptions.message}</span>
        </FormGroup>
      </div>
    </Col>
  );

  const BonusProductPromotionInput = () => (
    <Col xs="12">
      <Row>
        <Col xs="4">
          <FormGroup>
            <Label className="formTextLabel">
              {t("promotion:label_buy")}
              <span className="pl-1 text-danger">*</span>
            </Label>
            <InputGroup>
              <Input
                className="formText"
                placeholder=""
                name="buy"
                value={buy}
                onChange={handleFormChange}
                innerRef={
                  register({
                    required: t("promotion:validation_buy"),
                    min: {
                      value: 1,
                      message: t("promotion:validation_buy_invalid"),
                    },
                    pattern: {
                      value: IS_NUMBER,
                      message: t("common:validation_is_number"),
                    },
                  })
                }
                invalid={!!(errors.buy && errors.buy.message)}
              />
              <UncontrolledTooltip target="buyX">
                {t("promotion:tooltip_bonus_product")}
              </UncontrolledTooltip>
              <InputGroupAddon
                addonType="append"
              >
                <InputGroupAddonText>
                  <img
                    src={QuestionIcon}
                    alt="?"
                    className="ml-2"
                    id="buyX"
                  />
                </InputGroupAddonText>
              </InputGroupAddon>
              <FormFeedback>{errors.buy && errors.buy.message}</FormFeedback>
            </InputGroup>
          </FormGroup>
        </Col>
        <Col xs="2" className="d-flex align-items-center justify-content-center font-500">
          <h6>
            and Get
          </h6>
        </Col>
        <Col xs="6">
          <FormGroup>
            <Label className="formTextLabel">
              {t("promotion:label_bonus_product")}
              <span className="pl-1 text-danger">*</span>
            </Label>
            <SkuSelector
              singleSelect
              name="bonusProduct"
              options={Array.isArray(bonusProductDetails) ? bonusProductDetails : []}
              value={bonusProduct}
              onChange={(o) => handleChange("bonusProduct", o)}
              innerRef={
                register({
                  required: t("promotion:validation_select_bonus_product"),
                })
              }
              invalid={!!(errors.bonusProduct && errors.bonusProduct.message)}
              validator={validator}
            />
            <FormFeedback>{errors.bonusProduct && errors.bonusProduct.message}</FormFeedback>

          </FormGroup>
        </Col>
      </Row>
    </Col>
  );

  const productBundleInput = () => (
    <Col xs="12">
      <div className="p-3 my-1 border rounded">
        <FormGroup>
          <Row>
            <Col xs="12">
              <Label className="formTextLabel">{t("promotion:label_define_bundle")}</Label>
              <span className="float-right" title="Upload Product Bundle">
                <label htmlFor="promotionBundlesFile" className="btn btn-primary ">
                  {
                    loadingFlags && loadingFlags.context === "promotionBundles" && loadingFlags.value
                      ? <Spinner size="sm" />
                      : (
                        <img
                          src={publishIcon}
                          alt="upload"
                        />
                      )
                  }
                  <input
                    name="promotionBundles"
                    id="promotionBundlesFile"
                    type="file"
                    className="d-none"
                    accept={FILE_TYPES_PROMOTION}
                    onChange={(e) => handleBulkImport(e, SHEET_CONTEXT_BUNDLE)}
                  />
                </label>
              </span>
              <span
                className="float-right mr-3 btn btn-light border text-primary"
                role="presentation"
                onClick={() => downloadBulkImportTemplate("promotionBundles", SHEET_CONTEXT_BUNDLE)}
                title="Download Template"
              >
                <img
                  src={downloadIconfrom}
                  alt="download"
                />
              </span>
            </Col>
          </Row>
          <ProductBundleSelector
            name="promotionBundles"
            value={Array.isArray(promotionBundles) ? promotionBundles : []}
            promotionBundleDetails={Array.isArray(promotionBundleDetails) ? promotionBundleDetails : []}
            onChange={(o) => handleChange("promotionBundles", o)}
            validator={validator}
          />
        </FormGroup>
        {promotionType === FIXED_AMOUNT ? (
          <div className="alert alert-warning">
            <span className="font-italic font-weight-bold">{t("promotion:bundle_promotion_msg")}</span>
          </div>
        ) : ""}
      </div>
    </Col>
  );

  return (
    <Row className={clsx("common-page-card", "pb-1")}>
      <Col xs="12" className="text-truncate pageHeader">
        <span className="pageText">{t("promotion:title_promotion_congratulation")}</span>
        <hr />
      </Col>
      <Col xs="12">
        <Row>
          <Col xs="12" sm={onCampaign ? "12" : "6"}>
            <FormGroup>
              <Label className="formTextLabel">
                {t("promotion:label_class")}
                <span className="pl-1 text-danger">*</span>
              </Label>
              <ClassTypeSelector
                value={classType}
                name="classType"
                onChange={(o) => handleChange("classType", o)}
                // validation
                validator={validator}
                message={{
                  validate: t("promotion:validation_select_classType"),
                }}
                disabled={Array.isArray(translatableFileds) ? !isDefault && !translatableFileds.includes("classType") : false}
              />
              <FormFeedback>{errors.classType && errors.classType.message}</FormFeedback>
            </FormGroup>
          </Col>
          {classType && classType === CLASS_PRODUCT
            ? (
              <Col xs="12" sm={onCampaign ? "12" : "6"}>
                <FormGroup>
                  <Label className="formTextLabel">
                    {t("promotion:label_discount_for")}
                    <span className="pl-1 text-danger">*</span>
                  </Label>
                  <DiscountTypeSelector
                    name="discountType"
                    value={discountType}
                    onChange={(o) => handleChange("discountType", o)}
                    // validation
                    validator={validator}
                    message={{
                      validate: t("promotion:validation_select_discountType"),
                    }}
                    classType={classType}
                    disabled={Array.isArray(translatableFileds) ? !isDefault && !translatableFileds.includes("discountType") : false}
                  />
                  <FormFeedback>{errors.discountType && errors.discountType.message}</FormFeedback>
                </FormGroup>
              </Col>
            )
            : ""}
          {classType && discountType && discountType !== BONUS_OPTION_PROMOTION
            ? (
              <Col xs="12" sm={onCampaign ? "12" : "6"}>
                <FormGroup>
                  <Label className="formTextLabel">
                    {t("promotion:label_discount_rule")}
                    <span className="pl-1 text-danger">*</span>
                  </Label>
                  <PromotionTypeSelector
                    name="promotionType"
                    value={promotionType}
                    onChange={(o) => handleChange("promotionType", o)}
                    // validation
                    validator={validator}
                    message={{
                      validate: t("promotion:validation_select_discountRule"),
                    }}
                    classType={classType}
                    discountType={discountType}
                    disabled={Array.isArray(translatableFileds) ? !isDefault && !translatableFileds.includes("promotionType") : false}
                  />
                  <FormFeedback>{errors.promotionType && errors.promotionType.message}</FormFeedback>
                </FormGroup>
              </Col>
            ) : ""}
        </Row>
        <div ref={refProductOptions}>
          {
            classType && discountType && promotionType && (
              <>
                <div className="border rounded p-3 mb-3">
                  <Row className="mt-3 ">
                    {
                      discountType === DISCOUNT_AMOUNT && (
                        <>
                          {
                            (classType !== CLASS_PRODUCT) ? minCartValueSelector() : ""
                          }
                          {promotionType === PERCENTAGE_OFF && percentageOffSelector()}
                          {promotionType === FLAT_AMOUNT_OFF && discountRateSelector()}
                          {promotionType === FIXED_AMOUNT && discountRateSelector()}
                          {promotionType === PERCENTAGE_OFF && maxDiscountAmountSelector()}
                        </>
                      )
                    }
                    {
                      discountType === BUY_X_GET_Y_PROMOTION && (
                        <>
                          {buySelector()}
                          {promotionType === BUY_X_GET_Y_FREE && getSelector()}
                          {promotionType === PERCENTAGE_OFF && getSelector()}
                          {promotionType === PERCENTAGE_OFF && percentageOffSelector()}
                        </>
                      )
                    }
                    {
                      discountType === BUY_X_PAY_Y_PROMOTION && (
                        <>
                          {buySelector()}
                          {promotionType === BUY_X_PAY_Y && payForSelector()}
                          {promotionType === BUY_X_FIXED_AMOUNT && forSelector()}
                          {promotionType === PERCENTAGE_OFF && percentageOffSelector()}
                        </>
                      )
                    }
                    {
                      discountType === PRODUCT_OPTION_PROMOTION && (
                        <>
                          {promotionType === PERCENTAGE_OFF && percentageOffSelector()}
                          {promotionType === PERCENTAGE_OFF && maxDiscountAmountSelector()}
                          {promotionType === PERCENTAGE_OFF && ProductOptionInput()}
                        </>
                      )
                    }
                    {
                      discountType === BONUS_OPTION_PROMOTION && (
                        <>
                          {BonusProductPromotionInput()}
                        </>
                      )
                    }
                    {
                      discountType === BUNDLE_PROMOTION && (
                        <>
                          {promotionType === PERCENTAGE_OFF && percentageOffSelector()}
                          {promotionType === FLAT_AMOUNT_OFF && discountRateSelector()}
                          {promotionType === FIXED_AMOUNT && discountRateSelector()}
                          {productBundleInput()}

                        </>
                      )
                    }
                    {classType === CLASS_ORDER ? externalIdInput() : ""}
                    {classType === CLASS_ORDER || classType === CLASS_SHIPPING
                      ? fulfilmentTypeInput()
                      : ""}
                  </Row>
                </div>
                {discountType === BUY_X_GET_Y_PROMOTION
                  || discountType === BUY_X_PAY_Y_PROMOTION
                  || discountType === BONUS_OPTION_PROMOTION
                  ? (
                    <Col xs="12">
                      {maxPromotionsApplicationPerOrder()}
                    </Col>
                  )
                  : ""}
              </>
            )
          }
        </div>
      </Col>
      <Col xs="12">
        {exampleConstructor()}
      </Col>
    </Row>
  );
};

DiscountForm.propTypes = {
  classType: PropTypes.string.isRequired,
  discountType: PropTypes.string.isRequired,
  cartValue: PropTypes.string.isRequired,
  promotionType: PropTypes.string.isRequired,
  discountRate: PropTypes.string.isRequired,
  discountAmount: PropTypes.string.isRequired,
  miniProduct: PropTypes.string.isRequired,
  buy: PropTypes.string.isRequired,
  payFor: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  onCampaign: PropTypes.bool.isRequired,
  isapplicationsPerOrderUnlimited: PropTypes.bool.isRequired,
  maxApplicationsPerOrder: PropTypes.number.isRequired,
  externalId: PropTypes.string.isRequired,
  fulfilmentTypes: PropTypes.arrayOf(PropTypes.any).isRequired,
  fulfilmentTypesDetails: PropTypes.arrayOf(PropTypes.any).isRequired,
  productOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
  productOptionDetails: PropTypes.arrayOf(PropTypes.any).isRequired,
  bonusProductDetails: PropTypes.arrayOf(PropTypes.any).isRequired,
  bonusProduct: PropTypes.string.isRequired,
  promotionBundleDetails: PropTypes.arrayOf(PropTypes.any).isRequired,
  promotionBundles: PropTypes.arrayOf(PropTypes.any).isRequired,
  loadingFlags: PropTypes.objectOf(PropTypes.any).isRequired,
  // functions
  handleChange: PropTypes.func.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  handleBulkImport: PropTypes.func.isRequired,
  downloadBulkImportTemplate: PropTypes.func.isRequired,
  // validation
  validator: PropTypes.shape({
    register: PropTypes.func,
    setError: PropTypes.func,
    clearError: PropTypes.func,
    errors: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
  // Scrollref
  refProductOptions: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  translatableFileds: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default DiscountForm;
