/**
 * Copyright(c) 2022 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Naseef O
 *
 */

import React, { useEffect, useState, useMemo } from "react";
import CacheManagement from "../components/CacheManagement";
import { deleteCacheByUrl, getCacheSettings } from "../../../api/cacheService";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import commonUtils from "../../../common/utils/commonUtils";


const CacheManagementContainer = () => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedSettings, setSelectedSettings] = useState(null);
  const [cacheSettings, setCacheSettings] = useState([]);
  const [message, setMessage] = useState({ type: null, message: "" });

  /**
 * This method is used to toggle confirmation modal
 *
 * @param {*} settings
 */
  const toggleModal = (settings) => {
    if (settings) {
      setSelectedSettings(settings);
    }
    setModal(!modal);
  };

  /**
   * This method is used for delete cache with confirmation
   */
  const confirmDelete = async () => {
    if (selectedSettings) {
      const response = await deleteCacheByUrl(selectedSettings.url);
      if (response && response.success) {
        setMessage({ type: "success", message: response.messages[0] || "" });
        setTimeout(() => {
          setMessage({ type: null, message: "" });
        }, 3000);
      } else {
        setMessage({ type: "warning", message: response.messages[0] || "Something went wrong" });
        setTimeout(() => {
          setMessage({ type: null, message: "" });
        }, 3000);
      }
      toggleModal();
    }
  };

  useEffect(() => {
    setLoading(true);
    /** Fetching cart settings */
    getCacheSettings().then((response) => {
      if (response && response.success && commonUtils.isListNotEmpty(response.data)) {
        setCacheSettings(response.data);
      }
    }).then(() => setLoading(false));
  }, []);

  const content = useMemo(() => `Are you sure you want to clear ${selectedSettings && selectedSettings.name ? selectedSettings.name.toLowerCase() : "all"} cache ?`, [selectedSettings]);

  return (
    <>
      <CacheManagement
        toggleModal={toggleModal}
        message={message}
        loading={loading}
        cacheSettings={cacheSettings}
      />
      <ConfirmationModal
        isOpen={modal}
        toggleOpen={toggleModal}
        togglClose={toggleModal}
        handleConfirm={confirmDelete}
        content={content}
      />
    </>
  );
};

export default CacheManagementContainer;
