/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Aardra S
 */
import React, { useRef } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

/** ===== TAG COMPONENTS =========== */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Button from "../../../common/core/Button";
import Table from "../../../common/core/Table";
import Alert from "../../../common/core/Alert";

/** ========= SVG IMAGE ICON ============= */
import EditIcon from "../../../common/assets/images/svg/edit.svg";
import DeleteIcon from "../../../common/assets/images/svg/delete.svg";

/** ================== MODULE STYLES ================ */
import styles from "../css/StoreManagement.module.css";


const StoreManagement = (props) => {
  const {
    addNewStore, stores, setDeleteStore, editStore, setOpenModal,
    handlePushToLive, pushToLiveEnabled, message, previewSiteUrl, previewEnabled,
    getReplicationStatus,
    pushToLiveButtonEnabled,
  } = props;


  const currentRow = useRef(null);

  const deleteStore = (storeId) => {
    setDeleteStore({ storeId });
    setOpenModal(true)
  };
  /** internationalization using i18n from common and store  */
  const { t } = useTranslation(["common", "store"]);

  const redirectToPage = () => {
    const url = `${previewSiteUrl}`;
    window.open(url, "_blank");
  };

  return (
    <Row>
      <Col xs="12">
        {
          message && message.type && (
            <Alert color={message.type}>
              {message.message}
            </Alert>
          )
        }
        <Row className="pageHeader common-page-card">
          <Col xs="12" md="5" className="text-truncate">
            <span className="pageText">
              {t("store:title_store_locator")}
            </span>
          </Col>
          <Col xs="12" md="6" lg="7" className={styles.liveBtnWrapper}>
            <Row>
              <Col xs="12" md="4" lg="4" className={styles.liveBtnWrapper}>
                {previewEnabled && (
                  <Button
                    className={clsx(styles.liveBtn, "float-right", "text-truncate", "btn-tertiary", "button-font-size ")}
                    onClick={() => redirectToPage()}
                  >
                    {t("common:button_preview")}
                  </Button>
                )}
              </Col>
              <Col xs="12" md="4" lg="4" className={styles.liveBtnWrapper}>
                {pushToLiveEnabled && (
                  <Button
                    onClick={handlePushToLive}
                    disabled={!pushToLiveButtonEnabled}
                    className={clsx(styles.liveBtn, "float-right", "text-truncate", "btn-tertiary", "button-font-size ")}
                  >
                    {t("common:button_push_to_live")}
                  </Button>
                )}
              </Col>
              <Col xs="12" md="4" lg="4" className={styles.liveBtnWrapper}>
                {pushToLiveEnabled && (
                  <Button
                    onClick={getReplicationStatus}
                    className={clsx(styles.liveBtn, "float-right", "text-truncate", "btn-tertiary", "button-font-size")}
                  >
                    {t("common:button_refresh_status")}
                  </Button>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col xs="12">
        <Row className={clsx(styles.block1, "common-page-card")}>
          <Col xs="8">
            <span
              className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}
            >
              {t("store:title_manage_store")}
            </span>
          </Col>
          <Col xs="4">
            <Button onClick={addNewStore} className={clsx(styles.buttonNewStore, "text-uppercase bg-color-tertiary-main")}>
              {t("store:title_add")}
            </Button>
          </Col>
          <Col xs="12" md="4" lg="5" />
        </Row>
      </Col>
      <Col>
        <Row className={clsx(styles.block3, styles.tableWrapper, " common-page-card")}>
          <Col>
            <div className={styles.store}>
              <div
                className={clsx(styles.storeBody, "table-responsive")}
              >
                <Table borderless className={styles.storeTable}>
                  {/* ========= TABLE HEAD ======================= */}
                  <thead>
                    <tr>
                      <th>{t("store:label_id")}</th>
                      <th>{t("store:label_name")}</th>
                      {/* <th>{t("store:label_address")}</th> */}
                      <th>{t("store:table_title_edit")}</th>
                      <th>{t("store:table_title_delete")}</th>
                    </tr>
                  </thead>
                  {/* ========= TABLE HEAD  ======================= */}
                  {/* ========= TABLE BODY  ======================= */}
                  <tbody>
                    {
                      stores.length > 0
                        ? stores.map(({
                          id, storeId, name,
                        }) => (

                          <tr key={id} ref={currentRow}>
                            <td>
                              <span id={id} className={"text-truncate", "d-block"}>
                                {storeId}
                              </span>
                            </td>
                            <td>{name}</td>
                            {/* <td>{contactInfo}</td> */}
                            <td>
                              <span
                                onClick={(o) => editStore(id, o)}
                                onKeyDown={() => { }}
                                tabIndex={0}
                                role="button"
                                className="commonPointer"
                              >
                                <img
                                  className={clsx("commonPointer", styles.icon)}
                                  src={EditIcon}
                                  alt="Edit"
                                />
                              </span>
                            </td>
                            <td>
                              <span className="commonPointer" onClick={(o) => deleteStore(storeId)} role="button" tabIndex={0} onKeyPress={() => { }}>
                                <img
                                  className={clsx("commonPointer", styles.icon)}
                                  src={DeleteIcon}
                                  alt="Delete"
                                />
                              </span>
                            </td>
                          </tr>
                        )) : (
                          <tr>
                            <td colSpan={8} align="center" valign="center">
                              {" "}
                              {t("store:message_no_data")}
                            </td>
                          </tr>
                        )
                    }
                  </tbody>
                  {/* ========= TABLE BODY ======================= */}
                </Table>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

StoreManagement.propTypes = {
  addNewStore: PropTypes.func.isRequired,
  stores: PropTypes.arrayOf(PropTypes.any).isRequired,
  setDeleteStore: PropTypes.func.isRequired,
  editStore: PropTypes.func.isRequired,
  pushToLiveEnabled: PropTypes.bool.isRequired,
  previewEnabled: PropTypes.bool.isRequired,
  previewSiteUrl: PropTypes.string.isRequired,
  handlePushToLive: PropTypes.func.isRequired,
  message: PropTypes.objectOf(PropTypes.any).isRequired,
  getReplicationStatus: PropTypes.func.isRequired,
  pushToLiveButtonEnabled: PropTypes.bool.isRequired,
};

export default StoreManagement;
