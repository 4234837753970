/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * Pagination
 *
 * @author Naseef O
 */

import React from "react";
import PropTypes from "prop-types";

import Pagination from "../core/Pagination";
import PaginationItem from "../core/PaginationItem";
import PaginationLink from "../core/PaginationLink";

/**
 * This is the numbered item component
 * @param {Object} param {
 * {Number} index
 * {Function} onClick
 * {active} Boolean
 * @returns {Component}
 */
const PaginationItem1 = ({ index, onClick, active }) => (
  <PaginationItem
    key={index}
    className={` pagination-each page-item ${active && "active"}`}
    onClick={onClick}
    onKeyDown={() => { }}
    tabIndex={0}
    role="button"
  >
    <PaginationLink first>
      {index + 1}
    </PaginationLink>
  </PaginationItem>

);

PaginationItem1.propTypes = {
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

/**
 * This component is the pagination container
 */
const PaginationAlpha = ({
  limit, getPageData, page, totalCount,
}) => {
  const itemsAtAtime = 5;
  const totalPages = Math.ceil(totalCount / limit);
  const paginationStart = 0;
  const paginationEnd = totalPages
    && !limit ? totalPages : Math.ceil(totalCount / limit);

  /**
   * This method is used to switch to the selected page
   */
  const getSelected = async (selectedPage) => {
    await getPageData(selectedPage, (selectedPage + 1) * limit);
  };

  /**
   * This method is used to switch to the selected page
   */
  const next = async () => {
    await getPageData(page + 1, (page + 2) * limit);
  };

  /**
   * This method is used to switch to the selected page
   */
  const prev = () => {
    getPageData(page - 1, ((page + 1) - 1) * limit);
  };

  /**
   * This method is used to render pagination tabs
   * @param {Number} start
   * @param {Number} end
   * @param {Number} totalPagesIn
   */
  const paginationItem = (start, end, totalPagesIn) => {
    const indents = [];
    let localStart = start;

    localStart = Math.max(start, page);
    const expectedEnd = localStart + itemsAtAtime;
    const localHeader = Math.min(expectedEnd, end, totalPagesIn);
    localStart = Math.max(0, localHeader - itemsAtAtime);

    for (let i = localStart; i <= localHeader && i < end; i += 1) {
      indents.push(
        <PaginationItem1
          key={i}
          active={page === i}
          onClick={() => page !== i && getSelected(i)}
          index={i}
        />,
      );
    }
    return indents;
  };

  return (
    <>
      {totalPages > 1
        && (
          <Pagination className="custom-pagination d-flex justify-content-end">
            <PaginationItem
              onClick={() => page > 0 && getSelected(0)}
              role="presentation"
              disabled={page < 1}
            >
              <PaginationLink first>
                First
              </PaginationLink>
            </PaginationItem>
            <PaginationItem
              onClick={() => page > 0 && prev(page)}
              role="presentation"
              disabled={page < 1}
            >
              <PaginationLink previous>
                {"<<"}
              </PaginationLink>
            </PaginationItem>
            {paginationItem(paginationStart, paginationEnd, totalPages)}
            <PaginationItem
              onClick={() => (page + 1 === paginationEnd ? null : next(page))}
              role="presentation"
              disabled={page + 1 === paginationEnd}
            >
              <PaginationLink next>
                {">>"}
                <i className="icon-jumptoright ml-1" />
              </PaginationLink>
            </PaginationItem>
            <PaginationItem
              onClick={() => (page + 1 === paginationEnd ? null : getSelected(totalPages - 1))}
              role="presentation"
              disabled={page + 1 === paginationEnd}
            >
              <PaginationLink last>
                Last
              </PaginationLink>
            </PaginationItem>
          </Pagination>
        )}
    </>
  );
};

PaginationAlpha.defaultProps = {
  limit: 0,
};

PaginationAlpha.propTypes = {
  getPageData: PropTypes.func.isRequired,
  limit: PropTypes.number,
  page: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
};
export default PaginationAlpha;
