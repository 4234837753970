/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * @author Aardra S
 *
 */
import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ChromePicker } from "react-color";
import ProductSelector from "../../productManagement/containers/SimpleProductSelectorContainer";

/** ========= TAG COMPONENTS ========= */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Button from "../../../common/core/Button";
import Form from "../../../common/core/Form";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import Input from "../../../common/core/Input";
import Alert from "../../../common/core/Alert";
import FormFeedback from "../../../common/core/FormFeedback";
import validateForm from "../../../common/utils/validateForm";

/** ========= MODULE STYLES ========= */
import styles from "../css/EditBadge.module.css";
import LocaleDropdownContainer from "../../LocaleDropdown/containers/LocaleDropdownContainer";

const EditBadge = (props) => {
  const {
    form,
    products,
    productsDetails,
    handleChange,
    handleFormChange,
    formCancel,
    message,
    onSubmitting,
    onSubmit,
    handleChangeFontColor,
    handleChangeBackgroundColor,
    translatableFileds,
    localeHandler,
    isDefault,
  } = props;
  const [isFontOpen, setIsFontOpen] = useState(false);
  const [isBackgroundOpen, setIsBackgroundOpen] = useState(false);

  const toggleFontColorPicker = () => setIsFontOpen(!isFontOpen);
  const toggleBackgroundColorPicker = () => setIsBackgroundOpen(!isBackgroundOpen);

  /** internationalization using i18n from common and coupon  */
  const { t } = useTranslation(["common", "badge"]);
  const validator = validateForm();
  const { register, handleSubmit, errors } = validator;
  const popover = {
    position: "absolute",
    zIndex: "2",
  };
  const cover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  };

  return (
    <>
      <Row>
        <Col xs="12">
          <Form onSubmit={handleSubmit(onSubmit)}>
            {message && message.type && (
              <Alert color={message.type}>{message.message}</Alert>
            )}
            <fieldset disabled={onSubmitting}>
              <Row className="pageHeader common-page-card">
                <Col xs="12" md="7" className="text-truncate">
                  <span className="pageText">{t("badge:title_edit")}</span>
                </Col>
                <Col xs="12" md="5" className="d-flex justify-content-end">
                  <Button
                    onClick={formCancel}
                    type="button"
                    color="secondary"
                    className={clsx(
                      styles.borderRadius2,
                      styles.btn,
                      styles.secondaryColor,
                      "text-truncate",
                      "mr-1",
                    )}
                  >
                    {t("common:button_cancel")}
                  </Button>
                  <Button
                    type="submit"
                    color="secondary"
                    className={clsx(
                      styles.borderRadius2,
                      styles.btn,
                      styles.primaryColor,
                      "text-truncate",
                      "mr-1",
                      "button-font-size",
                    )}

                  >
                    {t("common:button_update")}
                  </Button>
                </Col>
              </Row>
              {/*  ====== EDIT COUPON FORM ============ */}
              <Row className=" common-page-card">
                <Col xs="12">
                  <LocaleDropdownContainer
                    entityName="badge"
                    isIndocument
                    localeHandler={localeHandler}
                  />
                </Col>
              </Row>
              <Row
                className={clsx(
                  styles.blockWrapper,
                  " common-page-card",
                  "pb-0",
                )}
              >
                <Col xs="12" lg="10">
                  <div className="common-page-card">
                    <Row>
                      <Col xs="12" sm="6">
                        <FormGroup>
                          <Label className="formTextLabel">
                            {t("badge:label_badge_primary_text")}
                          </Label>
                          <span className="pl-1 text-danger">*</span>
                          <Input
                            className="formText"
                            placeholder={t("coupon:placeholder_code")}
                            name="primaryText"
                            value={form.primaryText}
                            onChange={handleFormChange}
                            innerRef={register({
                              required: "Please enter a coupon code",
                              minLength: {
                                value: 3,
                                message: "Please enter a coupon code",
                              },
                            })}
                            disabled={Array.isArray(translatableFileds) ? !isDefault && !translatableFileds.includes("primaryText") : false}
                            invalid={!!(errors.code && errors.code.message)}
                          />
                          <FormFeedback>
                            {errors.code && errors.code.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col xs="12" sm="6">
                        <FormGroup>
                          <Label className="formTextLabel">
                            {t("badge:label_badge_secondary_text")}
                          </Label>
                          <Input
                            className="formText"
                            placeholder={t("coupon:placeholder_description")}
                            name="secondaryText"
                            value={form.secondaryText}
                            onChange={handleFormChange}
                            disabled={Array.isArray(translatableFileds) ? !isDefault && !translatableFileds.includes("secondaryText") : false}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" sm="6">
                        <FormGroup className={clsx(styles.inputTag)}>
                          <Label className="formTextLabel">
                            {/* {t("badge:label_badge_font_color")} */}
                            Font Color
                          </Label>
                          <span className="pl-1 text-danger">*</span>
                          <Input
                            placeholder={t("coupon:placeholder_code")}
                            name="code"
                            value={form.fontColor}
                            onClick={toggleFontColorPicker}
                            onChange={handleChangeFontColor}
                            innerRef={register({
                              required: "Please enter a coupon code",
                              minLength: {
                                value: 3,
                                message: "Please enter a coupon code",
                              },
                            })}
                            invalid={!!(errors.code && errors.code.message)}
                            disabled={Array.isArray(translatableFileds) ? !isDefault && !translatableFileds.includes("fontColor") : false}
                          />
                          <FormFeedback>
                            {errors.code && errors.code.message}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col xs="12" sm="6">
                        <FormGroup>
                          <Label className="formTextLabel">
                            {t("badge:label_badge_background_color")}
                          </Label>
                          <Input
                            placeholder={t("coupon:placeholder_code")}
                            name="code"
                            value={form.backgroundColor}
                            onClick={toggleBackgroundColorPicker}
                            onChange={handleChangeBackgroundColor}
                            innerRef={register({
                              required: "Please enter a coupon code",
                              minLength: {
                                value: 3,
                                message: "Please enter a coupon code",
                              },
                            })}
                            disabled={Array.isArray(translatableFileds) ? !isDefault && !translatableFileds.includes("backgroundColor") : false}
                            invalid={!!(errors.code && errors.code.message)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" sm="6">
                        {isFontOpen ? (
                          <div style={popover}>
                            <div
                              style={cover}
                              onClick={toggleFontColorPicker}
                            />
                            <ChromePicker
                              color={form.fontColor}
                              className={clsx(styles.colorPicker)}
                              onChange={handleChangeFontColor}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col xs="12" sm="6">
                        {isBackgroundOpen ? (
                          <div style={popover}>
                            <div
                              style={cover}
                              onClick={toggleBackgroundColorPicker}
                            />
                            <ChromePicker
                              color={form.backgroundColor}
                              className={clsx(styles.colorPicker)}
                              onChange={handleChangeBackgroundColor}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" sm="12">
                        <FormGroup>
                          <Label className="formTextLabel">
                            Selected products
                          </Label>
                          <ProductSelector
                            name="products"
                            value={Array.isArray(products) ? products : []}
                            options={
                              Array.isArray(productsDetails)
                                ? productsDetails
                                : []
                            }
                            onChange={(o) => handleChange("applicableProducts", o)}
                            disabled={Array.isArray(translatableFileds) ? !isDefault && !translatableFileds.includes("products") : false}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              {/*  ====== Advanced Settings Edit============ */}
            </fieldset>
          </Form>
        </Col>
      </Row>
    </>
  );
};

EditBadge.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  products: PropTypes.arrayOf(PropTypes.any).isRequired,
  productsDetails: PropTypes.arrayOf(PropTypes.any).isRequired,
  message: PropTypes.objectOf(PropTypes.any).isRequired,
  onSubmitting: PropTypes.bool.isRequired,
  updateBadge: PropTypes.func.isRequired,
  handleChangeFontColor: PropTypes.func.isRequired,
  handleChangeBackgroundColor: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  formCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  localeHandler: PropTypes.func.isRequired,
  translatableFileds: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default EditBadge;
