/**
 * Copyright(c) 2022 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * SellerMessaging Container
 *
 * @author Krishnanunny H
 *
 */

import React, { useState, useEffect } from "react";
import ConfirmationModal from "../../../common/components/ConfirmationModal";
import SellerMessaging from "../components/SellerMessaging";

/** ===== API SERVICE FUNCTIONS =========== */
import { getThreads, block } from "../../../api/messagingServices";

import constants from "../../../common/utils/constants";

const {
  SELLER_MESSAGING: { CONVERSATION_DATE_FROM, CONVERSATION_DATE_TO }, DATE_FORMAT_WITH_HYPHEN,
} = constants;

const moment = require("moment");
const Datetime = require("react-datetime");

const SellerMessagingContainer = () => {
  /** state variables */
  const [threads, setThreads] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [paginationLimit, setPaginationLimit] = useState(10);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [thread, setThread] = useState("");
  const [enabled, setEnabled] = useState(null);

  const [pagination, setPagination] = useState({
    page: 0,
    totalPages: 0,
    totalCount: 0,
    limit: 10,
    numberOfElements: 0,
  });

  const onHandleSearch = (newPage) => {
    const searchData = {
      startDate: fromDate
        ? moment(fromDate).format("YYYY-MM-DD HH:mm:ss")
        : null,
      endDate: toDate
        ? moment(toDate).endOf("day").format("YYYY-MM-DD HH:mm:ss")
        : null,
      searchTerm: searchTerm ? searchTerm.trim() : "",
      threadBlocked: enabled,
    };
    const pageLimit = Math.max(newPage, 0);
    const size = paginationLimit;
    getThreads(searchData, pageLimit, size).then((response) => {
      if (response && response.success === true) {
        const {
          data: {
            content, totalPages, totalElements, endOfResult,
          },
        } = response;
        if (Array.isArray(content)) {
          setThreads(content);
          setPagination({
            ...pagination,
            page: newPage,
            totalPages,
            totalCount: totalElements,
            numberOfElements: !endOfResult
              ? pagination.limit * (newPage + 1)
              : totalElements,
          });
          setHasMoreData(true);
        } else {
          newPage = page;
          setHasMoreData(false);
        }
        setPage(Math.max(newPage, 0));
      }
    });
  };

  useEffect(() => {
    onHandleSearch(0);
  }, [fromDate, toDate, paginationLimit, searchTerm, enabled]);

  const initializeData = async () => {
    setFromDate("");
    setToDate("");
    setSearchTerm("");
    setPage(0);
    setPaginationLimit(10);
    setHasMoreData(true);
    setEnabled(null);
  };

  /**
    * This function returns the true or false to disable the previous and future dates in datepicker
    */
  const isValidDate = (date, type) => {
    if (type === CONVERSATION_DATE_FROM) {
      if (toDate) {
        return date.isBefore(moment(toDate, DATE_FORMAT_WITH_HYPHEN).add(1, "days"));
      }
      return date.isBefore(Datetime.moment());
    }
    if (type === CONVERSATION_DATE_TO) {
      if (fromDate) {
        return (
          date.isSameOrAfter(moment(fromDate, DATE_FORMAT_WITH_HYPHEN))
           && date.isSameOrBefore(Datetime.moment())
        );
      }
      return date.isSameOrBefore(Datetime.moment());
    }
  };

  const onHandleClear = () => {
    initializeData();
  };

  /**
   * This method is used to cancel confirm form
   */
  const cancelConfirm = () => {
    setOpenConfirmModal(false);
  };

  /**
   * This method is used to block the thread
   */
  const blockThread = () => {
    block(thread).then((response) => {
      if (response && response.success && response.data) {
        onHandleSearch(pagination.page);
        setOpenConfirmModal(false);
      }
    });
  };

  /**
   * This method is used black
   */
  const confirmBlocking = (threadId) => {
    setThread(threadId);
    setOpenConfirmModal(true);
  };


  return (
    <>
      <SellerMessaging
        setFromDate={setFromDate}
        setToDate={setToDate}
        fromDate={fromDate}
        toDate={toDate}
        setSearchTerm={setSearchTerm}
        onHandleSearch={onHandleSearch}
        threads={threads}
        page={page}
        hasMoreData={hasMoreData}
        isValidDate={isValidDate}
        onHandleClear={onHandleClear}
        searchTerm={searchTerm}
        confirmBlocking={confirmBlocking}
        setEnabled={setEnabled}
        pagination={pagination}
        enabled={enabled}
      />
      <ConfirmationModal
        isOpen={openConfirmModal}
        toggleOpen={cancelConfirm}
        togglClose={cancelConfirm}
        handleConfirm={blockThread}
        content="Please note: once a conversation is disabled, it cannot be enabled again"
      />
    </>
  );
};

export default SellerMessagingContainer;
