/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Naseef O
 */
import httpApi from "./httpApi";

/**
 * This method get offers by product Id
 * @param {String} productId
 * @returns {Object}
 */
const getOffersByProductId = async (productId, page = 0, size = 5) => {
  const response = await httpApi.get(`v1/offers/product/${productId}`, {
    params: { page, size },
  });
  if (response && response.data) {
    return response.data;
  }
  return null;
};


export default {
  getOffersByProductId,
};
