// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n/**\n * Copyright(c) 2021 Mozanta Technologies Private Ltd.\n *\n * All rights reserved.\n *\n * This software is the confidential and proprietary information of Mozanta\n * (\"Confidential Information\"). You shall not disclose such Confidential\n * Information and shall use it only in accordance with the terms of the\n * contract agreement you entered into with Mozanta.\n *\n * Table.module.css\n *\n * @author Naseef O\n *\n */\n.Table_marketplaceTable__2CP27 th .Table_tableId__3Agf0 {\n\twidth: 10px;\n}\n.Table_categoryChip__haLdn {\n\tdisplay: flex;\n\tpadding: 0 25px;\n\tborder-radius: 25px;\n\tmargin-bottom: 10px;\n\tmargin-left: 10px;\n\tbackground-color: #f1f1f1;\n}\n.Table_successChip__PPY3k {\n  display: flex;\n  padding: 0 11px;\n  border-radius: 25px;\n  width: -moz-fit-content;\n  width: fit-content;\n  color:white;\n\tbackground-color:#28a745 ;\n  font-size: 12px;\n}\n.Table_warningChip__1zlgT {\n  display: flex;\n  padding: 0 9px;\n  font-size: 12px;\n  border-radius: 25px;\n  width: -moz-fit-content;\n  width: fit-content;\n  color: white;\n  background-color: #ff8737;\n}\n.Table_viewAllChip__2f51b {\n  font-size: 12px;\n  display: flex;\n  align-items: center;\n  padding: 0 25px;\n  border-radius: 25px;\n  margin-bottom: 10px;\n  margin-left: 10px;\n  cursor:pointer;\n  background-color: #ffecec;\n}\n.Table_showLineage__1HbvF {\n  font-size: 12px;\n  display: flex;\n  align-items: center;\n  padding: 0 10px;\n  border-radius: 25px;\n  cursor:pointer;\n  width: -moz-fit-content;\n  width: fit-content;\n  background-color: #faecec;\n}\n.Table_disabled__2nWs5{\n  opacity: 0.3;\n  cursor: progress;\n}\n.Table_date__2dkgZ{\n  font-size: 10px;\n  color: #756977;\n}\n\n.Table_warningMsg__3gZcp{\n color: #ffbb33;\n font-size: 10px;\n}\n.Table_spaceWrap__3OA-y{\n  width: 20px;\n  height: 20px;\n}\n.Table_lineageText__1ec0c{\n  color: #808080;\n  font-size: 10px;\n}", ""]);
// Exports
exports.locals = {
	"marketplaceTable": "Table_marketplaceTable__2CP27",
	"tableId": "Table_tableId__3Agf0",
	"categoryChip": "Table_categoryChip__haLdn",
	"successChip": "Table_successChip__PPY3k",
	"warningChip": "Table_warningChip__1zlgT",
	"viewAllChip": "Table_viewAllChip__2f51b",
	"showLineage": "Table_showLineage__1HbvF",
	"disabled": "Table_disabled__2nWs5",
	"date": "Table_date__2dkgZ",
	"warningMsg": "Table_warningMsg__3gZcp",
	"spaceWrap": "Table_spaceWrap__3OA-y",
	"lineageText": "Table_lineageText__1ec0c"
};
module.exports = exports;
