/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Sreelekshmi
 */

import React from "react";
import PropTypes from "prop-types";
import Modal from "../../../common/core/Modal";
import ModalBody from "../../../common/core/ModalBody";
import ModalHeader from "../../../common/core/ModalHeader";
import ModalFooter from "../../../common/core/ModalFooter";

const CartLimitUploadStatusModal = (props) => {
  const {
    isOpen,
    togglClose,
    content,
    header,
    failedSKUs,
  } = props;
  return (
    <>
      <Modal modalClassName="modalWrapper" isOpen={isOpen}>
        <ModalHeader>
          {header}
        </ModalHeader>
        <ModalBody>
          {content}
          {Array.isArray(failedSKUs) && failedSKUs.length > 0 && failedSKUs.map((invalidSKU) => (
            <span>
              {" "}
              {invalidSKU}
              {" "}
            </span>
          ))
          }
        </ModalBody>
        <ModalFooter>
          <button type="button" className="secondaryButton" onClick={togglClose}>Ok</button>
        </ModalFooter>
      </Modal>
    </>
  );
};

CartLimitUploadStatusModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  togglClose: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  failedSKUs: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default CartLimitUploadStatusModal;
