/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * ProductDetails
 *
 * @author Naseef O
 */

import clsx from "clsx";
import React, { useState } from "react";
import PropTypes from "prop-types";

import Row from "../../../common/core/Row";
import Button from "../../../common/core/Button";
import NavItem from "../../../common/core/NavItem";
import TabContent from "../../../common/core/TabContent";
import TabPane from "../../../common/core/TabPane";
import Nav from "../../../common/core/Nav";
import NavLink from "../../../common/core/NavLink";
import Col from "../../../common/core/Col";
import Spinner from "../../../common/core/Spinner";

import warningIcon from "../../../common/assets/images/svg/warning-mui.svg";

import OffersTable from "./OffersTable";
import Image from "./Image";
import VariantsContainer from "../containers/VariantsContainer";
import LocaleDropdownContainer from "../../LocaleDropdown/containers/LocaleDropdownContainer";

import styles from "../css/ProductDetails.module.css";

import { matchYoutubeUrl, youtubeThumbnailExtractor } from "../../../common/utils/imageUtils";
import { getProductType } from "../../../common/utils/commonUtils";

const ProductDetails = (props) => {
  const {
    product,
    selectedVariant,
    toggleVariant,
    goBack,
    loading,
    offers,
    getPageData,
    pagination,
    localeHandler,
    loadingOffers,
    priceAndInventoriesLoading,
  } = props;

  const [activeTab, setActiveTab] = useState("1");

  /**
   * This method is used to get primary Parent Category
   *
   * @param {String} categoryId
   * @param {Array} categories
   * @return {String}
   */
  const getPrimaryParentCategory = (categoryId, categories) => {
    let primaryCat = "";
    if (categoryId && Array.isArray(categories) && categories.length > 0) {
      const category = categories.find((item) => item.id === categoryId);
      primaryCat = category ? `${category.name}[${categoryId}]` : "";
    }
    return primaryCat;
  };

  /**
   * This method is used to toggle tab
   *
   * @param {String} tab
   */
  const toggleTab = (tab) => {
    setActiveTab(tab);
  };

  return (
    <Row>
      <Col xs="12">
        <Row className={clsx(styles.block1, "common-page-card justify-content-between align-items-center")}>
          <Col xs="auto" className="text-truncate d-flex  align-items-center">
            <span className={styles.pageTitle}>
              {product ? product.id : ""}
            </span>
          </Col>
          <Col xs="auto" className="d-flex justify-content-between align-items-center ">
            <LocaleDropdownContainer localeHandler={localeHandler} entityName="product" isIndocument={false} />
            <Button className="common-primary-button ml-3" role="presentation" onClick={() => goBack()}>
              Back
            </Button>
          </Col>
        </Row>
        {!loading && product ? (
          <>
            <Row
              className={clsx(
                styles.block2, "common-page-card justify-content-between align-items-center",
              )}
            >
              <Col xs="auto" className={styles.product}>
                <div className="d-flex align-items-center ">
                  <span className={clsx(styles.text1)}>Name: </span>
                  <span className={styles.textName2}>{product.name || "Not available"}</span>
                </div>
                <div className="d-flex ">
                  <span className={clsx(styles.text1)}>External Id: </span>
                  <span className={styles.text2}>{product.uniqueId || "Not available"}</span>
                </div>
                <div className="d-flex ">
                  <span className={clsx(styles.text1)}>Marketplace Id: </span>
                  <span className={styles.text2}>{product.marketPlaceProductId || "Not available"}</span>
                </div>
              </Col>
              <Col xs="auto">
                {Array.isArray(product.medias)
                  && product.medias.length > 0
                  && product.medias[0]
                  && (
                    <Image
                      width="90px"
                      alt={product.name}
                      className="img-thumbnail"
                      src={product.medias[0].mediaThumbnailUrl || product.medias[0].defaultMediaUrl}
                    />
                  )}
              </Col>
            </Row>
            <Nav tabs className="border-0">
              <NavItem
                active={activeTab === "1"}
                className={clsx(styles.navLinkWrapper, "cursor-pointer", "text-center", activeTab === "1" ? "bg-white" : " bg-light")}
              >
                <NavLink
                  className="border-0"
                  onClick={() => { toggleTab("1"); }}
                >
                  Details
                </NavLink>
              </NavItem>
              <NavItem
                active={activeTab === "2"}
                className={clsx(styles.navLinkWrapper, "cursor-pointer", "text-center", activeTab === "2" ? "bg-white" : " bg-light")}
              >
                <NavLink
                  className="border-0"
                  onClick={() => { toggleTab("2"); }}
                >
                  External attributes
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1" className="p-0">
                <Row className="common-page-card justify-content-between align-items-center">
                  <Col xs="auto" className={styles.product}>
                    <div className="d-flex ">
                      <span className={clsx(styles.text1)}>Type: </span>
                      <span className={styles.text2}>{getProductType(product)}</span>
                    </div>
                    <div className="d-flex ">
                      <span className={clsx(styles.text1)}>Active: </span>
                      <span className={styles.text2}>{product.active ? "Yes" : "No"}</span>
                    </div>
                    <div className="d-flex ">
                      <span className={clsx(styles.text1)}>Seller restriction: </span>
                      <span className={styles.text2}>{product.sellerRestriction ? "Yes" : "No"}</span>
                    </div>
                    <div className="d-flex ">
                      <span className={clsx(styles.text1)}>Description: </span>
                      <span className={styles.text2}>
                        {product.description || "Not available"}
                      </span>
                    </div>
                    <div className="d-flex ">
                      <span className={clsx(styles.text1)}>Long Description: </span>
                      <span className={styles.text2}>
                        {product.longDescription || "Not available"}
                      </span>
                    </div>
                    <div className="d-flex ">
                      <span className={clsx(styles.text1)}>Brand: </span>
                      <span className={styles.text2}>
                        {product.productBrand
                          ? `${product.brandName} [${product.productBrand}]`
                          : "Not available"}
                      </span>
                    </div>
                    <div className="d-flex ">
                      <span className={clsx(styles.text1)}>Primary category: </span>
                      <span className={styles.text2}>
                        {Array.isArray(product.parentCategoryDetails)
                          && product.parentCategoryDetails.length > 0
                          ? (
                            <span>
                              {getPrimaryParentCategory(
                                product.primaryParentCategory,
                                product.parentCategoryDetails,
                              )}
                            </span>
                          )
                          : "Not available"}
                      </span>
                    </div>
                    <div className="d-flex ">
                      <span className={clsx(styles.text1)}>Parent categories: </span>
                      <span className={styles.text2}>
                        {Array.isArray(product.parentCategoryDetails)
                          && product.parentCategoryDetails.length > 0
                          ? product.parentCategoryDetails.map((category, index) => (
                            <span key={`product-cat-${index + 1}`}>
                              {`${category.name} [${category.id}]${(index) < product.parentCategoryDetails.length - 1 ? ", " : ""}`}
                            </span>
                          )) : "Not available"}
                      </span>
                    </div>
                    <div className="d-flex ">
                      <span className={clsx(styles.text1)}>Product options: </span>
                      <span className={styles.text2}>
                        {Array.isArray(product.productOptions) && product.productOptions.length > 0
                          ? (
                            <span>
                              {Array.isArray(product.productOptions) ? product.productOptions.join(", ") : null}
                            </span>
                          )
                          : "Not available"}
                      </span>
                    </div>
                    <div className="d-flex ">
                      <span className={clsx(styles.text1)}>Landing page URL: </span>
                      <span className={styles.text2}>
                        {product.landingPageBaseUrl || ""}
                      </span>
                    </div>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2" className="p-0">
                <Row className="common-page-card">
                  <Col xs="auto" className={styles.product}>
                    {product.retailerExtensions
                      && Object.entries(product.retailerExtensions).map(([key, value]) => typeof value === "string" && (
                        <div className="d-flex " key={`product-eternal-attributes-${key}`}>
                          <span className={clsx(styles.text1)}>
                            {`${key}:`}
                          </span>
                          <span className={styles.text2}>{value}</span>
                        </div>
                      ))}
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
            {priceAndInventoriesLoading
              ? (
                <Row className={clsx(styles.spinnerWrapper2, "common-page-card")}>
                  <Col xs="12" className={clsx(styles.color, "d-flex justify-content-center align-items-center")}>
                    <Spinner size="sm" />
                  </Col>
                </Row>
              )
              : (product.priceInfo
                && (
                  <Row className="common-page-card justify-content-between align-items-center">
                    <Col xs="auto" className={styles.product}>
                      <div className={clsx(styles.title, "d-flex mb-2")}>
                        Price
                      </div>
                      <div className="d-flex ">
                        <span className={clsx(styles.text1)}>List price: </span>
                        <span className={styles.text2}>{`${product.priceInfo.listPrice} ${product.priceInfo.currencyCode || "AED"}`}</span>
                      </div>
                      <div className="d-flex ">
                        <span className={clsx(styles.text1)}>Sale price: </span>
                        <span className={styles.text2}>{`${product.priceInfo.salePrice} ${product.priceInfo.currencyCode || "AED"}`}</span>
                      </div>
                    </Col>
                  </Row>
                ))}

            {priceAndInventoriesLoading
              ? (
                <Row className={clsx(styles.spinnerWrapper2, "common-page-card")}>
                  <Col xs="12" className={clsx(styles.color, "d-flex justify-content-center align-items-center")}>
                    <Spinner size="sm" />
                  </Col>
                </Row>
              )
              : (product.inventory
                && (
                  <Row className="common-page-card justify-content-between align-items-center">
                    <Col xs="auto" className={styles.product}>
                      <div className={clsx(styles.title, "d-flex mb-2")}>
                        Inventory
                      </div>
                      <div className="d-flex ">
                        <span className={clsx(styles.text1)}>Quantity: </span>
                        <span className={styles.text2}>{`${product.inventory.quantity}`}</span>
                      </div>
                      <div className="d-flex ">
                        <span className={clsx(styles.text1)}>Perpetual: </span>
                        <span className={styles.text2}>{`${product.inventory.perpetual}`}</span>
                      </div>
                      <div className="d-flex ">
                        <span className={clsx(styles.text1)}>Status: </span>
                        <span className={styles.text2}>{`${product.inventory.status}`}</span>
                      </div>
                    </Col>
                  </Row>
                ))}
            <Row className="common-page-card justify-content-between align-items-center">
              <Col xs="auto" className={styles.product}>
                <div className={clsx(styles.title, "d-flex mb-2")}>
                  SEO Info
                </div>
                <div className="row">
                  <div className={clsx("col-12", styles.text1)}>Page title: </div>
                  <div className={clsx("col-12", styles.text2)}>
                    {product.pageTitle || ""}
                  </div>
                </div>
                <div className="row">
                  <div className={clsx("col-12", styles.text1, "")}>Meta description: </div>
                  <div className={clsx("col-12", styles.text2)}>
                    {product.metaDescription || ""}
                  </div>
                </div>
              </Col>
            </Row>
            {Array.isArray(product.medias)
              && product.medias.length > 0
              && (
                <Row className="common-page-card justify-content-between align-items-center">
                  <Col xs="auto" className={styles.product}>
                    <div className={clsx(styles.title, "d-flex mb-3")}>
                      Medias
                    </div>
                    <div className="row">
                      {product.medias.map((media, index) => (
                        <Col key={`product-media-${index + 1}`} xs="auto" className="d-flex align-items-center  my-2">
                          {!matchYoutubeUrl(media.largeMediaUrl) ? (
                            <Image
                              width="100px"
                              alt={product.name}
                              src={media.mediaThumbnailUrl || media.defaultMediaUrl}
                            />
                          )
                            : (
                              <Image
                                width="100px"
                                src={`https://img.youtube.com/vi/${youtubeThumbnailExtractor(media.largeMediaUrl)}/0.jpg`}
                                alt={product.name}
                              />
                            )}
                        </Col>
                      ))}
                    </div>
                  </Col>
                </Row>
              )}
            <Row className="common-page-card">
              <Col xs="12">
                <span className="d-flex justify-content-between align-items-center">
                  <span className={styles.title}>Offers</span>
                  {!product.vendorFulfilled && product.sellerRestriction
                    && (
                      <span className={clsx(styles.warningMsg, "d-flex align-items-center")}>
                        <img src={warningIcon} width="25px" className="pr-2" alt="warning" />
                        This product is currently restricted for sellers
                      </span>
                    )}
                </span>
                <OffersTable
                  offers={offers}
                  getPageData={getPageData}
                  loading={loadingOffers}
                  pagination={pagination}
                />
              </Col>
            </Row>
            <Row className="common-page-card">
              <Col xs="12" className={styles.variants}>
                <span className={clsx(styles.title)}>Variants</span>
                <div className="my-3">
                  {Array.isArray(product.variants)
                    && product.variants.length > 0
                    ? product.variants.map((variant) => variant.id !== product.id && (
                      <VariantsContainer
                        key={`product-variant-${variant.id}`}
                        variant={variant}
                        toggleVariant={toggleVariant}
                        selectedVariant={selectedVariant}
                      />
                    ))
                    : (
                      <span className="d-flex align-items-center">
                        No variants found!
                      </span>
                    )}
                </div>
              </Col>
            </Row>
          </>
        )
          : (
            <Row className={clsx(styles.spinnerWrapper, "common-page-card")}>
              <Col xs="12" className={clsx(styles.color, "d-flex justify-content-center align-items-center")}>
                <Spinner size="sm" />
              </Col>
            </Row>
          )}
      </Col>
    </Row>
  );
};

ProductDetails.propTypes = {
  product: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedVariant: PropTypes.string.isRequired,
  toggleVariant: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  loadingOffers: PropTypes.bool.isRequired,
  offers: PropTypes.arrayOf(PropTypes.any).isRequired,
  getPageData: PropTypes.func.isRequired,
  localeHandler: PropTypes.func.isRequired,
  pagination: PropTypes.objectOf(PropTypes.any).isRequired,
  priceAndInventoriesLoading: PropTypes.bool.isRequired,
};

export default ProductDetails;
