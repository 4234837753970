/**
 * Copyright(c) 2022 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Abdul Razack
 */

import React, { useEffect, useState } from "react";
import XLSX from "xlsx";
import PropTypes from "prop-types";

import bulkUploadUtils, { getModesByType, getJobTypeByType } from "../../../common/utils/bulkUploadUtils";
import constants from "../../../common/utils/constants";
/** ===== COMPONENTS  =========== */
import UploadManagement from "../components/UploadManagement";
import UploadStatusModal from "../components/UploadStatusModal";
/** ===== API SERVICE FUNCTIONS =========== */
import {
  updateBatchConfig, getJobHistory, getBatchStatus,
} from "../../../api/InventoryReportServices";
import { getJobStepAuditByJobId, getJobStepExAuditByJobId, downloadProcessedFile } from "../../../api/jobConfigurationService";
import { uploadBulkMaintanenceFile } from "../../../api/productManagementServices";

const {
  PRODUCT_BULK_MAINTENANCE: {
    DEFAULT_UPLOAD_MODE,
  },
  JOB_TYPES: {
    PRODUCT_SHADE_EXPORT_JOB,
  },
} = constants;

const UploadManagementContainer = ({ maintenanceType }) => {
  const [selectedUploadMode, setSelectedUploadMode] = useState(DEFAULT_UPLOAD_MODE);
  const [modalAttributes, setModalAttributes] = useState({
    isOpen: false,
    content: "",
    header: "",
    errors: [],
  });

  const [isExporting, setIsExporting] = useState(false);
  const [checkingExportStatus, setCheckingExportStatus] = useState(false);
  const [shadeExportResult, setShadeExportResult] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isProcessingUploadJob, setIsProcessingUploadJob] = useState(false);
  const [message, setMessage] = useState({
    type: null,
    message: "",
  });

  const setAlert = (alertData) => {
    setMessage(alertData);
    setTimeout(() => {
      setMessage({ type: null, message: "" });
    }, 6000);
  };

  const handleUploadMode = (mode) => {
    const modes = getModesByType(maintenanceType);
    const selectedMode = Array.isArray(modes)
      ? modes.find((uploadMode) => uploadMode.name === mode)
      : [];
    if (selectedMode) {
      setSelectedUploadMode(selectedMode);
    }
  };

  useEffect(() => {
    setSelectedUploadMode(DEFAULT_UPLOAD_MODE);
  }, [maintenanceType]);

  const downloadFile = async (id, fileName, type) => {
    const response = await downloadProcessedFile(id, type);
    if (response) {
      const { request: responseData } = response || {};
      if (responseData) {
        const disposition = responseData.getResponseHeader("content-disposition");
        const matches = /"([^"]*)"/.exec(disposition);
        const filename = (matches != null && matches[1] ? matches[1] : fileName);
        const blob = new Blob([responseData.response], { type: "application/xml" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  const documentDownloadSteps = (jobId, type) => {
    getJobStepAuditByJobId(jobId).then(async (response) => {
      if (response && response.success && response.data && response.data.length > 0) {
        const stepId = response.data[0].id;
        const size = 1;
        const page = 0;
        getJobStepExAuditByJobId(jobId, stepId, page, size).then(async (stepAuditResponse) => {
          if (stepAuditResponse && stepAuditResponse.success
            && stepAuditResponse.data && stepAuditResponse.data.content.length > 0) {
            downloadFile(stepAuditResponse.data.content[0].id,
              stepAuditResponse.data.content[0].fileName
              || stepAuditResponse.data.content[0].filePath, type);
          } else {
            const alertData = {
              type: "warning",
              message: "This file does not contain any products, please export once again and check",
            };
            setAlert(alertData);
          }
        });
      }
    });
  };

  const updateExportShadeStatus = (reqObj) => {
    getJobHistory(reqObj).then(async (response) => {
      if (response && response.data) {
        const { data } = response;
        if (data && Array.isArray(data.content) && data.content.length > 0) {
          setShadeExportResult(data.content[0]);
        }
      }
    });
  };

  const checkShadeExportStatus = async () => {
    let timeOut;
    setCheckingExportStatus(true);
    const type = PRODUCT_SHADE_EXPORT_JOB;
    const response = await getBatchStatus(type);
    if (response.data === true) {
      setIsExporting(true);
      setCheckingExportStatus(false);
      timeOut = setTimeout(() => {
        checkShadeExportStatus();
      }, 60000);
    } else {
      clearTimeout(timeOut);
      setIsExporting(false);
      updateExportShadeStatus({
        page: 0,
        size: 1,
        status: null,
        jobConfigId: null,
        productId: "",
        jobConfigType: null,
        searchTerm: type,
        startDate: null,
        endDate: null,
      });
    }
  };

  useEffect(() => {
    checkShadeExportStatus();
  }, []);

  const bulkUpload = async (batchSchedulerDetails) => {
    const response = await uploadBulkMaintanenceFile(batchSchedulerDetails);
    setIsUploading(false);
    if (response && response.data === true && response.success) {
      setIsProcessingUploadJob(true);
    } else if (response && !response.success) {
      setModalAttributes({
        isOpen: true,
        content: "Upload failed. ",
        errors: [],
        header: "Error",
      });
    }
  };

  const getSheetHeaders = (sheet) => {
    const headers = [];
    const range = XLSX.utils.decode_range(sheet["!ref"]);
    let C; const
      R = range.s.r; /* start in the first row */
    for (C = range.s.c; C <= range.e.c; C += 1) {
      const cell = sheet[XLSX.utils.encode_cell(
        { c: C, r: R },
      )]; /* find the cell in the first row */
      let hdr = `UNKNOWN ${C}`;
      if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);
      headers.push(hdr);
    }
    return headers;
  };

  const handleUploadBulkImport = async (e) => {
    if (e && e.target && e.target.files) {
      setModalAttributes({
        isOpen: false,
        content: "",
        header: "",
        errors: [],
      });
      const file = e.target.files[0];
      const fileName = file.name;

      if (!fileName.endsWith(".xlsx") && !fileName.endsWith(".xls")) {
        setModalAttributes({
          isOpen: true,
          content: "Invalid file type. Please upload a XLSX/XLS file",
          errors: [],
          header: "Error",
        });
      } else {
        const reader = new FileReader();
        const readerBase64 = new FileReader();
        readerBase64.onloadend = () => {
          const base64String = readerBase64.result
            .replace("data:", "")
            .replace(/^.+,/, "");
          setIsUploading(true);
          bulkUpload({
            data: base64String,
            mode: selectedUploadMode.code,
            uploadType: maintenanceType,
            type: getJobTypeByType(maintenanceType),
            fileName,
          });
        };
        reader.onload = async (f) => {
          try {
            const data = new Uint8Array(f.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const sheetNameList = workbook.SheetNames;
            const rawData = XLSX.utils.sheet_to_json(
              workbook.Sheets[sheetNameList[0]], { raw: true },
            );
            const parsedData = await bulkUploadUtils.parseDataFromSheet(
              rawData, getSheetHeaders(workbook.Sheets[sheetNameList[0]]), maintenanceType,
            );
            if (parsedData.isValid) {
              readerBase64.readAsDataURL(file);
            } else {
              setModalAttributes({
                isOpen: true,
                content: parsedData.message || "Something went wrong.Please try again",
                errors: parsedData.errors || [],
                header: "Error",
              });
            }
          } catch (error) {
            setModalAttributes({
              isOpen: true,
              content: "Something went wrong.Please try again",
              errors: [],
              header: "Error",
            });
          }
        };
        reader.readAsArrayBuffer(file);
      }
      e.target.value = null;
    }
  };

  const downloadBulkImportTemplate = (name) => {
    const fileName = `${name}_template.xlsx`;
    const templateData = bulkUploadUtils.getImportSheetTemplate(maintenanceType);
    const ws = XLSX.utils.json_to_sheet(templateData);
    const wb = XLSX.utils.book_new();
    ws["!cols"] = [
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
    ];
    XLSX.utils.book_append_sheet(wb, ws, name);
    XLSX.writeFile(wb, fileName);
  };
  const exportColorPalette = async () => {
    const response = await updateBatchConfig({
      criteria: {},
      type: PRODUCT_SHADE_EXPORT_JOB,
    });
    if (response && response.data === true && response.success) {
      setIsExporting(response.data);
      checkShadeExportStatus();
    }
  };

  return (
    <>
      <UploadManagement
        maintenanceType={maintenanceType}
        DataUploadModes={getModesByType(maintenanceType)}
        selectedUploadMode={selectedUploadMode}
        isExporting={isExporting}
        isUploading={isUploading}
        isProcessingUploadJob={isProcessingUploadJob}
        shadeExportResult={shadeExportResult}
        handleUploadMode={handleUploadMode}
        message={message}
        handleUploadBulkImport={handleUploadBulkImport}
        downloadBulkImportTemplate={downloadBulkImportTemplate}
        exportColorPalette={exportColorPalette}
        documentDownloadSteps={documentDownloadSteps}
        checkShadeExportStatus={checkShadeExportStatus}
        checkingExportStatus={checkingExportStatus}
      />
      <UploadStatusModal
        isOpen={modalAttributes.isOpen}
        content={modalAttributes.content}
        header={modalAttributes.header}
        errors={modalAttributes.errors}
        toggleClose={() => setModalAttributes({ ...modalAttributes, isOpen: false })}
      />
    </>
  );
};

UploadManagementContainer.defaultProps = {
  maintenanceType: "",
};

UploadManagementContainer.propTypes = {
  maintenanceType: PropTypes.string,
};

export default UploadManagementContainer;
