/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Mobile App Customization Container
 *
 * @author Naseef O
 *
 */

import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import qs from "query-string";
import PageCustomization from "../components/MobileAppCustomization";
import { PageTypes } from "../../../common/utils/layoutUtils";
import commonUtils from "../../../common/utils/commonUtils";
import {
  updateSectionConfig,
  getMobilePageConfigurationsByPageType,
} from "../../../api/pageConfigurationsServices";

import { getSeoInformation, getCategoryDetails } from "../../../api/categoryManagementServices";
import { getAllBrands } from "../../../api/brandServices";
import { getSitePreviewConfigurations, replicateContent, getContentReplicationStatus } from "../../../api/sitePreviewConfigurationsServices";
import { getWidgetConfigurationsByPageType } from "../../../api/widgetConfigurationsServices";

const MobileAppCustomizationContainer = () => {
  const location = useLocation();
  const { categoryId, pageType } = commonUtils.getQueryParams(location);
  const [pageConfiguration, setPageConfiguration] = useState(null);
  const [seoInformation, setSeoInformation] = useState(null);
  const [selectedPage, setSelectedPage] = useState(pageType || PageTypes.HOME);
  const [isLoading, setIsLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [parentCategory, setParentCategory] = useState(categoryId || "");
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [dropdownBrand, toggleDropdownBrand] = useState(false);
  const [previewSiteUrl, setPreviewSiteUrl] = useState("");
  const [previewEnabled, setPreviewEnabled] = useState(false);
  const [pushToLiveEnabled, setPushToLiveEnabled] = useState(false);
  const [pushToLiveButtonEnabled, setPushToLiveButtonEnabled] = useState(false);
  const [showPushToLiveAlert, setShowPushToLiveAlert] = useState(false);

  const [dropdownLanguage, toggleDropdownLanguage] = useState(false);
  const [locale, setLocale] = useState("en_AE");

  const history = useHistory();

  /**
   * This method is used to get mobile app configurations
   *
   * @returns
   */
  const getPageConfigurations = async () => {
    const response = await getMobilePageConfigurationsByPageType(selectedPage);
    if (response && response.success && response.data) {
      setPageConfiguration(response.data);
      return true;
    }
    return false;
  };
  const toggleActiveWidget = (widget, sectionId, configId, active) => {
    const requestData = {
      ...widget,
      sectionId,
      configId,
    };
    requestData.active = !active;
    updateSectionConfig(requestData, locale).then((response) => {
      if (response && response.success) {
        getPageConfigurations();
        setIsEdit(false);
      }
    });
  };

  useEffect(() => {
    getPageConfigurations(selectedPage).then((response) => {
      if (response) {
        setIsLoading(true);
      }
    });
  }, [selectedPage, parentCategory]);

  const [widgetConfigurations, setWidgetConfigurations] = useState(null);
  useEffect(() => {
    getWidgetConfigurationsByPageType(selectedPage, "mobile").then((response) => {
      if (response && response.success && response.data) {
        setWidgetConfigurations(response.data);
      }
    });
  }, [selectedPage]);

  useEffect(() => {
    if (selectedPage === "BLP") {
      getAllBrands().then((response) => {
        if (response && response.success && response.data) {
          const { data } = response;
          setBrands(data);
          if (parentCategory) {
            const brand = data.find((item) => item.id === parentCategory);
            setSelectedBrand(brand);
            setParentCategory(parentCategory);
          } else {
            setSelectedBrand(data[0] ? data[0] : null);
            setParentCategory(data[0] ? data[0].id : null);
          }
        }
      });
    }
  }, [selectedPage]);

  const [categoryLabel, setCategoryLabel] = useState("");
  useEffect(() => {
    if (categoryId) {
      getCategoryDetails(categoryId).then((response) => {
        if (response && response.success && response.data) {
          setCategoryLabel(response.data.name);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (parentCategory) {
      getSeoInformation(parentCategory).then((response) => {
        if (response && response.success && response.data) {
          setSeoInformation(response.data);
        }
      });
    } else {
      setSeoInformation(null);
    }
  }, [selectedPage, parentCategory]);

  const [isEdit, setIsEdit] = useState(false);
  const [selectedWidget, setWelectedWidget] = useState(null);
  const [widgetTitle, setWidgetTitle] = useState("");

  /**
   * This method is used to handle edit widget title
   *
   * @param {*} widget
   * @param {*} name
   */
  const handleEditWidget = (widget, name) => {
    setWelectedWidget(widget);
    setWidgetTitle(name);
    setIsEdit(true);
  };

  /**
   * This method is used to handle save
   *
   * @param {*} widget
   * @param {*} sectionId
   * @param {*} configId
   */
  const handleSaveTitle = (widget, sectionId, configId) => {
    const requestData = {
      ...widget,
      sectionId,
      configId,
    };
    requestData.title = widgetTitle;
    updateSectionConfig
      (requestData, locale).then((response) => {
        if (response && response.success) {
          getPageConfigurations();
          setIsEdit(false);
        }
      });
  };

  /**
   *This method is used to toogle dropdown
   *
   */
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  /**
 *This method is used to handle select page
 *
 * @param {String} PageTypes
 */
  const handleSelectPage = (type) => {
    setSelectedPage(type);
    setParentCategory("");
    setCategoryLabel("");
    toggleDropdown();
    toggleDropdownBrand(false);
  };

  /**
   * This method is used to handle cancel
   *
   */
  const handleCancelTitle = () => {
    setWidgetTitle("");
    setIsEdit(false);
  };

  /**
  * This method is used to change parent category
  * @param {Array} categories
  */
  const handleParentCategory = (categories) => {
    setParentCategory(categories);
  };

  /**
  * This method is used to change parent category
  * @param {Array} categories
  */
  const handleSelectBrand = (brand) => {
    setParentCategory(brand.id);
    setSelectedBrand(brand);
    toggleDropdownBrand(!dropdownBrand);
  };

  /**
   * This funciton is used query param
   * @param {Object} newPrams 
   * @param {Boolean} update 
   */
  const updateQueryPrams = (newPrams, update = true) => {
    const locationIn = history.location || location;
    const search = locationIn?.search;
    let searchObject = qs.parse(search);
    searchObject = { ...searchObject, ...newPrams }
    history.push({
      pathname: location.pathname,
      search: `?${qs.stringify(searchObject)}`,
    });
  }
  const handleLanguageSelect = (localeLang) => {
    setLocale(localeLang);
    toggleDropdownLanguage(!dropdownLanguage);
    updateQueryPrams({ locale: localeLang });
  };
  useEffect(() => {
    getSitePreviewConfigurations().then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        if (data) {
          setPreviewEnabled(data.previewEnabled || false);
          setPushToLiveEnabled(data.pushToLiveEnabled || false);
          const { previewSiteUrl: localPreviewSiteUrl } = data;
          setPreviewSiteUrl(localPreviewSiteUrl);
          getReplicationStatus();
        }
      }
    });
  }, []);

  const [message, setMessage] = useState({
    type: null,
    message: "",
  });

  const setAlert = (alertData) => {
    setMessage(alertData);
    setTimeout(() => {
      setMessage({ type: null, message: "" });
    }, 3000);
  };

  const handlePushToLive = async () => {
    setPushToLiveButtonEnabled(false);
    setShowPushToLiveAlert(true);
    replicateContent().then((response) => {
      if (response && response.success) {
        const alertData = {
          type: "success",
          message: "Page contents pushed to live successfully, there may be a small delay for reflecting the same in live.",
        };
        setAlert(alertData);
      } else {
        const alertData = {
          type: "danger",
          message: "Something went wrong. Push to live failed",
        };
        setAlert(alertData);
      }
    }).catch(error => {
      const alertData = {
        type: "danger",
        message: "Something went wrong. Push to live failed",
      };
      setAlert(alertData);
    });
  };

  const getReplicationStatus = async () => {
    getContentReplicationStatus().then((response) => {
      if (response
        && response.success
        && ((response.data && response.data.completed) || (!response.data))) {
        setPushToLiveButtonEnabled(true);
        if (showPushToLiveAlert) {
          const alertData = {
            type: "success",
            message: "The last content push to live is successfully completed.",
          };
          setAlert(alertData);
        }
        setShowPushToLiveAlert(true);
      } else {
        setPushToLiveButtonEnabled(false);
        if (showPushToLiveAlert) {
          const alertData = {
            type: "warning",
            message: "Please wait some more time to reflect the changes in live.",
          };
          setAlert(alertData);
        }
        setShowPushToLiveAlert(true);
      }
    }).catch(error => {
      setPushToLiveButtonEnabled(false);
      setShowPushToLiveAlert(true);
    });
    return null;
  };

  return (
    <PageCustomization
      getPageConfigurations={getPageConfigurations}
      pageConfiguration={pageConfiguration}
      isLoading={isLoading}
      isEdit={isEdit}
      selectedWidget={selectedWidget}
      setWidgetTitle={setWidgetTitle}
      widgetTitle={widgetTitle}
      handleEditWidget={handleEditWidget}
      handleSaveTitle={handleSaveTitle}
      handleCancelTitle={handleCancelTitle}
      toggleDropdown={toggleDropdown}
      dropdownOpen={dropdownOpen}
      handleSelectPage={handleSelectPage}
      selectedPage={selectedPage}
      categoryId={parentCategory}
      handleParentCategory={handleParentCategory}
      seoInformation={seoInformation}
      categoryLabel={categoryLabel}
      brands={brands}
      selectedBrand={selectedBrand}
      handleSelectBrand={handleSelectBrand}
      dropdownBrand={dropdownBrand}
      toggleDropdownBrand={toggleDropdownBrand}
      handlePushToLive={handlePushToLive}
      previewSiteUrl={previewSiteUrl}
      previewEnabled={previewEnabled}
      message={message}
      widgetConfigurations={widgetConfigurations}
      toggleActiveWidget={toggleActiveWidget}
      pushToLiveButtonEnabled={pushToLiveButtonEnabled}
      getReplicationStatus={getReplicationStatus}
      setCategoryLabel={setCategoryLabel}
      handleLanguageSelect={handleLanguageSelect}
      dropdownLanguage={dropdownLanguage}
      toggleDropdownLanguage={toggleDropdownLanguage}
      locale={locale}
    />
  );
};
export default MobileAppCustomizationContainer;
