/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * @author Aardra S
 *
 */

import React, { useState, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { getProductByIds } from "../../../api/productManagementServices";

/** ========= SUB COMPONENT ========= */
import EditBadge from "../components/EditBadge";

/** ========= API SERVICE FUNCTIONS ========= */
import { getBadge, updateBadge } from "../../../api/badgeService";

const EditBadgeContainer = () => {
  const history = useHistory();
  const { params } = useRouteMatch();

  if (!params.badgeId) {
    history.push("/marketing/badges");
  }

  const [form, setForm] = useState({
    id: "",
    badgeCode: "",
    backgroundColor: "",
    fontColor: "",
    primaryText: "",
    secondaryText: "",
    applicableProducts: [],
    enabled: false,
    startDate: new Date(),
    endDate: new Date(),
    active: false,
  });
  const [onSubmitting, setOnSubmitting] = useState(false);
  const [selectedLanguage, setLanguage] = useState("en_AE");
  const [translatableFileds, setSelectedtranslatableFiledsect] = useState([]);
  const [isDefault, setIsDefault] = useState(true);
  const [message, setMessage] = useState({
    type: null,
    message: "",
  });

  useEffect(() => {
    if (selectedLanguage) {
      let localForm = form;
      getBadge(params.badgeId, selectedLanguage).then((response) => {
        if (response && response.success && response.data) {
          const { data } = response;
          localForm = {
            ...form,
            id: data.id,
            appId: data.appId,
            retailer: data.retailer,
            brand: data.brand,
            location: data.location,
            badgeCode: data.badgeCode,
            backgroundColor: data.backgroundColor ? data.backgroundColor : "#fffb00",
            fontColor: data.fontColor ? data.fontColor : "#24292E",
            primaryText: data.primaryText,
            secondaryText: data.secondaryText,
            applicableProducts: Array.isArray(data.applicableProducts)
              ? data.applicableProducts
              : [],
            enabled: false,
            startDate: new Date(),
            endDate: new Date(),
          };
          setForm(localForm);
        }
      });
    }
  }, [params.badgeId, selectedLanguage]);

  useEffect(() => {
    if (form.applicableProducts && form.applicableProducts.length > 0) {
      getProductByIds(form.applicableProducts).then((response) => {
        if (response && response.success && response.data) {
          const { data } = response;
          setForm({ ...form, applicableProductsDetails: data });
        }
      });
    }
  }, [form.applicableProducts]);
  /**
   * This method is used to change form state
   * @param {String} name
   * @param {String} value
   */
  const handleChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  /**
   * This method is used to change form filed input
   * @param {Event} event
   */
  const handleFormChange = (event) => {
    handleChange(event.target.name, event.target.value);
  };

  /**
   * This method is used to submit the form
   * @param {Event} event
   */
  const onSubmit = async (event) => {
    const requestBody = {
      id: form.id ? form.id : "",
      appId: form.appId ? form.appId : "",
      retailer: form.retailer ? form.retailer : "",
      brand: form.brand ? form.brand : "",
      location: form.location ? form.location : "",
      badgeCode: form.badgeCode ? form.badgeCode : "",
      backgroundColor: form.backgroundColor ? form.backgroundColor : "",
      fontColor: form.fontColor ? form.fontColor : "",
      primaryText: form.primaryText ? form.primaryText : "",
      secondaryText: form.secondaryText ? form.secondaryText : "",
      applicableProducts: form.applicableProducts
        ? form.applicableProducts
        : [],
      enabled: false,
    };

    /** clear the messages */
    setMessage({ type: null, message: "" });
    /** setting on submit true */
    setOnSubmitting(true);

    const response = await updateBadge(requestBody, selectedLanguage);
    if (response && response.success) {
      setMessage({ type: "success", message: response.messages && Array.isArray(response.messages) && response.messages[0] });
      setTimeout(() => {
        setOnSubmitting(false);
        history.push("/marketing/badges");
        setMessage({ type: null, message: "" });
      }, 3000);
    } else {
      setOnSubmitting(false);
      setMessage({ type: "warning", message: "Something went wrong." });
      setTimeout(() => {
        setMessage({ type: null, message: "" });
      }, 3000);
    }
  };

  /**
   * This method is used to go back to badges listing page
   */
  const formCancel = () => {
    history.push("/marketing/badges");
  };

  const handleChangeFontColor = (data) => {
    if (data && data.hex && data.hex !== form.fontColor) {
      setForm({ ...form, fontColor: data.hex });
    }
  };
  const handleChangeBackgroundColor = (data) => {
    if (data && data.hex && data.hex !== form.backgroundColor) {
      setForm({ ...form, backgroundColor: data.hex });
    }
  };

  const localeHandler = (localeLang, defaultIn, translatableProperties) => {
    setLanguage(localeLang);
    setSelectedtranslatableFiledsect(translatableProperties);
    setIsDefault(defaultIn);
  }

  return (
    <EditBadge
      handleChangeFontColor={handleChangeFontColor}
      handleChangeBackgroundColor={handleChangeBackgroundColor}
      form={form}
      products={form.applicableProducts}
      productsDetails={form.applicableProductsDetails}
      handleChange={handleChange}
      handleFormChange={handleFormChange}
      localeHandler={localeHandler}
      selectedLanguage={selectedLanguage}
      isDefault={isDefault}
      translatableFileds={translatableFileds}
      formCancel={formCancel}
      message={message}
      onSubmitting={onSubmitting}
      onSubmit={onSubmit}
    />
  );
};

export default EditBadgeContainer;