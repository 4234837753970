/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Naseef O
 *
 */

import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getExternalCategoryById, updateExternalCategory } from "../../../api/externalCategoryServices";
import ExternalCategoryEditor from "../components/ExternalCategoryEditor";
import commonUtils from "../../../common/utils/commonUtils";

// TODO: Remove Sample Categories on next sprit
const cats = [
  {
    id: "UTCAT0000109",
    type: "Root",
    active: true,
    name: "Babies",
    description: "Whether you're on the move or at home, we have all the basics and must-haves you'll need to keep your little tot happy.Whether you're on the move or at home, we have all the basics and must-haves you'll need to keep your little tot happy.Whether you're on the move or at home, we have all the basics and must-haves you'll need to keep your little tot happy.Whether you're on the move or at home, we have all the basics and must-haves you'll need to keep your little tot happy.Whether you're on the move or at home, we have all the basics and must-haves you'll need to keep your little tot happy.Whether you're on the move or at home, we have all the basics and must-haves you'll need to keep your little tot happy.",
    startDate: "2021-06-01 08:55:29",
    endDate: "2121-06-01 08:55:29",
    fullyQualifiedName: "Toys R Us [UAE] -> ToysRUS",
    medias: [

    ],
    childCategories: [
      "UTCAT0000110",
      "UTCAT0000111",
      "UTCAT0000112",
      "UTCAT0000113",
      "UTCAT0000114",
      "UTCAT0000115",
      "UTUCAT000540",
    ],
    childProducts: [
      "UTUPRD001244",
      "UTUPRD000771",
      "UTPRD0000449",
    ],
    parentCategory: "UTCAT0000108",
    createdDate: "2020-10-11T14:40:03.379",
    updatedDate: "2021-06-02T08:55:29.842",
    uniqueId: "babiesrus",
    landingPageBaseUrl: "/babies/",
    landingPageConfigId: "5f9019f8215ece2adcb7fbbb",
    customLandingPageConfigured: true,
  },
  {
    id: "UTCAT0000156",
    type: "Root",
    active: true,
    name: "Pretend play & costumes",
    description: "With our range of pretend play toys,, costumes and accessories, kids can be who they want to be. Princess, superhero, master chef, engineer – their possibilities are endless!vvvvv",
    startDate: "2021-06-01 08:55:42",
    endDate: "2121-06-01 08:55:42",
    fullyQualifiedName: "Toys R Us [UAE] -> ToysRUS",
    medias: [

    ],
    childCategories: [
      "UTCAT0000157",
      "UTCAT0000158",
      "UTCAT0000159",
      "UTCAT0000160",
      "UTCAT0000161",
      "UTUCAT000001",
    ],
    childProducts: [
      "UTPRD0000076",
      "UTUPRD000030",
      "UTUPRD000910",
    ],
    parentCategory: "UTCAT0000108",
    createdDate: "2020-10-11T14:40:06.272",
    updatedDate: "2021-06-10T21:02:26.501",
    uniqueId: "tru_pretend_play_costumes",
    landingPageBaseUrl: "/pretend-play-and-costumes/",
    landingPageConfigId: "5f906502b42ed72623f3fb82",
    customLandingPageConfigured: false,
  },
  {
    id: "UTCAT0000142",
    type: "Root",
    active: true,
    name: "Crafts, books & electronics",
    description: "Encourage creativity and enhance imagination with our collection of books, crafts, art materials and accessories for kids. Try now!!",
    startDate: "2021-06-01 08:55:39",
    endDate: "2121-06-01 08:55:39",
    fullyQualifiedName: "Toys R Us [UAE] -> ToysRUS",
    medias: [

    ],
    childCategories: [
      "UTCAT0000143",
      "UTCAT0000144",
      "UTCAT0000145",
      "UTCAT0000146",
      "UTCAT0000147",
      "UTCAT0000148",
      "UTCAT0000150",
      "UTCAT0000151",
      "UTCAT0000149",
    ],
    childProducts: [
      "UTPRD0000083",
      "UTUPRD000025",
      "UTUPRD006453",
    ],
    parentCategory: "UTCAT0000108",
    createdDate: "2020-10-11T14:40:05.461",
    updatedDate: "2021-06-10T21:04:58.298",
    uniqueId: "tru_crafts_books_electronics",
    landingPageBaseUrl: "/crafts-books-and-electronics/",
    landingPageConfigId: "5f9064f7215ece2adcb7fe41",
    customLandingPageConfigured: true,
  },
  {
    id: "UTUCAT000539",
    type: "Root",
    active: true,
    name: "watertoys",
    description: "testing 1063",
    startDate: "2021-04-22 11:18:33",
    endDate: "2021-04-22 11:18:33",
    childCategories: [

    ],
    childProducts: [

    ],
    parentCategory: "",
    createdDate: "2021-04-22T07:19:49.831",
    updatedDate: "2021-06-10T21:05:34.322",
    uniqueId: "UTUCAT000539",
    landingPageBaseUrl: "/watertoys/",
    customLandingPageConfigured: false,
  },
  {
    id: "UTCAT0000152",
    type: "Root",
    active: true,
    name: "Games & puzzles",
    description: "Game time? We’ve got you covered!",
    startDate: "2021-06-01 08:55:41",
    endDate: "2121-06-01 08:55:41",
    fullyQualifiedName: "Toys R Us [UAE] -> ToysRUS",
    medias: [

    ],
    childCategories: [
      "UTCAT0000153",
      "UTCAT0000154",
      "UTCAT0000155",
    ],
    childProducts: [
      "UTPRD0000246",
    ],
    parentCategory: "UTCAT0000108",
    createdDate: "2020-10-11T14:40:06.022",
    updatedDate: "2021-06-10T21:10:34.903",
    uniqueId: "tru_games_puzzles",
    landingPageBaseUrl: "/games-and-puzzles/",
    landingPageConfigId: "5f9064fcb42ed72623f3fb81",
    customLandingPageConfigured: false,
  },
  {
    id: "UTCAT0000127",
    type: "Root",
    active: true,
    name: "Sports & outdoor",
    description: "Adventure is calling. ",
    startDate: "2021-06-01 08:55:34",
    endDate: "2121-06-01 08:55:34",
    fullyQualifiedName: "Toys R Us [UAE] -> ToysRUS",
    medias: [

    ],
    childCategories: [
      "UTCAT0000138",
      "UTCAT0000139",
      "UTCAT0000140",
      "UTCAT0000141",
      "UTCAT0000128",
      "UTCAT0000129",
      "UTCAT0000130",
      "UTCAT0000131",
      "UTCAT0000132",
      "UTCAT0000134",
      "UTCAT0000135",
      "UTCAT0000136",
      "UTCAT0000137",
      "UTCAT0000133",
    ],
    childProducts: [
      "UTPRD0000408",
      "UTPRD0000243",
      "UTPRD0000244",
      "UTPRD0000245",
      "UTPRD0000247",
      "UTUPRD004456",
      "UTUPRD004457",
      "UTPRD0000357",
      "UTUPRD000026",
      "UTUPRD000199",
      "UTUPRD000200",
      "UTUPRD000154",
      "UTUPRD000169",
      "UTUPRD002175",
    ],
    parentCategory: "UTCAT0000108",
    createdDate: "2020-10-11T14:40:04.492",
    updatedDate: "2021-06-10T21:10:36.701",
    uniqueId: "tru_sports_outdoor",
    landingPageBaseUrl: "/sports-and-outdoor/",
    landingPageConfigId: "5f90636073f51a58a1772ee5",
    customLandingPageConfigured: false,
  },
  {
    id: "UTCAT0000116",
    type: "Root",
    active: true,
    name: "Toys",
    description: "Whether you are looking for the best building toys or any other toys.",
    startDate: "2021-06-01 08:55:31",
    endDate: "2121-06-01 08:55:31",
    fullyQualifiedName: "Toys R Us [UAE] -> ToysRUS",
    medias: [

    ],
    childCategories: [
      "UTCAT0000126",
      "UTCAT0000117",
      "UTCAT0000118",
      "UTCAT0000119",
      "UTCAT0000120",
      "UTCAT0000121",
      "UTCAT0000122",
      "UTCAT0000123",
      "UTCAT0000124",
      "UTCAT0000125",
    ],
    childProducts: [
      "UTUPRD000011",
      "UTUPRD000033",
      "UTUPRD000085",
      "UTUPRD001149",
      "UTUPRD000020",
      "UTUPRD000010",
      "UTUPRD000027",
      "UTUPRD000028",
      "UTUPRD000029",
      "UTUPRD000743",
      "UTUPRD001782",
      "UTUPRD000032",
    ],
    parentCategory: "UTCAT0000108",
    createdDate: "2020-10-11T14:40:03.846",
    updatedDate: "2021-06-10T21:10:40.309",
    uniqueId: "tru_toys",
    landingPageBaseUrl: "/toys/",
    landingPageConfigId: "5f9064edb42ed72623f3fb80",
    customLandingPageConfigured: true,
  },
  {
    id: "UTCAT0000202",
    type: "Root",
    active: true,
    name: "Offers",
    description: "Offer related categories",
    startDate: "2020-10-15 12:03:33",
    endDate: "2020-10-15 12:03:33",
    bannerImages: [
      "https://afgecomcommonstorage.blob.core.windows.net/storefront/tru/uat/content/8c260ac7-bf53-4090-a853-381c42c54359.png",
    ],
    medias: [

    ],
    childCategories: [

    ],
    childProducts: [
      "UTPRD0000005",
      "UTPRD0000006",
      "UTPRD0000007",
      "UTPRD0000477",
      "UTPRD0000499",
    ],
    parentCategory: "",
    createdDate: "2020-10-15T08:04:10.826",
    updatedDate: "2021-06-10T21:12:30.106",
    uniqueId: "UTCAT0000202",
    landingPageBaseUrl: "/offers/",
    landingPageConfigId: "5f96578678a9f959ee035513",
    customLandingPageConfigured: true,
  },
];

const ExternalCategoryEditorContainer = () => {
  const location = useLocation();
  const { view } = commonUtils.getQueryParams(location);

  const history = useHistory();
  const { id: categoryId } = useParams();
  const [activeTab, setActiveTab] = useState("1");
  const [categories, setCategories] = useState(cats);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [form, setForm] = useState({
    externalCategoryId: "",
    externalCategoryName: "",
    lineage: "",
    mappedCategories: [],
    updatedDate: "",
    processed: false,
  });
  const [loading, setLoading] = useState(false);

  /**
 * This method is used to toggle Dropdown
 *
 */
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  /**
 * This method is used to go to marketplace page
 *
 */
  const handleRedirectToMarketPlace = () => {
    history.push(`/merchandising/taxonomy-mapping?view=${view}`);
  };


  useEffect(() => {
    setLoading(true);
    getExternalCategoryById(categoryId).then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        setForm({
          ...form,
          externalCategoryId: data.externalCategoryId,
          externalCategoryName: data.externalCategoryName,
          lineage: data.lineage,
          mappedCategories: data.mappedCategories || [],
          updatedDate: data.updatedDate,
          processed: data.processed,
        });
        setCategories(data.mappedCategories || []);
      } else {
        handleRedirectToMarketPlace();
      }
    }).finally(() => { setLoading(false); });
  }, [categoryId]);

  /**
 * This method is used to select categories
 *
 * @param {Array} selected
 */
  const handleChangeCategory = (selected) => {
    setCategories(selected);
  };

  /**
 * This method is used to clear categories
*
 * @param {String} selected
 */
  const clearCategories = (selected) => {
    if (selected) {
      setCategories(categories.filter((item) => item.id !== selected));
    } else {
      setCategories([]);
    }
  };

  /**
 * This method is used toggle tab
 *
 * @param {*} tab
 */
  const toggleTab = (tab) => {
    setActiveTab(tab);
  };

  /**
 * This method is used to update ExternalCategory
 *
 */
  const handleSave = () => {
    const requestData = {
      externalCategoryId: form.externalCategoryId,
      storeFrontCategories: categories.map((category) => category.id),
    };
    updateExternalCategory(requestData).then((response) => {
      if (response && response.success) {
        handleRedirectToMarketPlace();
      }
    });
  };

  return (
    <ExternalCategoryEditor
      form={form}
      loading={loading}
      activeTab={activeTab}
      toggleTab={toggleTab}
      categories={categories}
      dropdownOpen={dropdownOpen}
      toggleDropdown={toggleDropdown}
      clearCategories={clearCategories}
      handleChangeCategory={handleChangeCategory}
      handleRedirectToMarketPlace={handleRedirectToMarketPlace}
    />
  );
};

export default ExternalCategoryEditorContainer;
