/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Ajay J A
 */
import clsx from "clsx";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Input from "../../../common/core/Input";
import Table from "../../../common/core/Table";
import Button from "../../../common/core/Button";
import InputGroup from "../../../common/core/InputGroup";
import InputGroupAddon from "../../../common/core/InputGroupAddon";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import Select from "../../../common/components/Select";
import StatusBadge from "../common/StatusBadge";
import ConfirmationModal from "../../../common/components/ConfirmationModal";

import SearchIcon from "../../../common/assets/images/svg/search.svg";
import DeleteIcon from "../../../common/assets/images/svg/delete.svg";
import toggleOnIcon from "../../../common/assets/images/svg/toggle-on.svg";
import StartIcon from "../../../common/assets/images/svg/run.svg";
import ExcelIcon from "../../../common/assets/images/svg/excel.svg";
import TextDownloadIcon from "../../../common/assets/images/svg/text-download.svg";
import ToggleDisableIcon from "../../../common/assets/images/svg/toggle-disable.svg";
import DisableDeleteIcon from "../../../common/assets/images/svg/delete-disable.svg";
import AddIcon from "../../../common/assets/images/svg/add-24px.svg";
import MinusIcon from "../../../common/assets/images/svg/minus-24px.svg";
import StopCircle from "../../../common/assets/images/svg/stop-circle.svg";
import styles from "../css/JobConfiguration.module.css";
import constants from "../../../common/utils/constants";

const JobListConfiguration = (props) => {
  const {
    jobList,
    jobListStatuses,
    jobListScopes,
    onJobHistoryPage,
    newJob,
  } = props;

  const {
    STATUS_INPROGRESS,
  } = constants;

  /** internationalization using i18n from common and job  */
  const { t } = useTranslation(["common", "job-configuration"]);

  const [dropdownRoleOpen, setDropdownRoleOpen] = useState(false);
  const [dropdownStatusOpen, setDropdownStatusOpen] = useState(false);
  const [selectedindex, setSelectedindex] = useState(false);
  const [initialStage, setInitialStage] = useState(false);
  const [onConfirmationModal, setConfirmationModal] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [toggleOpen, setToggleOpen] = useState(false);
  const [actionIndex, setActionIndex] = useState(null);

  /**
    *This method is used to toggle role dropdown
    *
    */
  const toggleDropdownRole = () => {
    setDropdownRoleOpen(!dropdownRoleOpen);
  };

  /**
     *This method is used to toggle status dropdown
     *
     */
  const toggleDropdownStatus = () => {
    setDropdownStatusOpen(!dropdownStatusOpen);
  };

  /**
     *This method is used to toggle arrow in Job Listing Table
     *
     */
  const toggleArrow = (index) => {
    setInitialStage(false);
    if (selectedindex === index) {
      setSelectedindex(null);
    } else {
      setSelectedindex(index);
    }
  };

  /**
     *This method is used to hnadle Logs column
     *
     */
  const handleLogs = (status, index) => {
    if (status !== STATUS_INPROGRESS && actionIndex !== index) {
      return true;
    }
  };

  /**
     *This method is used to handle delete action modal
     *
     */
  const handleDelete = (index) => {
    if (actionIndex !== index) {
      setConfirmationModal(true);
      setIsOpen(true);
      setToggleOpen(true);
    }
  };

  /**
     *This method is used to handle delete action modal
     *
     */
  const handleToggleModal = () => {
    setIsOpen(false);
    setToggleOpen(false);
  };

  /**
     *This method is used to handle run and stop
     *
     */
  const handleStart = (index) => {
    if (actionIndex === index) {
      setActionIndex(null);
    } else {
      setActionIndex(index);
    }
  };

  return (
    <>
      <Row className="common-page-card pageHeader">
        <Col xs="12">
          <span className="pageText">
            {t("job-configuration:span_job_configuration_title")}
          </span>
        </Col>
        <Col xs="12" md="4" lg="5" />
      </Row>
      <Row className={clsx(styles.block1, "pageHeader common-page-card")}>
        <Col xs="8">
          <span className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}>{t("job-configuration:sub_title_jobs")}</span>
        </Col>
        <Col xs="4">
          <Button className={clsx(styles.buttonNewJob, "text-uppercase bg-color-tertiary-main")} onClick={newJob}>{t("job-configuration:span_new_job")}</Button>
        </Col>
        <Col xs="12" md="4" lg="5" />
      </Row>
      <Row className={clsx(styles.block2, "common-page-card")}>
        <Col xs="12">
          <Row className="m-0 ">
            <Col xs="12" md="4">
              <FormGroup>
                <Label className="formTextLabel">{t("job-configuration:label_job_status")}</Label>
                <Select
                  className="formTextLabel"
                  placeholder={t("job-configuration:job_status_all")}
                  name="status"
                  onChange={toggleDropdownRole}
                  options={[
                    ...(Array.isArray(jobListStatuses) ? jobListStatuses : []),
                  ]}
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="4">
              <FormGroup>
                <Label className="formTextLabel">{t("job-configuration:label_job_scope")}</Label>
                <Select
                  className="formTextLabel"
                  placeholder={t("job-configuration:job_status_all")}
                  name="status"
                  onChange={toggleDropdownStatus}
                  options={[
                    ...(Array.isArray(jobListScopes) ? jobListScopes : []),
                  ]}
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="4" className="p-0">
              <FormGroup>
                <Label className="formTextLabel  pt-3" for="exampleEmail" />
                <div className={styles.areaActionRight}>
                  <div>
                    <InputGroup className={clsx(styles.search, "color-tertiary-main")}>
                      <Input placeholder={t("job-configuration:placeholder_search")} />
                      <InputGroupAddon
                        addonType="append"
                        className="commonPointer bg-color-tertiary-main"
                      >
                        <img
                          src={SearchIcon}
                          alt={t("job-configuration:placeholder_search")}
                          className={styles.icon}
                        />
                      </InputGroupAddon>
                    </InputGroup>
                    {" "}
                  </div>
                </div>
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={clsx(styles.block3, styles.tableWrapper, " common-page-card")}>
        <Col>
          <div className={styles.job}>
            <div
              className={clsx(styles.jobTable, "table-responsive")}
            >
              <Table borderless>
                <thead>
                  <tr>
                    <th>{ }</th>
                    <th>{t("job-configuration:label_job_name")}</th>
                    <th>{t("common:label_status")}</th>
                    <th>{t("job-configuration:label_last_run")}</th>
                    <th>{t("job-configuration:label_scope")}</th>
                    <th>{t("job-configuration:label_logs")}</th>
                    <th>{t("job-configuration:label_actions")}</th>
                  </tr>
                </thead>
                {jobList.length > 0 ? (
                  jobList.map((data, index) => (
                    <tbody>
                      <tr>
                        <td>
                          <a
                            onClick={() => { toggleArrow(index); }}
                            href="##"
                          >
                            <img
                              src={selectedindex === index || initialStage ? MinusIcon : AddIcon}
                              className="commonPointer"
                              role="presentation"
                              alt="drag indiactor icon"
                              id="dropDownIcon"
                              width="20px"
                            />
                          </a>
                        </td>
                        <td>
                          <a
                            onClick={() => { onJobHistoryPage(data.id); }}
                            href="##"
                          >
                            {data.name}
                          </a>
                        </td>
                        <td>
                          <StatusBadge status={data.status} />
                        </td>
                        <td>{data.lastRun}</td>
                        <td>{data.scope}</td>
                        {handleLogs(data.status, index) ? (
                          <td>
                            {" "}
                            <img
                              src={ExcelIcon}
                              className="commonPointer"
                              role="presentation"
                              alt="drag indiactor icon"
                              width="20px"
                            />
                            {" "}
                            <img
                              src={TextDownloadIcon}
                              className="commonPointer"
                              role="presentation"
                              alt="drag indiactor icon"
                              width="20px"
                            />
                          </td>
                        ) : (<td />)}
                        <td>
                          <a
                            onClick={() => { handleStart(index); }}
                            href="##"
                          >
                            <img
                              src={actionIndex === index ? StopCircle : StartIcon}
                              className="playIcon"
                              role="presentation"
                              alt="drag indiactor icon"
                              width={actionIndex === index ? "17px" : "13px"}
                            />
                          </a>
                          {" "}
                          <img
                            src={actionIndex === index ? ToggleDisableIcon : toggleOnIcon}
                            className="commonPointer"
                            role="presentation"
                            alt="drag indiactor icon"
                            width="20px"
                          />
                          {" "}
                          <a
                            onClick={() => { handleDelete(index); }}
                            href="##"
                          >
                            <img
                              src={actionIndex === index ? DisableDeleteIcon : DeleteIcon}
                              className="commonPointer"
                              role="presentation"
                              alt="drag indiactor icon"
                              width={actionIndex === index ? "12px" : "20px"}
                            />
                          </a>
                        </td>
                      </tr>

                      {selectedindex === index || initialStage ? (
                        data.jobTask.map((task) => (
                          <tr>
                            <td>{" "}</td>
                            <td>
                              {task.name}
                            </td>
                            <td><StatusBadge status={data.status} /></td>
                            <td>{task.lastRun}</td>
                            <td>{task.scope}</td>
                            {handleLogs(data.status, index) ? (
                              <td>
                                {" "}
                                <img
                                  src={ExcelIcon}
                                  className="commonPointer"
                                  role="presentation"
                                  alt="drag indiactor icon"
                                  width="20px"
                                />
                                {" "}
                                <img
                                  src={TextDownloadIcon}
                                  className="commonPointer"
                                  role="presentation"
                                  alt="drag indiactor icon"
                                  width="20px"
                                />
                              </td>
                            ) : (<td />)}
                            <td>
                              {" "}
                            </td>
                          </tr>
                        ))) : (" ")}
                    </tbody>
                  ))) : (
                    <tr>
                      <td colSpan={8} align="center" valign="center">
                        {t("common:td_nothing_to_show")}
                      </td>
                    </tr>
                  )}
              </Table>
            </div>
            <div className={clsx(styles.pagination, "p-3", "d-flex", "justify-content-end")}>
              <div className="d-flex">
                <span className={clsx(styles.item, styles.arrowLeft)} disabled>
                  <span />
                </span>
                <span className={styles.item} active="active"> 1 </span>
                <span className={clsx(styles.item, styles.arrowRight)} disabled>
                  <span />
                </span>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {onConfirmationModal ? (
        <ConfirmationModal
          isOpen={isOpen}
          toggleOpen={toggleOpen}
          togglClose={handleToggleModal}
          handleConfirm
          content={t("job-configuration:modal_confirmation")}
        />
      ) : (" ")}
    </>
  );
};

JobListConfiguration.propTypes = {
  jobList: PropTypes.arrayOf(PropTypes.any).isRequired,
  jobListStatuses: PropTypes.arrayOf(PropTypes.any).isRequired,
  jobListScopes: PropTypes.arrayOf(PropTypes.any).isRequired,
  onJobHistoryPage: PropTypes.func.isRequired,
  newJob: PropTypes.func.isRequired,
};

export default JobListConfiguration;
