/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Mohammed Shefeeq
 */

import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import styles from "../css/JobScheduler.module.css";
import Label from "../../../common/core/Label";
import Form from "../../../common/core/Form";
import Button from "../../../common/core/Button";
import FormGroup from "../../../common/core/FormGroup";
import Input from "../../../common/core/Input";
import FormFeedback from "../../../common/core/FormFeedback";
import validateForm from "../../../common/utils/validateForm";

const SchedulerManagement = ({
  onHandleCancel, handleFormChange, form, onHandleSave, isValidCronExpression,
}) => {
  const { t } = useTranslation(["common", "job-scheduler"]);
  const validator = validateForm();
  const { handleSubmit } = validator;
  return (
    <>
      <Form onSubmit={handleSubmit(onHandleSave)}>
        <Row className="common-page-card pageHeader">
          <Col xs="12">
            <span className="pageText">{t("job-scheduler:job-scheduler-configuration")}</span>
          </Col>
          <Col xs="12" md="4" lg="5" />
        </Row>
        <Row className={clsx(styles.block1, "common-page-card ")}>
          <Col xs="12">
            <Row className="m-0 ">
              <Col xs="6" className="p-0">
                <span
                  className={clsx(styles.pageSelectionText, "fontsizeMediumBold")}
                >
                  {t("job-scheduler:job-scheduler")}
                </span>
              </Col>
              <Col className="mb-2" xs="6" sm>
                <div className={styles.areaActionRight}>
                  <div>
                    <Button
                      color="secondary"
                      className={clsx(
                        styles.borderRadius2,
                        styles.btn,
                        styles.secondaryColor,
                        "text-uppercase",
                        "text-truncate",
                        "btn-tertiary",
                        "ml-1",
                      )}
                      onClick={onHandleCancel}
                    >
                      {t("common:button_cancel")}
                    </Button>
                    <Button
                      color="primary"
                      className={clsx(
                        styles.borderRadius2,
                        "text-uppercase",
                        "text-truncate",
                        "btn-tertiary",
                        "ml-1",
                      )}
                      type="submit"
                    >
                      {t("common:button_save")}
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className={clsx(styles.tableWrapper, "common-page-card")}>
          <Col xs="12">
            <Row>
              <Col xs="12">
                <Row>
                  <Col xs="6">
                    <FormGroup>
                      <Label className="formTextLabel">{t("job-scheduler:job-scheduler")}</Label>
                      <Input
                        className="formText"
                        placeholder=""
                        name="jobSchedule"
                        value={form.jobSchedule}
                        onChange={(e) => handleFormChange(e)}
                        invalid={!!(!isValidCronExpression && "invalid cron expression")}
                      />
                      <FormFeedback>{!isValidCronExpression && "invalid cron expression"}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <Label className="formTextLabel">{t("job-scheduler:next-execution-time")}</Label>
                      <Input
                        className="formText"
                        placeholder=""
                        name="nextJobExecutionTime"
                        value={form.nextJobExecutionTime}
                        onChange={(e) => handleFormChange(e)}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <FormGroup>
                        <Label className="formTextLabel">{t("job-scheduler:previous-execution-time")}</Label>
                        <Input
                          className="formText"
                          placeholder=""
                          name="prvsJobCompletionTime"
                          value={form.prvsJobCompletionTime}
                          onChange={(e) => handleFormChange(e)}
                          disabled
                        />
                      </FormGroup>
                    </FormGroup>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <Label className="formTextLabel">{t("job-scheduler:job-config-id")}</Label>
                      <Input
                        className="formText"
                        placeholder=""
                        name="jobConfigId"
                        value={form.jobConfigId}
                        onChange={(e) => handleFormChange(e)}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <Label className="formTextLabel">{t("job-scheduler:execution-mode")}</Label>
                      <Input
                        className="formText"
                        placeholder=""
                        name="executionMode"
                        value={form.executionMode}
                        onChange={(e) => handleFormChange(e)}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
};

SchedulerManagement.propTypes = {
  onHandleCancel: PropTypes.func.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  onHandleSave: PropTypes.func.isRequired,
  isValidCronExpression: PropTypes.func.isRequired,
};

export default SchedulerManagement;
