/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * OffersTable
 *
 * @author Naseef O
 */

import clsx from "clsx";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Table from "../../../common/core/Table";
import ModalBody from "../../../common/core/ModalBody";
import Modal from "../../../common/core/Modal";
import Pagination from "../../../common/components/Pagination";
import ClearIcon from "../../../common/assets/images/svg/clear-alt2.svg";
import SadIcon from "../../../common/assets/images/svg/sad.svg";
import styles from "../css/ProductDetails.module.css";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import constants from "../../../common/utils/constants";
import { getLocalDateTime } from "../../../common/utils/commonUtils";

const ProductOptions = (props) => {
  const {
    modal, toggle, selectedOffer,
  } = props;
  const [options, setOptions] = useState(null);
  useEffect(() => {
    // TODO: MOVE THIS LOGIC TO BACKEND
    const productOptions = {};
    if (selectedOffer
      && Array.isArray(selectedOffer.giftWrap) && selectedOffer.giftWrap.length > 0) {
      let isGiftWrap = false;
      let isGiftWrapWithMsg = false;
      selectedOffer.giftWrap.map((item) => {
        if (item) {
          if (item.endsWith("GIFT_WRAP")) {
            isGiftWrap = true;
          }
          if (item.endsWith("GFTWRPMSG")) {
            isGiftWrapWithMsg = true;
          }
        }
        return null;
      });
      productOptions.isGiftWrap = isGiftWrap;
      productOptions.isGiftWrapWithMsg = isGiftWrapWithMsg;
    }

    if (selectedOffer
      && Array.isArray(selectedOffer.warranty) && selectedOffer.warranty.length > 0) {
      const warranty = [];
      selectedOffer.warranty.map((item) => {
        if (item) {
          if (item.endsWith("WARRANTY1")) {
            warranty.push("1 Year Warranty");
          } else if (item.endsWith("WARRANTY2")) {
            warranty.push("2 Years Warranty");
          } else if (item.endsWith("WARRANTY3")) {
            warranty.push("3 Years Warranty");
          } else if (item.endsWith("WARRANTY4")) {
            warranty.push("4 Years Warranty");
          } else if (item.endsWith("WARRANTY5")) {
            warranty.push("5 Years Warranty");
          }
        }
        return null;
      });
      productOptions.warranty = warranty;
    }
    if (selectedOffer && selectedOffer.installation) {
      productOptions.installation = true;
    }
    setOptions(productOptions);
  }, [selectedOffer]);

  return (options
    && (
      <Modal
        className={styles.modalWrapper}
        isOpen={modal}
        toggle={toggle}
      >
        <Row className="my-2 mx-0">
          <Col xs="12" className={styles.modalHeader}>
            <span>
              Product options
            </span>
            <img
              src={ClearIcon}
              alt="Close"
              role="presentation"
              onClick={() => toggle()}
            />
          </Col>
        </Row>
        <ModalBody>
          <Row>
            <Col>
              <span className="mr-1"> Gift Wrap:</span>
              <span>{options.isGiftWrap ? "Yes" : "No"}</span>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <span className="mr-1"> Gift Wrap with Message:</span>
              <span>{options.isGiftWrapWithMsg ? "Yes" : "No"}</span>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <span className="mr-1">Installation:</span>
              <span>{options.installation ? "Yes" : "No"}</span>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col xs="auto" className="">Warranty: </Col>
            <Col>
              <Row>
                {Array.isArray(options.warranty) && options.warranty.length > 0
                  ? options.warranty.map((item) => (
                    <Col
                      key={`product-option-${item}`}
                      xs="auto"
                      className={styles.optionsChip}
                    >
                      {item}
                    </Col>
                  ))

                  : "No warranty"}
              </Row>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    )
  );
};

ProductOptions.propTypes = {
  modal: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  selectedOffer: PropTypes.objectOf(PropTypes.any).isRequired,
};

const OffersTable = (props) => {
  const {
    offers, pagination, getPageData, loading,
  } = props;

  const [modal, setModalOpen] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(null);

  /**
   * This method is used to view product options.
   *
   * @param {Event} e
   * @param {Object} offer
   */
  const viewProductOptions = (e, offer) => {
    setModalOpen(!modal);
    if (offer && offer.id) {
      setSelectedOffer(offer.id !== (selectedOffer && selectedOffer.id) ? offer : null);
    }
  };

  return (
    <div>
      <div className={clsx(styles.offerTable, "mt-3")}>
        <Table borderless>
          <thead>
            <tr>
              <th className="text-left">
                Offer Id
              </th>
              <th>
                Seller Id
              </th>
              <th>
                Seller Name
              </th>
              <th>
                Product options
              </th>
              <th>
                List price
              </th>
              <th>
                Sale Price
              </th>
              <th>
                Shipping Charge
              </th>
              <th>
                Total Price
              </th>
              <th>
                Quantity
              </th>
              <th>
                Discount Start Date
              </th>
              <th>
                Discount End Date
              </th>
              <th>
                Start date
              </th>
              <th>
                End date
              </th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(offers)
              && offers.length > 0
              ? offers.map((offer) => (
                <tr key={offer.id}>
                  <td>
                    <span className={clsx("text-truncate", "d-block")}>
                      {offer.offerId}
                    </span>
                  </td>
                  <td>
                    <span className={clsx("text-truncate", "d-block")}>
                      {offer.sellerId}
                    </span>
                  </td>
                  <td>
                    <span>
                      {offer.sellerName}
                    </span>
                  </td>
                  <td>
                    <span
                      role="presentation"
                      className={clsx(styles.viewButton)}
                      onClick={(e) => viewProductOptions(e, offer)}
                    >
                      View
                    </span>
                  </td>
                  <td>
                    <span className={clsx("text-truncate", "d-block")}>
                      {offer.listPrice ? `${offer.listPrice} ${offer.currency}` : "-"}
                    </span>
                  </td>
                  <td>
                    <span className={clsx("text-truncate", "d-block")}>
                      {offer.salePrice ? `${offer.salePrice} ${offer.currency}` : "-"}
                    </span>
                  </td>
                  <td>
                    <span className={clsx("text-truncate", "d-block")}>
                      {offer.minShippingCharge ? `${offer.minShippingCharge} ${offer.currency}` : "-"}
                    </span>
                  </td>
                  <td>
                    <span className={clsx("text-truncate", "d-block")}>
                      {offer.salePrice ? `${offer.salePrice + offer.minShippingCharge} ${offer.currency}` : "-"}
                    </span>
                  </td>
                  <td>
                    <span className={clsx("text-truncate", "d-block")}>
                      {offer.quantity || "-"}
                    </span>
                  </td>
                  <td>
                    <span className={clsx("text-truncate", "d-block")}>
                      {offer.discountStartDate ? getLocalDateTime(offer.discountStartDate).format(constants.DATE_TIME_FORMAT) : "-"}
                    </span>
                  </td>
                  <td>
                    <span className={clsx("text-truncate", "d-block")}>
                      {offer.discountEndDate ? getLocalDateTime(offer.discountEndDate).format(constants.DATE_TIME_FORMAT) : "-"}
                    </span>
                  </td>
                  <td>
                    <span className={clsx("text-truncate", "d-block")}>
                      {offer.startDate ? getLocalDateTime(offer.startDate).format(constants.DATE_TIME_FORMAT) : "-"}
                    </span>
                  </td>
                  {offer.attributes && offer.attributes.offerValidity ? (
                    <td>
                      <span className={clsx("text-truncate", "d-block")}>
                        {offer.endDate ? getLocalDateTime(offer.endDate).format(constants.DATE_TIME_FORMAT) : "-"}
                      </span>
                    </td>
                  ) : ""}
                </tr>
              ))
              : (
                <tr>
                  <td colSpan="100%" align="center" valign="center">
                    {loading ? "Loading..." : (
                      <span>
                        <img src={SadIcon} alt="Not found" className="mr-1" width="15" />
                        No offers found!
                      </span>
                    )}
                  </td>
                </tr>
              )}
          </tbody>
        </Table>
      </div>
      {
        Array.isArray(offers) && offers.length > 0 && (
          <>
            <ProductOptions
              modal={modal}
              selectedOffer={selectedOffer}
              toggle={viewProductOptions}
              offer={selectedOffer}
            />
            <Pagination
              totalCount={pagination.totalCount}
              page={pagination.page}
              limit={pagination.limit}
              getPageData={getPageData}
            />
          </>
        )
      }
    </div>
  );
};

OffersTable.defaultProps = {
  loading: false,
};

OffersTable.propTypes = {
  offers: PropTypes.arrayOf(PropTypes.any).isRequired,
  pagination: PropTypes.objectOf(PropTypes.any).isRequired,
  getPageData: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default OffersTable;
