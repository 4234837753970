/**
 * Copyright(c) 2023 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Neeraj Raj
 */

import React from "react";
import PropTypes from "prop-types";

/** ===== TAG COMPONENTS =========== */
import Spinner from "../../../common/core/Spinner";
import Button from "../../../common/core/Button";

/** ===== IMAGE ICONS =========== */
import publishIcon from "../../../common/assets/images/svg/publish.svg";

/** ======== MODULE STYLES ========== */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";

const CatalogueMangement = (props) => {
  const { loading, handleUploadFile, fileSelector } = props;

  return (
    <Row>
      <Col xs="12">
        <Row className="pageHeader common-page-card">
          <Col xs="12" md="7" className="text-truncate">
            <span className="pageText">Catalogue Management</span>
          </Col>
        </Row>
        <Row className="common-page-card">
          <Col xs="12">
            <Row className="common-page-card">
              <Col xs="12">
                <div className="my-2">
                  <Col xs="12" className="mb-3 d-flex algin-items-center">
                    <span className=" fontsizeMediumBold text-truncate m-0 pr-3">Upload Catalogue File</span>
                    <span className="ml-auto col-xs-4 col-md-3 col-lg-2 p-0">
                      <Button
                        size="sm"
                        color="primary"
                        block
                        className="text-white"
                        id="uploadButton"
                        title="Upload catalogue file"
                      >
                        <label htmlFor="uploadFile" className="commonPointer w-100 h-100 m-0">
                          {loading ? <Spinner size="sm" /> : <img src={publishIcon} alt="upload" />}
                          <input
                            id="uploadFile"
                            accept=".pdf"
                            type="file"
                            className="d-none"
                            onChange={(e) => handleUploadFile(e)}
                            ref={fileSelector}
                          />
                        </label>
                      </Button>
                    </span>
                  </Col>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

CatalogueMangement.defaultProps = {
  fileSelector: null,
};

CatalogueMangement.propTypes = {
  loading: PropTypes.bool.isRequired,
  handleUploadFile: PropTypes.func.isRequired,
  fileSelector: PropTypes.objectOf(PropTypes.any),
};

export default CatalogueMangement;
