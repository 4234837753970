// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Copyright(c) 2021 Mozanta Technologies Private Ltd.\n * \n * All rights reserved.                \n * \n * This software is the confidential and proprietary information of Mozanta (\"Confidential\n * Information\"). You shall not disclose such Confidential Information and shall use it only in\n * accordance with the terms of the contract agreement you entered into with Mozanta.\n * \n * CategorySelector.module.css\n\n * @author Naseef O\n */\n\n .CategorySelector_block2__e9lXW {\n  padding-top: 20px;\n  padding-bottom: 20px;\n} \n\n.CategorySelector_borderRadius2__2D39u {\n  border-radius: 0.125rem !important;\n}\n\n.CategorySelector_secondaryColor__28QSt {\n  background-color: #757575 !important;\n  border-color: #757575 !important;\n}\n\n.CategorySelector_primaryColor__3P9Gc {\n  background: #17a2b8 !important;\n  border-color: #17a2b8 !important;\n}\n\n.CategorySelector_btn__248HY {\n  min-width: 7rem;\n} \n\n/* ============= PRODUCT TITLE ========= */\n.CategorySelector_titleTextContainer__1IEzo {\n  padding: 0 2.1rem 0.125rem 2.1rem;\n  border-bottom: 1px solid #edf0f5;\n  margin: 0 -0.938rem;\n}\n\n/** ========= PARENT CATEGORY  ================ **/\n\n.CategorySelector_dropdownMenu__1YKF8 {\n  max-height: 350px;\n  overflow-y: auto;\n  width: 100%;\n  background-color: #fff;\n}\n\n.CategorySelector_dropdownMenu__1YKF8::-webkit-scrollbar-track {\n  background: #fff !important;\n}\n\n.CategorySelector_dropdown__3CRfg > button {\n  background: transparent !important;\n  text-align: left !important;\n  color: black !important;\n  display: flex !important;\n  align-items: center !important;\n}\n\n.CategorySelector_dropdown__3CRfg > button .CategorySelector_clear__2kzD5 {\n  margin-left: auto;\n  display: flex;\n  padding: 0.125rem;\n  border: 1px solid;\n  border-radius: 2px;\n  border-color: #b7b2b2;\n}\n\n.CategorySelector_dropdown__3CRfg > button .CategorySelector_clear__2kzD5> img {\n  width: 20px;\n  height: 20px;\n}", ""]);
// Exports
exports.locals = {
	"block2": "CategorySelector_block2__e9lXW",
	"borderRadius2": "CategorySelector_borderRadius2__2D39u",
	"secondaryColor": "CategorySelector_secondaryColor__28QSt",
	"primaryColor": "CategorySelector_primaryColor__3P9Gc",
	"btn": "CategorySelector_btn__248HY",
	"titleTextContainer": "CategorySelector_titleTextContainer__1IEzo",
	"dropdownMenu": "CategorySelector_dropdownMenu__1YKF8",
	"dropdown": "CategorySelector_dropdown__3CRfg",
	"clear": "CategorySelector_clear__2kzD5"
};
module.exports = exports;
