/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * ExternalCategorySelector
 *
 * @author Naseef O
 */

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import Col from "../../../common/core/Col";
import Row from "../../../common/core/Row";
import FormGroup from "../../../common/core/FormGroup";
import InputGroup from "../../../common/core/InputGroup";
import Input from "../../../common/core/Input";
import InputGroupAddon from "../../../common/core/InputGroupAddon";
import Button from "../../../common/core/Button";

import SearchIcon from "../../../common/assets/images/svg/search.svg";
import ClearIconAlt from "../../../common/assets/images/svg/clear-alt.svg";
import ClearIcon from "../../../common/assets/images/svg/clear.svg";

import TreeExternalCategorySelector from "./TreeExternalCategorySelector";

import styles from "../css/ExternalCategorySelector.module.css";

const ExternalCategorySelector = ({
  loading,
  disabled,
  searchKey,
  handleSearch,
  externalCategories,
  handleRemove,
  handleSelectCategories,
  loadingExternalCategories,
  tempSelectedExternalCategories,
  loadingSelectedExternalCategories,
  handleSaveExternalCategoryMappings,
}) => (
  <div className={clsx(styles.block2, styles.blockWrapper, "common-page-card")}>
    <Row className={clsx(styles.externalCategorySection, "justify-content-between m-0")}>
      <Col xs="6" className="">
        <FormGroup className=" m-0">
          <InputGroup className={clsx(styles.customInput, "color-tertiary-main")}>
            <Input placeholder="Search categories " value={searchKey} onChange={(e) => handleSearch(e)} />
            <InputGroupAddon
              addonType="append"
              className="commonPointer bg-color-tertiary-main"
            >
              <img
                role="presentation"
                src={searchKey ? ClearIconAlt : SearchIcon}
                onClick={() => handleSearch({ target: { value: "" } })}
                width="25px"
                alt={searchKey ? "clear search" : "search category"}
              />
            </InputGroupAddon>
          </InputGroup>
        </FormGroup>
      </Col>
      <Col xs="auto">
        <Button
          type="submit"
          color="secondary"
          disabled={disabled || loading}
          onClick={() => !disabled && !loading && handleSaveExternalCategoryMappings()}
          className="common-primary-button "
        >
          SAVE
        </Button>
      </Col>
    </Row>
    <Row className="mx-0 mt-4">
      <Col xs="6" className={styles.extCatTree}>
        <TreeExternalCategorySelector
          externalCategories={externalCategories}
          handleSelectCategories={handleSelectCategories}
          loadingExternalCategories={loadingExternalCategories}
          tempSelectedExternalCategories={tempSelectedExternalCategories}
        />
      </Col>
      <Col xs="6">
        <Row className="m-0">
          {Array.isArray(tempSelectedExternalCategories)
            && tempSelectedExternalCategories.length > 0
            ? tempSelectedExternalCategories.map((item) => (
              <Col xs="auto">
                <span className={clsx(styles.categoryChip, "d-flex justify-content-between align-items-center")}>
                  <span className={clsx(styles.textPrimary, "mr-1")}>{item.externalCategoryName}</span>
                  <span
                    className={styles.closeIconWrapper}
                    role="presentation"
                    title="Remove"
                    onClick={() => handleRemove(item.id)}
                  >
                    <img className={styles.close} src={ClearIcon} alt="clear" />
                  </span>
                </span>
              </Col>
            )) : (
              <Col xs="auto" className="mt-3">
                {loadingSelectedExternalCategories ? "Loading..." : "Nothing found!"}
              </Col>
            )}
        </Row>
      </Col>
    </Row>
  </div>
);

ExternalCategorySelector.defaultProps = {
  loading: false,
  disabled: false,
  searchKey: "",
  externalCategories: [],
  loadingExternalCategories: false,
  tempSelectedExternalCategories: [],
  loadingSelectedExternalCategories: false,
};
ExternalCategorySelector.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  searchKey: PropTypes.string,
  handleSearch: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  loadingExternalCategories: PropTypes.bool,
  handleSelectCategories: PropTypes.func.isRequired,
  loadingSelectedExternalCategories: PropTypes.bool,
  externalCategories: PropTypes.arrayOf(PropTypes.any),
  tempSelectedExternalCategories: PropTypes.arrayOf(PropTypes.any),
  handleSaveExternalCategoryMappings: PropTypes.func.isRequired,
};

export default ExternalCategorySelector;
