/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * App configurations
 * @module
 */

const getSubdomain = (hostname, siteNameSeparator) => {
  const containsWww = hostname.includes("www.");
  const splitIndex = siteNameSeparator === "." && containsWww ? 1 : 0;
  let subDomain = hostname.split(siteNameSeparator)[splitIndex];
  if (subDomain && siteNameSeparator !== "." && containsWww) {
    subDomain = subDomain.split(".")[1];
  }
  return subDomain;
};

const constructAppApiBaseURL = () => {
  const apiHost = process.env.REACT_APP_API_HOST || "adminapi.local.mozantacommerce.com:8071";
  const siteNameSeparator = process.env.REACT_APP_SITE_NAME_SEPARATOR || ".";
  const disableConstructApiUrl = process.env.REACT_APP_DISABLE_CONSTRUCT_API_URL || false;
  if (String(disableConstructApiUrl).toLowerCase() === "true") {
    return apiHost;
  }
  const { location: { protocol, hostname } } = window;
  const hostSubdomain = hostname ? getSubdomain(hostname, siteNameSeparator) : null;
  return `${protocol}//${hostSubdomain}${siteNameSeparator}${apiHost}`;
};

const apiHostName = constructAppApiBaseURL();

const config = {
  environment: process.env.ENVIRONMENT || "LOCAL",
  api: {
    baseURL: `${apiHostName}/api/`,
    loginURL: `${apiHostName}/login`,
    logoutURL: `${apiHostName}/v1/auth/logout`,
  },
  storeFrontURL: process.env.REACT_APP_STOREFRONT_URL || "http://local.famoz.com/:3000",
  imageBaseUrl: "https://backupimages.blob.core.windows.net/kiabi/images",
  key: {
    googleMap: "AIzaSyDp0qkfNuXBDwggC5l78-qXqYs2-fpbfVY",
  },
  authServerHost: process.env.REACT_APP_AUTH_SERVER_HOST || "http://localhost:6443",
};

export default config;
