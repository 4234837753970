/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Aardra S
 */

import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ===== TAG COMPONENTS =========== */
import Col from "../../../common/core/Col";
import Row from "../../../common/core/Row";
import Form from "../../../common/core/Form";
import Button from "../../../common/core/Button";
import Alert from "../../../common/core/Alert";

/** ========== SUB COMPONENT ================ */
import EditStoreForm from "./EditStoreForm";

/** ======== MODULE STYLES ========== */
import styles from "../css/EditStore.module.css";

const EditStore = (props) => {
  const {
    cancelForm,
    name,
    events,
    services,
    showInStorefront,
    hdAvailable,
    cncAvailable,
    contactInfo,
    workingHours,
    pageTitle,
    metaDescription,
    handleChange,
    handleFormChange,
    message,
    onSubmitting,
    onSubmit,
    handleFormObjectChange,
    onLocationChange,
    storeId,
    validateStoreId,
    error,
    cityList,
    dncAvailable,
    handleShowInstoreStatus,
    selectedLanguageChange,
    translatableFields,
    selectedLanguage,
    areas,
    fulfillmentStore,
    serviceAvilableHours,
  } = props;

  /** internationalization using i18n from common and store  */
  const { t } = useTranslation(["common", "store"]);

  return (
    <Row className="">
      <Col xs="12">
        <Form onSubmit={onSubmit}>
          {
            message && message.type && (
              <Alert color={message.type}>
                {message.message}
              </Alert>
            )
          }
          <fieldset disabled={onSubmitting}>
            <Row className="pageHeader common-page-card">
              <Col xs="12" md="7" className="text-truncate">
                <span className="pageText">
                  {t("store:title_edit")}
                </span>
              </Col>
              <Col xs="12" md="5" className="d-flex justify-content-end">
                <Button type="button" onClick={cancelForm} color="secondary" className={clsx(styles.borderRadius2, styles.btn, styles.secondaryColor, "text-truncate", "mr-1")}>
                  {t("common:button_cancel")}
                </Button>
                <Button color="secondary" className={clsx(styles.borderRadius2, styles.btn, styles.primaryColor, "text-truncate", "mr-1", "button-font-size")}>
                  {t("common:button_update")}
                </Button>
              </Col>
            </Row>
            <Row className="common-page-card">
              <Col xs="12" sm="6">
                <select className="form-control" onChange={(e) => selectedLanguageChange(e.target.value)}>
                  <option value="en_AE">English</option>
                  <option value="ar_AE">Arabic</option>
                </select>
              </Col>
            </Row>
            <div className="common-page-card">
              {/*  ====== STORE FORM ============ */}
              <EditStoreForm
                name={name}
                events={events}
                services={services}
                showInStorefront={showInStorefront}
                hdAvailable={hdAvailable}
                dncAvailable={dncAvailable}
                cncAvailable={cncAvailable}
                contactInfo={contactInfo}
                workingHours={workingHours}
                pageTitle={pageTitle}
                metaDescription={metaDescription}
                handleFormChange={handleFormChange}
                handleChange={handleChange}
                handleFormObjectChange={handleFormObjectChange}
                onLocationChange={onLocationChange}
                storeId={storeId}
                validateStoreId={validateStoreId}
                error={error}
                cityList={cityList}
                handleShowInstoreStatus={handleShowInstoreStatus}
                translatableFields={translatableFields}
                selectedLanguage={selectedLanguage}
                areas={areas}
                fulfillmentStore={fulfillmentStore}
                serviceAvilableHours={serviceAvilableHours}
              />
            </div>
          </fieldset>
        </Form>
      </Col>
    </Row>
  );
};

EditStore.propTypes = {
  cancelForm: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  events: PropTypes.string.isRequired,
  services: PropTypes.string.isRequired,
  showInStorefront: PropTypes.bool.isRequired,
  hdAvailable: PropTypes.bool.isRequired,
  dncAvailable: PropTypes.bool.isRequired,
  cncAvailable: PropTypes.bool.isRequired,
  handleFormObjectChange: PropTypes.func.isRequired,
  contactInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  workingHours: PropTypes.objectOf(PropTypes.any).isRequired,
  pageTitle: PropTypes.string.isRequired,
  metaDescription: PropTypes.string.isRequired,
  onSubmitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  message: PropTypes.objectOf(PropTypes.any).isRequired,
  onLocationChange: PropTypes.func.isRequired,
  storeId: PropTypes.string.isRequired,
  validateStoreId: PropTypes.func.isRequired,
  error: PropTypes.objectOf(PropTypes.any).isRequired,
  cityList: PropTypes.objectOf(PropTypes.any).isRequired,
  handleShowInstoreStatus: PropTypes.func.isRequired,
  selectedLanguageChange: PropTypes.func.isRequired,
  translatableFields: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedLanguage: PropTypes.string.isRequired,
  areas: PropTypes.objectOf(PropTypes.any).isRequired,
  serviceAvilableHours: PropTypes.string.isRequired,
};

export default EditStore;
