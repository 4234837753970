/**
 * Copyright(c) 2022 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * CategoryTableListView
 *
 * @author Roshna Accu
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import clsx from "clsx";
import moment from "moment";
import Table from "../../../common/core/Table";
import Label from "../../../common/core/Label";

import categoryUtils from "../../../common/utils/categoryUtils";
import commonUtils from "../../../common/utils/commonUtils";
import ConfirmationModal from "../../../common/components/ConfirmationModal";

import styles from "../css/CategoryManagement.module.css";

import EditIcon from "../../../common/assets/images/svg/edit.svg";
import DeleteIcon from "../../../common/assets/images/svg/delete.svg";

const CategoryTableListView = (props) => {
  const {
    loading,
    tableData,
    hideSource,
    confirmDelete,
    submitting,
    changeCategoryStatus,
    editCategory,
    cancelConfirm,
    deleteCategory,
    isOpen,
    handleDeleteCategory
  } = props;

  const { t } = useTranslation(["common", "category"]);

  return (
    <div className="custom-table mt-3">
      <Table className={clsx(styles.categoryTable)} borderless>
        <thead>
          <tr>
            <th>{t("category:label_category_id")}</th>
            <th>{t("category:label_category_name")}</th>
            <th>{t("common:label_type")}</th>
            <th>{t("common:label_startDate")}</th>
            <th>{t("common:label_endDate")}</th>
            <th className={clsx(styles.unsetMinWidth)}>{t("common:label_status")}</th>
            {!hideSource ? (
              <th className="text-center">Source</th>
            ) : ""}
            <th className="text-center">{t("common:label_actions")}</th>
          </tr>
        </thead>
        <tbody>
          {
            tableData.length > 0
              ? tableData.map((each) => (
                <tr key={each.id}>
                  <td className="text-truncate">
                    {each.id}
                  </td>
                  <td className="text-truncate">
                    {each.name}
                  </td>
                  <td className="text-truncate">{each.type}</td>
                  <td className="text-truncate">
                    {moment(each.startDate).format("DD-MMM-YY")}
                  </td>
                  <td className="text-truncate">
                    {moment(each.endDate).format("DD-MMM-YY")}
                  </td>
                  <td align="center">
                    <div
                      className={clsx(
                        "custom-control",
                        "custom-switch",
                        submitting ? "loading" : "",
                      )}
                    >
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id={`expand-multiple${each.id}`}
                        checked={each.active}
                        onChange={(o) => changeCategoryStatus(!each.active, each.id, o)}
                        disabled={isOpen}
                      />
                      <Label className="custom-control-label" htmlFor={`expand-multiple${each.id}`} />
                    </div>
                  </td>
                  {!hideSource ? (
                    <td align="center">
                      <div>{categoryUtils.getSourceByCode(each.source) || "Standard"}</div>
                    </td>) : ""}

                  <td align="center">
                    <div className={clsx("d-flex", "justify-content-center", styles.actions)}>
                      <span disabled={!each.active} className="commonPointer mr-2" onClick={(o) => editCategory(each.id, o)} role="button" tabIndex={0} onKeyPress={() => { }}>
                        <img
                          className={styles.icon}
                          src={EditIcon}
                          alt="Edit"
                        />
                      </span>
                      <span disabled={Boolean(commonUtils.isListNotEmpty(each.childCategories) && each.active) || (commonUtils.isListNotEmpty(each.childProducts))} className="commonPointer" onClick={(o) => handleDeleteCategory(each.id, o)} role="button" tabIndex={0} onKeyPress={() => { }}>
                        <img
                          className={styles.icon}
                          src={DeleteIcon}
                          alt="Delete"
                        />
                      </span>
                    </div>
                  </td>
                </tr>
              )) : (
                <tr>
                  <td colSpan="100%" align="center">
                    {loading ? "Loading..." : "Nothing to show."}
                  </td>
                </tr>
              )}
        </tbody>
      </Table>
      <ConfirmationModal
        isOpen={Boolean(deleteCategory)}
        toggleOpen={cancelConfirm}
        togglClose={cancelConfirm}
        handleConfirm={confirmDelete}
        content=" Are you sure you want to delete this category ?"
      />
    </div>
  );
};

CategoryTableListView.propTypes = {
  loading: PropTypes.bool.isRequired,
  tableData: PropTypes.arrayOf(PropTypes.any).isRequired,
  hideSource: PropTypes.string.isRequired,
  confirmDelete: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  changeCategoryStatus: PropTypes.func.isRequired,
  editCategory: PropTypes.func.isRequired,
  cancelConfirm: PropTypes.func.isRequired,
  deleteCategory: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleDeleteCategory: PropTypes.func.isRequired,
};

export default CategoryTableListView;