/**
 * Copyright(c) 2022 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Rahna 
 */

import React, { useState, useEffect } from "react";
import { useHistory, useRouteMatch, useLocation } from "react-router-dom";
/** ======== CUSTOM COMPONENTS ========= */
import EditExtendedWarranty from "../components/EditExtendedWarranty";

/** ===== API SERVICE FUNCTIONS =========== */
import { updateWarrantyProductOption, getProductOptionById } from "../../../api/productManagementServices";
import commonUtils from "../../../common/utils/commonUtils";


/** Base container for edit productOption details */
const EditExtendedWarrantyContainer = () => {
  const history = useHistory();
  const { params } = useRouteMatch();
  const location = useLocation();
  const { productOptionId } = commonUtils.getQueryParams(location);
  const { productId } = params;
  if (!productId) {
    history.push("/merchandising/product");
  }

  /** local states */
  const [form, setForm] = useState({
    id: "",
    name: "",
    productValue: "",
    rate: "",
    year: "",
    productId: ""
  });
  const [onSubmitting, setOnSubmitting] = useState(false);
  const [locale, setLocale] = useState("en_AE");

  const localeHandler = (localeLang) => {
    setLocale(localeLang);
  };
  useEffect(() => {
    let localForm = form;
    getProductOptionById(productOptionId, locale).then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        if (data) {
          localForm = {
            ...form,
            id: data.id,
            name: data.uniqueId || "",
            productValue: data.name || "",
            rate: data.rate || 0,
            year: data.year || 1,
            productId: data.productId,
            priceType: data.priceType
          };
          setForm(localForm);
        }
      }
    });
  }, [productOptionId, locale]);

  /**
   * This method is used to change fom state
   * @param {String} name
   * @param {String} value
   */
  const handleChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };
  /**
 
  /**
   * This method is used to change form filed input
   * @param {Event} event
   */
  const handleFormChange = (name, event) => {
    const { value } = event.target
    handleChange(name, value);
  };

  /**
   * This method is used for  cancel and redirect to product listing page
   */
  const onCancel = () => {
    history.push(`/merchandising/product/edit/${productId}`);
  };

  /**
   * This method is used to submit the form
   * @param {Event} event
   */
  const onSubmit = async (value) => {
    const requestBody = {
      id: form.id,
      name: form.productValue,
      type: form.productType,
      rate: form.rate,
      year: form.year,
      priceType: form.priceType
    };
    if (value) {
      /** setting on submit true */
      setOnSubmitting(true);
      const response = await updateWarrantyProductOption(requestBody, locale);
      if (response && response.success) {
        setOnSubmitting(false);
        history.push(`/merchandising/product/edit/${productId}`);
      }
    }
  };

  return (
    <EditExtendedWarranty
      /** form data */
      name={form.name}
      productValue={form.productValue}
      price={form.rate}
      year={form.year}
      /** sate change events */
      handleChange={handleChange}
      handleFormChange={handleFormChange}
      /** form action */
      onSubmitting={onSubmitting}
      onCancel={onCancel}
      onSubmit={onSubmit}
      localeHandler={localeHandler}
    />
  );
};

EditExtendedWarrantyContainer.propTypes = {

};
export default EditExtendedWarrantyContainer;
