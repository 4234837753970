/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Mohammed Shefeeq
 */

import httpApi from "./httpApi";

const getBatchSchedulers = async () => {
  const response = await
    httpApi.get("/v1/batch-scheduler/all");
  if (response && response.data.success && response.data) {
    return response.data;
  }
  return null;
};

const changeStatus = async (id) => {
  const response = await httpApi.patch(`/v1/batch-scheduler/activate/${id}`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

const getBatchScheduler = async (id) => {
  const response = await httpApi.get(`/v1/batch-scheduler/id/${id}`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

const updateScheduler = async (jobScheduler) => {
  const response = await httpApi.patch("/v1/batch-scheduler", jobScheduler);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

const validateCron = async (cron) => {
  const params = { cron };
  const response = await httpApi.get("/v1/batch-scheduler/validate-cron", { params });
  if (response && response.data) {
    return response.data;
  }
  return null;
};

const restartInactiveScheduler = async (id) => {
  const response = await httpApi.patch(`/v1/batch-scheduler/restart/id/${id}`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

export {
  getBatchSchedulers,
  changeStatus,
  getBatchScheduler,
  updateScheduler,
  validateCron,
  restartInactiveScheduler,
};
