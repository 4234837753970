/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * CategorySelector
 *
 * @author Naseef O
 */

import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import clsx from "clsx";

import TreeCategorySelector from "./TreeCategorySelector";

import Col from "../../../common/core/Col";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import Dropdown from "../../../common/core/Dropdown";
import DropdownToggle from "../../../common/core/DropdownToggle";
import DropdownMenu from "../../../common/core/DropdownMenu";

import styles from "../css/CategorySelector.module.css";
import styles2 from "../css/ExternalCategories.module.css";

import { getRootCategoriesBySource } from "../../../api/categoryManagementServices";
import constants from "../../../common/utils/constants";

const CategorySelector = ({
  categories, onChange,
  noProductsOnly,
  id,
  exclude,
  dropdownOpen,
  toggle,
  clearCategories,
}) => {
  const [categoryList, setCategoryList] = useState([]);
  const [totalCategoryList, setTotalCategoryList] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  /**
   * This method is sued to update global category list
   * @param {Array} localList
   */
  const updateUniqueTotalCategoryList = (localList) => {
    if (Array.isArray(localList)) {
      setTotalCategoryList((oldList) => {
        const listIds = oldList.map((l) => l && l.id);
        const filteredLocalList = localList.filter((each) => !listIds.includes(each.id));
        return [...oldList, ...filteredLocalList];
      });
    }
  };

  useEffect(() => {
    getRootCategoriesBySource(constants.CATEGORY_SOURCE.PIM).then((response) => {
      if (response && response.success === true) {
        const { data } = response;
        if (Array.isArray(data)) {
          setCategoryList(data);
          updateUniqueTotalCategoryList(data);
        }
      }
    });
  }, []);


  useEffect(() => {
    if (Array.isArray(categories) && categories.length > 0) {
      const ids = categories.map((item) => item.id);
      setSelectedCategories(ids);
    }
  }, [categories]);

  /**
   * This method is used to clear categories
   */
  const localClearCategories = () => {
    clearCategories();
    setSelectedCategories([]);
  };

  /**
  * This method is sued to handle category change
  * @param {String} categoryId
  */
  const handleSelectedCategories = (categoryId) => {
    const localSelectedCategories = [...selectedCategories];
    const localIndex = selectedCategories.indexOf(categoryId);

    if (localIndex > -1) {
      localSelectedCategories.splice(localIndex, 1);
    } else {
      localSelectedCategories.push(categoryId);
    }

    setSelectedCategories(localSelectedCategories);
    const newSelectedListWithObjects = totalCategoryList.filter(
      (each) => localSelectedCategories.includes(each.id),
    );
    onChange(newSelectedListWithObjects);
  };

  return (
    <Col xs="12" sm="7" md="6" className={clsx(styles.rightDivider, "position-relative")}>
      <FormGroup>
        <Label className={styles2.formLabelWrapper}>Select categories</Label>
        <Dropdown inNavbar isOpen={dropdownOpen} toggle={toggle} direction="down" className={`${styles.dropdown}`}>
          <DropdownToggle
            className="formText form-control"
            caret
          >
            <span className="text-truncate">
              {Array.isArray(categories) && categories.length > 0 ? categories.map((item, index) => <>{` ${item.name}${categories.length - 1 > index ? "," : ""}`}</>) : "Select categories"}
            </span>
            <span className="ml-auto">
              <span />
            </span>
          </DropdownToggle>
          <DropdownMenu className={clsx(styles.dropdownMenu, "custom-scrollbar")}>
            <TreeCategorySelector
              categoryList={categoryList}
              categories={selectedCategories}
              handleSelectedCategories={handleSelectedCategories}
              handleTreeCategorySelector={onChange}
              updateUniqueTotalCategoryList={updateUniqueTotalCategoryList}
              noProductsOnly={noProductsOnly}
              htmlId={id}
              exclude={exclude}
            />
          </DropdownMenu>
        </Dropdown>
      </FormGroup>
    </Col>

  );
};

CategorySelector.defaultProps = {
  noProductsOnly: false,
  id: "cat-tree",
  exclude: [],
};

CategorySelector.propTypes = {
  categories: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.any)]).isRequired,
  onChange: PropTypes.func.isRequired,
  noProductsOnly: PropTypes.bool,
  id: PropTypes.string,
  exclude: PropTypes.arrayOf(PropTypes.any),
  dropdownOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  clearCategories: PropTypes.func.isRequired,
};

export default CategorySelector;
