/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */
import clsx from "clsx";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

/** ========= TAG COMPONENTS ========== */
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Input from "../../../common/core/Input";
import Table from "../../../common/core/Table";
import Button from "../../../common/core/Button";
import InputGroup from "../../../common/core/InputGroup";
import InputGroupAddon from "../../../common/core/InputGroupAddon";
import Alert from "../../../common/core/Alert";
import DropdownMenu from "../../../common/core/DropdownMenu";
import Dropdown from "../../../common/core/Dropdown";
import DropdownToggle from "../../../common/core/DropdownToggle";

/** ========= CUSTOM COMPONENTS =============== */
import Image from "./Image";
import Pagination from "../../../common/components/Pagination";

/** ========= SVG IMAGE ICON ============= */
import EditIcon from "../../../common/assets/images/svg/edit.svg";
import SearchIcon from "../../../common/assets/images/svg/search.svg";
import successIcon from "../../../common/assets/images/svg/success.svg";
// TODO: Hiding delete button for now
// import DeleteIcon from "../../../common/assets/images/svg/delete.svg";
import ClearIconAlt from "../../../common/assets/images/svg/clear-alt.svg";
import FilterIcon from "../../../common/assets/images/svg/filter.svg";
import ProductListIcon from "../../../common/assets/images/svg/product-list.svg";
import copyIcon from "../../../common/assets/images/svg/copy.svg";

/** ========= UTILS/CONFIG =============== */
import { getProductType } from "../../../common/utils/commonUtils";

/** ========= MODULE STYLES =============== */
import styles from "../css/ProductManagement.module.css";

const options = [
  { label: "DSV", value: true },
  { label: "Operator", value: false },
];

const ProductManagement = (props) => {
  const {
    productData,
    changeProductStatus,
    submitting,
    getPageData,
    editProduct,
    searchTerm,
    handleSearchTerm,
    handlePushToLive,
    pushToLiveEnabled,
    message,
    previewSiteUrl,
    previewEnabled,
    pushToLiveButtonEnabled,
    getReplicationStatus,
    pagination,
    dropdownOpen,
    toggleDropdown,
    selectedFilter,
    handleSelectFilter,
    viewDetails,
  } = props;
  /** internationalization using i18n from common and product  */
  const { t } = useTranslation(["common", "product"]);

  const getPreviewImage = (medias) => {
    let imageUrl = null;
    if (Array.isArray(medias) && medias[0]) {
      imageUrl = medias[0].mediaThumbnailUrl;
    }
    return imageUrl;
  };

  const redirectToPage = () => {
    const url = `${previewSiteUrl}`;
    window.open(url, "_blank");
  };

  const [successId, setSuccess] = useState(false);

  /**
 * This method is used to copy text to clipboard.
 *
 * @param {Object} e
 * @param {String} text
 */
  const copyToClipboard = (e, text) => {
    e.stopPropagation();
    setSuccess(text);
    const dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    setTimeout(() => {
      setSuccess("");
    }, 2000);
  };


  return (
    <>
      {
        message && message.type && (
          <Alert color={message.type}>
            {message.message}
          </Alert>
        )
      }
      <Row className="pageHeader  common-page-card">
        <Col xs="12" md="6" lg="5" className="text-truncate d-flex align-items-center">
          <img width="20px" src={ProductListIcon} alt="product list" className="mr-2" />
          <span className="pageText">{t("product:title_manage")}</span>
        </Col>
        <Col xs="12" md="6" lg="7" className={styles.liveBtnWrapper}>
          <Row>
            <Col xs="12" md="4" lg="4" className={styles.liveBtnWrapper}>
              {previewEnabled && (
                <Button
                  className={clsx(styles.liveBtn, "float-right", "text-truncate", "btn-tertiary", "button-font-size ")}
                  onClick={() => redirectToPage()}
                >
                  {t("common:button_preview")}
                </Button>
              )}
            </Col>
            <Col xs="12" md="4" lg="4" className={styles.liveBtnWrapper}>
              {pushToLiveEnabled && (
                <Button
                  onClick={handlePushToLive}
                  disabled={!pushToLiveButtonEnabled}
                  className={clsx(styles.liveBtn, "float-right", "text-truncate", "btn-tertiary", "button-font-size")}
                >
                  {t("common:button_push_to_live")}
                </Button>
              )}
            </Col>
            <Col xs="12" md="4" lg="4" className={styles.liveBtnWrapper}>
              {pushToLiveEnabled && (
                <Button
                  onClick={getReplicationStatus}
                  className={clsx(styles.liveBtn, "float-right", "text-truncate", "btn-tertiary", "button-font-size")}
                >
                  {t("common:button_refresh_status")}
                </Button>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={clsx(styles.block2, " common-page-card")}>
        <Col xs="auto" className="py-2">
          <InputGroup className={clsx(styles.customInput, "color-tertiary-main")}>
            <Input
              placeholder={t("product:placeholder_search")}
              value={searchTerm}
              onChange={handleSearchTerm}
            />
            <InputGroupAddon
              addonType="append"
              className="commonPointer bg-color-tertiary-main px-2"
            >
              <img
                src={searchTerm ? ClearIconAlt : SearchIcon}
                alt={t("product:placeholder_search")}
                role="presentation"
                width="25px"
                onClick={() => searchTerm && handleSearchTerm({ target: { value: "" } })}
                className=""
              />
            </InputGroupAddon>
          </InputGroup>
        </Col>

        <Col xs="auto" className="py-2">
          <Dropdown
            isOpen={dropdownOpen}
            toggle={toggleDropdown}
            className={styles.dropdownButtonWrapper}
          >
            <DropdownToggle caret>
              <span
                className={
                  clsx(styles.selectedFilter,
                    !selectedFilter && styles.filterText)
                }
              >
                {selectedFilter ? selectedFilter.label : "Filter by"}
              </span>
            </DropdownToggle>
            <div
              role="presentation"
              className={clsx(styles.dropdownClear, "cursor-pointer")}
              onClick={() => (selectedFilter ? handleSelectFilter(null) : toggleDropdown())}
            >
              {!selectedFilter ? (
                <img
                  src={FilterIcon}
                  alt="Select filter"
                  width="20px"
                  className={styles.icon}
                />
              )
                : (
                  <img
                    src={ClearIconAlt}
                    alt="Clear filter"
                    width="20px"
                    className={styles.icon}
                  />
                )}
            </div>
            <DropdownMenu className={styles.dropdownWrapper}>
              {options.map((option) => (
                option.disabled ? (
                  <div
                    key={`filter-${option.label}`}
                    className={clsx(styles.disabled)}
                  >
                    {option.label}
                  </div>
                )
                  : (
                    <div
                      key={`filter-${option.label}`}
                      onKeyPress={() => { }}
                      role="button"
                      tabIndex="0"
                      className={clsx(styles.dropdownItem)}
                      onClick={() => !option.disabled && handleSelectFilter(option)}
                    >
                      {option.label}
                    </div>
                  )
              ))}
            </DropdownMenu>
          </Dropdown>
        </Col>
        {Array.isArray(productData.content) && productData.content.length > 0 && (
          <Col xs="auto" className="py-2 text-truncate d-flex  align-items-center">
            <span className="font-weight-bold pl-1">{`${pagination.numberOfElements} of ${pagination.totalCount}`}</span>
          </Col>
        )}
        {/* Hiding Add new product button */}
        {/* <Col className={styles.areaActionRight}>
              <Button
                color="primary"
                onClick={addNewProduct}
                className={clsx(
                  styles.borderRadius2,
                  "text-uppercase",
                  "text-truncate",
                  "btn-tertiary",
                )}
              >
                New
              </Button>
            </Col> */}
      </Row>
      <Row
        className={clsx(
          styles.block3,
          styles.tableWrapper,
          " common-page-card",
        )}
      >
        <Col>
          <div className={styles.product}>
            {/*  ====== MAIN COMPONENT ROOT  S[0001]============ */}
            <div className={clsx(styles.productBody, "table-responsive")}>
              <Table borderless className={styles.productTable}>
                {/* ========= TABLE HEAD S[0003] ======================= */}
                <thead>
                  <tr>
                    <th>{t("product:label_id")}</th>
                    <th>{t("product:label_external_id")}</th>
                    <th>{t("common:label_image")}</th>
                    <th>{t("common:label_name")}</th>
                    <th>{t("common:label_product_type")}</th>
                    <th>{t("common:label_status")}</th>
                    <th>{t("common:label_edit")}</th>
                    {/* TODO: Hiding delete button for now */}
                    {/* <th>{t("common:label_delete")}</th> */}
                  </tr>
                </thead>
                {/* ========= TABLE HEAD S[0003] ======================= */}
                <tbody>
                  {/* ========= TABLE BODY S[0002] ======================= */}
                  {Array.isArray(productData.content) && productData.content.length > 0 ? (
                    productData.content.map(({
                      uniqueId, id, name, active, medias, vendorFulfilled, vendorFulfilledFromPim,
                    }) => (
                      <tr
                        key={id}
                        className={styles.productTreeItem}
                        onClick={() => viewDetails(id)}
                      >
                        {/* ========= EACH CATALOGUE ITEM S[0003] ======================= */}
                        <td>
                          <div className="d-flex align-items-center">
                            {successId === id ? (
                              <img
                                src={successIcon}
                                alt="copy"
                                role="presentation"
                                width="15px"
                                className="mr-1"
                                onClick={(e) => copyToClipboard(e, id)}
                              />
                            ) : (
                              <img
                                src={copyIcon}
                                alt="copy"
                                role="presentation"
                                width="15px"
                                className="mr-1"
                                onClick={(e) => copyToClipboard(e, id)}
                              />
                            )}
                            {id}
                          </div>
                        </td>
                        <td>
                          {uniqueId || ""}
                        </td>
                        <td className="p-1 align-middle text-center">
                          <Image
                            src={getPreviewImage(medias)}
                            alt={name}
                            className={clsx(styles.productImage, "img-fluid")}
                          />
                        </td>
                        <td>
                          <span
                            title={name}
                            className={clsx(
                              styles.productName,
                              "text-truncate",
                              "d-block",
                            )}
                          >
                            {name}
                          </span>
                        </td>
                        <td>
                          {getProductType({ uniqueId, vendorFulfilled, vendorFulfilledFromPim })}
                        </td>
                        <td role="presentation" onClick={(o) => o.stopPropagation()}>
                          <div
                            className={clsx(
                              "custom-control",
                              "custom-switch",
                              submitting ? "loading" : "",
                            )}
                            role="presentation"
                            onClick={(o) => changeProductStatus(!active, id, o)}
                          >
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id={`expand-multiple${id}`}
                              checked={active}
                            />
                            {/* added input tag for removing ESLint error >
                               if added above input tag inside label it wont work */}
                            <label
                              className="custom-control-label"
                              htmlFor={`expand-multiple${id}`}
                            >
                              <input type="hidden" />
                            </label>
                          </div>
                        </td>
                        <td role="presentation" onClick={(o) => o.stopPropagation()}>
                          <span
                            onClick={(o) => editProduct(id, o)}
                            onKeyDown={() => { }}
                            tabIndex={0}
                            role="button"
                            className="commonPointer"
                          >
                            <img
                              className={clsx("commonPointer", styles.icon)}
                              src={EditIcon}
                              alt="Edit"
                            />
                          </span>
                        </td>
                        {/* TODO: Hiding delete button for now */}
                        {/* <td>
                          <img
                            className={clsx("commonPointer", styles.icon)}
                            src={DeleteIcon}
                            alt="Delete"
                          />
                        </td> */}
                        {/* ========= EACH CATALOGUE ITEM E[0003] ======================= */}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={8} align="center" valign="center">
                        {" "}
                        Nothing to show.
                      </td>
                    </tr>
                  )}
                  {/* ========= TABLE BODY E[0002] ======================= */}
                </tbody>
              </Table>
            </div>
            {Array.isArray(productData.content) && productData.content.length > 0 && (
              <Pagination
                totalCount={pagination.totalCount}
                page={pagination.page}
                limit={pagination.limit}
                getPageData={getPageData}
              />
            )}
            {/*  ====== MAIN COMPONENT ROOT  E[0001]============ */}
          </div>
        </Col>
      </Row>
    </>
  );
};

ProductManagement.propTypes = {
  productData: PropTypes.objectOf(PropTypes.any).isRequired,
  changeProductStatus: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  getPageData: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  editProduct: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  handleSearchTerm: PropTypes.func.isRequired,
  handlePushToLive: PropTypes.func.isRequired,
  pushToLiveEnabled: PropTypes.bool.isRequired,
  previewEnabled: PropTypes.bool.isRequired,
  previewSiteUrl: PropTypes.string.isRequired,
  pushToLiveButtonEnabled: PropTypes.bool.isRequired,
  getReplicationStatus: PropTypes.func.isRequired,
  message: PropTypes.objectOf(PropTypes.any).isRequired,
  pagination: PropTypes.objectOf(PropTypes.any).isRequired,
  dropdownOpen: PropTypes.bool.isRequired,
  toggleDropdown: PropTypes.func.isRequired,
  selectedFilter: PropTypes.objectOf(PropTypes.any).isRequired,
  handleSelectFilter: PropTypes.func.isRequired,
  viewDetails: PropTypes.func.isRequired,
};

export default ProductManagement;
