/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * SEOInfoEditor
 *
 * @author Naseef O
 *
 */

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Row from "../../../common/core/Row";
import Col from "../../../common/core/Col";
import Input from "../../../common/core/Input";
import Form from "../../../common/core/Form";
import FormGroup from "../../../common/core/FormGroup";
import Label from "../../../common/core/Label";
import FormFeedback from "../../../common/core/FormFeedback";
import Button from "../../../common/core/Button";
import styles from "../css/WidgetContentEditor.module.css";
import validateForm from "../../../common/utils/validateForm";

const WidgetContentEditor = (props) => {
  const {
    handleChange,
    seoInfoForm,
    handleRedirectToPageCustomization,
    handleSubmitForm,
    handleCustomChange,
  } = props;

  const validator = validateForm();
  const { handleSubmit } = validator;
  const { register, errors, unregister } = validator;


  const handleToggle = () => {
    if (seoInfoForm.customSeoEnabled) {
      unregister(["pageTitle", "metaDescription"])
    }
    handleCustomChange("customSeoEnabled", !seoInfoForm.customSeoEnabled);
  }

  return (
    <>
      <Form onSubmit={handleSubmit(handleSubmitForm)}>
        <Row className="common-page-card pageHeader">
          <Col xs="12" md="3" lg="3">
            <span className="pageText">
              SEO Information
          </span>
          </Col>
          <Col xs="12" md="4" lg="5" />
          <Col xs="12" md="5" lg="4" className="buttonGroup">
            <Row>
              <Col xs="6">
                <Button
                  className="buttonCancel"
                  onClick={() => handleRedirectToPageCustomization()}
                >
                  Cancel
              </Button>
              </Col>
              <Col xs="6">
                <Button
                  type="submit"
                  className="buttonSave bg-color-tertiary-main"
                >
                  Save
              </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="common-page-card">
          <Col xs="12">
            <Row>
              <Col xs="12" className="pt-3">
                <h5>Edit</h5>
              </Col>
            </Row>
            <Row className={styles.block2}>
              <Col xs="12">
                <Row>
                  <Col md="8">
                    <Row>
                      <Col md="6" className="p-3">
                        <FormGroup>
                          <Label className="formTextLabel" for="exampleEmail">Page Title</Label>
                          <Input
                            value={seoInfoForm.description}
                            className="formText"
                            placeholder="Title"
                            onChange={(e) => handleChange("description", e)}
                          />
                          <FormFeedback>You will not be able to see this</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12" className="p-3">
                        <FormGroup>
                          <Label className="formTextLabel" for="exampleEmail">Page description</Label>
                          <Input
                            style={{
                              height: "400px",
                              backgroundColor: " black",
                              color: "white",
                            }}
                            type="textarea"
                            value={seoInfoForm.longDescription}
                            placeholder="Provide meta description in html"
                            onChange={(e) => handleChange("longDescription", e)}
                          />
                          <FormFeedback>You will not be able to see this</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Col xs="6" sm="5" lg="4">
                      <FormGroup className={clsx(styles.noLabelFormGroup, "d-flex")}>
                        <Label className="formTextLabel">Custom SEO Enabled</Label>
                        <div
                          className={clsx(
                            "custom-control",
                            "custom-switch",
                            "ml-3",
                          )}
                        >
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customSeoEnabled"
                            name="customSeoEnabled"
                            checked={seoInfoForm.customSeoEnabled}
                            onChange={handleToggle}
                          />
                          <Label className="custom-control-label" htmlFor="customSeoEnabled" />
                        </div>
                      </FormGroup>
                    </Col>
                    <Row>
                      <Col md="6" className="p-3">
                        <FormGroup>
                          <Label className="formTextLabel" for="exampleEmail">Meta Title</Label>
                          <Input
                            value={seoInfoForm.pageTitle}
                            className="formText"
                            placeholder=" Meta Title"
                            name="pageTitle"
                            onChange={(e) => handleChange("pageTitle", e)}
                            disabled={!seoInfoForm.customSeoEnabled}
                            innerRef={
                              seoInfoForm.customSeoEnabled ? (
                                register({
                                  required: "Please enter Meta Title",
                                })) : undefined
                            }
                            invalid={!!(errors.pageTitle && errors.pageTitle.message)}
                          />
                          <FormFeedback>{(errors.pageTitle && errors.pageTitle.message)}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md="6" className="p-3">
                        <FormGroup>
                          <Label className="formTextLabel" for="exampleEmail">Meta Keywords</Label>
                          <Input
                            value={seoInfoForm.metaKeywords}
                            className="formText"
                            placeholder="Meta Keywords"
                            onChange={(e) => handleChange("metaKeywords", e)}
                          />
                          <FormFeedback>You will not be able to see this</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12" className="p-3">
                        <FormGroup>
                          <Label className="formTextLabel" for="exampleEmail">Meta Description</Label>
                          <Input
                            value={seoInfoForm.metaDescription}
                            className="formText"
                            placeholder="Meta Description"
                            name="metaDescription"
                            onChange={(e) => handleChange("metaDescription", e)}
                            disabled={!seoInfoForm.customSeoEnabled}
                            innerRef={
                              seoInfoForm.customSeoEnabled ? (
                                register({
                                  required: "Please enter Meta Description",
                                })) : undefined
                            }
                            invalid={!!(errors.metaDescription && errors.metaDescription.message)}
                          />
                          <FormFeedback>{(errors.metaDescription && errors.metaDescription.message)}</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
};


WidgetContentEditor.propTypes = {
  handleChange: PropTypes.func.isRequired,
  seoInfoForm: PropTypes.objectOf(PropTypes.any).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleRedirectToPageCustomization: PropTypes.func.isRequired,
};

export default WidgetContentEditor;
