/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Indrajith C
 */

import { useHistory, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";

/** ===== COMPONENTS  =========== */
import CategoryManagement from "../components/CategoryManagement";

/** ===== API SERVICE FUNCTIONS =========== */
import { getRootCategories, getCategories } from "../../../api/categoryManagementServices";
import {
  getSitePreviewConfigurations, getRunningJobs, replicateCatalogue, getCatalogueReplicationStatus,
} from "../../../api/sitePreviewConfigurationsServices";

import categoryUtils from "../../../common/utils/categoryUtils";
import constants from "../../../common/utils/constants";
import commonUtils from "../../../common/utils/commonUtils";

const CategoryManagementContainer = () => {
  const history = useHistory();
  const location = useLocation();
  const { view } = commonUtils.getQueryParams(location);

  /** local states */
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [pushToLiveEnabled, setPushToLiveEnabled] = useState(false);
  const [message, setMessage] = useState({
    type: null,
    message: "",
  });
  const [selectedFilter, setFilter] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const [dropdownOpen, setDropdown] = useState(false);

  /**
   * This method is used to valid table view
   *
   * @returns
   */
  const getValidTableView = () => {
    let validView = constants.TABLE_VIEW.TREE_VIEW;
    if (view === constants.TABLE_VIEW.LIST_VIEW) {
      validView = constants.TABLE_VIEW.LIST_VIEW;
    }
    return validView;
  };
  const [tableView, setTabView] = useState(getValidTableView());
  const [pagination, setPagination] = useState({
    page: 0,
    totalPages: 0,
    totalCount: 0,
    limit: 6,
    numberOfElements: 0,
  });

  const clearData = () => {
    setSearchKey("");
    setFilter(null);
    setPagination({
      page: 0,
      totalPages: 0,
      totalCount: 0,
      limit: 6,
      numberOfElements: 0,
    });
  };

  useEffect(() => {
    setCategories([]);
    setLoading(true);
    if (tableView === constants.TABLE_VIEW.LIST_VIEW) {
      getCategories(
        searchKey.trim(),
        selectedFilter ? categoryUtils.getSourceByLabel(selectedFilter.label) : null,
      ).then((response) => {
        if (response && response.success && response.data) {
          const {
            content, totalPages, totalElements, numberOfElements: totalItems,
          } = response.data;
          setPagination({
            ...pagination,
            page: 0,
            totalPages,
            totalCount: totalElements,
            numberOfElements: totalItems,
          });
          setCategories(content);
        }
      });
    } else {
      getRootCategories().then((response) => {
        if (response && response.success && response.data) {
          setCategories(response.data);
          clearData();
        } else {
          setCategories([]);
          clearData();
        }
      });
    }

    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, [selectedFilter, searchKey, tableView]);

  /**
   * This method is used to toggle dropdown
   */
  const toggleDropdown = () => {
    setDropdown(!dropdownOpen);
  };


  /**
  * This method is used to select filter
  * @param {Object} selected
  */
  const handleSelectFilter = (selected) => {
    if (selected) {
      setFilter(selected);
      toggleDropdown();
    } else {
      setFilter(null);
    }
  };

  const setAlert = (alertData) => {
    setMessage(alertData);
    setTimeout(() => {
      setMessage({ type: null, message: "" });
    }, 3000);
  };

  const [previewSiteUrl, setPreviewSiteUrl] = useState("");
  const [previewEnabled, setPreviewEnabled] = useState(false);
  const [pushToLiveButtonEnabled, setPushToLiveButtonEnabled] = useState(false);
  const [showPushToLiveAlert, setShowPushToLiveAlert] = useState(false);

  useEffect(() => {
    getSitePreviewConfigurations().then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        if (data) {
          setPreviewEnabled(data.previewEnabled || false);
          setPushToLiveEnabled(data.pushToLiveEnabled || false);
          const { previewSiteUrl: localPreviewSiteUrl } = data;
          setPreviewSiteUrl(localPreviewSiteUrl);
          getReplicationStatus();
        }
      }
    });
  }, []);

  /**
   * This method is used to load edit page
   * @param {String} categoryId
   */
  const addNewCategory = () => {
    history.push("/merchandising/navigation-taxonomy/new");
  };


  const handlePushToLive = async () => {
    const runningJobsResponse = await getRunningJobs();
    if (runningJobsResponse) {
      const { data: runningJobs } = runningJobsResponse;
      if (!runningJobs || runningJobs.length === 0) {
        setPushToLiveButtonEnabled(false);
        setShowPushToLiveAlert(true);
        replicateCatalogue().then((response) => {
          if (response && response.success) {
            const alertData = {
              type: "success",
              message: "Categories pushed to live successfully. Please refresh the search indexes through Administration > Search Index Management, there may be a small delay for reflecting the same in live.",
            };
            setAlert(alertData);
          } else {
            const alertData = {
              type: "danger",
              message: "Something went wrong. Push to live failed",
            };
            setAlert(alertData);
          }
        });
      } else {
        const alertData = {
          type: "warning",
          message: "An Automated batch job is running in background, Please wait and try again later to make your changes live.",
        };
        setAlert(alertData);
      }
    }
    return null;
  };

  const getReplicationStatus = async () => {
    getCatalogueReplicationStatus().then((response) => {
      if (response
        && response.success
        && ((response.data && response.data.completed) || (!response.data))) {
        setPushToLiveButtonEnabled(true);
        if (showPushToLiveAlert) {
          const alertData = {
            type: "success",
            message: "The last category push to live is successfully completed.",
          };
          setAlert(alertData);
        }
        setShowPushToLiveAlert(true);
      } else {
        setPushToLiveButtonEnabled(false);
        if (showPushToLiveAlert) {
          const alertData = {
            type: "warning",
            message: "Please wait some more time to reflect the changes in live.",
          };
          setAlert(alertData);
        }
        setShowPushToLiveAlert(true);
      }
    }).catch((error) => {
      setPushToLiveButtonEnabled(false);
      setShowPushToLiveAlert(true);
    });
    return null;
  };

  const updatedQueryParam = (query) => {
    const params = new URLSearchParams()
    Object.keys(query).forEach(each => {
      params.append(each, query[each])
    })
    history.push({ search: params.toString() })
  }
  
  /**
* This method is used to toggle table view
*
* @param {*} id
*/
  const toggleTableView = (id) => {
    setTabView(id);
    updatedQueryParam({ view: id })
  };

  /**
   * This method is used to handle change search
   * @param {Object} e
   */
  const handleSearch = async (e) => {
    const key = e.target.value;
    setSearchKey(key);
  };

  /**
 * This method is used get all categories
 * @param {Number} pageNo
 */
  const getPageData = (pageNo = 0) => {
    getCategories(
      searchKey.trim(),
      selectedFilter ? categoryUtils.getSourceByLabel(selectedFilter.label) : null,
      pageNo,
    ).then((response) => {
      if (response && response.success && response.data) {
        const {
          content, totalPages, totalElements, endOfResult,
        } = response.data;
        setPagination({
          ...pagination,
          page: pageNo,
          totalPages,
          totalCount: totalElements,
          numberOfElements: !endOfResult
            ? pagination.limit * (pageNo + 1)
            : totalElements,
        });
        setCategories(content);
      }
    });
  };
  return (
    <CategoryManagement
      page={constants.CATEGORY_PAGE_TYPE.CATEGORY_PAGE}
      loading={loading}
      categories={categories}
      addNewCategory={addNewCategory}
      pushToLiveEnabled={pushToLiveEnabled}
      previewSiteUrl={previewSiteUrl}
      handlePushToLive={handlePushToLive}
      previewEnabled={previewEnabled}
      pushToLiveButtonEnabled={pushToLiveButtonEnabled}
      getReplicationStatus={getReplicationStatus}
      message={message}
      toggleTableView={toggleTableView}
      tableView={tableView}
      setTabView={setTabView}
      searchKey={searchKey}
      handleSearch={handleSearch}
      pagination={pagination}
      handleSelectFilter={handleSelectFilter}
      dropdownOpen={dropdownOpen}
      toggleDropdown={toggleDropdown}
      getPageData={getPageData}
      selectedFilter={selectedFilter}
    />
  );
};
export default CategoryManagementContainer;
