/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Roshna Accu
 */

import React, { useState, useEffect } from "react";
import XLSX from "xlsx";
import CartManagement from "../component/CartManagement";
import {
  getCartSettings,
  updateCartSettings,
} from "../../../api/cartSettingsService";
import {
  getSitePreviewConfigurations,
  replicateGlobalSettings,
  getGlobalSettingsReplicationStatus,
} from "../../../api/sitePreviewConfigurationsServices";
import cartUtils from "../../../common/utils/cartUtils";
import CartLimitUploadStatusModal from "../component/CartLimitUploadStatusModal";
import { updateCartLimitByUniqueId } from "../../../api/skuServices";
import { updateBatchConfig, getJobHistory, getBatchStatus } from "../../../api/InventoryReportServices";


const CartManagementContainer = () => {
  const [cartSettings, setCartSettings] = useState({
    maxAllowedCartAmount: "",
    maxAllowedItemsCount: "",
    cartValidity: "",
    anonymousCartValidity: "",
    maxAllowedItemsQuantity: "",
    maxAllowedPromotionAppliedItemsCount: "",
    maxAllowedQuantityPerOrder: "",
    maxAllowedQuantityPerProduct: "",
    minAllowedCartAmount: "",
    notificationEnabled: false,
    itemLevelRevival: false,
    orderLevelRevival: false,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [abandonCartOpen, setAdandonCartOpen] = useState(false);
  const [allowMinCartAmount, setAllowMinCartAmount] = useState(false);
  const [allowMaximumItemsCount, setAllowMaximumItemsCount] = useState(false);
  const [itemQuantityRestricted, setItemQuantityRestricted] = useState(false);
  const [loadingFlags, setLoadingFlags] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  const [modalAttibutes, setModalAttributes] = useState({
    isOpen: false,
    content: "",
    header: "",
    failedSKUs: [],
  });
  const [schedulerDetails, setSchedulerDetails] = useState({
    criteria: {},
    type: "cartSKUQuantityLimitExportJob",
  });

  const [jobHistoryReqData, setJobHistoryReqData] = useState({
    page: 0,
    size: 1,
    status: null,
    jobConfigId: null,
    productId: "",
    jobConfigType: null,
    searchTerm: "cartSKUQuantityLimitExportJob",
    startDate: null,
    endDate: null,
  });
  const [jobHistoryData, setJobHistoryData] = useState([]);

  const jobHistoryServiceCall = (reqObj) => {
    getJobHistory(reqObj).then(async (response) => {
      if (response && response.data) {
        const { data } = response;
        if (data && data.content) {
          setJobHistoryData(data.content);
          setIsExporting(false);
        }
      }
    });
  };

  const checkDownloadStatus = async () => {
    const response = await getBatchStatus(schedulerDetails.type);
    if (response && response.success) {
      if (response.data == true) {
        setIsExporting(true);
        setTimeout(() => {
          checkDownloadStatus();
        }, 12000);
      } else {
        jobHistoryServiceCall(jobHistoryReqData);
      }
    }
  };

  useEffect(() => {
    checkDownloadStatus();
  }, []);

  const fetchCartSettings = () => {
    getCartSettings().then((response) => {
      if (response && response.success === true) {
        const { data } = response;
        setAllowMaximumItemsCount(data.allowMaximumItemsCount);
        setAllowMinCartAmount(data.allowMinCartAmount);
        setItemQuantityRestricted(data.itemQuantityRestricted);
        setCartSettings(data);
      }
    });
  };
  useState(() => {
    fetchCartSettings();
  }, []);

  /**
   * This method is used to change form state
   * @param {String} name
   * @param {String} value
   */
  const handleChange = (name, value) => {
    setCartSettings({
      ...cartSettings,
      [name]: value,
    });
  };

  /**
   * This method is used to change form filed input
   * @param {Event} event
   */
  const handleFormChange = (event) => {
    handleChange(event.target.name, event.target.value);
  };


  const saveCartSettings = async () => {
    const requestBody = {
      maxAllowedCartAmount: cartSettings.maxAllowedCartAmount,
      minAllowedCartAmount: cartSettings.minAllowedCartAmount,
      maxAllowedItemsCount: cartSettings.maxAllowedItemsCount,
      cartValidity: cartSettings.cartValidity,
      anonymousCartValidity: cartSettings.anonymousCartValidity,
      maxAllowedItemsQuantity: cartSettings.maxAllowedItemsQuantity,
      maxAllowedPromotionAppliedItemsCount: cartSettings.maxAllowedPromotionAppliedItemsCount,
      maxAllowedQuantityPerOrder: cartSettings.maxAllowedQuantityPerOrder,
      maxAllowedQuantityPerProduct: cartSettings.maxAllowedQuantityPerProduct,
      notificationEnabled: cartSettings.notificationEnabled,
      itemLevelRevival: cartSettings.itemLevelRevival,
      orderLevelRevival: cartSettings.orderLevelRevival,
      itemQuantityRestricted,
      allowMinCartAmount,
      allowMaximumItemsCount,
    };
    const response = await updateCartSettings(requestBody);
    if (response && response.success) {
      const { data } = response;
      setCartSettings(data);
      setIsOpen(false);
      setAdandonCartOpen(false);
    }
  };

  const [previewSiteUrl, setPreviewSiteUrl] = useState("");
  const [previewEnabled, setPreviewEnabled] = useState(false);
  const [pushToLiveEnabled, setPushToLiveEnabled] = useState(false);
  const [pushToLiveButtonEnabled, setPushToLiveButtonEnabled] = useState(false);
  const [showPushToLiveAlert, setShowPushToLiveAlert] = useState(false);
  const [message, setMessage] = useState({
    type: null,
    message: "",
  });
  const handleAllowChangeMaxItemCount = () => {
    setAllowMaximumItemsCount(!allowMaximumItemsCount);
  };

  const handleItemQuantityRestricted = () => {
    setItemQuantityRestricted(!itemQuantityRestricted);
  };

  const setAlert = (alertData) => {
    setMessage(alertData);
    setTimeout(() => {
      setMessage({ type: null, message: "" });
    }, 3000);
  };

  useEffect(() => {
    getSitePreviewConfigurations().then((response) => {
      if (response && response.success && response.data) {
        const { data } = response;
        if (data) {
          setPreviewEnabled(data.previewEnabled || false);
          setPushToLiveEnabled(data.pushToLiveEnabled || false);
          const { previewSiteUrl: localPreviewSiteUrl } = data;
          setPreviewSiteUrl(localPreviewSiteUrl);
        }
      }
    });
  }, []);

  const handlePushToLive = async () => {
    replicateGlobalSettings().then((response) => {
      if (response && response.success) {
        const alertData = {
          type: "success",
          message:
            "Global settings locations pushed to live successfully, there may be a small delay for reflecting the same in live.",
        };
        setAlert(alertData);
      } else {
        const alertData = {
          type: "danger",
          message: "Something went wrong. Push to live failed",
        };
        setAlert(alertData);
      }
    });
    return null;
  };

  const getReplicationStatus = async () => {
    getGlobalSettingsReplicationStatus().then((response) => {
      if (response
        && response.success
        && ((response.data && response.data.completed) || (!response.data))) {
        setPushToLiveButtonEnabled(true);
        if (showPushToLiveAlert) {
          const alertData = {
            type: "success",
            message: "The last global settings push to live is successfully completed.",
          };
          setAlert(alertData);
        }
        setShowPushToLiveAlert(true);
      } else {
        setPushToLiveButtonEnabled(false);
        if (showPushToLiveAlert) {
          const alertData = {
            type: "warning",
            message: "Please wait some more time to reflect the changes in live.",
          };
          setAlert(alertData);
        }
        setShowPushToLiveAlert(true);
      }
    }).catch(error => {
      setPushToLiveButtonEnabled(false);
      setShowPushToLiveAlert(true);
    });
    return null;
  };

  const handleAllowMinCartAmount = () => {
    setAllowMinCartAmount(!allowMinCartAmount);
  };

  /**
  * This method is used to update  cart limit in sku
  * @param {String} cartLimitUpdateData
  */
  const updateCartLimit = (cartLimitUpdateData) => {
    updateCartLimitByUniqueId(cartLimitUpdateData).then((response) => {
      if (response && response.success === true) {
        setLoadingFlags(false);
        if (response.data != null) {
          const invalidSKUData = response.data.failedSKUs;
          if (Array.isArray(invalidSKUData) && invalidSKUData.length > 0) {
            setModalAttributes({
              isOpen: true,
              content: "Upload Failed for some of the SKUs",
              header: "Error",
              failedSKUs: invalidSKUData,
            });
          }
          else {
            setModalAttributes({
              isOpen: true,
              content: "Cart Limit Updated Successfully",
              header: "Success",
            });
          }
        }
      }
      else {
        setModalAttributes({
          isOpen: true,
          content: "Something Went Wrong",
          header: "Error",
        });
      }
    });
  };


  const handleBulkImport = (e) => {
    if (e && e.target && e.target.files) {
      const file = e.target.files[0];
      const fileName = file.name;
      if (!fileName.endsWith(".xlsx") && !fileName.endsWith(".xls")) {
        setModalAttributes({
          isOpen: true,
          content: "Invalid file type. Please upload a XLSX/XLS file",
          header: "Error",
        });
      } else {
        const { name } = e.target;
        const reader = new FileReader();
        reader.onload = async (f) => {
          const data = new Uint8Array(f.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const sheetNameList = workbook.SheetNames;
          const rawData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetNameList[0]], { raw: true });
          const parsedData = cartUtils.parseDataFromSheet(rawData);
          if (parsedData.isValid) {
            setLoadingFlags(true);
            const sequenceDataFiltered = parsedData.options.map((each) => ({
              uniqueId: each.ProductSKU,
              cartLimit: each.CartLimit,
            }));
            updateCartLimit(sequenceDataFiltered);
          } else {
            setModalAttributes({
              isOpen: true,
              content: parsedData.message || "Something went wrong.Please try again",
              header: "Error",
            });
          }
        };
        reader.readAsArrayBuffer(file);
      }
      e.target.value = null;
    }
  };

  const downloadBulkImportTemplate = (name, context) => {
    const fileName = `${name}_template.xlsx`;
    const templateData = cartUtils.getImportSheetTemplate(context);
    const ws = XLSX.utils.json_to_sheet(templateData);
    const wb = XLSX.utils.book_new();
    ws["!cols"] = [
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
    ];
    XLSX.utils.book_append_sheet(wb, ws, name);
    XLSX.writeFile(wb, fileName);
  };

  const exportCartSkuQuantityLimit = async (event) => {
    const response = await updateBatchConfig(schedulerDetails);
    if (response && response.data == true && response.success) {
      setIsExporting(response.data);
      checkDownloadStatus();
    }
  };

  return (
    <>
      <CartManagement
        cartSettings={cartSettings}
        handleFormChange={handleFormChange}
        saveCartSettings={saveCartSettings}
        fetchCartSettings={fetchCartSettings}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        abandonCartOpen={abandonCartOpen}
        setAdandonCartOpen={setAdandonCartOpen}
        handleChange={handleChange}
        pushToLiveEnabled={false}
        previewSiteUrl={previewSiteUrl}
        previewEnabled={false}
        message={message}
        handlePushToLive={handlePushToLive}
        pushToLiveButtonEnabled={pushToLiveButtonEnabled}
        getReplicationStatus={getReplicationStatus}
        setAllowChangeMaxItemsCount={handleAllowChangeMaxItemCount}
        allowMaximumItemsCount={allowMaximumItemsCount}
        allowMinCartAmount={allowMinCartAmount}
        setAllowMinCartAmount={handleAllowMinCartAmount}
        itemQuantityRestricted={itemQuantityRestricted}
        setItemQuantityRestricted={handleItemQuantityRestricted}
        handleBulkImport={handleBulkImport}
        downloadBulkImportTemplate={downloadBulkImportTemplate}
        exportCartSkuQuantityLimit={exportCartSkuQuantityLimit}
        loadingFlags={loadingFlags}
        jobHistoryData={jobHistoryData}
        isExporting={isExporting}
      />
      <CartLimitUploadStatusModal
        isOpen={modalAttibutes.isOpen}
        content={modalAttibutes.content}
        header={modalAttibutes.header}
        failedSKUs={modalAttibutes.failedSKUs}
        togglClose={() => setModalAttributes({
          isOpen: false,
          content: "",
          header: "",
          failedSKUs: [],
        })}
      />
    </>
  );
};

export default CartManagementContainer;
