/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Roshna Accu
 */

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import DropdownToggle from "../../../common/core/DropdownToggle";
import DropdownMenu from "../../../common/core/DropdownMenu";
import Dropdown from "../../../common/core/Dropdown";
import styles from "../css/Locale.module.css";
import languageIcon from "../../../common/assets/images/svg/language.svg";

const LocaleDropdown = ({
  locales, locale, dropdownLanguage, toggleDropdownLanguage, handleLanguageSelect,
}) => {
  const { t } = useTranslation(["common"]);

  return (Array.isArray(locales) && locales.length > 0
    && (
      <Dropdown
        id="locale-selector"
        isOpen={dropdownLanguage}
        toggle={toggleDropdownLanguage}
        className={clsx(styles.dropdownButtonWrapper)}
      >
        <DropdownToggle caret>
          <span
            className={
              clsx(styles.selectedFilter, "d-flex align-items-center")
            }
          >
            {t(`common:label_${locale}`)}
          </span>
        </DropdownToggle>
        <div
          role="presentation"
          className={clsx(styles.dropdownClear, "cursor-pointer d-flex align-items-center")}
          onClick={toggleDropdownLanguage}
        >
          <img src={languageIcon} alt="View" width="20px" />
        </div>
        <DropdownMenu className={styles.dropdownWrapper}>
          {locales.map((localeIn) => (
            <div
              key={`table-view-tree-list${localeIn}`}
              onKeyPress={() => { }}
              role="button"
              tabIndex="0"
              onClick={() => handleLanguageSelect(localeIn)}
              className={clsx(styles.dropdownItem, "d-flex align-items-center commonPointer")}
            >
              {t(`common:label_${localeIn}`)}
            </div>
          ))}
        </DropdownMenu>
      </Dropdown>
    )

  );
};
LocaleDropdown.propTypes = {
  locale: PropTypes.string.isRequired,
  locales: PropTypes.objectOf(PropTypes.any).isRequired,
  toggleDropdownLanguage: PropTypes.func.isRequired,
  handleLanguageSelect: PropTypes.func.isRequired,
  dropdownLanguage: PropTypes.bool.isRequired,
};

export default LocaleDropdown;
