/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * WYSIWYG EDITOR
 *
 * @author Naseef O
 *
 */

import React from "react";
import PropTypes from "prop-types";

/** ========= SUN-EDITOR COMPONENTS AND CSS ========= */
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

const WYSIWYG = ({ handleChange, content, disabled, isEnableCodeView }) => (
  <div className="text-editor">
    <SunEditor
      height="300"
      onChange={handleChange}
      setContents={content && content}
      placeholder="Please type here..."
      setOptions={{
        height: 200,
        buttonList: [
          ["undo", "redo"],
          ["font", "fontSize", "formatBlock", "paragraphStyle"],
          ["blockquote", "bold", "underline", "italic", "strike"],
          ["subscript", "superscript"],
          ["fontColor", "hiliteColor", "removeFormat"],
          ["outdent", "indent", "align"],
          ["horizontalRule", "list", "lineHeight"],
          ["table"],
          ["link"],
          // ["image"], TODO --> azure image uploading
          isEnableCodeView
            ? ["fullScreen", "codeView", "preview"]
            : ["fullScreen", "preview"],
        ],
      }}
      disable={disabled}
    />
  </div>
);

WYSIWYG.propTypes = {
  handleChange: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  isEnableCodeView: PropTypes.bool.isRequired,
};
export default WYSIWYG;
