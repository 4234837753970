/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Angela
 *
 */


import httpApi from "./httpApi";

/**
 * This method is used to delete all cache
 * @return {Object} response
 */
const deleteAllCache = async () => {
  const response = await httpApi.delete("/v1/cache/all");
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to delete cache
 * @param {String} url
 * @return {Object} response
 */
const deleteCacheByUrl = async (url) => {
  const response = await httpApi.delete(`/v1/cache${url}`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to get cache settings.
 * @return {Object} response
 */
const getCacheSettings = async () => {
  const response = await httpApi.get("/v1/cache/settings");
  if (response && response.data) {
    return response.data;
  }
  return null;
};

export {
  deleteAllCache,
  getCacheSettings,
  deleteCacheByUrl,
};
