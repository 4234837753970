/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Sreelekshmi Prasannan
 * */

import httpApi from "./httpApi";

/**
 * This method is used  to get list of  coupon codes
 * */

const getCoupons = async (searchTerm, page, size, sortBy, sortDirection) => {
  const response = await httpApi.get("v1/coupons", {
    params: {
      searchTerm, page, size, sortBy, sortDirection,
    },
  });
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to crate new coupon
 * @param {Object} data
 * @returns {Object}
 */
const addCoupon = async (data) => {
  const response = await httpApi.post("v1/coupons", data);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to get coupons by Id
 * @param {String}
 * @returns {Object}
 */
const getCoupon = async (couponId) => {
  const response = await httpApi.get(`v1/coupons/id/${couponId}`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to update coupon details
 * @param {Object} data
 */
const updateCoupon = async (data) => {
  const response = await httpApi.patch("v1/coupons", data);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

/**
 * This method is used to change status a coupon
 * @param {String} id
 */
const setStatus = async (id) => {
  const response = await httpApi.patch(`v1/coupons/${id}/status`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};
/**
 * This method is used to delete a coupon
 * @param {String} couponId
 */
const deleteCoupon = async (couponId) => {
  const response = await httpApi.delete(`v1/coupons/id/${couponId}`);
  if (response && response.data) {
    return response.data;
  }
  return null;
};

const transformCouponCodes = async (codes) => {
  const filtered = codes.map((
    couponCode,
  ) => ({
    "Coupon codes": couponCode,
  }));
  return filtered;
};

export {
  getCoupons, addCoupon, getCoupon, updateCoupon, deleteCoupon, setStatus, transformCouponCodes,
};
