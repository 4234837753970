// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Copyright(c) 2021 Mozanta Technologies Private Ltd.\n *\n * All rights reserved.\n *\n * This software is the confidential and proprietary information of Mozanta (\"Confidential\n * Information\"). You shall not disclose such Confidential Information and shall use it only in\n * accordance with the terms of the contract agreement you entered into with Mozanta.\n *\n * @author Mohammed Shefeeq\n */\n .JobScheduler_borderRadius2__3_N5M {\n    border-radius: 0.125rem !important;\n  }\n  \n  /** =========== ACTION AREA STYLES ============== **/\n  .JobScheduler_areaActionRight__3gZiZ {\n    display: flex;\n    justify-content: flex-end;\n  }\n  \n  .JobScheduler_secondaryColor__m3wln,\n  .JobScheduler_secondaryColor__m3wln:focus,\n  .JobScheduler_secondaryColor__m3wln:active {\n    background-color: #757575 !important;\n    border-color: #757575 !important;\n  }\n  \n  .JobScheduler_tableWrapper__1JL3-{\n    min-height: calc(100vh - 365px);\n  }", ""]);
// Exports
exports.locals = {
	"borderRadius2": "JobScheduler_borderRadius2__3_N5M",
	"areaActionRight": "JobScheduler_areaActionRight__3gZiZ",
	"secondaryColor": "JobScheduler_secondaryColor__m3wln",
	"tableWrapper": "JobScheduler_tableWrapper__1JL3-"
};
module.exports = exports;
